var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar",
      class:
        _vm.settings.sideTheme === "theme1" ||
        _vm.settings.sideTheme === "theme4"
          ? "theme-dark"
          : "theme-light",
    },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "el-scrollbar-bg",
          style: {
            backgroundColor:
              _vm.settings.sideTheme === "theme1" ||
              _vm.settings.sideTheme === "theme4"
                ? _vm.variables.menuBg
                : _vm.variables.menuLightBg,
          },
          attrs: { "view-class": "scrollbar-wrapper", native: true },
        },
        [
          _c(
            "div",
            { staticClass: "main-menu" },
            _vm._l(_vm.sidebar, function (route, index) {
              return _c("sidebar-item", {
                key: route.path + index,
                attrs: { item: route, "base-path": route.path },
                on: {
                  overChildMenu: _vm.showChildMenu,
                  outChildMenu: function ($event) {
                    _vm.showChild = false
                  },
                  handleClick: _vm.getActiveRouter,
                },
              })
            }),
            1
          ),
        ]
      ),
      _vm.showChild
        ? _c(
            "div",
            {
              staticClass: "child-menu child-menu-top",
              on: {
                mouseenter: _vm.mouseenterChildMenu,
                mouseleave: _vm.mouseleaveChildMenu,
              },
            },
            [
              _c(
                "el-scrollbar",
                { attrs: { "view-class": "scrollbar-wrapper", native: true } },
                [
                  _c("child-menu", {
                    attrs: {
                      list: _vm.childMenu,
                      title: _vm.mainTitle,
                      "base-path": _vm.mainPath,
                    },
                    on: { handleClick: _vm.getActiveRouter },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "child-menu",
          class: { hide: _vm.isHide || !_vm.activeRouter.length },
        },
        [
          _c(
            "el-scrollbar",
            { attrs: { "view-class": "scrollbar-wrapper", native: true } },
            [
              _c("child-menu", {
                attrs: {
                  list: _vm.activeRouter,
                  title: _vm.activeTitle,
                  "base-path": _vm.activeBasePath,
                },
              }),
            ],
            1
          ),
          !_vm.isHide
            ? _c("img", {
                staticClass: "narrow",
                attrs: { src: _vm.narrow, alt: "" },
                on: { click: _vm.handleSwitch },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isHide && _vm.activeRouter.length
        ? _c("img", {
            staticClass: "expand",
            attrs: { src: _vm.expand, alt: "" },
            on: { click: _vm.handleSwitch },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }