import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

import ElementLocale from 'element-ui/lib/locale'
import ElementenLocale from 'element-ui/lib/locale/lang/en'
import ElementzhLocale from 'element-ui/lib/locale/lang/zh-CN'

import enLocale from '@/lang/en'
import zhLocale from '@/lang/zh'

// 行业应用翻译文案
import { appZhLocale } from '@/app/lang/zh'
import { appEnLocale } from '@/app/lang/en'
const appZh = { app: { ...appZhLocale } }
const appEn = { app: { ...appEnLocale } }

Vue.use(VueI18n)

// 语言环境信息
const messages = {
  'zh-CN': { ...zhLocale, ...ElementzhLocale, ...appZh },
  'en-US': { ...enLocale, ...ElementenLocale, ...appEn }
}

// 获取语言环境并保留状态
export function getLanguage () {
  const cookieLanguage = Cookies.get('lang')
  if (cookieLanguage) return cookieLanguage
  if (process.env.VUE_APP_ENV_TYPE === 'CHINA') {
    Cookies.set('lang', 'zh-CN')
    return 'zh-CN'
  } else {
    Cookies.set('lang', 'en-US')
    return 'en-US'
  }
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
