/**
 * 确认框全局封装
 *
 * options参数
 *   title：标题
 *   content：内容
 *   type：提示框类型
 *   html：html自定义
 *
 *   del: 删除的样式 （Bool）
 *   alert：alert弹窗（Bool）
 */
import i18n from '@/lang'
import { MessageBox } from 'element-ui'

export function confirmBox (options, cb, reject) {
  const iconList = [
    { type: 'info', icon: 'iconnew_news' }, // 消息
    { type: 'success', icon: 'iconsuccess' }, // 成功
    { type: 'warning', icon: 'iconwarning' }, // 警告
    { type: 'error', icon: 'iconunable' } // 错误
  ]
  let title = options.title || i18n.t('common.warn')// 标题默认 警告
  let getIncon = options.type ? iconList.find(item => item.type === options.type).icon : 'iconwarning'// 默认警告icon
  let confirmText = options.del ? i18n.t('common.delete') : options.confirmButton || i18n.t('common.confirmButton')// 确认按钮文字
  let content = options.html || options.content
  let boxType = options.alert ? MessageBox.alert : MessageBox.confirm// 【目前仅支持 this.$alert、this.$confirm（默认）】

  boxType(content, title, {
    confirmButtonText: confirmText,
    cancelButtonText: options.cancelButton || i18n.t('common.cancel'),
    confirmButtonClass: options.del ? 'del' : '',
    iconClass: `iconfont ${getIncon}`,
    customClass: 'q-confirm',
    type: 'warning',
    dangerouslyUseHTMLString: true
  })
    .then((res) => {
      if (typeof cb === 'function') {
        cb()
      }
    })
    .catch(() => {
      if (reject) {
        reject()
      }
    })
}
