var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showApp
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _vm.isRouterAlive
            ? _c("router-view", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: !_vm.isRouterAlive,
                    expression: "!isRouterAlive",
                  },
                ],
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }