import request from '@/utils/request'

// API路由枚举
export const ApiRouter = {
  RENT: '/rent',
  HOUSEHOLD: '/household',
  PAYMENT: '/payment',
  CHARGEPILE: '/chargepile',
  CHARGEPILE_EV: '/chargepileev'
}

// 获取路由
export const getRouters = (query) => {
  return request({
    url: '/sysuser/menu/getRouters',
    method: 'get',
    params: query
  })
}
