var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            size: "1000px",
            top: "50px",
            "append-to-body": true,
            "modal-append-to-body": false,
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "before-close": _vm.closeDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("Table", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.taskRunData,
              columns: _vm.columns,
              "has-selection": true,
              "action-min-width": 200,
            },
            scopedSlots: _vm._u([
              {
                key: "batchTaskNo",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(row.batchTaskNo))]
                    ),
                  ]
                },
              },
              {
                key: "taskType",
                fn: function ({ row }) {
                  return [
                    row.taskType === 1
                      ? _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("task.table.taskTypeOptions.1")
                            ),
                          },
                        })
                      : row.taskType === 2
                      ? _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("task.table.taskTypeOptions.2")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "tsCreateTime",
                fn: function (scope) {
                  return [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          scope.row.tsFinishTime
                            ? parseInt(
                                (scope.row.tsFinishTime -
                                  scope.row.tsCreateTime) /
                                  1000
                              ) + "s"
                            : parseInt(
                                (Date.parse(new Date()) -
                                  scope.row.tsCreateTime) /
                                  1000
                              ) + "s"
                        ),
                      },
                    }),
                  ]
                },
              },
              {
                key: "Progress",
                fn: function (scope) {
                  return [
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        "stroke-width": 20,
                        percentage: Number(
                          (
                            ((scope.row.successNum + scope.row.failNum) * 100) /
                            scope.row.totalNum
                          ).toFixed(3)
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.taskDetailVisible
        ? _c("TaskDetail", {
            ref: "addDialog",
            attrs: {
              visible: _vm.taskDetailVisible,
              title: _vm.$t("common.seeDetails"),
              "parent-form": _vm.form,
            },
            on: {
              "update:visible": function ($event) {
                _vm.taskDetailVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }