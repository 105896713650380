const state = {
  chargeDatavCurrentOrg: null
}

const mutations = {
  CHANGE_ORG: (state, currentSelectOrg) => {
    state.chargeDatavCurrentOrg = currentSelectOrg
  }
}

const actions = {
  chargeDatavCurrentOrgChange ({ commit }, currentSelectOrg) {
    commit('CHANGE_ORG', currentSelectOrg)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
