var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-input-number",
    _vm._g(
      _vm._b(
        {
          attrs: { max: _vm.max, min: _vm.min, disabled: _vm.disabled },
          on: { change: _vm.onChange, blur: _vm.onBlur },
          model: {
            value: _vm.inputNumber,
            callback: function ($$v) {
              _vm.inputNumber = $$v
            },
            expression: "inputNumber",
          },
        },
        "el-input-number",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }