/**
 * element-ui重置操作处理，主要用于一些问题修复补丁
 * @param {Element} Element element-ui对象
 */
export default function elementReset (Element) {
  // Input组件输入内容过滤器
  Element.Input.mixins.push({
    props: {
      /** 去除首尾空格 */
      trim: {
        type: Boolean,
        default: true
      },
      /** 是否可输入emoji表情 */
      emoji: {
        type: Boolean,
        default: false
      }
    },
    created () {
      this.$on('blur', this.inputFiler)
    },
    methods: {
      // 输入内容过滤器
      inputFiler () {
        if (this.value) {
          let res = this.value
          // 去除表情(正则需要及时更新，防止新emoji表情遗漏, 以下数组是历史版本)
          const emojiReg = [
            /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]/g,
            // eslint-disable-next-line no-misleading-character-class
            /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
          ]
          if (this.emoji === false) res = res.replace(emojiReg[1], '')
          // 去除首尾空格
          if (this.trim) res = res.trim()
          // 通知父组件更新value
          this.$nextTick(() => this.$emit('input', res))
        }
      }
    }
  })
}
