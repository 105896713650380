var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: (_vm.classObj, { gdprOpen: _vm.gdpr }),
    },
    [
      _vm.device === "mobile"
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _c("sidebar"),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { pcOpenSidebar: _vm.isActive && _vm.sideStyle === "menu1" },
          style: {
            marginLeft:
              _vm.sideStyle === "menu1"
                ? _vm.variables.sideBarWidth
                : _vm.variables.twoSideBarWidth,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "fixed-header",
              style: {
                width:
                  _vm.sideStyle === "menu1"
                    ? `calc(100% - ${_vm.variables.sideBarWidth})`
                    : `calc(100% - ${_vm.variables.twoSideBarWidth})`,
              },
            },
            [_c("navbar"), _c("Alert")],
            1
          ),
          _c("app-main"),
          _c("right-panel", [_c("settings")], 1),
        ],
        1
      ),
      _vm.gdpr
        ? _c("div", { staticClass: "gdpr-mask" }, [
            _c("div", { staticClass: "gdpr" }, [
              _c("div", { staticClass: "gdpr-header clearfix" }, [
                _c("h1", { staticClass: "gdpr-header-title fl" }, [
                  _vm._v(" " + _vm._s(_vm.$t("gdpr.title")) + " "),
                ]),
                _c("img", {
                  staticClass: "gdpr-header-logo fr",
                  attrs: { src: _vm.quectelLogo, alt: "" },
                }),
              ]),
              _c("p", { staticClass: "gdpr-version" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("gdpr.time1")))]),
                _c("span", [_vm._v(_vm._s(_vm.$t("gdpr.time2")))]),
              ]),
              _c("div", { staticClass: "gdpr-tab" }, [
                _c("div", [
                  _c(
                    "span",
                    {
                      class: { active: _vm.currentType === "privacy" },
                      on: {
                        click: function ($event) {
                          _vm.currentType = "privacy"
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("gdpr.item1")))]
                  ),
                  _c(
                    "span",
                    {
                      class: { active: _vm.currentType === "service" },
                      on: {
                        click: function ($event) {
                          _vm.currentType = "service"
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("gdpr.item2")))]
                  ),
                ]),
              ]),
              _vm.lang === "zh-CN"
                ? _c("div", { staticClass: "gdpr-container" }, [
                    _vm.currentType === "privacy"
                      ? _c("div", [
                          _c("p", [
                            _vm._v(
                              " 本隐私协议适用于移远（以下简称“移远”、“我们”）提供的所有产品和服务，规定了移远收集哪些数据、为什么收集这些数据、如何利用这些数据做什么以及如何保护这些数据。您的信任对我们至关重要，我们深知个人信息对您的重要性。我们将按照法律法规要求，在合理、必要的范围内，向您收集个人信息。我们竭尽全力通过合理有效的信息安全技术和管理流程，采取相应的安全保护措施，防止您的信息泄露、损毁或丢失。 "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [_vm._v("定义：")]),
                          _c("p", [
                            _vm._v(
                              " 1.个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。 "
                            ),
                          ]),
                          _vm._m(0),
                          _c("p", [
                            _vm._v(
                              " 3.个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。 "
                            ),
                          ]),
                          _vm._m(1),
                          _c("br"),
                          _c("b", [
                            _vm._v(
                              " 请注意，本协议不适用于第三方向您提供的服务，例如通过阿里云短信服务、微信支付服务，您向该第三方服务商提供的个人信息不适用本协议。 "
                            ),
                          ]),
                          _c("br"),
                          _c("br"),
                          _c("b", [
                            _vm._v(
                              " 在使用移远平台各项产品或服务前，请您务必仔细阅读并透彻理解本协议，特别是加粗、加下划线标识的条款，您应当重点阅读，在确认充分理解并同意后再开始使用。当您访问或使用移远平台，即表示您已经同意本隐私协议并信赖我们对您的信息的处理方式。如对本协议有任何疑问，您可向移远客服咨询（400-960-7678）或通过移远任一平台上提供的联系方式与我们联系。如您为未满18周岁的未成年人 ，请在法定监护人陪同下阅读本协议，并特别注意未成年人使用条款。 "
                            ),
                          ]),
                          _c("br"),
                          _c("br"),
                          _c("b", [_vm._v(" 本协议包含以下内容： ")]),
                          _c("p", [_vm._v("1、我们收集您哪些信息。")]),
                          _c("p", [_vm._v("2、我们如何使用您的信息。")]),
                          _c("p", [
                            _vm._v("3、我们在何种情况下共享您的信息。"),
                          ]),
                          _c("p", [_vm._v("4、我们如何存储您的信息。")]),
                          _c("p", [_vm._v("5、我们如何保护您的信息。")]),
                          _c("p", [_vm._v("6、您所享有的权利。")]),
                          _c("p", [_vm._v("7、个人信息主体注销帐号。")]),
                          _c("p", [_vm._v("8、未成年人信息如何保护。")]),
                          _c("p", [_vm._v("9、您如何联系我们。")]),
                          _c("br"),
                          _c("b", [_vm._v("1、我们收集您哪些信息")]),
                          _c("p", [
                            _vm._v(
                              " 我们收集您的信息以合法、合理、必要为原则，在向您提供某项具体服务时，我们将明确告知您将收集的信息以及用途，来响应您具体的服务请求。根据您选择的服务，我们在以下场景可能收集以下信息的一种或多种： "
                            ),
                          ]),
                          _vm._m(2),
                          _vm._m(3),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 1.3、为提高您使用移远平台服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或移远平台相关协议规则的情况，我们可能使用或整合您的帐号信息、设备交互信息、网络日志信息以及我们取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取 必要的记录、审计、分析、处置措施。 "
                            ),
                          ]),
                          _vm._m(4),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 若我们将信息用于本协议未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。 "
                            ),
                          ]),
                          _vm._m(5),
                          _vm._m(6),
                          _vm._m(7),
                          _vm._m(8),
                          _vm._m(9),
                          _vm._m(10),
                          _vm._m(11),
                          _vm._m(12),
                          _vm._m(13),
                          _vm._m(14),
                          _vm._m(15),
                          _vm._m(16),
                          _c("br"),
                          _c("b", [_vm._v("2、我们如何使用您的信息")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 2.1、我们会保持您的个人数据及时更新；安全地存储或销毁它；最小化收集和保留我们需要为您提供服务所需的数据；保护个人信息免遭泄露、滥用、未经授权的访问和披露；采取适当的技术措施保护您个人信息的安全。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("2.2、我们将您的信息用于以下用途："),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "2.2.1、根据我们与您签订的合同向您提供服务（账号登录、消息通知等）。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "2.2.2、按照我们收集信息时所述的要求提供其他您所要求的服务。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "2.2.3、为了内部管理和后台支持，与全球各地公司的分支机构分享您的联系方式。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "2.2.4、维持我们储存和处理您个人信息的数据技术系统的完整性和安全性。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "2.2.5、执行或保护我们的协议或与您签订的合同。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "2.2.6、检测和调查信息泄露、非法活动和欺诈行为。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("2.2.7、联系您解决问题。"),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("2.2.8、经过您许可的其它用途。"),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 2.3、需要注意的是，以上使用方式涵盖了我们使用您信息的主要方式。我们可能不定期通过更新网站页面方式，为用户提供更加多样化的服务。当我们要将信息用于本协议未载明的其他用途时，或要将基于特定目的收集而来的信息用于其他目的时，会以签署协议、弹窗提示、更新本协议等形式再次征求您的同意。 "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [
                            _vm._v(" 3、我们在何种情况下共享您的信息 "),
                          ]),
                          _c("b", [
                            _vm._v(
                              " 我们不会与移远以外的公司、组织和个人共享您的个人信息，但以下情况除外： "
                            ),
                          ]),
                          _vm._m(17),
                          _vm._m(18),
                          _vm._m(19),
                          _vm._m(20),
                          _vm._m(21),
                          _vm._m(22),
                          _vm._m(23),
                          _vm._m(24),
                          _vm._m(25),
                          _vm._m(26),
                          _c("b", [
                            _vm._v(
                              " 如您不同意我们按照上述条款向第三方提供您的个人信息，请您立即停止使用我方平台服务。 "
                            ),
                          ]),
                          _c("br"),
                          _c("br"),
                          _c("b", [_vm._v("4、我们如何存储您的信息")]),
                          _c("p", [
                            _vm._v(
                              "除非法律有另外规定，移远平台将按照如下期限保存您的信息："
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 4.1、您在使用移远平台产品或服务期间，我们将持续为您保存，除非您主动删除或注销AEP应用使能平台帐号。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 4.2、当您删除信息或注销移远平台帐号后，移远平台将停止对您个人信息的使用，并在一定期限内及时删除您的个人信息。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 4.3、您的个人信息经过匿名化与脱敏处理后将形成可以使用的流通数据，移远平台对此数据的保存和使用无需另外征得您的同意。 "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [_vm._v("5、我们如何保护您的信息")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 5.1、我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对移远平台各网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 5.2、互联网并非绝对安全的环境，使用移远平台产品或服务时，我们强烈建议您不要使用非移远平台推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 5.3、在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。 "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [_vm._v("6、您所享有的权利")]),
                          _c("p", [_vm._v("您有权利管理您的信息，包括：")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("6.1、访问您的个人信息："),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 6.1.1、帐号信息：如果您希望访问或编辑您的帐户中的个人资料信息、更改您的密码、头像、昵称等，您可以登录您的帐户后通过设置访问您的信息。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 6.1.2、其它个人信息（例如系统登录、消息推送等服务时）：对于您使用我们的产品或服务过程中产生的个人信息，如您需要，可通过第九章的联系方式，与我们取得联系。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("6.2、更正您的个人信息"),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 当您发现我们处理您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过访问个人信息设置页面进行更正或补充说明或者直接联系我们。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("6.3、删除您的个人信息"),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "在下列情况中，您可以联系移远平台客服提出删除个人信息请求："
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "6.3.1、如果我们处理个人信息的行为违反法律法规。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "6.3.2、如果我们收集、使用您的个人信息，却未征得您的明确同意。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "6.3.3、如果我们处理个人信息的行为严重违反了与您的约定。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "6.3.4、如果您不需要使用我们的产品与服务，或您主动注销了移远应用使能平台帐号。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("6.3.5、如果永久不需要我们提供产品或服务。"),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 如我们决定响应您的删除请求，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息。 "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [_vm._v("7、个人信息主体注销帐号")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 7.1、你随时可以注销此前注册的帐号，如您需要，您可以通过第九章的联系方式，与我们取得联系,进行个信息主体注销帐号。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "7.2、在你注销帐号之后，我们将停止为您提供产品或服务，并依据你的要求，删除你的个人信息。"
                            ),
                          ]),
                          _c("br"),
                          _c("b", [_vm._v("8、未成年人信息如何保护")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 8.1、我们的产品和服务主要面向成年人。对于经法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、法定监护人明确同意或者保护未成年人所必要的情况下使用或公开披露合法收集到的未成年人的个人信息。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 8.2、如果我们发现在未事先获得法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如果法定监护人有理由相信未成年人未经他们事先同意而向移远平台提交了个人信息，请联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的移远应用使能平台服务。 "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [_vm._v("9、您如何联系我们")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 9.1、如果您对本隐私协议有任何疑问、意见或建议，或者您想要行使任何权利，或者您有任何要求要与我们讨论，请通过以下方式与我们联系： "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "地址：上海市闵行区田林路1016号科技绿洲3期（B区）5号楼"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("电子邮件：quecteldpo@quectel.com"),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("联系电话：400-960-7678"),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 9.2、为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。正常情况下，我们将在15天内回复。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 9.3、本协议签订地为中华人民共和国上海市闵行区。本协议的生效、履行、解释及争议的解决，您因使用移远平台或服务而产生的或与本协议相关的一切争议、权利主张或其他事项，均适用中华人民共和国法律（港澳台地区除外）。您与移远公司或移远平台发生的一切争议，应友好协商，如协商不成，可选择如下任一途径解决： "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 9.3.1、提交上海仲裁委员会，按照申请仲裁时现行有效的仲裁规则进行仲裁；仲裁裁决为终局裁决，对各方均有约束力； "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("9.3.2、提交协议签订地人民法院诉讼管辖。"),
                          ]),
                          _c("br"),
                          _c("p", [
                            _vm._v(
                              "请您再次确认您已经全部阅读并充分理解上述条款。"
                            ),
                          ]),
                          _vm._m(27),
                        ])
                      : _vm._e(),
                    _vm.currentType === "service"
                      ? _c("div", [
                          _c("b", [_vm._v("1、重要提示")]),
                          _vm._m(28),
                          _vm._m(29),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 1.3、您声明您有充分的权力、能力和权限来同意和履行本条款。如果您代表您的雇主或客户同意本条款，您声明您有充分的法律授权使您的雇主或客户受到本条款的约束。如果您没有充分的法律授权，请您确保由您的雇主或客户的授权人士来同意本条款。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 1.4、如您是未成年用户，请在法定监护人的陪同下仔细阅读本使用协议且在您的法定监护人同意的前提下用我们的产品或服务或向我们提供信息。 "
                            ),
                          ]),
                          _vm._m(30),
                          _c("br"),
                          _c("b", [_vm._v("2、个人信息保护")]),
                          _vm._m(31),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 2.2、当您使用移远产品或服务，即表示您信赖我们对您的信息的处理方式，并已同意移远服务协议及隐私协议等服务政策中关于您的信息收集及使用的条款。 "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [_vm._v("3、服务说明")]),
                          _vm._m(32),
                          _vm._m(33),
                          _vm._m(34),
                          _c("br"),
                          _c("b", [_vm._v("4、授权及许可")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 4.1、移远产品或服务提供的内容是指移远通过移远产品或服务提供的任何内容，不论该内容是否由移远创造或被其他第三方许可使用（以下简称为“相关内容”）。 "
                            ),
                          ]),
                          _vm._m(35),
                          _vm._m(36),
                          _vm._m(37),
                          _vm._m(38),
                          _c("br"),
                          _c("b", [_vm._v("5、使用规则")]),
                          _vm._m(39),
                          _vm._m(40),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 5.2.1、对移远产品或服务进行修改、复制、发布、传输、公开展示、展览、播放、翻制、翻译、发行、出版、授权、从其创建衍生产品、转让、出售、逆向工程、反编译，或试图从移远产品或服务或其任何部分提取源代码或获取原始数据； "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "5.2.2、将移远产品或服务以收费或其他获利方式进行提供，无论是否为直接经济或金钱收益；"
                            ),
                          ]),
                          _vm._m(41),
                          _vm._m(42),
                          _c("br"),
                          _c("b", [_vm._v("6、第三方服务")]),
                          _vm._m(43),
                          _c("br"),
                          _c("b", [_vm._v("7、知识产权")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 7.1、移远拥有移远产品或服务及相关内容的著作权、商标权、专利权等所有法定权利、资格和利益（不论这些权利是否已注册、不论这些权利可能存在于世界何处）。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 7.2、您对本条款的同意和对移远产品或服务的使用，不涉及上述任何知识产权的转让，上述全部知识产权仍归移远或相应权利人所有。未经移远和/或相应权利人事先书面同意，您不能自行实施、利用、转让或许可任何三方实施、利用、转让上述知识产权，您亦不能修改、出租、出借、出售、散布、据以制作衍生作品或以其他方式使用上述知识产权的任何部分或全部。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 7.3、您不得以任何方式删除、掩藏、修改或替换移远产品或服务中所附的或包含的任何专有权利声明（包含但不限于著作权、商标权、专利权、所有权声明等），或移远及其合作伙伴的任何版权标识、商标、服务标记、标识、商号、企业名称、域名、审图号、出版号及其他明显的标志。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 7.4、除非您与移远或相应权利人另行达成书面协议，您不能使用移远及相应权利人的任何标志。本条款没有授予您以任何形式或目的使用移远产品或服务标志的权利，也没有授予您使用任何移远其他标志或任何第三方标志的权利。 "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [_vm._v("8、用户内容")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 8.1、您声明并保证，您对您通过移远产品或服务发布或上传的信息（统称为“用户内容”），拥有相应的、合法的权利、许可或授权。否则，移远可以对您提供的信息依法或依本条款进行删除或屏蔽。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 8.2、对于您通过移远产品或服务提供的用户内容，您同意授予移远一项永久的、全球范围的、免许可费的、可转让的、可分许可的、不可撤销的、非排他性的使用许可。您理解并同意，前述许可在您停止使用移远产品或服务后依然有效。 "
                            ),
                          ]),
                          _vm._m(44),
                          _vm._m(45),
                          _c("br"),
                          _c("b", [_vm._v("9、担保与保证")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 9.1、移远按照适用的法律、法规、规章、规范、政策、强制标准、行业标准等的规定提供移远产品或服务。 "
                            ),
                          ]),
                          _vm._m(46),
                          _vm._m(47),
                          _vm._m(48),
                          _vm._m(49),
                          _vm._m(50),
                          _vm._m(51),
                          _c("br"),
                          _c("b", [_vm._v("10、免责及责任限制")]),
                          _vm._m(52),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 10.2、移远产品或服务及其雇员一概毋须以任何方式就任何信息传递或传送的失误、不准确或错误对用户或任何其他人士负任何直接或间接的责任。 "
                            ),
                          ]),
                          _vm._m(53),
                          _vm._m(54),
                          _c("br"),
                          _c("b", [_vm._v("11、变更、中断或终止")]),
                          _vm._m(55),
                          _c("br"),
                          _c("b", [_vm._v("12、一般条款")]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "12.1、移远公布的《移远隐私协议》为本协议的有效组成部分。"
                            ),
                          ]),
                          _vm._m(56),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 12.3、本协议签订地为中华人民共和国上海市闵行区。本协议的生效、履行、解释及争议的解决，您和移远就移远产品或服务使用、本条款内容或其执行发生相关争议、权利主张或其他事项，均适用中华人民共和国法律（港澳台地区除外）。相关争议或权利主张等事项，应友好协商解决；如协商不成，可选择如下任一途径解决： "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 12.3.1、提交上海仲裁委员会，按照申请仲裁时现行有效的仲裁规则进行仲裁；仲裁裁决为终局裁决，对各方均有约束力； "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v("12.3.2、提交协议签订地人民法院诉讼管辖。"),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "12.4、如果本条款的任何条款与适用的法律法规冲突的，本使用协议其他规定仍应具有完整的效力。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "12.5、本服务协议的标题仅供方便而设，不具任何法律或契约效果。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "12.6、除本条款和法律法规明确规定的之外，本条款未赋予您任何其他权利。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "12.7、为了移远及其继承人和受让人的利益，本条款项下的全部权利和义务可以被移远转让。"
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              " 12.8、本条款的英文翻译版本（如有）仅为您的理解便利而提供，移远和您之间的关系由本条款的中文版确定。如果两个版本存在任何冲突之处，则以中文版本规定为准。 "
                            ),
                          ]),
                          _c("p", { staticClass: "p1" }, [
                            _vm._v(
                              "12.9、您对移远产品或服务或本条款的意见及建议可通过移远公开披露的联系方式进行联系。"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _c("div", { staticClass: "gdpr-container" }, [
                    _vm.currentType === "privacy"
                      ? _c("div", [
                          _c("p", [
                            _vm._v(
                              ' The Privacy Agreement applies to all products and services provided by Quectel Wireless Solutions Co., Ltd (hereinafter referred to as "Quectel" or "we") and specifies what data is collected, why it is collected, how it is used and how it is protected. Your trust is of great importance to us, and we know how important the personal information to you. We will collect your personal information within a reasonable and necessary scope as required by laws and regulations. We make every effort to protect your information from disclosure, destruction or loss through reasonable and effective information security technology and management process as well as appropriate security measures. '
                            ),
                          ]),
                          _c("br"),
                          _c("h1", [_vm._v("Definition")]),
                          _vm._m(57),
                          _vm._m(58),
                          _vm._m(59),
                          _vm._m(60),
                          _c("p", [
                            _vm._v(
                              " Please note that this Agreement does not apply to services provided to you by third parties, such as Alibaba Cloud SMS Services and WeChat Payment Services, and the personal information you provide to that third party service provider does not apply to this Agreement. "
                            ),
                          ]),
                          _vm._m(61),
                          _c("br"),
                          _vm._m(62),
                          _vm._m(63),
                          _c("br"),
                          _c("h1", [_vm._v("1. What Information We Collect")]),
                          _c("p", [
                            _vm._v(
                              " We collect your information in a lawful, reasonable and necessary manner. When we provide you with a particular service, we will clearly inform you what information we collect and why we collect the information to respond to your specific service request. Depending on the service you choose, we may collect one or more of the following information in the following scenarios: "
                            ),
                          ]),
                          _vm._m(64),
                          _vm._m(65),
                          _vm._m(66),
                          _vm._m(67),
                          _c("p", [
                            _vm._v(
                              " We will obtain your consent in advance if we intend to use the information for other purposes not specified in this statement, or we intend to use the information collected for a specific purpose for other purposes, or we actively obtain your information from third parties. "
                            ),
                          ]),
                          _vm._m(68),
                          _c("br"),
                          _c("h1", [_vm._v("2. How We Use Your Information")]),
                          _vm._m(69),
                          _vm._m(70),
                          _vm._m(71),
                          _c("br"),
                          _c("h1", [
                            _vm._v("3. When We Share Your Information"),
                          ]),
                          _vm._m(72),
                          _vm._m(73),
                          _vm._m(74),
                          _vm._m(75),
                          _vm._m(76),
                          _vm._m(77),
                          _vm._m(78),
                          _c("br"),
                          _c("h1", [
                            _vm._v("4. How We Store Your Information"),
                          ]),
                          _c("p", [
                            _vm._v(
                              " Unless otherwise required by law, Quectel Platform will store your information for the following period of time: "
                            ),
                          ]),
                          _vm._m(79),
                          _vm._m(80),
                          _vm._m(81),
                          _c("br"),
                          _c("h1", [
                            _vm._v("5. How We Protect Your Information"),
                          ]),
                          _vm._m(82),
                          _vm._m(83),
                          _vm._m(84),
                          _c("br"),
                          _c("h1", [_vm._v("6. Your Rights")]),
                          _c("p", [
                            _vm._v(
                              "You have the rights to manage your own information, which include:"
                            ),
                          ]),
                          _vm._m(85),
                          _vm._m(86),
                          _c("p", [
                            _vm._v(
                              " You have the right to request us to correct or supplement your personal information if you find that we have made mistakes in handling your personal information. You can make corrections or supplements by visiting the Personal Information Settings page or contact us directly. "
                            ),
                          ]),
                          _vm._m(87),
                          _c("p", [
                            _vm._v(
                              " If we decide to respond to your removal request, we will stop providing you with products or services and delete your personal information as required by applicable law. "
                            ),
                          ]),
                          _c("br"),
                          _c("h1", [_vm._v("7. Cancel Account")]),
                          _vm._m(88),
                          _vm._m(89),
                          _c("br"),
                          _c("h1", [
                            _vm._v(
                              "8. How We Protect the Information of Minors"
                            ),
                          ]),
                          _vm._m(90),
                          _vm._m(91),
                          _c("br"),
                          _c("h1", [_vm._v("9. Contact Us")]),
                          _vm._m(92),
                          _vm._m(93),
                          _vm._m(94),
                          _vm._m(95),
                          _vm._m(96),
                          _vm._m(97),
                        ])
                      : _vm._e(),
                    _vm.currentType === "service"
                      ? _c("div", [
                          _c("h1", [_vm._v("1. Important Notes")]),
                          _vm._m(98),
                          _vm._m(99),
                          _vm._m(100),
                          _vm._m(101),
                          _vm._m(102),
                          _c("br"),
                          _c("h1", [
                            _vm._v("2. Personal Information Protection"),
                          ]),
                          _vm._m(103),
                          _vm._m(104),
                          _c("br"),
                          _c("h1", [_vm._v("3. Service Note")]),
                          _vm._m(105),
                          _vm._m(106),
                          _vm._m(107),
                          _c("br"),
                          _c("h1", [_vm._v("4. Authorizations and Licenses")]),
                          _vm._m(108),
                          _vm._m(109),
                          _vm._m(110),
                          _vm._m(111),
                          _vm._m(112),
                          _c("br"),
                          _c("h1", [_vm._v("5. Use of the Services")]),
                          _vm._m(113),
                          _vm._m(114),
                          _vm._m(115),
                          _c("br"),
                          _c("h1", [_vm._v("6. Service of Third Parties")]),
                          _c("p", [
                            _vm._v(
                              " Quectel Products or Services may contain services of third parties. Access to or acceptance of services provided by third parties is at your sole discretion. We do not provide any warranty of any kind as to the availability, authenticity, adequacy or reliability of the services provided by third parties. Exercise caution when using third-party services. If you decide to accept the products or services of a third party, you shall bear all the results and risks arising therefrom. "
                            ),
                          ]),
                          _c("br"),
                          _c("h1", [_vm._v("7. Intellectual Property Rights")]),
                          _vm._m(116),
                          _vm._m(117),
                          _vm._m(118),
                          _vm._m(119),
                          _c("br"),
                          _c("h1", [_vm._v("8. User Content")]),
                          _vm._m(120),
                          _vm._m(121),
                          _vm._m(122),
                          _vm._m(123),
                          _c("br"),
                          _c("h1", [_vm._v("9. Warranty")]),
                          _vm._m(124),
                          _vm._m(125),
                          _c("br"),
                          _c("h1", [
                            _vm._v(
                              "10. Disclaimer and Limitation of Liability"
                            ),
                          ]),
                          _vm._m(126),
                          _vm._m(127),
                          _vm._m(128),
                          _vm._m(129),
                          _c("br"),
                          _c("h1", [_vm._v("11. Change, Suspend and Cease")]),
                          _vm._m(130),
                          _c("br"),
                          _c("h1", [_vm._v("12. Miscellaneous Provisions")]),
                          _vm._m(131),
                          _vm._m(132),
                          _vm._m(133),
                          _vm._m(134),
                          _vm._m(135),
                          _vm._m(136),
                          _vm._m(137),
                          _vm._m(138),
                          _vm._m(139),
                        ])
                      : _vm._e(),
                  ]),
              _c(
                "div",
                { staticClass: "gdpr-foot clearfix" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.gdprChecked,
                        callback: function ($$v) {
                          _vm.gdprChecked = $$v
                        },
                        expression: "gdprChecked",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("gdpr.text")))]
                  ),
                  _c(
                    "div",
                    { staticClass: "gdpr-foot-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: !_vm.gdprChecked,
                          },
                          on: { click: _vm.agree },
                        },
                        [_vm._v(_vm._s(_vm.$t("gdpr.agree")))]
                      ),
                      _c("el-button", { on: { click: _vm.refuse } }, [
                        _vm._v(_vm._s(_vm.$t("common.back"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " 2.个人敏感信息：包括身份证件号码、个人生物识别信息、财产信息、行动轨迹、交易信息、14岁以下（含）儿童的个人信息。"
      ),
      _c("b", [_vm._v("敏感信息在本协议中会做加粗加下划线提示。")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" 4.本协议适用于移远平台所有产品和服务。"),
      _c("b", [_vm._v("移远平台")]),
      _vm._v(
        "是指上海移远通信技术股份有限公司及其子公司、关联公司（以下称为“移远公司”）运营的移远官方网站（https://www.quectel.com/）、（https://iot-aep.com/）、（https://iot-cloud.quectel.com/），以及后续由移远公司所有或运营的其他移远相关平台。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 1.1、当您开通账号成功后，在个人资料栏您可以选择性填写您的个人信息，例如"
      ),
      _c("b", [_vm._v("您的昵称、性别、头像、电子邮件、手机号码")]),
      _vm._v(
        "等相关信息。此类信息由您自行维护，在使用AEP应用使能平台相关服务时，可节省填写带来的服务迟延，除必要信息外我们不会强制要求您填写，您可选择性维护，也可以选择实际使用时再填写。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 1.2、 "),
      _c("b", [
        _vm._v(
          " 需要特别注意的是，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 1.4、 "),
      _c("b", [
        _vm._v(
          " 若您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前获得对应儿童监护人的同意，前述情况下的监护人有权通过本协议第9条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 1.5、 "),
      _c("b", [
        _vm._v(
          " 征得授权同意的例外：您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求： "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.1、"),
      _c("b", [_vm._v("与国家安全、国防安全有关的；")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.2、"),
      _c("b", [_vm._v("与公共安全、公共卫生、重大公共利益有关的；")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.3、"),
      _c("b", [
        _vm._v("与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.4、"),
      _c("b", [
        _vm._v(
          "出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.5、"),
      _c("b", [_vm._v("您自行向社会公众公开的个人信息；")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.6、"),
      _c("b", [
        _vm._v(
          "从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.7、"),
      _c("b", [_vm._v("根据与您签订和履行相关协议或其他书面文件所必需的；")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 1.5.8、"),
      _c("b", [
        _vm._v(
          "用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.9、"),
      _c("b", [_vm._v("为合法的新闻报道所必需的；")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 1.5.10、"),
      _c("b", [
        _vm._v(
          "学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("1.5.11、"),
      _c("b", [_vm._v("法律法规规定的其他情形。")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.1、 "),
      _c("b", [
        _vm._v(
          " 在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.2、 "),
      _c("b", [
        _vm._v(
          " 获取明确同意的情况下共享：获得您的明确同意或授权或您主动选择的情况下，我们会与其他方共享您的个人信息。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.3、 "),
      _c("b", [
        _vm._v(
          " 与关联公司间共享：为便于我们向您提供产品和服务，识别帐号异常，保护其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本协议中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.4、 "),
      _c("b", [
        _vm._v(
          " 与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本协议声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。此类处理后数据的使用和共享我们无需另行向您通知并征得您的同意。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _c("b", [_vm._v(" 目前，我们的授权合作伙伴包括以下类型： ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.4.1、 "),
      _c("b", [
        _vm._v(
          " 供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量服务的有效性、提供客户服务、支付便利或进行学术研究和调查。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.5、 "),
      _c("b", [
        _vm._v(
          " 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外： "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.5.1、 "),
      _c("b", [
        _vm._v(
          " 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息； "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.5.2、 "),
      _c("b", [
        _vm._v(
          " 在移远平台服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本协议的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.5.3、 "),
      _c("b", [
        _vm._v(
          " 共享、转让、披露个人信息时的事先征得授权同意的例外，依据相关现行有效的法律法规的要求执行。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p-text-r" }, [
      _vm._v(" 移远"),
      _c("br"),
      _vm._v(" 2021年07月21日版本"),
      _c("br"),
      _vm._v(" 2021年07月21日生效 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 1.1、在使用移远（上海移远股份有限公司及其关联公司）提供任何的软件、网站、服务（统称为“移远产品或服务”）之前， "
      ),
      _c("b", [
        _vm._v(
          " 请您务必认真阅读并充分理解《移远服务协议》、《移远隐私协议》（统称为“移远服务条款”），特别是粗体标注的内容。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 1.2、如果您对本条款有疑问，请您通过移远公开披露的渠道进行询问。 "
      ),
      _c("b", [
        _vm._v(
          " 本协议的条款可由移远随时修改，您理解并同意，修订后的本条款具有溯及力。您一旦开始使用移远产品或服务，即表示您已经理解并同意相关协议，该协议即构成对您具有法律约束力的法律文件。 "
        ),
      ]),
      _vm._v(
        " 当您使用移远某一单项服务时，您应遵守与该服务相关的指引和规则。上述所有的指引和规则，均构成本使用协议的一部分。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 1.5、 "),
      _c("b", [
        _vm._v(
          " 您确认，您不是任何组织、地区或国家的贸易限制、制裁或其他法律、规则限制的对象。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 2.1、个人信息保护是移远坚守的基本原则之一。 "),
      _c("b", [
        _vm._v(
          " 在您使用移远产品或服务的过程中，我们会可能基于您需求的服务，收集和使用您的个人信息，包括您的位置信息、设备信息，部分功能中还会包括您的身份信息、联系方式等信息。 "
        ),
      ]),
      _vm._v(" 移远将根据 "),
      _c("b", [
        _vm._v(
          " 《移远隐私协议》的规定收集、存储、使用和披露您的个人信息。请您认真阅读《移远隐私协议》并在确认同意后使用移远产品或服务。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 3.1、移远产品或服务由移远及其关联公司（本条款中统称为“移远”或“我们”）提供。 "
      ),
      _c("b", [
        _vm._v(
          " 您了解并同意，移远提供的产品与服务仅依其当前所呈现的状况提供，移远明确拒绝对“产品”或“服务”等作出任何明示或暗示的担保或保证，包括但不限于，为商业使用、适合于特定目的或未侵害他人权利之担保或保证等。移远对“产品”或“服务”等所产生的任何直接、间接、附带的或因此而导致的损失概不负责。 "
        ),
      ]),
      _vm._v(
        " 移远有权自行确定移远产品或服务及其功能和服务的具体内容和形式。移远有权自行决定增加、变更、中断和停止移远产品或服务及其功能或服务。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 3.2、为完善移远产品或服务，移远将适时发布移远产品或服务后续版本。 "
      ),
      _c("b", [
        _vm._v(
          " 除非本条款另有明确规定，移远产品或服务的任何升级或更新版本，均受到本条款的规范。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 3.3、新版本移远产品或服务发布后，旧的版本可能无法使用。 "),
      _c("b", [
        _vm._v(" 移远不保证旧版本可继续使用，因此请您及时下载最新的版本。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 4.2、 "),
      _c("b", [
        _vm._v(
          " 您不得（且您不得允许其他人）为任何商业目的使用移远产品或服务，从而直接或间接获得任何形式的收益。如果您有商业运营需要，则您需同移远另行签署书面协议或事先获得移远书面许可。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 4.3、您使用产品或服务，可通过移远授权的第三方下载等方式获取移远产品或服务。 "
      ),
      _c("b", [
        _vm._v(
          " 若您非从移远或移远授权的第三方获取移远产品或服务，移远无法保证该产品正常使用，您遭受的任何损失与移远无关。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 4.4、 "),
      _c("b", [
        _vm._v(
          " 本条款未明示授权的其他一切权利仍归移远所有，并且不能将本条款理解为以默示或其他方式授予您任何其他权利。您使用其他权利时需要同移远另行签署书面协议或事先获得移远的书面许可。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 4.5、尽管移远会持续不断提高服务质量，但由于各种客观或技术等原因，可能导致移远产品或服务有局限性，因此 "
      ),
      _c("b", [
        _vm._v(
          " 移远产品或服务提供的功能和服务仅供您参考，请不要以此作为您判断的唯一依据。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 5.1、 "),
      _c("b", [
        _vm._v(
          " 您在使用移远产品或服务时，应遵守所有适用的法律、法规、规章、规范、政策、行政命令及社会公序良俗（统称为“法律法规”）。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 5.2、 "),
      _c("b", [
        _vm._v(
          " 您仅能按照本条款允许的方式使用移远产品或服务，除非您与移远另行达成书面协议或附加条款中有明确许可，您在使用移远产品或服务过程中，不得实施也不得同意、授权或指示任何第三人从事包括但不限于以下行为： "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("5.2.3、进行其他未经移远"),
      _c("b", [_vm._v("明确许可")]),
      _vm._v("的行为。"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 5.3、 "),
      _c("b", [
        _vm._v(
          " 如果出现您对本条款的违反行为，移远保留针对该违反行为采取法律所能提供的所有补救手段的权利。由于您的任何违法或违反本条款的使用行为而对移远或其他任何人造成任何损失的，移远有权要求您给予相应的赔偿，赔偿范围包括但不限于移远因此支付的罚款、赔偿金、诉讼费、律师费、公证费、调查证据费用等。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 移远产品或服务可能保有第三方服务。是否访问或接受第三方提供服务将由您自主决定， "
      ),
      _c("b", [
        _vm._v(
          " 移远不对第三方服务的可用性、真实性、充分性或可靠性提供任何形式的担保或保证。 "
        ),
      ]),
      _vm._v(" 请您谨慎判断是否使用第三方服务。 "),
      _c("b", [
        _vm._v(
          " 如您决定接受第三方的产品或服务，其可能带来的结果和风险全部由您自行承担。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 8.3、您理解并同意，尽管有上述约定， "),
      _c("b", [
        _vm._v(
          " 您通过移远产品或服务上报、反馈、分享、纠正或以其他方式向移远提交或共享的问题截图、纠错信息、问题反馈、改进建议或意见等内容或类似内容时，前述任何内容的著作权等全部知识产权、所有权及其他现行及未来法律规定的全部法律权利自您提交之时全部自动转让给移远，并且无需移远向您支付任何费用。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 8.4、 "),
      _c("b", [
        _vm._v(
          " 您理解并同意，您应遵守中华人民共和国相关法律法规，并对您通过移远产品或服务提交的任何信息的真实性、准确性、及时性、完整性负责，您保证上述信息不侵犯任何第三方的合法权益或违反任何法律法规，亦不得涉及或鼓励侵权行为或其他非法行为，否则，您自行承担因此产生的责任和后果。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 9.2、 "),
      _c("b", [
        _vm._v(
          " 您理解并同意，移远及其许可人不就以下各项向您提供明示或默示的担保或保证： "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("9.2.1、"),
      _c("b", [_vm._v("移远产品或服务将符合您的实际或特定需求或目的；")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v("9.2.2、"),
      _c("b", [_vm._v("移远产品或服务的及时性、准确性、完整性及精确性；")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 9.2.3、 "),
      _c("b", [
        _vm._v("移远产品或服务将不受干扰、无中断、持续稳定或没有错误；"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 9.2.4、 "),
      _c("b", [
        _vm._v("使用移远产品或服务获得的任何结果或资料将是正确或可靠的；"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 9.2.5、 "),
      _c("b", [
        _vm._v("移远产品或服务中的任何错误、问题、故障都将得到更正或解决；"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 10.1、移远产品或服务可能会受到多种因素影响，包括但不限于您的原因、第三方原因、通信网络服务质量、连接故障、网络设备或系统维护、电脑或通讯或其他系统故障、电力故障、罢工、暴乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令、社会环境及其他不可抗力等。 "
      ),
      _c("b", [
        _vm._v(
          " 除法律法规明确规定要求移远承担责任之外，因本款所述原因给您造成的任何损失由您本人自行承担，移远对此不承担责任。在所有情况下，移远对于任何不能合理预见的损失或损害不承担责任。由于您违反法律法规或本条款使用移远产品或服务所产生的全部责任和后果，由您本人自行承担。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 10.3、 "),
      _c("b", [
        _vm._v(
          " 移远产品或服务所提供的信息，若在任何司法管辖地区供任何人士使用或分发给任何人士时会违反该司法管辖地区的法律或条例的规定或会导致移远产品或服务或其第三方代理人受限于该司法管辖地区内的任何监管规定时，则该等信息不宜在该司法管辖地区供该等任何人士使用或分发给该等任何人士。用户须自行保证不会受限于任何限制或禁止用户使用或分发移远产品或服务所提供信息的当地的规定。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 10.4、 "),
      _c("b", [
        _vm._v(
          " 您了解并同意，您应承担因您使用本服务、违反本服务条款或在您的账户下采取的任何行动而导致的任何第三方索赔。如果由此引起移远及其关联公司、员工、客户和合作伙伴被第三方索赔的，您应负责处理，并赔偿移远及其关联公司由此遭受的全部损失和责任。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(
        " 11.1、移远有权在发出通知或不发出通知的情况下变更、中断或终止提供移远产品或服务，也有权随时修改、中断或终止您对移远产品或服务的使用。 "
      ),
      _c("b", [
        _vm._v(
          " 如果移远永久终止提供移远产品或服务的，移远将会提前在移远产品或服务或相关网站上进行公告，但无需单独通知您。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p1" }, [
      _vm._v(" 12.2、移远产品或服务不会以所有语言或在所有国家提供。 "),
      _c("b", [
        _vm._v(
          " 移远不承诺移远产品或服务对中国以外的其他国家或地区或在任何特定地点均适用或可用；并且禁止在移远产品或服务被认为是非法的地区对其使用。您确认，您遵守本协议所适用的一切出口管制法律法规。您承诺不会将移远的产品或服务用于可适用法律禁止的用途。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Personal information: refers to all kinds of information recorded electronically or in other ways that can identify a specific natural person or reflect the activities of a specific natural person individually or in combination with other information. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Sensitive personal information: refers to a particular category of personal information which includes Identity Card Number, personal biometric information, property information, motion trails, transaction information and personal information of children under the age of 14. "
        ),
        _c("b", [
          _vm._v(
            "Sensitive information will be bolded and underlined in this agreement."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Personal information deletion: refers to the removal of personal information from the system involved in the realization of daily business functions, so that the personal information keeps irretrievable and inaccessible. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " The Privacy Agreement applies to all products and services provided by Quectel platforms. "
        ),
        _c("b", [_vm._v("Quectel Platform")]),
        _vm._v(
          ' refers to the Quectel official website (https://www.quectel.com/), (https://iot-aep.com) and (https://iot-cloud.quectel.com/) and other Quectel-related platforms subsequently owned or operated by Quectel Wireless Solutions Co., Ltd and its subsidiaries and affiliates (hereinafter referred to as "Quectel Company"). '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [
        _vm._v(
          " Please read and understand this Agreement carefully and thoroughly before using the products or services provided by Quectel, especially the bold and underlined terms, which you need read carefully and confirm that you fully understand and agree. By accessing or using Quectel Platform, you agree to this Privacy Agreement and trust us in the way we process your information. If you have any questions about this Agreement, please contact Quectel Customer Service (400-960-7678) or contact us by the ways provided on any platform of Quectel. If you are a minor under the age of 18, please read this Agreement accompanied by a legal guardian and pay special attention to the Terms of Use for minors. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [_vm._v("The Agreement contains the following content:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" 1. What information we collect"),
      _c("br"),
      _vm._v(" 2. How we use your information"),
      _c("br"),
      _vm._v(" 3. When we share your information"),
      _c("br"),
      _vm._v(" 4. How we store your information"),
      _c("br"),
      _vm._v(" 5. How we protect your information"),
      _c("br"),
      _vm._v(" 6. Your rights"),
      _c("br"),
      _vm._v(" 7. Cancel account"),
      _c("br"),
      _vm._v(" 8. How we protect the information of minors"),
      _c("br"),
      _vm._v(" 9. Contact us "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " When your registration is successful, you can selectively fill in your personal information in your profile, such as "
        ),
        _c("b", [
          _vm._v(
            "nickname, gender, profile picture, email, phone number, and other relevant information. "
          ),
        ]),
        _vm._v(
          "And such information is maintained by yourself. When using AEP platform to enable the relevant services, you can save the service delay caused by information filling. They are non-required field information. You can choose to maintain the information or fill it out when you actually use it. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " What calls for special attention is that device information or service log information alone is unable to identify a particular natural person. If we combine such non-personal information with other information to identify a particular natural person or use non-personal information in combination with personal information, such non-personal information will be considered as personal information during the period of use. We will anonymize and de-identify such information, unless otherwise authorized by you or required by law or regulation. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " To improve the security of your use of services on Quectel Platform, protect the personal and property safety of you, other users or the public from infringements, better prevent security risks such as phishing websites, fraud, network vulnerabilities, computer viruses, cyberattacks, network intrusions, and more accurately identify violations of laws and regulations or the rules of the Quectel Platform-related agreements, we may use or integrate your account information, device interaction information, network blog information, and information that you have authorized or shared in accordance with the law to comprehensively determine your account and transaction risks, authenticate identity, detect and prevent security incidents, and take the necessary recording, auditing, analyzing and disposing measures in accordance with the law. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " If the information you provide contains personal information from other users, you need to ensure that you have obtained legal authorization before providing us with such personal information. If any personal information about a child is involved, you must obtain the consent from the child's guardian prior to publication, in which case the guardian has the right to contact us by the ways in Article 9 of this Agreement to request correction or deletion of the content relating to the child's personal information. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " Exception Authorization: you fully understand and agree that we collect and use your personal information without your consent and we may not respond to your requests for correction/modification, deletion, cancellation, withdrawal of consent and request for information under the following circumstances: "
          ),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Directly related to national security and national defense security; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Directly related to public safety, public health and major public interests; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.3")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Directly related to judicial or administrative enforcement, such as crime investigation, prosecution, trial and judgment enforcement; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.4")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " It is difficult to obtain your own consent in order to protect your or other individuals' personal life, property, and other important legitimate rights and interests; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.5")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(" Personal information that you disclose to the public; "),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.6")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Where personal information is collected from legally and publicly disclosed information, such as legal news reports, government information disclosure and other channels; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.7")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Necessary for signing and fulfilling the relevant agreement or other written documents with you; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.8")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Necessary for maintaining the safe and stable operation of the products and/or services provided, such as detecting and handling product or service faults; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.9")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(" Necessary for legal news reports; "),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.10")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Necessary for academic research institutions to conduct statistics or academic research based on the public interest, and the personal information contained in the results is de-identified when the results of academic research or descriptions are released to the public; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5.11")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Other circumstances as prescribed by laws and regulations. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("2.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " We keep your personal data up-to-date, store or destroy it securely, minimize the data that we need to collect and retain to provide you with services, protect personal information from disclosure, misuse, unauthorized access and disclosure, and take appropriate technical measures to protect the security of your personal information. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(" We use your personal information for following purposes: "),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Provide services according to our contract with you (account login, notifications and so on). "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Provide other services that you request as stated when we collect information. "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2.3")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Share your contact information with branches of companies around the world for internal management and back-office support. "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2.4")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Maintain the integrity and security of our technical data system for storing and processing your personal information. "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2.5")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Enforce or protect our agreements or contracts with you. "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2.6")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Detect and investigate the information disclosure, illegal activities and fraud. "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2.7")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(" Contact you to resolve an issue. "),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2.8")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(" Other uses with your content. "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("2.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " It is important to note that the above usage covers the main ways in which we use your information. From time to time, we may provide you with a more diverse range of services by updating website pages. When we intend to use the information for other purposes not specified in this statement or use the information collected for a specific purpose for other purposes, we will ask for your content again by signing an agreement, pop-up prompts, or updating this Agreement and so on. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [
        _vm._v(
          " We do not share your information with any organization, individual or company except Quectel and its affiliates, except as follows: "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("3.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " Circumstances prescribed by laws and regulations: we may share your personal information with the public in accordance with laws and regulations, the need for resolving litigation dispute, or as required by administrative and judicial authorities in accordance with the law. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("3.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " Sharing with explicit consent: we will share your personal information with other parties after obtaining your explicit consent. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("3.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " Sharing with affiliated companies: to facilitate our provision of products and services to you, identify abnormal accounts, and protect the personal and property of other users or the public from infringement, we may share your personal information with our affiliated companies and/or their designated service providers. We only share necessary personal information and are subject to the purposes stated in this Agreement. If we share your sensitive personal information or the affiliates want to change the purpose of processing personal information, we will ask for your authorization again. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("3.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " Sharing with authorized partners: We may entrust authorized partners to provide you with certain services or perform functions on our behalf. We will only share your personal information for legitimate, proper, necessary, specific and clear purposes stated in this Agreement. Our authorized partners only have access to the information they need to perform their duties and are not authorized to use the shared personal information for any other purpose. We do not need to notify you and obtain your additional consent to the use and sharing of such data. "
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("p", [
          _c("b", [
            _vm._v(
              " Currently, our authorized partners include the following types: "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("3.4.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                " Suppliers, service providers and other partners. We send information to suppliers, service providers, and other partners who support our business. The support includes technical infrastructure services entrusted by us, analyzing how our services are used, measuring the effectiveness of our services, providing customer services, facilitating payments, or conducting academic researches and investigations. "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("3.5")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " We will not transfer your personal information to any company, organization, or individual, except as follows: "
          ),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("3.5.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                " Transfer with explicit consent: we will transfer your personal information to other parties after obtaining your explicit consent. "
              ),
            ]),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("3.5.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                " In the event of a merger, acquisition or liquidation of Quectel Platform service providers, or other circumstances involving a merger, acquisition or liquidation, we will require new companies and organizations that hold your personal information to remain subject to this Agreement, otherwise we will require the company, organization and individual to obtain your consent again. "
              ),
            ]),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("3.5.3")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                " Exceptions to prior authorization for the sharing, transfer or disclosure of personal information shall be implemented in accordance with the requirements of the relevant and currently effective laws and regulations. "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [
        _vm._v(
          " If you do not agree that we provide your personal information to third parties in accordance with the above terms, please stop using the services of Quectel Platform immediately. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("4.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " We will retain your information during your use of the products or services of Quectel Platform, unless you voluntarily delete or cancel your account on Quectel AEP Platform. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("4.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " When you delete information or cancel your Quectel Platform account, Quectel Platform will stop using your personal information and delete your personal information in a timely manner within a certain period of time. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("4.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Your personal information will be anonymized and desensitized to available circulation data, and Quectel Platform will store and use such data without your additional consent. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("5.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " We have taken reasonable and feasible security measures to protect your information from unauthorized access, public disclosure, use, modification, damage or loss in accordance with industry standards. For example, when exchanging data between your browser and server, your information is protected by SSL protocol encryption; meanwhile we provide HTTPS protocol secure browsing to websites of Quectel Platform; we use encryption technology to improve the security of personal information; we use trusted protection mechanisms to protect personal information from malicious attacks; we deploy access control mechanisms to ensure that only authorized personnel can access your personal information, and we conduct security and privacy training courses to strengthen the employee’s awareness of the importance of personal information protection. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("5.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " The Internet is not absolutely secure and we strongly recommend that you do not send your information using the communication methods not recommended by Quectel Platform when using a product or service of Quectel Platform. You can connect and share information with each other through our services. When you create communications, conduct transactions or share information through our services, you can choose who you communicate with, trade with or share with as a third party that can view your transaction information and contact details, exchange information or share content with you. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("5.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Once a user information security incident occurs, we will inform you in accordance with the requirements of laws and regulations: the basic situation and possible impact of the security incident, the measures we have taken or are about to take, the advice you can choose on preventing and reducing risks, remedies for you and so on. We will inform you about the event in the form of emails, letters, phone calls, push notifications and so on. If it is difficult to notify them one by one, we will take a reasonable and effective way to make announcements. At the same time, we will also report the handling of user information security incidents in accordance with the requirements of relevant regulatory authorities. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("6.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(" Access your personal information "),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("6.1.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Account information: If you want to access or edit your profile information in your account, update your password, profile picture, nickname and so on, you can access your information by visiting the settings after you log in to your account. "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("6.1.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Other personal information (such as system login, notifications and other services): for personal information generated during your use of our products or services, if you need, you can contact us by the ways in Article 9. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("6.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(" Correct your personal information "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("6.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(" Delete your personal information "),
        _c("p", [
          _vm._v(
            " In the following cases, you can contact Quectel Platform Customer Service to request the deletion of your personal information. "
          ),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("6.3.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " If our handling of personal information violates laws and regulations; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("6.3.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " If we collect and use personal information without your explicit consent; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("6.3.3")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " If our handling of personal information has seriously violated our agreement with you; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("6.3.4")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " If you do not need to use our products and services, or you voluntarily cancel your Quectel AEP account; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("6.3.5")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " If you do not need our products or services permanently. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("7.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " You can cancel your previously registered account at any time, and if you need our assistance in cancelling your account, you can contact us by the ways in Article 9. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("7.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " When you cancel your account, we will stop providing you with products or services, and delete your personal information according to your request. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("8.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Our products and services are primarily intended for adults. In the case of the collection of personal information of minors with the consent of the legal guardian, we will only use or disclose the information of minors that we collect legally to public under the circumstances permitted by law, with the express consent of the legal guardian or necessary for protecting the minors. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("8.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " If we find that personal information from a minor has been collected without prior consent of the legal guardian, we will try to delete the information as soon as possible. If the legal guardians have reason to believe that a minor has submitted personal information to Quectel Platform without their prior consent, please contact us to ensure that such personal information is removed and that the minor cancels his or her subscription to any applicable services of Quectel APE Platform. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("9.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " If you have any questions, comments or suggestions about the content of this Privacy Agreement, or you want to exercise your rights, or if you have any request to discuss with us, you can contact us by the following ways: "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " Address: Building 5, Shanghai Business Park Phase III (Area B), No.1016 Tianlin Road, Minhang District, Shanghai 200233, China"
      ),
      _c("br"),
      _vm._v(" Email: quecteldpo@quectel.com"),
      _c("br"),
      _vm._v(" Phone: 400-960-7678 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("9.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " To ensure security, you may need to provide a written request or otherwise prove your identity. Generally, we will reply within 15 days. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("9.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " This agreement is signed in Minhang District, Shanghai, People's Republic of China. The entry into force, performance, interpretation and dispute resolution of this Agreement, all disputes, claims or other matters arising out of your use of products or services of Quectel Platform, or all disputes relating to this Agreement shall be governed by the laws of the People's Republic of China (except Hong Kong, Macao and Taiwan). All disputes between you and Quectel company or Quectel Platform must be resolved in a friendly manner; if no consultation is possible, either of the following options can be selected: "
        ),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("9.3.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Submit disputes to the Arbitration Commission for arbitration which shall be conducted in accordance with the arbitration rules in effect at the time of applying for arbitration. The arbitral award is final and binding upon both parties. "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("9.3.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Submit disputes to the jurisdiction of the people's court in the place where the agreement is signed. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [
        _vm._v(
          "Please reconfirm that you have read and understand the above terms completely. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "p-text-r" }, [
      _vm._v(" Quectel Wireless Solutions Co., Ltd"),
      _c("br"),
      _vm._v(" Drafting Date: July 21, 2021"),
      _c("br"),
      _vm._v(" Effective Date: July 21,2021 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          ' Before using any software, website or service (collectively called "Quectel Products or Service") provided by Quectel Wireless Solutions Co., Ltd. and its affiliates (hereinafter “Quectel”), '
        ),
        _c("b", [
          _vm._v(
            " please read Quectel Terms of Service and Quectel Privacy Policy (hereinafter “Terms”) carefully, especially the contents marked in bold. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " If you have any confusion about this Agreement, please inquire through Quectel public disclosure channels. "
        ),
        _c("b", [
          _vm._v(
            "The terms and conditions of this Agreement shall be modified at any time by Quectel. You understand and agree that the revised terms and conditions shall have retroactive effect. By enabling Quectel Products or Services, you acknowledge that you have read, understood, and agree to all the contents stated in the Agreement, and that the Agreement is legally binding."
          ),
        ]),
        _vm._v(
          " When you use a certain Quectel service, you should follow the guidelines and rules related to that service. All the above guidelines and rules constitute part of this Agreement. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " You declare that you have sufficient power, ability and authority to agree to and fulfill this Agreement. If you agree to this Agreement on behalf of your employer or customer, you declare that you have sufficient legal authority to bind your employer or customer to this Agreement. If you do not have sufficient legal authority, please ensure that your employer or customer's authorized person agrees to this Agreement. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " In the case that you are a minor user, please read this Agreement carefully accompanied by your legal guardian and use our Products or Services or provide us with information with the consent of your legal guardian. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("1.5")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " You confirm that you are not the subject of trade restrictions, sanctions, legal restrictions and rules limits imposed by any organization, region or country. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("2.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Personal information protection is one of the fundamental principles to which Quectel adheres. "
        ),
        _c("b", [
          _vm._v(
            "When you use Quectel Products or Services, we may collect and use your personal information, including your location information and equipment information, and some functions may also include your identity information, contact information and other information. "
          ),
        ]),
        _vm._v(
          " will collect, store, use and disclose your personal information in accordance with the provisions of the "
        ),
        _c("b", [_vm._v("Quectel Privacy Agreement")]),
        _vm._v(
          ". Please read the Quectel Privacy Agreement carefully and use Quectel Products or Services only on the basis that you agree with its contents. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("2.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " When using Quectel Product or Services, you trust the way we handle your information and have agreed to the terms of your information collection and use in service policies such as Quectel Terms of Service and Privacy Agreement. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("3.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          ' Quectel Products or Services is provided by Quectel Wireless Solutions Co., Ltd. and its affiliates (collectively referred to as "Quectel" or "we"). '
        ),
        _c("b", [
          _vm._v(
            "You understand and agree that the products and services provided by Quectel are only provided in their current conditions, and Quectel expressly refuses to make any express or implied warranties for Products or Services, including but not limited to warranties for commercial use, suitable for specific purposes or not infringing on the rights of others. Quectel is not liable for any direct, indirect, incidental or consequential loss arising from the Products or Services. "
          ),
        ]),
        _vm._v(
          "Quectel has the right to determine the specific content and form of Products or Services and their functions and services. Quectel has the right to add, change, suspend and cease Quectel Products or Services and their functions or services at its own discretion. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("3.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " In order to improve Quectel Products or Services, Quectel will release subsequent versions of Quectel Products or Services in due course. "
        ),
        _c("b", [
          _vm._v(
            "Unless otherwise expressly specified in this Agreement, any upgrade or update of Quectel Products or Services shall be governed by this Agreement."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("3.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " After new Quectel Products or Services is released, the old version may not be available."
        ),
        _c("b", [
          _vm._v(
            " We do not guarantee that the old version can continue to be used, so please download the latest version in time."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("4.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          ' Content provided by Quectel Products or Services means any content provided by Quectel through Quectel Products or Services, regardless of whether such content is created by Quectel or licensed to be used by other third parties (hereinafter referred to as "Relevant Content"). '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("4.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " You shall not (and you shall not permit others to) use Quectel Products or Services for any commercial purpose in order to directly or indirectly gain any form of interest. If you have commercial operation needs, you will need to enter into a separate written agreement with Quectel or obtain Quectel's prior written permission."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("4.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " If you use the products or services, you may download Quectel Products or Services from a third party authorized by Quectel "
        ),
        _c("b", [
          _vm._v(
            "If you obtain Quectel Products or Services from a third party without authorization from Quectel, we cannot guarantee the normal use of the products and any loss you suffer is not related to Quectel."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("4.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " All other rights not expressly authorized by this Agreement shall remain Quectel, and this Agreement shall not be construed as conferring any other rights on you by implication or otherwise. You need to sign a separate written agreement or obtain prior written permission from Quectel when using other rights."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("4.5")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Although we will continuously improve the service quality, due to various objective or technical reasons, it may lead to limitations of Quectel Products or Services. Therefore, "
        ),
        _c("b", [
          _vm._v(
            "the functions and services provided by Quectel Products or Services are for your reference only, and please do not use this as the only basis for your judgment."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("5.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            ' When using Quectel Products or Services, you shall comply with all applicable laws, regulations, rules, norms, policies, administrative orders and social order (collectively, the "Laws and Regulations").'
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("5.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " You may only use Quectel Products or Services in the manner permitted by this Agreement, and unless otherwise expressly permitted by a written agreement or additional terms between you and Quectel, you shall not perform, agree to, authorize or instruct any third party to do any of the following during your use of Quectel Products or Services, including but not limited to:"
          ),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("5.2.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Modify, copy, publish, transmit, publicly display, exhibit, broadcast, reproduce, translate, distribute, publish, authorize, create derivative products from, transfer, sell, reverse engineer, decompile, or attempt to extract source code or obtain original data from or any part of Quectel Products or Services; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("5.2.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Provide Quectel Products or Services for a fee or other profit, whether or not for direct economic benefits or money. "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("5.2.3")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(" Any other acts without the "),
            _c("b", [_vm._v("express permission")]),
            _vm._v(" of Quectel. "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("5.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " In the event of a breach of this Agreement by you, Quectel reserves the right to pursue all remedies available to you by law for such breach. In case of any loss caused to Quectel or any other person due to your illegal or violation of this Agreement, we have the right to claim compensation from you, including but not limited to fines, compensation, legal fees, attorney's fees, notarial fees, evidence investigation fees."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("7.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Quectel owns all legal rights, qualifications and interests (no matter whether these rights have been registered or not, and no matter where these rights may exist in the world) in the copyright, trademark and patent rights of Quectel Products or Services and related contents. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("7.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Your agreement to this Agreement and your use of the Quectel Products or Services shall not involve the transfer of any of the intellectual property rights mentioned above, and all such intellectual property rights shall remain the property of Quectel or the corresponding right holders. You cannot, without the prior written consent of Quectel and/or the corresponding obligee, exercise, utilize, transfer or permit any three parties to exercise, utilize, transfer the above intellectual property rights, nor can you modify, lease, lend, sell, distribute, make derivative works or otherwise use any part or all of the above intellectual property rights. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("7.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " You may not in any way delete, conceal, modify or replace any exclusive rights statement (including but not limited to copyright, trademark, patent, ownership statement.) attached to or included in Quectel Products or Services or any copyright marks, trademarks, service marks, logos, trade names, business names, domain names, drawing approval numbers, publication numbers and other obvious signs of Quectel and its partners. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("7.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " You may not use any of the marks of Quectel or the corresponding obligee unless you have a separate written agreement with Quectel or the corresponding obligee. This Agreement does not grant you the right to use the logo of Quectel Products or Services in any form or purpose, nor does it grant you the right to use any other logo of Quectel and third party. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("8.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          ' You declare and warrant that you have appropriate and lawful rights, licenses or authorizations for information (collectively referred to as "User Content") that you publish or upload through Quectel Products or Services. Otherwise, Quectel may delete or block the information provided by you in accordance with the law or this Agreement. '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("8.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " You agree to grant Quectel a permanent, worldwide, fee-free, transferable, sublicensable, irrevocable, and non-exclusive license to use the User Content you provide through Quectel Products or Services. You understand and agree that the aforesaid license will remain valid after you cease to use Quectel Products or Services. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("8.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " You understand and agree that, notwithstanding the above Agreement, "
        ),
        _c("b", [
          _vm._v(
            "when you report, feedback, share, correct or otherwise submit or share problem screenshots, error correction information, problem feedback, improvement suggestions or opinions or similar content to Quectel Products or Services, all intellectual property rights such as copyright, ownership and other legal rights stipulated by current and future laws of any of the foregoing contents will be automatically transferred to Quectel at the time of your submission, and no fees will be paid to you by Quectel."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("8.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            "You understand and agree that you should abide by the relevant laws and regulations of the People's Republic of China. You are responsible for the authenticity, accuracy, timeliness and completeness of any information submitted by you through Quectel Products or Services. You guarantee that the above information will not infringe the legitimate rights and interests of any third party or violate any laws and regulations, nor will it involve or encourage the infringement or other illegal acts, otherwise, you will bear the responsibilities and consequences arising therefrom."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("9.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Quectel provides the Products or Services in accordance with applicable laws, regulations, rules, norms, policies, compulsory standards, industry standards, etc. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("9.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            "You understand and agree that Quectel and its licensors do not provide you with any warranties express or implied, with respect to:"
          ),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("9.2.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                "Quectel Products or Services will meet your actual or specific needs or purposes;"
              ),
            ]),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("9.2.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                "Timeliness, accuracy, completeness and accuracy of Quectel Products or Services;"
              ),
            ]),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("9.2.3")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                "Quectel Products or Services will be uninterrupted, interrupt free, consistent, or error-free;"
              ),
            ]),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("9.2.4")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                "Any results or information obtained using Quectel Products or Services will be correct or reliable."
              ),
            ]),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("9.2.5")]),
          _c("span", { staticClass: "en-p-text" }, [
            _c("b", [
              _vm._v(
                "Any errors, problems, failures in Quectel Products or Services will be corrected or resolved;"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("10.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Quectel Products or Services may be affected by many factors, including but not limited to your reasons, third party reasons, communication network service quality, connection failure, network equipment or system maintenance, computer or communication or other system failures, power failure, strike, riot, fire, flood, storm, explosion, war, government actions, judicial administrative organs' orders, social environment and other force majeure. "
        ),
        _c("b", [
          _vm._v(
            "Except for the liability required by laws and regulations, any losses caused to you due to the reasons mentioned in this paragraph shall be borne by you, and we shall not be liable for this. In all cases, Quectel shall not be liable for any loss or damage which cannot be reasonably foreseen. You shall bear all the responsibilities and consequences arising from your use of Quectel Products or Services in violation of laws and regulations or this Agreement."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("10.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Quectel Products or Services and employees shall not be liable in any way, directly or indirectly, to users or any other person for any fault, inaccuracy or error in the transmission or transfer of any information. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("10.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " Information provided by Quectel Products or Services, If the use or distribution to any person in any jurisdiction would violate the laws or regulations of that jurisdiction or cause Quectel Products or Services or its third-party agents to be subject to any regulatory requirements in that jurisdiction. It is not appropriate for the information to be used by or distributed to any such person in that jurisdiction. Users shall undertake that you will not be subject to any local regulations that restrict or prohibit users from using or distributing the information provided by Quectel Products or Services. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("10.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _c("b", [
          _vm._v(
            " You understand and agree that you shall be liable for any third-party claims arising out of your use of the Service, violation of this Agreement or any actions taken under your account. You shall be responsible for handling any claims made by any third party arising out of this, and compensate for all losses and liabilities incurred by Quectel and its affiliates. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " Quectel reserves the right to change, suspend and cease providing Quectel Products or Services with or without notice, and to change, suspend and cease your use of Quectel Products or Services at any time. "
      ),
      _c("b", [
        _vm._v(
          "If Quectel permanently terminating the provision of Quectel Products or Services, Quectel will make an announcement on Quectel Products or Services or related websites in advance without notifying you separately."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.1")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " These Terms, including Quectel Privacy Agreement, constitute the entire Agreement between you and Quectel. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.2")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Quectel Products or Services will not be available in all languages or in all countries. "
        ),
        _c("b", [
          _vm._v(
            "Quectel does not promise availability in other countries or regions outside China and prohibits the use of products or services in areas where they are considered illegal. You confirm that you are in compliance with all export control laws and regulations applicable to this Agreement. You undertake not to use Products or Services for purposes prohibited by applicable law."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.3")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " This Agreement is signed in Minhang District, Shanghai, The People's Republic of China. The entry into force, performance, interpretation and dispute resolution of this Agreement, and any disputes, claims or other matters arising between you and Quectel regarding the use of Quectel Products or Services, the content of this Agreement or its enforcement, shall be governed by the laws of the People's Republic of China (except Hong Kong, Macao and Taiwan regions China). Relevant disputes or claims should be settled through friendly consultation; If negotiation fails, you can choose any of the following ways to solve it: "
        ),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("12.3.1")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Submit it to Shanghai Arbitration Commission for arbitration in accordance with the arbitration rules in force at the time of applying for arbitration; The arbitration award shall be final and binding on all parties; "
            ),
          ]),
        ]),
        _c("p", { staticClass: "en-p clearfix" }, [
          _c("span", { staticClass: "en-p-no" }, [_vm._v("12.3.2")]),
          _c("span", { staticClass: "en-p-text" }, [
            _vm._v(
              " Submit it to the People's Court where the Agreement is signed for litigation jurisdiction. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.4")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " In the event that any provision of this Agreement conflicts with applicable laws and regulations, the other provisions of this Agreement shall remain in full force and effect. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.5")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " The title of this Agreement is for convenience only and has no legal or contractual effect. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.6")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " This Agreement does not grant you any other rights except as expressly provided by this Agreement and laws and regulations. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.7")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " All rights and obligations under this article may be transferred by Quectel for the benefit of Quectel and its successors and assigns. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.8")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " The English translation of this Agreement (if any) is provided for your convenience only, and the relationship between you and Quectel is determined by the Chinese version. In case of a conflict between the two versions, the Chinese version shall prevail. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "en-p clearfix" }, [
      _c("span", { staticClass: "en-p-no" }, [_vm._v("12.9")]),
      _c("span", { staticClass: "en-p-text" }, [
        _vm._v(
          " Your opinions and suggestions can be delivered through the contact channel disclosed by Quectel. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }