var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notice-card-wrapper" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "message" }, [
          _c("div", {
            staticClass: "inner-container",
            domProps: { innerHTML: _vm._s(_vm.handleNotice) },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }