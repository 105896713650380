import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import aepLayout from '@/layout'

let Layout = aepLayout

const originalPush = Router.prototype.push

Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/forget',
    component: (resolve) => require(['@/views/login/forget-password'], resolve),
    hidden: true
  },
  {
    path: '/reset-password',
    component: (resolve) => require(['@/views/login/reset-password'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/error',
    component: Layout,
    hidden: true,
    children: [{
      path: '401',
      component: (resolve) => require(['@/views/error/401'], resolve),
      hidden: true
    }]
  },
  {
    path: '',
    component: Layout,
    redirect: 'index'
  },
  {
    path: '/oauth',
    component: (resolve) => require(['@/views/login/oauth'], resolve),
    hidden: true
  },
  {
    path: '/loading',
    component: (resolve) => require(['@/views/login/loading'], resolve),
    hidden: true
  },
  {
    path: '/maintenance',
    component: (resolve) => require(['@/views/maintenance'], resolve),
    hidden: true
  },
  {
    path: '/job',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'log',
        component: (resolve) => require(['@/views/monitor/job/log'], resolve),
        name: 'JobLog',
        meta: { title: '调度日志', otherName: 'job_log' }
      }
    ]
  },
  {
    path: '/dict',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'type/data/:dictId(\\d+)',
        component: (resolve) => require(['@/views/system/dict/data'], resolve),
        name: 'Data',
        meta: { title: '字典数据', icon: '', otherName: 'dict_data' }
      }
    ]
  },
  {
    path: '/letterAdmin',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'LetterInfo',
        component: (resolve) => require(['@/views/system/letterAdmin/components/LetterInfo'], resolve),
        name: 'LetterEdit',
        meta: { title: '站内信编辑', icon: '', otherName: 'sys_letter_edit' }
      },
      {
        path: 'LetterInfo',
        component: (resolve) => require(['@/views/system/letterAdmin/components/LetterInfo'], resolve),
        name: 'LetterAdd',
        meta: { title: '站内信创建', icon: '', otherName: 'm_letter_add' }
      }
    ]
  },
  {
    path: '/function',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'info/:key/:id',
        component: (resolve) => require(['@/views/product/function'], resolve),
        name: 'Function',
        meta: { title: '功能定义', icon: '', otherName: 'function' }
      },
      {
        path: 'StatsConfig/:pk/:id',
        component: (resolve) => require(['@/views/product/statsConfig'], resolve),
        name: 'StatsConfig',
        meta: { title: '统计配置', icon: '', otherName: 'statsConfig' }
      }
    ]
  },
  {
    path: '/notice',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'email/:id/:eventId/:subType',
        component: (resolve) => require(['@/views/product/notice/email'], resolve),
        name: 'NoticeEmail',
        meta: { title: '邮件通知', icon: '', otherName: 'notice_email' }
      },
      {
        path: 'sms/:id/:eventId/:subType',
        component: (resolve) => require(['@/views/product/notice/sms'], resolve),
        name: 'NoticeSms',
        meta: { title: '短信通知', icon: '', otherName: 'notice_sms' }
      },
      {
        path: 'voice/:id/:eventId/:subType',
        component: (resolve) => require(['@/views/product/notice/voice'], resolve),
        name: 'NoticeVoice',
        meta: { title: '语音通知', icon: '', otherName: 'notice_voice' }
      },
      {
        path: 'app/:id/:eventId/:subType',
        component: (resolve) => require(['@/views/product/notice/app'], resolve),
        name: 'NoticeApp',
        meta: { title: 'App通知', icon: '', otherName: 'notice_app' }
      }
    ]
  },
  {
    path: '/device',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/map/TrackPlay',
        component: (resolve) => require(['@/views/device/map/TrackPlay'], resolve),
        name: 'TrackPlay',
        meta: { title: '轨迹回放' }
      },
      {
        path: '/fence/addFence',
        component: (resolve) => require(['@/views/device/fence/addFence'], resolve),
        name: 'AddFence',
        meta: { title: '自定义围栏', otherName: 'fence_add' }
      },
      {
        path: '/fence/email/:id',
        component: (resolve) => require(['@/views/device/fence/notice/email'], resolve),
        name: 'FenceNoticeEmail',
        meta: { title: '邮件通知', icon: '', otherName: 'notice_email' }
      },
      {
        path: '/fence/sms/:id',
        component: (resolve) => require(['@/views/device/fence/notice/sms'], resolve),
        name: 'FenceNoticeSms',
        meta: { title: '短信通知', icon: '', otherName: 'notice_sms' }
      },
      {
        path: '/fence/voice/:id',
        component: (resolve) => require(['@/views/device/fence/notice/voice'], resolve),
        name: 'FenceNoticeVoice',
        meta: { title: '语音通知', icon: '', otherName: 'notice_voice' }
      },
      {
        path: '/fence/app/:id',
        component: (resolve) => require(['@/views/device/fence/notice/app'], resolve),
        name: 'FenceNoticeApp',
        meta: { title: 'App通知', icon: '', otherName: 'notice_app' }
      },
      // {
      //   path: '/group/group/addGroup',
      //   component: (resolve) => require(['@/views/group/group/addGroup'], resolve),
      //   name: 'AddGroup',
      //   meta: { title: '添加分组', otherName: 'group_add' }
      // },
      // {
      //   path: '/groupgroup/manageDevice/manageDevice',
      //   component: (resolve) => require(['@/views/group/group/manageDevice'], resolve),
      //   name: 'ManagerDevice',
      //   meta: { title: '设备管理', otherName: 'group_manage_device' }
      // },
      {
        path: '/fence/bindFence',
        component: (resolve) => require(['@/views/device/fence/bindFence'], resolve),
        name: 'BindFence',
        meta: { title: '绑定设备', otherName: 'fence_bind' }
      }
    ]
  }
]

function handleRouter () {
  return [...constantRoutes]
}

export const Routes = handleRouter()

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: handleRouter()
})
