/**
 * 自定义指令，用来控制按钮权限
 * list：不可点击的数组
 */
export default {
  inserted (el, binding) {
    // 当前按钮传递的值( v-hasDisable="'add'" 中的值)
    const currentCode = binding.value.code
    const currentList = binding.value.list // 获取到存放在当前行的权限数据
    if (currentList) {
      // 判断是否存在对应的按钮权限
      const permissionCode = currentList.filter((permissionCode) => {
        return permissionCode === currentCode
      })
      // 不可点击
      if (permissionCode.length !== 0) {
        el.disabled = true
        el.classList.add('is-disabled')
      }
    }
  }
}
