/**
 * 通用js方法封装处理
 */
import Cookies from 'js-cookie'
import { getStamp, getStampSearch } from '@/utils/date'
const baseURL = process.env.VUE_APP_BASE_API

// 日期格式化
export function parseTime (time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/')
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    const newStamp = getStamp(time)
    date = new Date(newStamp)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

export function newTime (data) {
  // let year = data.getFullYear()
  // let month = data.getMonth() + 1
  // let day = data.getDate()
  // if (month < 10) month = '0' + month
  // if (day < 10) day = '0' + day

  // return year + '-' + month + '-' + day

  return data.getTime()
}

export function formatTime (data) {
  let year = data.getFullYear()
  let month = data.getMonth() + 1
  let day = data.getDate()
  if (month < 10) month = '0' + month
  if (day < 10) day = '0' + day

  return year + '-' + month + '-' + day
}

// 表单重置
export function resetForm (refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields()
  }
}

// 添加日期范围
export function addDateRange (params, dateRange, propName) {
  let search = { ...params }
  propName = propName != null && propName !== '' && propName.length > 1 ? propName : []
  if (dateRange != null && dateRange !== '' && dateRange.length > 0) {
    if (propName && propName.length === 0) {
      search.startTime = getStampSearch(dateRange[0])
      search.endTime = getStampSearch(dateRange[1])
    } else {
      search[propName[0]] = getStampSearch(dateRange[0])
      search[propName[1]] = getStampSearch(dateRange[1])
    }
    search.offset = Cookies.get('offset')
  }
  return search
}

export function addDateRangeNo (params, dateRange, propName) {
  var search = { ...params }
  if (dateRange != null && dateRange !== '' && dateRange.length > 0) {
    if (typeof (propName) === 'undefined') {
      search.beginTime = getStampSearch(dateRange[0])
      search.endTime = getStampSearch(dateRange[1])
    } else {
      search.params['begin' + propName] = getStampSearch(dateRange[0])
      search.params['end' + propName] = getStampSearch(dateRange[1])
    }
  }

  return search
}

// 回显数据字典
export function selectDictLabel (datas, value, selectKey) {
  // selectKey => {label,value}
  var actions = []
  Object.keys(datas).some((key) => {
    let matchValue = selectKey?.value || 'dictValue'
    let matchLabel = selectKey?.label || 'dictLabel'
    if ('' + datas[key][matchValue] === ('' + value)) {
      actions.push(datas[key][matchLabel])
      return true
    }
  })
  return actions.join('')
}

// 回显数据字典（字符串数组）
export function selectDictLabels (datas, value, separator) {
  var actions = []
  var currentSeparator = undefined === separator ? ',' : separator
  var temp = value.split(currentSeparator)
  Object.keys(value.split(currentSeparator)).some((val) => {
    Object.keys(datas).some((key) => {
      if ('' + datas[key].dictValue === ('' + temp[val])) {
        actions.push(datas[key].dictLabel + currentSeparator)
      }
    })
  })
  return actions.join('').substring(0, actions.join('').length - 1)
}

// 通用下载方法
export function download (fileName) {
  window.location.href = baseURL + '/system/file/download?fileName=' + encodeURI(fileName) + '&delete=' + true
}

// 字符串格式化(%s )
export function sprintf (str) {
  var args = arguments; var flag = true; var i = 1
  str = str.replace(/%s/g, function () {
    var arg = args[i++]
    if (typeof arg === 'undefined') {
      flag = false
      return ''
    }
    return arg
  })
  return flag ? str : ''
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty (str) {
  if (!str || str === 'undefined' || str === 'null') {
    return ''
  }
  return str
}

/**
 * @description: 构造树型结构数据
 * @param {*} src 数据源
 * @param {*} key 每个对象 id 字段
 * @param {*} parentKey 每个对象的父节点字段，默认 ‘parentId'
 * @param {*} childrenKey 每个对象的子节点字段, 默认 ‘children'
 * @return {*}
 */
export function handleTree (src, key, parentKey = 'parentId', childrenKey = 'children') {
  let root = new Node(key, 0, childrenKey, {})
  const treeMap = new Map([[root[key], root]])
  for (const node of [...src]) {
    treeMap.set(node[key], {
      ...node,
      [childrenKey]: treeMap.has(node[key])
        ? [...treeMap.get(node[key]).children]
        : []
    })
    treeMap.has(node[parentKey])
      ? treeMap.get(node[parentKey])[childrenKey].push(treeMap.get(node[key]))
      : treeMap.set(node[parentKey], { [childrenKey]: [treeMap.get(node[key])] })
  }
  return root[childrenKey]
}
function Node (key, value, childrenKey, options) {
  Object(this, options)
  this[key] = value
  this[childrenKey] = []
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
// export function handleTree (data, id, parentId, children) {
//   let config = {
//     id: id || 'id',
//     parentId: parentId || 'parentId',
//     childrenList: children || 'children'
//   }

//   var childrenListMap = {}
//   var nodeIds = {}
//   var tree = []

//   for (let d of data) {
//     let parentId = d[config.parentId]
//     if (childrenListMap[parentId] == null) {
//       childrenListMap[parentId] = []
//     }
//     nodeIds[d[config.id]] = d
//     childrenListMap[parentId].push(d)
//   }

//   for (let d of data) {
//     let parentId = d[config.parentId]
//     if (nodeIds[parentId] == null) {
//       tree.push(d)
//     }
//   }

//   for (let t of tree) {
//     adaptToChildrenList(t)
//   }

//   function adaptToChildrenList (o) {
//     if (childrenListMap[o[config.id]] !== null) {
//       o[config.childrenList] = childrenListMap[o[config.id]]
//     }
//     if (o[config.childrenList]) {
//       for (let c of o[config.childrenList]) {
//         adaptToChildrenList(c)
//       }
//     }
//   }
//   return tree
// }

/**
 *生成字符- 国际化使用
 * @param {*} lanuageKey 语言对象键值
 * @param {*} title 要转换的值
 */
export function generateText (lanuageKey, title) {
  const key = this.$t(`${lanuageKey}.${title}`)
  if (key) {
    return this.$t(`${lanuageKey}.${title}`)
  }
  return title
}

// HEX转RGB
export function convertHexToRGB (color) {
  if (color.length === 4) {
    let extendedColor = '#'
    for (let i = 1; i < color.length; i++) {
      extendedColor += color.charAt(i) + color.charAt(i)
    }
    color = extendedColor
  }
  const values = {
    r: parseInt(color.substr(1, 2), 16),
    g: parseInt(color.substr(3, 2), 16),
    b: parseInt(color.substr(5, 2), 16)
  }
  return `${values.r}, ${values.g}, ${values.b}`
}

// 随机生成字符串
export function randomString (expect) {
  let str = Math.random().toString(32).substring(2)
  while (str.length < expect) {
    str += Math.random().toString(32).substring(2)
  }
  return str.substring(0, expect)
}
