<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
    <QuickGuide v-if="orgtype!==1" />
  </section>
</template>

<script>
import QuickGuide from './QuickGuide/Index.vue'
import {mapGetters} from 'vuex'
export default {
  name: 'AppMain',
  computed: {
    cachedViews () {
      return this.$store.state.tagsView.cachedViews
    },
    key () {
      return this.$route.path
    },
    ...mapGetters(['orgtype'])
  },
  components: {
    QuickGuide
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh - 50px);
}

.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
