var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.type === "textarea" ? "el-textarea q-textarea" : "el-input q-input",
        _vm.inputSize ? "el-input--" + _vm.inputSize : "",
        {
          "is-disabled": _vm.inputDisabled,
          "is-exceed": _vm.inputExceed,
          "el-input-group": _vm.$slots.prepend || _vm.$slots.append,
          "el-input-group--append": _vm.$slots.append,
          "el-input-group--prepend": _vm.$slots.prepend,
          "el-input--prefix": _vm.$slots.prefix || _vm.prefixIcon,
          "el-input--suffix":
            _vm.$slots.suffix ||
            _vm.suffixIcon ||
            _vm.clearable ||
            _vm.showPassword,
        },
      ],
      on: {
        mouseenter: function ($event) {
          _vm.hovering = true
        },
        mouseleave: function ($event) {
          _vm.hovering = false
        },
      },
    },
    [
      _vm.type !== "textarea"
        ? [
            _vm.$slots.prepend
              ? _c(
                  "div",
                  { staticClass: "el-input-group__prepend" },
                  [_vm._t("prepend")],
                  2
                )
              : _vm._e(),
            _vm.type !== "textarea"
              ? _c(
                  "input",
                  _vm._b(
                    {
                      ref: "input",
                      staticClass: "el-input__inner",
                      style: _vm.inputStyle,
                      attrs: {
                        tabindex: _vm.tabindex,
                        type: _vm.showPassword
                          ? _vm.passwordVisible
                            ? "text"
                            : "password"
                          : _vm.type,
                        disabled: _vm.inputDisabled,
                        readonly: _vm.readonly,
                        autocomplete: _vm.autoComplete || _vm.autocomplete,
                        "aria-label": _vm.label,
                      },
                      on: {
                        compositionstart: _vm.handleCompositionStart,
                        compositionupdate: _vm.handleCompositionUpdate,
                        compositionend: _vm.handleCompositionEnd,
                        input: _vm.handleInput,
                        focus: _vm.handleFocus,
                        blur: _vm.handleBlur,
                        change: _vm.handleChange,
                      },
                    },
                    "input",
                    _vm.$attrs,
                    false
                  )
                )
              : _vm._e(),
            _vm.$slots.prefix || _vm.prefixIcon
              ? _c(
                  "span",
                  { staticClass: "el-input__prefix" },
                  [
                    _vm._t("prefix"),
                    _vm.prefixIcon
                      ? _c("i", {
                          staticClass: "el-input__icon",
                          class: _vm.prefixIcon,
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.getSuffixVisible()
              ? _c(
                  "span",
                  { ref: "inputSuffix", staticClass: "el-input__suffix" },
                  [
                    _c(
                      "span",
                      { staticClass: "el-input__suffix-inner" },
                      [
                        !_vm.showClear ||
                        !_vm.showPwdVisible ||
                        !_vm.isWordLimitVisible
                          ? [
                              _vm._t("suffix"),
                              _vm.suffixIcon
                                ? _c("i", {
                                    staticClass: "el-input__icon",
                                    class: _vm.suffixIcon,
                                  })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.showClear
                          ? _c("i", {
                              staticClass:
                                "el-input__icon el-icon-circle-close el-input__clear",
                              on: {
                                mousedown: function ($event) {
                                  $event.preventDefault()
                                },
                                click: _vm.clear,
                              },
                            })
                          : _vm._e(),
                        _vm.showPwdVisible
                          ? _c("i", {
                              staticClass:
                                "el-input__icon el-icon-view el-input__clear",
                              on: { click: _vm.handlePasswordVisible },
                            })
                          : _vm._e(),
                        _vm.isWordLimitVisible
                          ? _c("span", { staticClass: "el-input__count" }, [
                              _c(
                                "span",
                                { staticClass: "el-input__count-inner" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.textLength) +
                                      "/" +
                                      _vm._s(_vm.upperLimit) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm.validateState
                      ? _c("i", {
                          staticClass: "el-input__icon",
                          class: ["el-input__validateIcon", _vm.validateIcon],
                        })
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.$slots.append
              ? _c(
                  "div",
                  { staticClass: "el-input-group__append" },
                  [_vm._t("append")],
                  2
                )
              : _vm._e(),
          ]
        : _c(
            "textarea",
            _vm._b(
              {
                ref: "textarea",
                staticClass: "el-textarea__inner",
                style: _vm.textareaStyle,
                attrs: {
                  tabindex: _vm.tabindex,
                  disabled: _vm.inputDisabled,
                  readonly: _vm.readonly,
                  autocomplete: _vm.autoComplete || _vm.autocomplete,
                  "aria-label": _vm.label,
                },
                on: {
                  compositionstart: _vm.handleCompositionStart,
                  compositionupdate: _vm.handleCompositionUpdate,
                  compositionend: _vm.handleCompositionEnd,
                  input: _vm.handleInput,
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur,
                  change: _vm.handleChange,
                },
              },
              "textarea",
              _vm.$attrs,
              false
            )
          ),
      _vm.isWordLimitVisible && _vm.type === "textarea"
        ? _c("span", { staticClass: "el-input__count" }, [
            _vm._v(_vm._s(_vm.textLength) + "/" + _vm._s(_vm.upperLimit)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }