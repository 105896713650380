import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import i18n, { getLanguage } from '@/lang'
import Cookies from 'js-cookie'
import interceptors from './interceptors'
import Message from '@/utils/resetMessage'
import { confirmBox } from '@/utils/confirmBox'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 20000
})

// 防止执行重复 post、put提交
interceptors(service)

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers.Authorization = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers.saas = store.getters.saas
  config.headers.offset = Cookies.get('offset')
  config.headers['Accept-Language'] = getLanguage()
  // get请求映射params参数
  if ((config.method === 'get' || config.method === 'put') && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      var part = encodeURIComponent(propName) + '='
      if (value !== null && typeof (value) !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            url += subPart + encodeURIComponent(value[key]) + '&'
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 是否正在刷新的标记 -- 防止重复发出刷新token接口
let isRefreshing = false
// 失效后同时发送请求的容器 -- 缓存接口
let subscribers = []
// 刷新 token 后, 将缓存的接口重新请求一次
function onAccessTokenFetched (newToken) {
  subscribers.forEach((callback) => {
    callback(newToken)
  })
  // 清空缓存接口
  subscribers = []
}

// 添加缓存接口
function addSubscriber (callback) {
  subscribers.push(callback)
}

// 响应拦截器
service.interceptors.response.use(res => {
  const headers = res.headers
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = res.data.msg || res.data.message || errorCode[code] || errorCode.default
  if (code === 403 && !res.config.url.includes('/sysuser/login/pwdAuth')) {
    if (!isRefreshing && store.getters.refreshToken) {
      isRefreshing = true
      // 将刷新token的方法放在vuex中处理了, 可见下面区块代码
      store.dispatch('RefreshToken', store.getters.refreshToken).then((res) => {
        // 当刷新成功后, 重新发送缓存请求
        onAccessTokenFetched(res)
      }).catch(() => {
        // 刷新token报错的话, 就需要跳转到登录页面
        confirmBox(
          {
            title: i18n.t('common.sysHint'),
            content: i18n.t('common.cookiesInvalid'),
            confirmButton: i18n.t('common.reLogin'),
            type: 'warning',
            alert: true
          },
          () => { store.dispatch('LogOut').then(() => { location.href = '/login' }) }
        )
        // MessageBox.confirm(i18n.t('common.cookiesInvalid'), i18n.t('common.sysHint'),
        //   {
        //     confirmButtonText: i18n.t('common.reLogin'),
        //     type: 'warning',
        //     showClose: false,
        //     showCancelButton: false
        //   }
        // ).then(() => {
        //   store.dispatch('LogOut').then(() => { location.href = '/login' })
        // })
      }).finally(() => {
        isRefreshing = false
      })
    }

    // 将其他接口缓存起来
    const retryOriginalRequest = new Promise((resolve) => {
      // 这里是将其他接口缓存起来的关键, 返回Promise并且让其状态一直为等待状态,
      // 只有当token刷新成功后, 就会调用通过addSubscriber函数添加的缓存接口,
      // 此时, Promise的状态就会变成resolve
      addSubscriber((newToken) => {
        // 表示用新的token去替换掉原来的token
        res.config.headers.Authorization = newToken
        // 替换掉url -- 因为baseURL会扩展请求url
        res.config.url = res.config.url.replace(res.config.baseURL, '')
        // 用重新封装的config去请求, 就会将重新请求后的返回
        resolve(service(res.config))
      })
    })

    return retryOriginalRequest
  } else if (code === 500) {
    Message({ message: msg, type: 'error' })
    return Promise.reject(new Error(msg))
  } else if (code === 429) {
    Message({ message: msg, type: 'error' })
    return Promise.reject(code)
  } else if (code !== 200 && code !== 405 && code !== 40017 && code !== 40016 && code !== 11025) {
    // Notification.error({
    //   title: msg
    // })
    Message({ message: msg, type: 'error' })
    // if (code === 401) router.replace('/error/401')
    return Promise.reject(msg)
  } else if (headers['content-type'] === 'application/vnd.ms-excel;charset=UTF-8') {
    return res
  } else {
    return res.data
  }
}, error => {
  // 重复请求错误处理
  if (error.code === 40012) return Promise.reject(error)

  let { message } = error
  if (message === 'Network Error') {
    message = i18n.t('common.networkError')
  } else if (message.includes('timeout')) {
    message = i18n.t('common.timeOut')
  } else if (message.includes('Request failed with status code')) {
    message = i18n.t('common.systemInterface') + message.substr(message.length - 3) + i18n.t('common.abnormal')
  }

  Message({ message: message, type: 'error', duration: 5 * 1000 })
  return Promise.reject(error)
}
)

export default service
