import store from '@/store'

/**
 * 字符权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkPermi (value) {
  if (value && value instanceof Array && value.length > 0) {
    const permissions = store.getters && store.getters.permissions
    const permissionDatas = value
    const allPermission = '*:*:*'

    const hasPermission = permissions.some(permission => {
      return allPermission === permission || permissionDatas.includes(permission)
    })
    if (!hasPermission) {
      return false
    }
    return true
  } else {
    console.error('need roles! Like checkPermi="[\'system:user:add\',\'system:user:edit\']"')
    return false
  }
}

/**
 * 角色权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkRole (value) {
  if (value && value instanceof Array && value.length > 0) {
    const roles = store.getters && store.getters.roles
    const permissionRoles = value
    const superAdmin = 'admin'

    const hasRole = roles.some(role => {
      return superAdmin === role || permissionRoles.includes(role)
    })

    if (!hasRole) {
      return false
    }
    return true
  } else {
    console.error('need roles! Like checkRole="[\'admin\',\'editor\']"')
    return false
  }
}

// 判断是否为admin
export function isAdmin () {
  return store.getters.orgtype === 1
}

// 判断是否为当前行权限
export function checkListBtn (row, code) {
  return row?.banButtonList?.includes(code)
}

// 判断是否有当前name的路由
export function hasRoute (name, routeList) {
  if (routeList && Array.isArray(routeList)) {
    for (let i = 0; i < routeList.length; i++) {
      if (routeList[i].name === name) {
        return true
      }
      if (routeList[i].children) {
        let flag = hasRoute(name, routeList[i].children)
        if (flag) {
          return flag
        }
      }
    }
  }
  return false
}
