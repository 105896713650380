// Vue
import Vue from 'vue'
import App from './App'
import 'babel-polyfill'

// 路由
import router from '@/router'

// store
import store from '@/store'

// Element
import Element from 'element-ui'

// 多语言
import i18n from '@/lang'

// Cookies
import Cookies from 'js-cookie'

// 权限控制
import './permission'
import permission from '@/directive/permission'

// 输入指令
import input from '@/directive/input'

// 过滤器
import * as filters from '@/filters'

// icon
import '@/assets/icons'
import '@/assets/iconfont/iconfont.css'

// 样式文件
import '@/assets/styles/public.scss'
import '@/assets/styles/main.scss'

// 分页组件
import Pagination from '@/components/Pagination'
import Table from '@/components/Table'
import TableSearch from '@/components/TableSearch'
import TextLink from '@/components/TextLink'

// 自定义表格工具扩展
import RightToolbar from '@/components/RightToolbar'
import InputNumber from '@/components/InputNumber'

import { reSubmit } from '@/utils/reSubmit.js' // 防止重复提交
import { dateValid } from '@/utils/dateValid'
import { getDicts } from '@/api/system/dict/data' // 根据字典类型查询字典数据信息
import { parseTime, getStampSearch, resetForm, addDateRange, addDateRangeNo, selectDictLabel, selectDictLabels, download, handleTree, newTime, formatTime, convertHexToRGB, randomString } from '@/utils/aep'
import { cloneDeep } from 'lodash-es'
import { printAEP } from '@/utils/screenLog'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import QuecElement from '@/quec-element-ui'
import { goBack } from '@/utils/goBack'
import { confirmBox } from '@/utils/confirmBox'
import { checkListBtn, isAdmin } from '@/utils/permission'
import message from '@/utils/resetMessage'

// bigNumber 浮点数精度
import BigNumber from 'bignumber.js'
// dayjs
import dayjs from 'dayjs'
// remjs
import '@/utils/rem'
// 重置Element UI相关操作
import elementReset from '@/utils/elementReset'

Vue.use(VueAwesomeSwiper)

if (process.env.NODE_ENV === 'development') {
  printAEP()
}

// 设置 element-ui 默认大小
Vue.use(Element, {
  size: Cookies.get('size') || 'medium'
})

// 重置QuecElement相关操作
elementReset(QuecElement)
// 全局quec组件挂载(必须放在element-ui下面，才能覆盖)
Vue.use(QuecElement, {})

Object.keys(filters).forEach(item => {
  Vue.filter(item, filters[item])
})

Vue.use(permission)
Vue.use(input)
Vue.use(reSubmit)

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.newTime = newTime
Vue.prototype.$dValid = dateValid
Vue.prototype.cloneDeep = cloneDeep
Vue.prototype.formatTime = formatTime
Vue.prototype.addDateRangeNo = addDateRangeNo
Vue.prototype.getStampSearch = getStampSearch
Vue.prototype.convertHexToRGB = convertHexToRGB
Vue.prototype.randomString = randomString
Vue.prototype.$goBack = goBack
Vue.prototype.confirmBox = confirmBox
Vue.prototype.isAdmin = isAdmin
Vue.prototype.checkListBtn = checkListBtn
Vue.prototype.$message = message
Vue.prototype.bigNumber = function (val) {
  return new BigNumber(val)
}

Vue.prototype.$dayjs = dayjs

Vue.prototype.msgSuccess = function (msg) {
  this.$message.success({ iconClass: 'iconfont iconsuccess', message: msg })
}

Vue.prototype.msgError = function (msg) {
  this.$message.error({ iconClass: 'iconfont iconunable', message: msg })
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info({ iconClass: 'iconfont iconnew_news', message: msg })
}
Vue.prototype.msgWarning = function (msg) {
  this.$message.warning({ iconClass: 'iconfont iconwarning', message: msg })
}

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('Table', Table)
Vue.component('TableSearch', TableSearch)
Vue.component('TextLink', TextLink)
Vue.component('RightToolbar', RightToolbar)
Vue.component('InputNumber', InputNumber)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
