import i18n from '@/lang'
// 日期格式化
export function formatDates (time, fmt) {
  if (time == null) {
    return '-'
  }
  const date = new Date(time)
  fmt = fmt || 'yyyy-MM-dd hh:mm:ss'
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (var k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
    }
  }
  return fmt
}

// 设备列表-参数设置-定位方式
export function filterLocationType (status) {
  const mapStatus = {
    0: 'GNSS',
    1: 'LBS'
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// 电子围栏-触发状态
export function filterFenceStatus (status) {
  const mapStatus = {
    0: i18n.t('device.fence.trigger.item1'),
    1: i18n.t('device.fence.trigger.item2'),
    2: i18n.t('device.fence.trigger.item3')
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// 电子围栏-围栏类型
export function filterFenceTypeStatus (status) {
  const mapStatus = {
    0: i18n.t('device.fence.polygon'),
    1: i18n.t('device.fence.round')
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// 电子围栏-围栏生效时间类型
export function filterEffectiveTypeStatus (status) {
  const mapStatus = {
    0: i18n.t('device.fence.effectiveType0'),
    1: i18n.t('device.fence.effectiveType1')
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// 电子围栏-通知渠道
export function filterNotifyChannelStatus (status) {
  const mapStatus = {
    100: '邮件通知',
    '010': '手机短信',
    '001': 'APP推送',
    110: '邮件通知，手机短信',
    101: '邮件通知，APP推送',
    '011': '手机短信，APP推送',
    111: '邮件通知，手机短信，APP推送'
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// zh-CN和common.zh、en-US和common.zh 互转
export function langFilter (status) {
  const list = {
    'zh-CN': i18n.t('common.zh'),
    'en-US': i18n.t('common.en'),
    [i18n.t('common.zh')]: 'zh-CN',
    [i18n.t('common.en')]: 'en-US'
  }
  return list[status]
}
/**
 * 查找对象数组包含item,拼接成字符串
 * @param {String | String[]} status 查找值
 * @param {Array} arr 被查找对象数组
 * @param {String} join 拼接字符
 * @param {String} key 查找key
 * @param {String} label 拼接key
 * @returns
 */
export function filterArrJoin (status, arr, join = ',', key, label) {
  let f
  let res = ''
  if (status instanceof Array) f = status
  if (typeof status === 'string') f.push(status)
  if (f) {
    // 筛选
    const a = arr.filter(item => {
      if (key) return status.includes(item[key])
      else return status.includes(item)
    })
    // 取出属性
    let d = []
    if (label) a.forEach(item => d.push(item[label]))
    else d = a
    // 拼接结果
    res = d.join(join)
  } else {
    console.warn('[filterArrJoin] status error')
  }
  return res
}
/**
 * 字符串过长处理
 * @param {String} status 处理字符串
 * @param {Number} len 最大长度
 * @param {String} symbol 超出部分标识
 */
export function filterEllipsis (status, len, symbol = '...') {
  let res = ''
  if (status) {
    if (status.length > len) res = status.slice(0, len).concat(symbol)
    else res = status
  }
  return res
}

/**
 * 正负数值转换成带标识的字符串（undefined、null会转成 '0'）
 * @param {Number | String} status 处理数值 (字符串格式必须纯数字；支持带单位，例：99.99% => +99.99%、+99元 => +99元、0.99人民币 => +0.99人民币)
 */
export function filterPlusMinus (status) {
  let res = '0'
  if (status !== undefined && status !== null) {
    let s = '' + status
    // 提取数值部分(只处理第一部分)
    let v = s.match(/^([\+\-\d])([\.\d]+)?/g)
    if (v) v = v[0]

    // 提取单位部分
    let u = s.slice(v?.length)

    // 处理数据只有，没有数值：+、-
    if (v === '+' || v === '-') {
      res = '' + v + u
    } else {
      const n = Number(v)
      if (n > 0) res = '+' + n + u
      else res = '' + n + u
    }
  }
  return res
}

/**
 * 前面添加 “￥”，null不做任何处理；0.1 -> ￥0.1
 * @param {Number | String} status 传入值
 * @returns
 */
export function filterYuan (status) {
  let res = status
  if (status != null && status !== '' && status !== undefined) res = '￥' + status
  return res
}

/**
 * @description: 邮箱授权码，展示前四后四，中间部分*号展示
 * @param {String} code 邮箱授权码
 * @return {*}
 */
export function filterEmailCode (code) {
  let result = code ?? ''
  if (result && result.length && result.length >= 8) {
    let startPart = result.substring(0, 4)
    let endPart = result.substring(result.length - 4)
    result = startPart.padEnd(10, '*') + endPart
  }
  return result
}
