<template>
  <div v-if="orgtype!==1" class="tool guide-tool" :class="{active: popoverShow}">
    <transition name="slide-fade">
      <div v-if="popoverShow" class="popover-area">
        <div v-if="!allSetted" class="popover-box tips">
          发现您有以下设置未完成，未完成会影响本系统的整体使用！
        </div>
        <ul class="popover-box menus">
          <template v-for="(item, index) in list">
            <li v-if="item.status !== null" :key="item.name" :class="{'success-status': allSetted}" @click="jumpPage(item, 'name')">
              <svg-icon v-if="!allSetted && item.status" class="svg-icon" icon-class="success" />
              <svg-icon v-if="!item.status && !allSetted" class="svg-icon" icon-class="warning" />
              <span class="name">{{ item.name }}</span>
              <div v-if="!allSetted" class="operate-area">
                <div v-if="!item.status">
                  <el-button v-if="index === 4" type="text" @click.stop="ignoreHandle">忽略</el-button>
                  <el-button type="text" @click.stop="jumpPage(item)">去配置</el-button>
                </div>
                <svg-icon v-else icon-class="link-arrow" @click.stop="jumpPage(item)" />
              </div>
            </li>
          </template>
        </ul>
      </div>
    </transition>
    <div>
      <div class="icon" @click.stop="switchTool">
        <svg-icon icon-class="right-next" />
      </div>
      <div class="icon set" @click.stop="switchTool">
        <svg-icon icon-class="guide-tool" />
      </div>
      <div class="label" @click="popoverShow = !popoverShow">{{ allSetted ? $t('m.quickSet') : $t('m.fullSet') }}</div>
    </div>
  </div>
</template>

<script>
import { getGuide, ignoreDistributeGuide } from '@/api/system/notice'
import {mapGetters} from 'vuex'
// anime动画插件
import anime from 'animejs/lib/anime.es.js'
export default {
  name: 'Index',
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      guideInfo: {},
      timer: null,
      open: true,
      popoverShow: false // 弹窗默认不显示
    }
  },
  // 计算属性 类似于 data 概念
  computed: {
    allSetted: function () {
      return !this.list.some(item => item.status === false)
    },
    ...mapGetters(['orgtype']),
    list: function () {
      return [
        {
          name: '自助商用设备导入',
          link: '/device/deviceList',
          status: this.guideInfo.deviceGuide
        },
        {
          name: '产品规则设置',
          link: '/product/productList',
          status: this.guideInfo.productGuide
        },
        {
          name: '计费规则设置',
          link: '/payment/rates',
          status: this.guideInfo.rateGuide
        },
        {
          name: '收款账户设置',
          link: '/payment/payConfig',
          status: this.guideInfo.mchConfGuide
        },
        {
          name: '分润规则设置',
          link: '/payment/distribute',
          status: this.guideInfo.distributeGuide
        }
      ]
    }
  },
  // 监控 data 中的数据变化
  watch: {
    open: function (val) {
      if (!val) {
        this.popoverShow = false
      }
    },
    popoverShow: function (val) {
      if (!val) {
        this.initData()
      }
    },
    allSetted: function (val) {
      if (val) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
    this.initData()
  },
  beforeDestroy () {
    if (this.orgtype !== 1) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  // 方法集合
  methods: {
    // 切换显示隐藏状态
    switchTool () {
      const iconObj = {
        targets: '.tool.guide-tool .icon',
        rotate: 360,
        opacity: 0,
        easing: 'easeOutSine',
        duration: 1000
      }
      const iconSetObj = {
        targets: '.tool.guide-tool .icon.set',
        rotate: 360,
        opacity: 1,
        easing: 'easeOutSine',
        duration: 1000
      }
      const toolObj = {
        targets: '.tool.guide-tool',
        translateX: 80,
        easing: 'linear',
        duration: 300
      }
      if (!this.open) {
        delete toolObj.easing
        delete toolObj.duration
        toolObj.translateX = 0
        iconObj.rotate = iconSetObj.rotate = -360
        iconObj.opacity = 1
        iconSetObj.opacity = 0
      }
      anime(iconObj)
      anime(iconSetObj)
      anime(toolObj)
      // 切换开启关闭状态
      this.open = !this.open
    },
    initData () {
      getGuide().then(res => {
        if (res.code === 200) {
          this.guideInfo = res.data
          if (this.orgtype !== 1 && !this.allSetted) {
            if (!this.timer) {
              this.timer = setInterval(() => this.initData(), 10000)
            }
          }
        }
      })
    },
    // 忽略点击
    ignoreHandle () {
      ignoreDistributeGuide().then(res => {
        if (res.code === 200) {
          this.initData()
        }
      })
    },
    jumpPage (item, name) {
      if (name && !item.status) {
        return
      }
      let router = {
        path: item.link,
        query: {}
      }
      if (item.link === '/payment/distribute') {
        router.query.tab = 'rule'
      }
      if (item.link === '/payment/payConfig') {
        router.query.tab = 'merchant'
      }
      this.$router.push(router)
      this.popoverShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.tool{
  position: fixed;
  z-index: 1000;
  display: inline-flex;
  right: -14px;
  bottom: 60px;
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  align-items: center;
  gap: 16px;
  border-radius: 20px 0 0 20px;
  background: #456FF7;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.20);
  color: #FFF;
  font-size: 16px;
  opacity: 0.4;
  transition: opacity 0.8s;
  &:hover{
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.8s;
  }
  &.active{
    opacity: 1;
  }
  &>div{
    position: relative;
    .label{
      margin-left: 40px;
    }
    .icon{
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.20);
      position: absolute;
      &.set{
        background: transparent;
        padding: 0;
        opacity: 0;
        svg{
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .popover-area{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    right: 32px;
    align-self: flex-start;
    transform: translateY(calc(-100% - 28px));
    min-width: 188px;
    .popover-box{
      flex: 1 auto;
      padding: 24px 30px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 12px;
      background: #FFF;
      box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.20);
      color: #333;
      font-size: 16px;
      line-height: 46px;
      &.menus{
        li.success-status:hover{
          color: #456FF7;
        }
        li{
          display: flex;
          align-items: center;
          white-space: nowrap;
          width: 100%;
          .svg-icon{
            margin-right: 16px;
          }
          .name{
            display: inline-block;
            min-width: 128px;
            margin-right: 8px;
          }
          .operate-area{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
      &.tips{
        line-height: 22px;
      }
    }
  }
  /* 可以设置不同的进入和离开动画 */
  /* 设置持续时间和动画函数 */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(100%) translateY(calc(-100% - 28px));
    opacity: 0;
  }
}

</style>
