var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.filters.length > 0
    ? _c(
        "q-table-column",
        _vm._b(
          {
            key: _vm.code + _vm.width,
            attrs: {
              prop: _vm.code,
              label: _vm.otherName || _vm.name,
              width: _vm.width,
              "show-overflow-tooltip": _vm.showTip,
              "filter-method": _vm.filterMethod,
              filters: _vm.filters,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "span",
                        { attrs: { title: _vm.otherName || _vm.name } },
                        [_vm._v(_vm._s(_vm.otherName || _vm.name))]
                      ),
                      _vm.tip
                        ? _c(
                            "el-tooltip",
                            {
                              attrs: {
                                "popper-class": "q-tooltip",
                                content: _vm.tip,
                                placement: "top",
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-warning-outline tip-icon ml5",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm.type === "time"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.parseTime(scope.row[_vm.code]))),
                          ])
                        : _vm.format
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.format(scope.row[_vm.code]))),
                          ])
                        : _vm.type === "slot"
                        ? _vm._t(_vm.code, null, { scope: scope })
                        : _c("span", [_vm._v(_vm._s(scope.row[_vm.code]))]),
                    ]
                  },
                },
              ],
              null,
              false,
              2313856383
            ),
          },
          "q-table-column",
          _vm.$attrs,
          false
        )
      )
    : _c(
        "q-table-column",
        _vm._b(
          {
            key: _vm.code + _vm.width,
            attrs: {
              prop: _vm.code,
              label: _vm.otherName || _vm.name,
              width: _vm.width,
              "show-overflow-tooltip": _vm.showTip,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "span",
                      { attrs: { title: _vm.otherName || _vm.name } },
                      [_vm._v(_vm._s(_vm.otherName || _vm.name))]
                    ),
                    _vm.tip
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              "popper-class": "q-tooltip",
                              content: _vm.tip,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "el-icon-warning-outline tip-icon ml5",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.type === "time"
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.parseTime(scope.row[_vm.code]))),
                        ])
                      : _vm.format
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.format(scope.row[_vm.code]))),
                        ])
                      : _vm.type === "slot"
                      ? _vm._t(_vm.code, null, { scope: scope })
                      : _c("span", [_vm._v(_vm._s(scope.row[_vm.code]))]),
                  ]
                },
              },
            ]),
          },
          "q-table-column",
          _vm.$attrs,
          false
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }