<template>
  <div class="page">
    <h1>e-input演示demo</h1>
    <p>
      <span style="background: red">{{ value }}</span>
      <span style="background: red">{{ value.length }}</span>
    </p>
    <div class="input-block">
      <el-row :gutter="20">
        <el-col> 默认 </el-col>
        <el-col>
          <el-input v-model="value"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col> 限制输入</el-col>
        <el-col>
          <el-input v-model="value" maxlength="20" show-word-limit></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col> 可清空 </el-col>
        <el-col>
          <el-input v-model="value" maxlength="20" show-word-limit clearable></el-input>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/global';

.input-block {
  width: 200px;

  .el-row {
    margin-bottom: 20px;
  }

  .el-col {
    margin-bottom: 10px;
  }
}
</style>