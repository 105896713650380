var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "logo",
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme1" ||
          _vm.settings.sideTheme === "theme4"
            ? _vm.variables.menuBg
            : _vm.variables.menuLightBg,
      },
      attrs: { to: { name: _vm.firstRouters } },
    },
    [
      _c("img", {
        attrs: {
          src:
            _vm.settings.sideTheme === "theme1" ||
            _vm.settings.sideTheme === "theme4"
              ? _vm.darkLogo
              : _vm.brightLogo,
        },
      }),
      _c(
        "span",
        {
          style: {
            color:
              _vm.settings.sideTheme === "theme1" ||
              _vm.settings.sideTheme === "theme4"
                ? "#fff"
                : "#303133",
          },
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }