var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: {
        height: "100%",
        width: _vm.variables.sideBarWidth,
      },
    },
    [_c("logo"), _c("main-menu")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }