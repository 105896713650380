<template>
  <div id="tags-view-container" class="tags-view-container">
    <scroll-pane ref="scrollPane" class="tags-view-wrapper vm" @scroll="handleScroll">
      <router-link
        v-for="tag in visitedViews"
        ref="tag"
        :key="tag.path"
        :class="[
          isActive(tag) ? 'active' : '',
          settings.sideTheme === 'theme2' || settings.sideTheme === 'theme4' ? 'dark' : 'bright'
        ]"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        tag="span"
        class="tags-view-item"
        :style="{
          color: settings.sideTheme === 'theme2' || settings.sideTheme === 'theme4' ? '#fff' : '#495060'
        }"
        @click.native="handleSetActiveRouter(tag)"
        @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''"
        @contextmenu.prevent.native="openMenu(tag, $event)"
      >
        {{ generateText('m', tag.title) }}
        <span v-if="!isAffix(tag)" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
      </router-link>
    </scroll-pane>
    <ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="contextmenu">
      <li @click="refreshSelectedTag(selectedTag)">
        {{ $t('tagsView.refresh') }}
      </li>
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">
        {{ $t('tagsView.closeCurrent') }}
      </li>
      <li @click="closeOthersTags">{{ $t('tagsView.closeOthers') }}</li>
      <li v-if="!isLastView()" @click="closeRightTags">
        {{ $t('tagsView.closeRight') }}
      </li>
      <li @click="closeAllTags(selectedTag)">{{ $t('tagsView.closeAll') }}</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from './ScrollPane'
import path from 'path'
import { generateText } from '@/utils/aep'
import { mapGetters, mapState } from 'vuex'
import variables from '@/assets/styles/variables.scss'

export default {
  inject: ['adminReload'],
  components: { ScrollPane },
  data () {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: this.$route,
      affixTags: []
    }
  },
  computed: {
    visitedViews () {
      return this.$store.state.tagsView.visitedViews
    },
    routes () {
      return this.$store.state.permission.routes
    },
    theme () {
      return this.$store.state.settings.theme
    },
    ...mapGetters(['firstRouters', 'sidebarRouters']),
    ...mapState([{
      sideStyle: state => state.settings.sideStyle
    }, 'settings']),
    variables () {
      return variables
    }
  },
  watch: {
    $route () {
      this.addTags()
      this.moveToCurrentTag()
      this.selectedTag = this.$route
    },
    visible (value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
    visitedViews () {
      this.saveTabViews(this.visitedViews)
    }
  },
  mounted () {
    this.initTags()
    this.addTags()
    this.beforeUnload()
  },
  methods: {
    saveTabViews (visitedViews) {
      // visitedViews数据结构太复杂无法直接JSON.stringify处理，先转换需要的数据
      let tabViews = visitedViews.map(item => {
        return {
          fullPath: item.fullPath,
          hash: item.hash,
          meta: { ...item.meta },
          name: item.name,
          params: { ...item.params },
          path: item.path,
          query: { ...item.query },
          title: item.title
        }
      })
      localStorage.setItem('tabViews', JSON.stringify(tabViews))
    },
    beforeUnload () {
      // 监听页面刷新
      window.addEventListener('beforeunload', () => {
        this.saveTabViews(this.visitedViews)
      })
      // 页面初始化加载判断缓存中是否有数据
      let oldViews = JSON.parse(localStorage.getItem('tabViews')) || []
      // 移除没有权限的tab
      this.tabViewsFilter(oldViews)
      if (oldViews.length > 0) {
        this.$store.state.tagsView.visitedViews = oldViews
      }
    },
    // 过滤没有权限的tab
    tabViewsFilter (oldViews) {
      const routeNameAll = this.$store.getters.routeNameAll
      oldViews.forEach((route, index) => {
        if (routeNameAll.includes(route.name) === false) oldViews.splice(index, 1)
      })
    },
    isActive (route) {
      return route.path === this.$route.path
    },
    activeStyle (tag) {
      if (!this.isActive(tag)) return {}
      return {
        'background-color': this.theme,
        'border-color': this.theme
      }
    },
    isAffix (tag) {
      return tag.meta && tag.meta.affix
    },
    isLastView () {
      try {
        return this.selectedTag.fullPath === this.visitedViews[this.visitedViews.length - 1].fullPath
      } catch (err) {
        return false
      }
    },
    filterAffixTags (routes, basePath = '/') {
      let tags = []
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path)
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: { ...route.meta }
          })
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path)
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags]
          }
        }
      })
      return tags
    },
    initTags () {
      const affixTags = this.affixTags = this.filterAffixTags(this.routes)
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch('tagsView/addVisitedView', tag)
        }
      }
    },
    addTags () {
      const { name } = this.$route
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route)
      }
      return false
    },
    moveToCurrentTag () {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag)
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('tagsView/updateVisitedView', this.$route)
            }
            break
          }
        }
      })
    },
    refreshSelectedTag (view) {
      this.$store.dispatch('tagsView/delCachedView', view).then(() => {
        const { fullPath } = view
        this.$nextTick(() => {
          this.$router.replace({
            path: '/redirect' + fullPath
          })
        })
      })
    },
    closeSelectedTag (view) {
      this.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
        this.saveTabViews(visitedViews)
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view)
        }
      })
    },
    closeRightTags () {
      this.$store.dispatch('tagsView/delRightRoutes', this.selectedTag).then(visitedViews => {
        this.saveTabViews(visitedViews)
        if (!visitedViews.find(i => i.fullPath === this.$route.fullPath)) {
          this.toLastView(visitedViews)
        }
      })
    },
    closeOthersTags () {
      this.$router.push(this.selectedTag).catch(() => { })
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeAllTags (view) {
      this.$store.dispatch('tagsView/delAllViews').then(({ visitedViews }) => {
        this.saveTabViews(visitedViews)
        if (this.affixTags.some(tag => tag.path === this.$route.path)) {
          return
        }

        this.toLastView(visitedViews, view)
      })
    },
    toLastView (visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        if (view.path === `/${this.firstRouters}`) {
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push({ name: this.firstRouters })
        }
      }
    },
    openMenu (tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      this.top = e.clientY
      this.visible = true
      this.selectedTag = tag
    },
    contextmenuVisibleFn (e) {
      console.log(e)
      this.left = e.offsetX
      this.top = 50
      this.visible = true
      this.visitedViews.forEach(ele => {
        if (window.location.pathname === ele.path) {
          this.selectedTag = ele
        }
      })
    },
    closeMenu () {
      this.visible = false
    },
    handleScroll () {
      this.closeMenu()
    },
    handleSetActiveRouter (tag) {
      const { path } = tag
      const currentParentPath = `/${path.split('/')[1]}`
      console.log(this.sidebarRouters)
      for (const route of this.sidebarRouters) {
        if (currentParentPath === route.path && route.meta) {
          console.log(route)
          let activeRouter = {
            list: route.children,
            title: route.meta.otherName,
            basePath: route.path
          }
          this.$store.dispatch('ActiveRouter', activeRouter).then(() => { })
          return
        }
      }
    },
    generateText
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/variables';

.tags-view-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 42px;

  .tags-view-wrapper {
    height: 100%;

    .tags-view-item {
      position: relative;
      display: inline-block;
      height: 42px;
      margin: 0 21px;
      font-size: 14px;
      text-align: center;
      line-height: 44px;
      cursor: pointer;

      &:hover {
        color: $mainColor;
      }

      &.bright {
        &.active {
          background-image: url('../../../assets/images/tags-view.png');

          &::before {
            position: absolute;
            display: block;
            left: -21px;
            bottom: 0;
            width: 21px;
            height: 42px;
            background-repeat: no-repeat;
            content: '';
            background-image: url('../../../assets/images/tags-view-l.png');
          }

          &::after {
            position: absolute;
            display: block;
            right: -21px;
            bottom: 0;
            width: 21px;
            height: 42px;
            background-repeat: no-repeat;
            content: '';
            background-image: url('../../../assets/images/tags-view-r.png');
          }
        }

        .el-icon-close {
          color: #495060;

          &:hover {
            background-color: $mainColor;
            color: #fff;
          }
        }
      }

      &.dark {
        &.active {
          background-image: url('../../../assets/images/tags-view-dark.png');
          background-position: bottom;

          &::before {
            position: absolute;
            display: block;
            left: -21px;
            bottom: 0;
            width: 21px;
            height: 42px;
            background-position: bottom;
            background-repeat: no-repeat;
            content: '';
            background-image: url('../../../assets/images/tags-view-l-dark.png');
          }

          &::after {
            position: absolute;
            display: block;
            right: -21px;
            bottom: 0;
            width: 21px;
            height: 42px;
            background-position: bottom;
            background-repeat: no-repeat;
            content: '';
            background-image: url('../../../assets/images/tags-view-r-dark.png');
          }
        }
      }

      &.active {
        background-repeat: repeat-x;
        color: $mainColor;
      }
    }
  }

  .contextmenu {
    position: absolute;
    z-index: 1000;
    padding: 5px 0;
    border-radius: 4px;
    margin: 0;
    background: #fff;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    list-style-type: none;

    li {
      padding: 7px 16px;
      margin: 0;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }
}

.contextmenu {
  font-size: 12px;
  font-weight: 400;
  color: #333;

  li {
    padding: 7px 16px;
    margin: 0;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}

.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;

      &::before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -1px;
      }
    }
  }
}
</style>
