import request from '@/utils/request'

// 厂商管理，数据权限列表
export function orgAuth (query) {
  return request({
    url: '/sysuser/orgAuth/getByVendorId',
    method: 'get',
    params: query
  })
}

// 厂商管理，数据权限修改
export function orgAuthUpdate (data) {
  return request({
    url: '/sysuser/orgAuth/update',
    method: 'post',
    data: data
  })
}

// 查询企业列表
export function listVendor (query) {
  return request({
    url: '/sysuser/vendor/page',
    method: 'get',
    params: query
  })
}

// 查询企业列表下拉框
export function selectVendor () {
  return request({
    url: '/sysuser/vendor/select',
    method: 'get'
  })
}

// 获取企业产品列表
export function listProductVendor (query) {
  return request({
    url: '/product/product/getProductByVendorId',
    method: 'get',
    params: query
  })
}

// 产品关联应用
export function confProductApp (data) {
  return request({
    url: '/system/product/confProductApp',
    method: 'put',
    data: data
  })
}

// 更新基本设置
export function updateVendorAccessInfo (data) {
  return request({
    url: '/system/vendor/updateVendorAccessInfo',
    method: 'put',
    data: data
  })
}

// 获取主账号用户厂商信息
export function getInfo (query) {
  return request({
    url: '/sysuser/vendor/get',
    method: 'get',
    params: query
  })
}

// 测试是否能获取accessToken
export function testGetAccessToken (query) {
  return request({
    url: '/system/vendor/testGetAccessToken',
    method: 'get',
    params: query
  })
}

// 获取所有租户排除移远
export function tenantAll (query) {
  return request({
    url: '/sysuser/tenant/select',
    method: 'get',
    params: query
  })
}

// 获取平台OEM个性化配置
export function getPlatformOemConfInfo (query) {
  return request({
    url: '/system/company/conf/getPlatformOemConfInfo',
    method: 'get',
    params: query
  })
}

// 新增或者更新平台OEM个性化配置
export function updatePlatformOemConfInfo (data) {
  return request({
    url: '/system/company/conf/addOrUpdatePlatformOemConf',
    method: 'post',
    data: data
  })
}

// 查询系统安全配置
export function getSecuritySettingInfo (query) {
  return request({
    url: '/system/company/conf/getSecuritySettingInfo',
    method: 'get',
    params: query
  })
}

// 更新系统安全配置
export function updateSecuritySetting (data) {
  return request({
    url: '/system/company/conf/addOrUpdateSecuritySetting',
    method: 'post',
    data: data
  })
}

// 查询微信支付配置信息
export function getWechatPaymentConfInfo (query) {
  return request({
    url: '/system/company/conf/getWechatPaymentConfInfo',
    method: 'get',
    params: query
  })
}

// 更新微信支付配置
export function updateWechatPaymentConf (data) {
  return request({
    url: '/system/company/conf/addOrUpdateWechatPaymentConf',
    method: 'post',
    data: data
  })
}

// 查询企业邮件提醒配置
export function getEmailServerConfInfo (query) {
  return request({
    url: '/system/company/conf/getEmailServerConfInfo',
    method: 'get',
    params: query
  })
}

// 更新企业邮件提醒配置
export function updateEmailServerConf (data) {
  return request({
    url: '/system/company/conf/addOrUpdateEmailServerConf',
    method: 'post',
    data: data
  })
}

// 查询第三方接入配置
export function getThirdConfInfo (query) {
  return request({
    url: '/system/company/conf/getThirdConfInfo',
    method: 'get',
    params: query
  })
}

// 更新第三方接入配置
export function updateThirdConf (data) {
  return request({
    url: '/system/company/conf/addOrUpdateThirdConf',
    method: 'post',
    data: data
  })
}

// 获取是否允许代理商设置
export function getAllowAgentSetting (query) {
  return request({
    url: '/system/company/conf/getAllowAgentSetting',
    method: 'get',
    params: query
  })
}

// 校验域名唯一性
export function checkSystemDomainUnique (query) {
  return request({
    url: '/system/company/conf/checkSystemDomainUnique',
    method: 'get',
    params: query
  })
}

// 根据主账号用户id查询卡平台配置信息
export function getSimCardAccountConfInfo (query) {
  return request({
    url: '/system/company/conf/getSimCardAccountConfInfo',
    method: 'get',
    params: query
  })
}

// 更新卡平台配置
export function updateSimCardAccountConf (data) {
  return request({
    url: '/system/company/conf/addOrUpdateSimCardAccountConf',
    method: 'post',
    data: data
  })
}

// 修改预设角色数据
export function editCustomRole (data) {
  return request({
    url: '/sysuser/role/editCustomRole',
    method: 'put',
    data: data
  })
}

// 获取厂商的的标签角色
export function vendorLableRoles (query) {
  return request({
    url: '/sysuser/role/vendorLableRoles',
    method: 'get',
    params: query
  })
}

// 关联厂商的标签角色
export function associaVendorLableRole (data) {
  return request({
    url: '/system/role/associaVendorLableRole',
    method: 'post',
    data
  })
}

// 设置存储周期
export function editStorageDuration (data) {
  return request({
    url: '/product/product/editStorageDuration',
    method: 'put',
    data
  })
}
// 批量设置存储周期
export function batchStorageDuration (data) {
  return request({
    url: '/product/product/batchEditStorageDuration',
    method: 'put',
    data
  })
}

// 续费
export function renewal (data) {
  return request({
    url: '/bizorder/order/renewal',
    method: 'post',
    data
  })
}
// 二维码轮询
export function loopOrder (query) {
  return request({
    url: '/bizorder/order/getRenewalOrder',
    method: 'get',
    params: query
  })
}
// 查询续费费用
export function selectRenewalFeeItem (data) {
  return request({
    url: '/bizorder/order/selectRenewalFeeItem',
    method: 'post',
    data
  })
}

// 修改小程序和web预设角色数据(1.4.3)
export function editRoleCombine (data) {
  return request({
    url: '/sysuser/role/editCustomRoleCombine',
    method: 'put',
    data
  })
}
