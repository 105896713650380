import router from '@/router'

const routes = [
  {
    name: 'QInput',
    path: '/quec-element-ui/input',
    component: () => import('./input')
  }
]

router.addRoutes(routes)