<template>
  <div
    class="navbar"
    :class="settings.sideTheme === 'theme2' || settings.sideTheme === 'theme4' ? 'theme-dark' : 'theme-light'"
    :style="{
      backgroundColor:
        settings.sideTheme === 'theme2' || settings.sideTheme === 'theme4' ? variables.menuBg : variables.menuLightBg
    }"
  >
    <div v-if="showProgress" class="running" @click="taskRunVisible = true">
      <ProgressBall :chart-data="chartData" />
    </div>
    <!--list 弹窗-->
    <TaskRunData
      v-if="taskRunVisible"
      ref="addDialog"
      :visible.sync="taskRunVisible"
      :title="$t('task.running')"
      :task-run-data="taskRunList"
    />
    <div class="tags-view fl">
      <TagsView />
    </div>

    <div class="right-menu">
      <a v-if="orgtype === 2 && needFull === 'true'" type="text" class="fl full-screen" @click="toFullScreen">
        <img src="../../../assets/images/dashboard/icon-full-top.png" class="vm" alt="" />
        <i class="vm">可视化大屏</i>
      </a>
      <!-- 文档 -->
      <div class="notify" @click="goGuide()">
        <i class="el-icon-document" style="font-size: 21px" />
      </div>
      <!-- 站内信 -->
      <div v-hasPermi="['letter']" class="notify" @click="goLetter()">
        <el-badge :value="UnReadCount" :max="10" :hidden="UnReadCount === 0">
          <i class="el-icon-bell" style="font-size: 21px" />
        </el-badge>
      </div>
      <!-- 语言 -->
      <!-- <el-dropdown class="lang" trigger="click" @command="changeLang">
        <i class="iconfont iconlanguage" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh-CN">简体中文</el-dropdown-item>
          <el-dropdown-item command="en-US">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <!-- 组织信息 -->
      <div
        v-if="orgtype !== 1"
        class="orgName"
        :style="{
          border: `1px solid ${orgInfo.color}`,
          borderLeftStyle: 'none'
        }"
      >
        <span class="text-o" :style="{ background: `${orgInfo.color}` }">
          <svg-icon :icon-class="orgInfo.icon" />{{ orgInfo.name }}
        </span>
        <span class="text-o" :title="orgName" :style="{ color: ` ${orgInfo.color}` }">
          {{ orgName }}
        </span>
      </div>
      <!-- 个人信息 -->
      <el-dropdown class="avatar-container hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar" />
          <span class="user-name text-o">{{ name }}</span>
          <i class="el-icon-arrow-down user-icon" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link v-hasPermi="['personal']" to="/profile">
            <el-dropdown-item>{{ $t('navbar.personal') }}</el-dropdown-item>
          </router-link>
          <el-dropdown-item v-if="pan === true" v-hasPermi="['companyInfo']" @click.native="handleDeploy">
            {{ $t('navbar.company') }}
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>{{ $t('navbar.logout') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="theme" @click.stop="setting = true">
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TaskRunData from './TaskRunData'
import ProgressBall from '@/components/ProgressBall'
import TagsView from '../TagsView'
import variables from '@/assets/styles/variables.scss'
import { getUnReadCount } from '@/api/login'
const orgStyleList = [
  { orgtype: 2, name: '厂商', color: '#4C72EF', icon: 'factory' },
  { orgtype: 10, name: '运营商', color: '#FF7E37', icon: 'operator' },
  { orgtype: 20, name: '商户', color: '#08B0B1', icon: 'merchant' }
]
export default {
  inject: ['adminReload'],
  components: {
    TaskRunData,
    ProgressBall,
    TagsView
  },
  computed: {
    ...mapGetters([
      'avatar',
      'device',
      'name',
      'pan',
      'orgtype',
      'userId',
      'tenantId',
      'taskRunData',
      'UnReadCount',
      'orgName'
    ]),
    ...mapState(['settings']),
    setting: {
      get () {
        return this.$store.state.settings.showSettings
      },
      set (val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    variables () {
      return variables
    }
  },
  data () {
    return {
      needFull: process.env.VUE_APP_NEED_FULL,
      taskRunVisible: false,
      // 定时（进行中的：如果返回的数据不为空，要一直轮询调用接口，更新进度。每3秒轮询一次）
      timer: null,
      taskRunList: [],
      chartData: 0,
      // 成功数
      successNum: 0,
      // 失败数
      failNum: 0,
      // 总数
      totalNum: 0,
      // 是否显示进度球
      showProgress: false,
      orgInfo: []
    }
  },
  created () {
    if (!!this.taskRunData && this.taskRunData.length > 0) {
      this.taskRun()
    }
    this.getUnReadCount()
    this.formatSaasList()
  },
  methods: {
    formatSaasList () {
      let item = orgStyleList.find(item => item.orgtype === this.orgtype)
      if (item) {
        this.orgInfo = item
      }
    },
    goLetter () {
      this.$router.push({ name: 'Letter' })
    },
    goGuide () {
      window.open('https://biz-saas.feishu.cn/wiki/ATwJwCHXriWHXqkVaVyc4E86nLc', '_blank')
    },
    toFullScreen () {
      // eslint-disable-next-line no-case-declarations
      const router = this.$router.resolve({
        path: '/dashboardCharts'
      })
      window.open(router.href, '_blank')
    },
    async getUnReadCount () {
      const response = await getUnReadCount()
      if (response.data !== undefined) {
        this.$store.dispatch('getUnReadCount', response.data)
      }
    },
    async logout () {
      this.confirmBox(
        { title: this.$t('common.prompt'), content: this.$t('common.logout') },
        () => {
          this.$store.dispatch('LogOut').then(() => {
            location.href = '/index'
          })
        }
      )
    },
    // 切换语言
    changeLang (command) {
      this.$i18n.locale = command
      this.$store.dispatch('app/setLanguage', command)
      this.adminReload()
    },
    /** 企业配置 */
    handleDeploy () {
      this.$router.push({
        name: 'CompanyInfo',
        query: {
          userId: this.userId,
          tenantId: this.tenantId
        }
      })
    },
    // 如果有执行中的任务调用此方法
    taskRun () {
      this.taskRunList = this.taskRunData
      this.showProgress = true
      this.timer = setInterval(() => {
        this.$store.dispatch('GetTaskRunning').then((res) => {
          if (res.data && res.data.length > 0 && this.chartData < 1) {
            this.taskRunList = res.data
            this.successNum = 0
            this.failNum = 0
            this.totalNum = 0
            this.taskRunList.forEach(item => {
              this.successNum += item.successNum
              this.failNum += item.failNum
              this.totalNum += item.totalNum
              this.chartData = (this.successNum + this.failNum) / this.totalNum
            })
          } else {
            clearInterval(this.timer)
            this.showProgress = false
          }
        })
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  position: relative;
  display: flex;
  height: 50px;
  padding-right: 30px;
  background: #fff;
  justify-content: space-between;

  .tags-view {
    position: relative;
    height: 100%;
    flex: 1;
  }

  .right-menu {
    display: flex;
    max-width: 500px;
    height: 100%;
    line-height: 50px;

    .lang {
      float: left;
      padding: 0 10px;
      margin-right: 10px;
      cursor: pointer;

      .iconlanguage {
        font-size: 20px;
        color: #c5c7cd;
      }
    }

    .avatar-container {
      padding-right: 8px;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }

      .avatar-wrapper {
        position: relative;
        display: flex;
        float: right;
        height: 100%;
        align-items: center;

        .user-avatar {
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 100%;
        }

        .user-name {
          max-width: 145px;
          margin-left: 10px;
          font-size: 14px;

          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
        }

        .user-icon {
          margin-left: 10px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

.full-screen {
  width: 106px;
  height: 36px;
  margin: 7px 20px 0 0;
  background: url('../../../assets/images/dashboard/buton_top_bg.png') no-repeat center center;
  font-size: 12px;

  // font-size: .12rem;
  color: #fff;
  text-align: center;
  line-height: 36px;

  // color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  i {
    margin-left: 5px;
  }
}

.running {
  position: fixed;
  right: 20px;
  bottom: 50px;
  z-index: 1899;

  // padding: 4px 10px;
  // border-radius:4px;
  // background: $--color-primary;
  color: #fff;
  cursor: pointer;
}

.theme {
  float: right;
  width: 20px;
  height: 20px;
  margin-top: 15px;
  margin-left: 20px;
  cursor: pointer;

  span {
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    margin: 3px auto;
    background: #595959;
  }
}

.theme-dark {
  .iconlanguage,
  .user-name,
  .user-icon {
    color: #fff !important;
  }

  .theme span {
    background: #fff !important;
  }
}

.notify {
  float: left;
  padding: 0 10px;
  margin-right: 10px;
  font-size: 22px;
  cursor: pointer;

  /deep/ .el-badge__content.is-fixed {
    top: 10px;
  }
}

.orgName {
  display: flex;
  height: 24px;
  border: 1px solid #4c72ef;
  border-left-style: none;
  border-radius: 8px;
  margin: 13px 20px 13px 0;
  font-size: 14px;
  line-height: 23px;
  align-items: center;

  span {
    &:nth-child(1) {
      display: flex;
      max-width: 200px;
      padding: 0 12px 0 8px;
      border-radius: 8px;
      background-color: #5479f2;
      color: #fff;
      align-items: center;
      .svg-icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        margin-right: 8px;
      }
    }

    &:nth-child(2) {
      max-width: 160px;
      padding: 0 8px;
    }
  }
}
</style>
