var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("h1", [_vm._v("e-input演示demo")]),
    _c("p", [
      _c("span", { staticStyle: { background: "red" } }, [
        _vm._v(_vm._s(_vm.value)),
      ]),
      _c("span", { staticStyle: { background: "red" } }, [
        _vm._v(_vm._s(_vm.value.length)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "input-block" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", [_vm._v(" 默认 ")]),
            _c(
              "el-col",
              [
                _c("el-input", {
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", [_vm._v(" 限制输入")]),
            _c(
              "el-col",
              [
                _c("el-input", {
                  attrs: { maxlength: "20", "show-word-limit": "" },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", [_vm._v(" 可清空 ")]),
            _c(
              "el-col",
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "20",
                    "show-word-limit": "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }