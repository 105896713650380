import hasRole from './hasRole'
import hasPermi from './hasPermi'
import listBtnDisable from './listBtnDisable'// 表格当前行权限

const install = function (Vue) {
  Vue.directive('hasRole', hasRole)
  Vue.directive('hasPermi', hasPermi)
  Vue.directive('listBtnDisable', listBtnDisable)
}

if (window.Vue) {
  window.hasRole = hasRole
  window.hasPermi = hasPermi
  window.listBtnDisable = listBtnDisable
  Vue.use(install); // eslint-disable-line
}

export default install
