<template>
  <div class="child">
    <div class="main-title text-o">
      {{ title ? generateText('m', title) : '' }}
    </div>
    <div class="child-item">
      <child-item
        v-for="(route, index) in list"
        :key="route.path + index"
        :item="route"
        :base-path="basePath"
        @saveRouter="handleSaveRouter"
      />
    </div>
  </div>
</template>

<script>
import ChildItem from './ChildItem'
import { generateText } from '@/utils/aep'
export default {
  components: { ChildItem },
  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  methods: {
    handleSaveRouter () {
      let activeRouter = {
        list: this.list,
        title: this.title,
        basePath: this.basePath
      }
      this.$store.dispatch('ActiveRouter', activeRouter).then(() => {
        this.$emit('handleClick')
      })
    },
    generateText
  }
}
</script>

<style lang="scss" scoped>
.main-title {
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #f4f6f9;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  line-height: 50px;
}

.child {
  font-size: 14px;

  &-item {
    padding-left: 4px;
  }
}

.theme-light {
  .main-title {
    border-bottom: 1px solid #fff;
    color: #333;
  }
}
</style>
