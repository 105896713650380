<template>
  <div :class="(classObj, { gdprOpen: gdpr })" class="app-wrapper">
    <div v-if="device === 'mobile'" class="drawer-bg" @click="handleClickOutside" />
    <sidebar />
    <div
      class="main-container"
      :class="{ pcOpenSidebar: isActive && sideStyle === 'menu1' }"
      :style="{
        marginLeft: sideStyle === 'menu1' ? variables.sideBarWidth : variables.twoSideBarWidth
      }"
    >
      <div
        class="fixed-header"
        :style="{
          width:
            sideStyle === 'menu1'
              ? `calc(100% - ${variables.sideBarWidth})`
              : `calc(100% - ${variables.twoSideBarWidth})`
        }"
      >
        <navbar />
        <Alert />
      </div>
      <app-main />
      <right-panel>
        <settings />
      </right-panel>
    </div>

    <div v-if="gdpr" class="gdpr-mask">
      <div class="gdpr">
        <div class="gdpr-header clearfix">
          <h1 class="gdpr-header-title fl">
            {{ $t('gdpr.title') }}
          </h1>
          <img class="gdpr-header-logo fr" :src="quectelLogo" alt="" />
        </div>
        <p class="gdpr-version">
          <span>{{ $t('gdpr.time1') }}</span><span>{{ $t('gdpr.time2') }}</span>
        </p>
        <div class="gdpr-tab">
          <div>
            <span :class="{ active: currentType === 'privacy' }" @click="currentType = 'privacy'">{{
              $t('gdpr.item1')
            }}</span>
            <span :class="{ active: currentType === 'service' }" @click="currentType = 'service'">{{
              $t('gdpr.item2')
            }}</span>
          </div>
        </div>
        <div v-if="lang === 'zh-CN'" class="gdpr-container">
          <div v-if="currentType === 'privacy'">
            <p>
              本隐私协议适用于移远（以下简称“移远”、“我们”）提供的所有产品和服务，规定了移远收集哪些数据、为什么收集这些数据、如何利用这些数据做什么以及如何保护这些数据。您的信任对我们至关重要，我们深知个人信息对您的重要性。我们将按照法律法规要求，在合理、必要的范围内，向您收集个人信息。我们竭尽全力通过合理有效的信息安全技术和管理流程，采取相应的安全保护措施，防止您的信息泄露、损毁或丢失。
            </p>
            <br />
            <b>定义：</b>
            <p>
              1.个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
            </p>
            <p>
              2.个人敏感信息：包括身份证件号码、个人生物识别信息、财产信息、行动轨迹、交易信息、14岁以下（含）儿童的个人信息。<b>敏感信息在本协议中会做加粗加下划线提示。</b>
            </p>
            <p>
              3.个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
            </p>
            <p>
              4.本协议适用于移远平台所有产品和服务。<b>移远平台</b>是指上海移远通信技术股份有限公司及其子公司、关联公司（以下称为“移远公司”）运营的移远官方网站（https://www.quectel.com/）、（https://iot-aep.com/）、（https://iot-cloud.quectel.com/），以及后续由移远公司所有或运营的其他移远相关平台。
            </p>
            <br />
            <b>
              请注意，本协议不适用于第三方向您提供的服务，例如通过阿里云短信服务、微信支付服务，您向该第三方服务商提供的个人信息不适用本协议。
            </b>
            <br />
            <br />
            <b>
              在使用移远平台各项产品或服务前，请您务必仔细阅读并透彻理解本协议，特别是加粗、加下划线标识的条款，您应当重点阅读，在确认充分理解并同意后再开始使用。当您访问或使用移远平台，即表示您已经同意本隐私协议并信赖我们对您的信息的处理方式。如对本协议有任何疑问，您可向移远客服咨询（400-960-7678）或通过移远任一平台上提供的联系方式与我们联系。如您为未满18周岁的未成年人
              ，请在法定监护人陪同下阅读本协议，并特别注意未成年人使用条款。
            </b>
            <br />
            <br />
            <b> 本协议包含以下内容： </b>
            <p>1、我们收集您哪些信息。</p>
            <p>2、我们如何使用您的信息。</p>
            <p>3、我们在何种情况下共享您的信息。</p>
            <p>4、我们如何存储您的信息。</p>
            <p>5、我们如何保护您的信息。</p>
            <p>6、您所享有的权利。</p>
            <p>7、个人信息主体注销帐号。</p>
            <p>8、未成年人信息如何保护。</p>
            <p>9、您如何联系我们。</p>
            <br />
            <b>1、我们收集您哪些信息</b>
            <p>
              我们收集您的信息以合法、合理、必要为原则，在向您提供某项具体服务时，我们将明确告知您将收集的信息以及用途，来响应您具体的服务请求。根据您选择的服务，我们在以下场景可能收集以下信息的一种或多种：
            </p>
            <p class="p1">
              1.1、当您开通账号成功后，在个人资料栏您可以选择性填写您的个人信息，例如<b>您的昵称、性别、头像、电子邮件、手机号码</b>等相关信息。此类信息由您自行维护，在使用AEP应用使能平台相关服务时，可节省填写带来的服务迟延，除必要信息外我们不会强制要求您填写，您可选择性维护，也可以选择实际使用时再填写。
            </p>
            <p class="p1">
              1.2、
              <b>
                需要特别注意的是，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
              </b>
            </p>
            <p class="p1">
              1.3、为提高您使用移远平台服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或移远平台相关协议规则的情况，我们可能使用或整合您的帐号信息、设备交互信息、网络日志信息以及我们取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取
              必要的记录、审计、分析、处置措施。
            </p>
            <p class="p1">
              1.4、
              <b>
                若您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前获得对应儿童监护人的同意，前述情况下的监护人有权通过本协议第9条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。
              </b>
            </p>
            <p class="p1">
              若我们将信息用于本协议未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
            </p>
            <p class="p1">
              1.5、
              <b>
                征得授权同意的例外：您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
              </b>
            </p>
            <p class="p1">1.5.1、<b>与国家安全、国防安全有关的；</b></p>
            <p class="p1">1.5.2、<b>与公共安全、公共卫生、重大公共利益有关的；</b></p>
            <p class="p1">1.5.3、<b>与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</b></p>
            <p class="p1">1.5.4、<b>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</b></p>
            <p class="p1">1.5.5、<b>您自行向社会公众公开的个人信息；</b></p>
            <p class="p1">1.5.6、<b>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</b></p>
            <p class="p1">1.5.7、<b>根据与您签订和履行相关协议或其他书面文件所必需的；</b></p>
            <p class="p1">
              1.5.8、<b>用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；</b>
            </p>
            <p class="p1">1.5.9、<b>为合法的新闻报道所必需的；</b></p>
            <p class="p1">
              1.5.10、<b>学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</b>
            </p>
            <p class="p1">1.5.11、<b>法律法规规定的其他情形。</b></p>
            <br />
            <b>2、我们如何使用您的信息</b>
            <p class="p1">
              2.1、我们会保持您的个人数据及时更新；安全地存储或销毁它；最小化收集和保留我们需要为您提供服务所需的数据；保护个人信息免遭泄露、滥用、未经授权的访问和披露；采取适当的技术措施保护您个人信息的安全。
            </p>
            <p class="p1">2.2、我们将您的信息用于以下用途：</p>
            <p class="p1">2.2.1、根据我们与您签订的合同向您提供服务（账号登录、消息通知等）。</p>
            <p class="p1">2.2.2、按照我们收集信息时所述的要求提供其他您所要求的服务。</p>
            <p class="p1">2.2.3、为了内部管理和后台支持，与全球各地公司的分支机构分享您的联系方式。</p>
            <p class="p1">2.2.4、维持我们储存和处理您个人信息的数据技术系统的完整性和安全性。</p>
            <p class="p1">2.2.5、执行或保护我们的协议或与您签订的合同。</p>
            <p class="p1">2.2.6、检测和调查信息泄露、非法活动和欺诈行为。</p>
            <p class="p1">2.2.7、联系您解决问题。</p>
            <p class="p1">2.2.8、经过您许可的其它用途。</p>
            <p class="p1">
              2.3、需要注意的是，以上使用方式涵盖了我们使用您信息的主要方式。我们可能不定期通过更新网站页面方式，为用户提供更加多样化的服务。当我们要将信息用于本协议未载明的其他用途时，或要将基于特定目的收集而来的信息用于其他目的时，会以签署协议、弹窗提示、更新本协议等形式再次征求您的同意。
            </p>
            <br />
            <b> 3、我们在何种情况下共享您的信息 </b>
            <b> 我们不会与移远以外的公司、组织和个人共享您的个人信息，但以下情况除外： </b>
            <p class="p1">
              3.1、
              <b>
                在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
              </b>
            </p>
            <p class="p1">
              3.2、
              <b>
                获取明确同意的情况下共享：获得您的明确同意或授权或您主动选择的情况下，我们会与其他方共享您的个人信息。
              </b>
            </p>
            <p class="p1">
              3.3、
              <b>
                与关联公司间共享：为便于我们向您提供产品和服务，识别帐号异常，保护其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本协议中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
              </b>
            </p>
            <p class="p1">
              3.4、
              <b>
                与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本协议声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。此类处理后数据的使用和共享我们无需另行向您通知并征得您的同意。
              </b>
            </p>
            <p class="p1">
              <b> 目前，我们的授权合作伙伴包括以下类型： </b>
            </p>
            <p class="p1">
              3.4.1、
              <b>
                供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
              </b>
            </p>
            <p class="p1">
              3.5、
              <b> 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外： </b>
            </p>
            <p class="p1">
              3.5.1、
              <b> 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息； </b>
            </p>
            <p class="p1">
              3.5.2、
              <b>
                在移远平台服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本协议的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
              </b>
            </p>
            <p class="p1">
              3.5.3、
              <b> 共享、转让、披露个人信息时的事先征得授权同意的例外，依据相关现行有效的法律法规的要求执行。 </b>
            </p>
            <b> 如您不同意我们按照上述条款向第三方提供您的个人信息，请您立即停止使用我方平台服务。 </b>
            <br />
            <br />
            <b>4、我们如何存储您的信息</b>
            <p>除非法律有另外规定，移远平台将按照如下期限保存您的信息：</p>
            <p class="p1">
              4.1、您在使用移远平台产品或服务期间，我们将持续为您保存，除非您主动删除或注销AEP应用使能平台帐号。
            </p>
            <p class="p1">
              4.2、当您删除信息或注销移远平台帐号后，移远平台将停止对您个人信息的使用，并在一定期限内及时删除您的个人信息。
            </p>
            <p class="p1">
              4.3、您的个人信息经过匿名化与脱敏处理后将形成可以使用的流通数据，移远平台对此数据的保存和使用无需另外征得您的同意。
            </p>
            <br />
            <b>5、我们如何保护您的信息</b>
            <p class="p1">
              5.1、我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对移远平台各网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
            </p>
            <p class="p1">
              5.2、互联网并非绝对安全的环境，使用移远平台产品或服务时，我们强烈建议您不要使用非移远平台推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。
            </p>
            <p class="p1">
              5.3、在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
            </p>
            <br />
            <b>6、您所享有的权利</b>
            <p>您有权利管理您的信息，包括：</p>
            <p class="p1">6.1、访问您的个人信息：</p>
            <p class="p1">
              6.1.1、帐号信息：如果您希望访问或编辑您的帐户中的个人资料信息、更改您的密码、头像、昵称等，您可以登录您的帐户后通过设置访问您的信息。
            </p>
            <p class="p1">
              6.1.2、其它个人信息（例如系统登录、消息推送等服务时）：对于您使用我们的产品或服务过程中产生的个人信息，如您需要，可通过第九章的联系方式，与我们取得联系。
            </p>
            <p class="p1">6.2、更正您的个人信息</p>
            <p class="p1">
              当您发现我们处理您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过访问个人信息设置页面进行更正或补充说明或者直接联系我们。
            </p>
            <p class="p1">6.3、删除您的个人信息</p>
            <p class="p1">在下列情况中，您可以联系移远平台客服提出删除个人信息请求：</p>
            <p class="p1">6.3.1、如果我们处理个人信息的行为违反法律法规。</p>
            <p class="p1">6.3.2、如果我们收集、使用您的个人信息，却未征得您的明确同意。</p>
            <p class="p1">6.3.3、如果我们处理个人信息的行为严重违反了与您的约定。</p>
            <p class="p1">6.3.4、如果您不需要使用我们的产品与服务，或您主动注销了移远应用使能平台帐号。</p>
            <p class="p1">6.3.5、如果永久不需要我们提供产品或服务。</p>
            <p class="p1">
              如我们决定响应您的删除请求，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息。
            </p>
            <br />
            <b>7、个人信息主体注销帐号</b>
            <p class="p1">
              7.1、你随时可以注销此前注册的帐号，如您需要，您可以通过第九章的联系方式，与我们取得联系,进行个信息主体注销帐号。
            </p>
            <p class="p1">7.2、在你注销帐号之后，我们将停止为您提供产品或服务，并依据你的要求，删除你的个人信息。</p>
            <br />
            <b>8、未成年人信息如何保护</b>
            <p class="p1">
              8.1、我们的产品和服务主要面向成年人。对于经法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、法定监护人明确同意或者保护未成年人所必要的情况下使用或公开披露合法收集到的未成年人的个人信息。
            </p>
            <p class="p1">
              8.2、如果我们发现在未事先获得法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如果法定监护人有理由相信未成年人未经他们事先同意而向移远平台提交了个人信息，请联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的移远应用使能平台服务。
            </p>
            <br />
            <b>9、您如何联系我们</b>
            <p class="p1">
              9.1、如果您对本隐私协议有任何疑问、意见或建议，或者您想要行使任何权利，或者您有任何要求要与我们讨论，请通过以下方式与我们联系：
            </p>
            <p class="p1">地址：上海市闵行区田林路1016号科技绿洲3期（B区）5号楼</p>
            <p class="p1">电子邮件：quecteldpo@quectel.com</p>
            <p class="p1">联系电话：400-960-7678</p>
            <p class="p1">
              9.2、为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。正常情况下，我们将在15天内回复。
            </p>
            <p class="p1">
              9.3、本协议签订地为中华人民共和国上海市闵行区。本协议的生效、履行、解释及争议的解决，您因使用移远平台或服务而产生的或与本协议相关的一切争议、权利主张或其他事项，均适用中华人民共和国法律（港澳台地区除外）。您与移远公司或移远平台发生的一切争议，应友好协商，如协商不成，可选择如下任一途径解决：
            </p>
            <p class="p1">
              9.3.1、提交上海仲裁委员会，按照申请仲裁时现行有效的仲裁规则进行仲裁；仲裁裁决为终局裁决，对各方均有约束力；
            </p>
            <p class="p1">9.3.2、提交协议签订地人民法院诉讼管辖。</p>
            <br />
            <p>请您再次确认您已经全部阅读并充分理解上述条款。</p>
            <p class="p-text-r">
              移远<br />
              2021年07月21日版本<br />
              2021年07月21日生效
            </p>
          </div>
          <div v-if="currentType === 'service'">
            <b>1、重要提示</b>
            <p class="p1">
              1.1、在使用移远（上海移远股份有限公司及其关联公司）提供任何的软件、网站、服务（统称为“移远产品或服务”）之前，
              <b>
                请您务必认真阅读并充分理解《移远服务协议》、《移远隐私协议》（统称为“移远服务条款”），特别是粗体标注的内容。
              </b>
            </p>
            <p class="p1">
              1.2、如果您对本条款有疑问，请您通过移远公开披露的渠道进行询问。
              <b>
                本协议的条款可由移远随时修改，您理解并同意，修订后的本条款具有溯及力。您一旦开始使用移远产品或服务，即表示您已经理解并同意相关协议，该协议即构成对您具有法律约束力的法律文件。
              </b>
              当您使用移远某一单项服务时，您应遵守与该服务相关的指引和规则。上述所有的指引和规则，均构成本使用协议的一部分。
            </p>
            <p class="p1">
              1.3、您声明您有充分的权力、能力和权限来同意和履行本条款。如果您代表您的雇主或客户同意本条款，您声明您有充分的法律授权使您的雇主或客户受到本条款的约束。如果您没有充分的法律授权，请您确保由您的雇主或客户的授权人士来同意本条款。
            </p>
            <p class="p1">
              1.4、如您是未成年用户，请在法定监护人的陪同下仔细阅读本使用协议且在您的法定监护人同意的前提下用我们的产品或服务或向我们提供信息。
            </p>
            <p class="p1">
              1.5、
              <b> 您确认，您不是任何组织、地区或国家的贸易限制、制裁或其他法律、规则限制的对象。 </b>
            </p>
            <br />
            <b>2、个人信息保护</b>
            <p class="p1">
              2.1、个人信息保护是移远坚守的基本原则之一。
              <b>
                在您使用移远产品或服务的过程中，我们会可能基于您需求的服务，收集和使用您的个人信息，包括您的位置信息、设备信息，部分功能中还会包括您的身份信息、联系方式等信息。
              </b>
              移远将根据
              <b>
                《移远隐私协议》的规定收集、存储、使用和披露您的个人信息。请您认真阅读《移远隐私协议》并在确认同意后使用移远产品或服务。
              </b>
            </p>
            <p class="p1">
              2.2、当您使用移远产品或服务，即表示您信赖我们对您的信息的处理方式，并已同意移远服务协议及隐私协议等服务政策中关于您的信息收集及使用的条款。
            </p>
            <br />
            <b>3、服务说明</b>
            <p class="p1">
              3.1、移远产品或服务由移远及其关联公司（本条款中统称为“移远”或“我们”）提供。
              <b>
                您了解并同意，移远提供的产品与服务仅依其当前所呈现的状况提供，移远明确拒绝对“产品”或“服务”等作出任何明示或暗示的担保或保证，包括但不限于，为商业使用、适合于特定目的或未侵害他人权利之担保或保证等。移远对“产品”或“服务”等所产生的任何直接、间接、附带的或因此而导致的损失概不负责。
              </b>
              移远有权自行确定移远产品或服务及其功能和服务的具体内容和形式。移远有权自行决定增加、变更、中断和停止移远产品或服务及其功能或服务。
            </p>
            <p class="p1">
              3.2、为完善移远产品或服务，移远将适时发布移远产品或服务后续版本。
              <b> 除非本条款另有明确规定，移远产品或服务的任何升级或更新版本，均受到本条款的规范。 </b>
            </p>
            <p class="p1">
              3.3、新版本移远产品或服务发布后，旧的版本可能无法使用。
              <b> 移远不保证旧版本可继续使用，因此请您及时下载最新的版本。</b>
            </p>
            <br />
            <b>4、授权及许可</b>
            <p class="p1">
              4.1、移远产品或服务提供的内容是指移远通过移远产品或服务提供的任何内容，不论该内容是否由移远创造或被其他第三方许可使用（以下简称为“相关内容”）。
            </p>
            <p class="p1">
              4.2、
              <b>
                您不得（且您不得允许其他人）为任何商业目的使用移远产品或服务，从而直接或间接获得任何形式的收益。如果您有商业运营需要，则您需同移远另行签署书面协议或事先获得移远书面许可。
              </b>
            </p>
            <p class="p1">
              4.3、您使用产品或服务，可通过移远授权的第三方下载等方式获取移远产品或服务。
              <b>
                若您非从移远或移远授权的第三方获取移远产品或服务，移远无法保证该产品正常使用，您遭受的任何损失与移远无关。
              </b>
            </p>
            <p class="p1">
              4.4、
              <b>
                本条款未明示授权的其他一切权利仍归移远所有，并且不能将本条款理解为以默示或其他方式授予您任何其他权利。您使用其他权利时需要同移远另行签署书面协议或事先获得移远的书面许可。
              </b>
            </p>
            <p class="p1">
              4.5、尽管移远会持续不断提高服务质量，但由于各种客观或技术等原因，可能导致移远产品或服务有局限性，因此
              <b> 移远产品或服务提供的功能和服务仅供您参考，请不要以此作为您判断的唯一依据。 </b>
            </p>
            <br />
            <b>5、使用规则</b>
            <p class="p1">
              5.1、
              <b>
                您在使用移远产品或服务时，应遵守所有适用的法律、法规、规章、规范、政策、行政命令及社会公序良俗（统称为“法律法规”）。
              </b>
            </p>
            <p class="p1">
              5.2、
              <b>
                您仅能按照本条款允许的方式使用移远产品或服务，除非您与移远另行达成书面协议或附加条款中有明确许可，您在使用移远产品或服务过程中，不得实施也不得同意、授权或指示任何第三人从事包括但不限于以下行为：
              </b>
            </p>
            <p class="p1">
              5.2.1、对移远产品或服务进行修改、复制、发布、传输、公开展示、展览、播放、翻制、翻译、发行、出版、授权、从其创建衍生产品、转让、出售、逆向工程、反编译，或试图从移远产品或服务或其任何部分提取源代码或获取原始数据；
            </p>
            <p class="p1">5.2.2、将移远产品或服务以收费或其他获利方式进行提供，无论是否为直接经济或金钱收益；</p>
            <p class="p1">5.2.3、进行其他未经移远<b>明确许可</b>的行为。</p>
            <p class="p1">
              5.3、
              <b>
                如果出现您对本条款的违反行为，移远保留针对该违反行为采取法律所能提供的所有补救手段的权利。由于您的任何违法或违反本条款的使用行为而对移远或其他任何人造成任何损失的，移远有权要求您给予相应的赔偿，赔偿范围包括但不限于移远因此支付的罚款、赔偿金、诉讼费、律师费、公证费、调查证据费用等。
              </b>
            </p>
            <br />
            <b>6、第三方服务</b>
            <p class="p1">
              移远产品或服务可能保有第三方服务。是否访问或接受第三方提供服务将由您自主决定，
              <b> 移远不对第三方服务的可用性、真实性、充分性或可靠性提供任何形式的担保或保证。 </b>
              请您谨慎判断是否使用第三方服务。
              <b> 如您决定接受第三方的产品或服务，其可能带来的结果和风险全部由您自行承担。 </b>
            </p>
            <br />
            <b>7、知识产权</b>
            <p class="p1">
              7.1、移远拥有移远产品或服务及相关内容的著作权、商标权、专利权等所有法定权利、资格和利益（不论这些权利是否已注册、不论这些权利可能存在于世界何处）。
            </p>
            <p class="p1">
              7.2、您对本条款的同意和对移远产品或服务的使用，不涉及上述任何知识产权的转让，上述全部知识产权仍归移远或相应权利人所有。未经移远和/或相应权利人事先书面同意，您不能自行实施、利用、转让或许可任何三方实施、利用、转让上述知识产权，您亦不能修改、出租、出借、出售、散布、据以制作衍生作品或以其他方式使用上述知识产权的任何部分或全部。
            </p>
            <p class="p1">
              7.3、您不得以任何方式删除、掩藏、修改或替换移远产品或服务中所附的或包含的任何专有权利声明（包含但不限于著作权、商标权、专利权、所有权声明等），或移远及其合作伙伴的任何版权标识、商标、服务标记、标识、商号、企业名称、域名、审图号、出版号及其他明显的标志。
            </p>
            <p class="p1">
              7.4、除非您与移远或相应权利人另行达成书面协议，您不能使用移远及相应权利人的任何标志。本条款没有授予您以任何形式或目的使用移远产品或服务标志的权利，也没有授予您使用任何移远其他标志或任何第三方标志的权利。
            </p>
            <br />
            <b>8、用户内容</b>
            <p class="p1">
              8.1、您声明并保证，您对您通过移远产品或服务发布或上传的信息（统称为“用户内容”），拥有相应的、合法的权利、许可或授权。否则，移远可以对您提供的信息依法或依本条款进行删除或屏蔽。
            </p>
            <p class="p1">
              8.2、对于您通过移远产品或服务提供的用户内容，您同意授予移远一项永久的、全球范围的、免许可费的、可转让的、可分许可的、不可撤销的、非排他性的使用许可。您理解并同意，前述许可在您停止使用移远产品或服务后依然有效。
            </p>
            <p class="p1">
              8.3、您理解并同意，尽管有上述约定，
              <b>
                您通过移远产品或服务上报、反馈、分享、纠正或以其他方式向移远提交或共享的问题截图、纠错信息、问题反馈、改进建议或意见等内容或类似内容时，前述任何内容的著作权等全部知识产权、所有权及其他现行及未来法律规定的全部法律权利自您提交之时全部自动转让给移远，并且无需移远向您支付任何费用。
              </b>
            </p>
            <p class="p1">
              8.4、
              <b>
                您理解并同意，您应遵守中华人民共和国相关法律法规，并对您通过移远产品或服务提交的任何信息的真实性、准确性、及时性、完整性负责，您保证上述信息不侵犯任何第三方的合法权益或违反任何法律法规，亦不得涉及或鼓励侵权行为或其他非法行为，否则，您自行承担因此产生的责任和后果。
              </b>
            </p>
            <br />
            <b>9、担保与保证</b>
            <p class="p1">
              9.1、移远按照适用的法律、法规、规章、规范、政策、强制标准、行业标准等的规定提供移远产品或服务。
            </p>
            <p class="p1">
              9.2、
              <b> 您理解并同意，移远及其许可人不就以下各项向您提供明示或默示的担保或保证： </b>
            </p>
            <p class="p1">9.2.1、<b>移远产品或服务将符合您的实际或特定需求或目的；</b></p>
            <p class="p1">9.2.2、<b>移远产品或服务的及时性、准确性、完整性及精确性；</b></p>
            <p class="p1">
              9.2.3、
              <b>移远产品或服务将不受干扰、无中断、持续稳定或没有错误；</b>
            </p>
            <p class="p1">
              9.2.4、
              <b>使用移远产品或服务获得的任何结果或资料将是正确或可靠的；</b>
            </p>
            <p class="p1">
              9.2.5、
              <b>移远产品或服务中的任何错误、问题、故障都将得到更正或解决；</b>
            </p>
            <br />
            <b>10、免责及责任限制</b>
            <p class="p1">
              10.1、移远产品或服务可能会受到多种因素影响，包括但不限于您的原因、第三方原因、通信网络服务质量、连接故障、网络设备或系统维护、电脑或通讯或其他系统故障、电力故障、罢工、暴乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令、社会环境及其他不可抗力等。
              <b>
                除法律法规明确规定要求移远承担责任之外，因本款所述原因给您造成的任何损失由您本人自行承担，移远对此不承担责任。在所有情况下，移远对于任何不能合理预见的损失或损害不承担责任。由于您违反法律法规或本条款使用移远产品或服务所产生的全部责任和后果，由您本人自行承担。
              </b>
            </p>
            <p class="p1">
              10.2、移远产品或服务及其雇员一概毋须以任何方式就任何信息传递或传送的失误、不准确或错误对用户或任何其他人士负任何直接或间接的责任。
            </p>
            <p class="p1">
              10.3、
              <b>
                移远产品或服务所提供的信息，若在任何司法管辖地区供任何人士使用或分发给任何人士时会违反该司法管辖地区的法律或条例的规定或会导致移远产品或服务或其第三方代理人受限于该司法管辖地区内的任何监管规定时，则该等信息不宜在该司法管辖地区供该等任何人士使用或分发给该等任何人士。用户须自行保证不会受限于任何限制或禁止用户使用或分发移远产品或服务所提供信息的当地的规定。
              </b>
            </p>
            <p class="p1">
              10.4、
              <b>
                您了解并同意，您应承担因您使用本服务、违反本服务条款或在您的账户下采取的任何行动而导致的任何第三方索赔。如果由此引起移远及其关联公司、员工、客户和合作伙伴被第三方索赔的，您应负责处理，并赔偿移远及其关联公司由此遭受的全部损失和责任。
              </b>
            </p>
            <br />
            <b>11、变更、中断或终止</b>
            <p class="p1">
              11.1、移远有权在发出通知或不发出通知的情况下变更、中断或终止提供移远产品或服务，也有权随时修改、中断或终止您对移远产品或服务的使用。
              <b>
                如果移远永久终止提供移远产品或服务的，移远将会提前在移远产品或服务或相关网站上进行公告，但无需单独通知您。
              </b>
            </p>
            <br />
            <b>12、一般条款</b>
            <p class="p1">12.1、移远公布的《移远隐私协议》为本协议的有效组成部分。</p>
            <p class="p1">
              12.2、移远产品或服务不会以所有语言或在所有国家提供。
              <b>
                移远不承诺移远产品或服务对中国以外的其他国家或地区或在任何特定地点均适用或可用；并且禁止在移远产品或服务被认为是非法的地区对其使用。您确认，您遵守本协议所适用的一切出口管制法律法规。您承诺不会将移远的产品或服务用于可适用法律禁止的用途。
              </b>
            </p>
            <p class="p1">
              12.3、本协议签订地为中华人民共和国上海市闵行区。本协议的生效、履行、解释及争议的解决，您和移远就移远产品或服务使用、本条款内容或其执行发生相关争议、权利主张或其他事项，均适用中华人民共和国法律（港澳台地区除外）。相关争议或权利主张等事项，应友好协商解决；如协商不成，可选择如下任一途径解决：
            </p>
            <p class="p1">
              12.3.1、提交上海仲裁委员会，按照申请仲裁时现行有效的仲裁规则进行仲裁；仲裁裁决为终局裁决，对各方均有约束力；
            </p>
            <p class="p1">12.3.2、提交协议签订地人民法院诉讼管辖。</p>
            <p class="p1">12.4、如果本条款的任何条款与适用的法律法规冲突的，本使用协议其他规定仍应具有完整的效力。</p>
            <p class="p1">12.5、本服务协议的标题仅供方便而设，不具任何法律或契约效果。</p>
            <p class="p1">12.6、除本条款和法律法规明确规定的之外，本条款未赋予您任何其他权利。</p>
            <p class="p1">12.7、为了移远及其继承人和受让人的利益，本条款项下的全部权利和义务可以被移远转让。</p>
            <p class="p1">
              12.8、本条款的英文翻译版本（如有）仅为您的理解便利而提供，移远和您之间的关系由本条款的中文版确定。如果两个版本存在任何冲突之处，则以中文版本规定为准。
            </p>
            <p class="p1">12.9、您对移远产品或服务或本条款的意见及建议可通过移远公开披露的联系方式进行联系。</p>
          </div>
        </div>
        <div v-else class="gdpr-container">
          <div v-if="currentType === 'privacy'">
            <p>
              The Privacy Agreement applies to all products and services provided by Quectel Wireless Solutions Co., Ltd
              (hereinafter referred to as "Quectel" or "we") and specifies what data is collected, why it is collected,
              how it is used and how it is protected. Your trust is of great importance to us, and we know how important
              the personal information to you. We will collect your personal information within a reasonable and
              necessary scope as required by laws and regulations. We make every effort to protect your information from
              disclosure, destruction or loss through reasonable and effective information security technology and
              management process as well as appropriate security measures.
            </p>
            <br />
            <h1>Definition</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">1.1</span>
              <span class="en-p-text">
                Personal information: refers to all kinds of information recorded electronically or in other ways that
                can identify a specific natural person or reflect the activities of a specific natural person
                individually or in combination with other information.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.2</span>
              <span class="en-p-text">
                Sensitive personal information: refers to a particular category of personal information which includes
                Identity Card Number, personal biometric information, property information, motion trails, transaction
                information and personal information of children under the age of 14.
                <b>Sensitive information will be bolded and underlined in this agreement.</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.3</span>
              <span class="en-p-text">
                Personal information deletion: refers to the removal of personal information from the system involved in
                the realization of daily business functions, so that the personal information keeps irretrievable and
                inaccessible.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.4</span>
              <span class="en-p-text">
                The Privacy Agreement applies to all products and services provided by Quectel platforms.
                <b>Quectel Platform</b> refers to the Quectel official website (https://www.quectel.com/),
                (https://iot-aep.com) and (https://iot-cloud.quectel.com/) and other Quectel-related platforms
                subsequently owned or operated by Quectel Wireless Solutions Co., Ltd and its subsidiaries and
                affiliates (hereinafter referred to as "Quectel Company").
              </span>
            </p>
            <p>
              Please note that this Agreement does not apply to services provided to you by third parties, such as
              Alibaba Cloud SMS Services and WeChat Payment Services, and the personal information you provide to that
              third party service provider does not apply to this Agreement.
            </p>
            <p>
              <b>
                Please read and understand this Agreement carefully and thoroughly before using the products or services
                provided by Quectel, especially the bold and underlined terms, which you need read carefully and confirm
                that you fully understand and agree. By accessing or using Quectel Platform, you agree to this Privacy
                Agreement and trust us in the way we process your information. If you have any questions about this
                Agreement, please contact Quectel Customer Service (400-960-7678) or contact us by the ways provided on
                any platform of Quectel. If you are a minor under the age of 18, please read this Agreement accompanied
                by a legal guardian and pay special attention to the Terms of Use for minors.
              </b>
            </p>
            <br />
            <p>
              <b>The Agreement contains the following content:</b>
            </p>
            <p>
              1. What information we collect<br />
              2. How we use your information<br />
              3. When we share your information<br />
              4. How we store your information<br />
              5. How we protect your information<br />
              6. Your rights<br />
              7. Cancel account<br />
              8. How we protect the information of minors<br />
              9. Contact us
            </p>
            <br />
            <h1>1. What Information We Collect</h1>
            <p>
              We collect your information in a lawful, reasonable and necessary manner. When we provide you with a
              particular service, we will clearly inform you what information we collect and why we collect the
              information to respond to your specific service request. Depending on the service you choose, we may
              collect one or more of the following information in the following scenarios:
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.1</span>
              <span class="en-p-text">
                When your registration is successful, you can selectively fill in your personal information in your
                profile, such as
                <b>nickname, gender, profile picture, email, phone number, and other relevant information. </b>And such
                information is maintained by yourself. When using AEP platform to enable the relevant services, you can
                save the service delay caused by information filling. They are non-required field information. You can
                choose to maintain the information or fill it out when you actually use it.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.2</span>
              <span class="en-p-text">
                <b>
                  What calls for special attention is that device information or service log information alone is unable
                  to identify a particular natural person. If we combine such non-personal information with other
                  information to identify a particular natural person or use non-personal information in combination
                  with personal information, such non-personal information will be considered as personal information
                  during the period of use. We will anonymize and de-identify such information, unless otherwise
                  authorized by you or required by law or regulation.
                </b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.3</span>
              <span class="en-p-text">
                To improve the security of your use of services on Quectel Platform, protect the personal and property
                safety of you, other users or the public from infringements, better prevent security risks such as
                phishing websites, fraud, network vulnerabilities, computer viruses, cyberattacks, network intrusions,
                and more accurately identify violations of laws and regulations or the rules of the Quectel
                Platform-related agreements, we may use or integrate your account information, device interaction
                information, network blog information, and information that you have authorized or shared in accordance
                with the law to comprehensively determine your account and transaction risks, authenticate identity,
                detect and prevent security incidents, and take the necessary recording, auditing, analyzing and
                disposing measures in accordance with the law.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.4</span>
              <span class="en-p-text">
                <b>
                  If the information you provide contains personal information from other users, you need to ensure that
                  you have obtained legal authorization before providing us with such personal information. If any
                  personal information about a child is involved, you must obtain the consent from the child's guardian
                  prior to publication, in which case the guardian has the right to contact us by the ways in Article 9
                  of this Agreement to request correction or deletion of the content relating to the child's personal
                  information.
                </b>
              </span>
            </p>
            <p>
              We will obtain your consent in advance if we intend to use the information for other purposes not
              specified in this statement, or we intend to use the information collected for a specific purpose for
              other purposes, or we actively obtain your information from third parties.
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.5</span>
              <span class="en-p-text">
                <b>
                  Exception Authorization: you fully understand and agree that we collect and use your personal
                  information without your consent and we may not respond to your requests for correction/modification,
                  deletion, cancellation, withdrawal of consent and request for information under the following
                  circumstances:
                </b>

                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.1</span>
                  <span class="en-p-text"> Directly related to national security and national defense security; </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.2</span>
                  <span class="en-p-text">
                    Directly related to public safety, public health and major public interests;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.3</span>
                  <span class="en-p-text">
                    Directly related to judicial or administrative enforcement, such as crime investigation,
                    prosecution, trial and judgment enforcement;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.4</span>
                  <span class="en-p-text">
                    It is difficult to obtain your own consent in order to protect your or other individuals' personal
                    life, property, and other important legitimate rights and interests;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.5</span>
                  <span class="en-p-text"> Personal information that you disclose to the public; </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.6</span>
                  <span class="en-p-text">
                    Where personal information is collected from legally and publicly disclosed information, such as
                    legal news reports, government information disclosure and other channels;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.7</span>
                  <span class="en-p-text">
                    Necessary for signing and fulfilling the relevant agreement or other written documents with you;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.8</span>
                  <span class="en-p-text">
                    Necessary for maintaining the safe and stable operation of the products and/or services provided,
                    such as detecting and handling product or service faults;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.9</span>
                  <span class="en-p-text"> Necessary for legal news reports; </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.10</span>
                  <span class="en-p-text">
                    Necessary for academic research institutions to conduct statistics or academic research based on the
                    public interest, and the personal information contained in the results is de-identified when the
                    results of academic research or descriptions are released to the public;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">1.5.11</span>
                  <span class="en-p-text"> Other circumstances as prescribed by laws and regulations. </span>
                </p>
              </span>
            </p>
            <br />
            <h1>2. How We Use Your Information</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">2.1</span>
              <span class="en-p-text">
                We keep your personal data up-to-date, store or destroy it securely, minimize the data that we need to
                collect and retain to provide you with services, protect personal information from disclosure, misuse,
                unauthorized access and disclosure, and take appropriate technical measures to protect the security of
                your personal information.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">2.2</span>
              <span class="en-p-text">
                We use your personal information for following purposes:

                <p class="en-p clearfix">
                  <span class="en-p-no">2.2.1</span>
                  <span class="en-p-text">
                    Provide services according to our contract with you (account login, notifications and so on).
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">2.2.2</span>
                  <span class="en-p-text">
                    Provide other services that you request as stated when we collect information.
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">2.2.3</span>
                  <span class="en-p-text">
                    Share your contact information with branches of companies around the world for internal management
                    and back-office support.
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">2.2.4</span>
                  <span class="en-p-text">
                    Maintain the integrity and security of our technical data system for storing and processing your
                    personal information.
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">2.2.5</span>
                  <span class="en-p-text"> Enforce or protect our agreements or contracts with you. </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">2.2.6</span>
                  <span class="en-p-text">
                    Detect and investigate the information disclosure, illegal activities and fraud.
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">2.2.7</span>
                  <span class="en-p-text"> Contact you to resolve an issue. </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">2.2.8</span>
                  <span class="en-p-text"> Other uses with your content. </span>
                </p>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">2.3</span>
              <span class="en-p-text">
                It is important to note that the above usage covers the main ways in which we use your information. From
                time to time, we may provide you with a more diverse range of services by updating website pages. When
                we intend to use the information for other purposes not specified in this statement or use the
                information collected for a specific purpose for other purposes, we will ask for your content again by
                signing an agreement, pop-up prompts, or updating this Agreement and so on.
              </span>
            </p>
            <br />
            <h1>3. When We Share Your Information</h1>
            <p>
              <b>
                We do not share your information with any organization, individual or company except Quectel and its
                affiliates, except as follows:
              </b>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">3.1</span>
              <span class="en-p-text">
                <b>
                  Circumstances prescribed by laws and regulations: we may share your personal information with the
                  public in accordance with laws and regulations, the need for resolving litigation dispute, or as
                  required by administrative and judicial authorities in accordance with the law.
                </b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">3.2</span>
              <span class="en-p-text">
                <b>
                  Sharing with explicit consent: we will share your personal information with other parties after
                  obtaining your explicit consent.
                </b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">3.3</span>
              <span class="en-p-text">
                <b>
                  Sharing with affiliated companies: to facilitate our provision of products and services to you,
                  identify abnormal accounts, and protect the personal and property of other users or the public from
                  infringement, we may share your personal information with our affiliated companies and/or their
                  designated service providers. We only share necessary personal information and are subject to the
                  purposes stated in this Agreement. If we share your sensitive personal information or the affiliates
                  want to change the purpose of processing personal information, we will ask for your authorization
                  again.
                </b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">3.4</span>
              <span class="en-p-text">
                <b>
                  Sharing with authorized partners: We may entrust authorized partners to provide you with certain
                  services or perform functions on our behalf. We will only share your personal information for
                  legitimate, proper, necessary, specific and clear purposes stated in this Agreement. Our authorized
                  partners only have access to the information they need to perform their duties and are not authorized
                  to use the shared personal information for any other purpose. We do not need to notify you and obtain
                  your additional consent to the use and sharing of such data.
                </b>
                <br />
                <br />
                <p>
                  <b> Currently, our authorized partners include the following types: </b>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">3.4.1</span>
                  <span class="en-p-text">
                    <b>
                      Suppliers, service providers and other partners. We send information to suppliers, service
                      providers, and other partners who support our business. The support includes technical
                      infrastructure services entrusted by us, analyzing how our services are used, measuring the
                      effectiveness of our services, providing customer services, facilitating payments, or conducting
                      academic researches and investigations.
                    </b>
                  </span>
                </p>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">3.5</span>
              <span class="en-p-text">
                <b>
                  We will not transfer your personal information to any company, organization, or individual, except as
                  follows:
                </b>
                <p class="en-p clearfix">
                  <span class="en-p-no">3.5.1</span>
                  <span class="en-p-text">
                    <b>
                      Transfer with explicit consent: we will transfer your personal information to other parties after
                      obtaining your explicit consent.
                    </b>
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">3.5.2</span>
                  <span class="en-p-text">
                    <b>
                      In the event of a merger, acquisition or liquidation of Quectel Platform service providers, or
                      other circumstances involving a merger, acquisition or liquidation, we will require new companies
                      and organizations that hold your personal information to remain subject to this Agreement,
                      otherwise we will require the company, organization and individual to obtain your consent again.
                    </b>
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">3.5.3</span>
                  <span class="en-p-text">
                    <b>
                      Exceptions to prior authorization for the sharing, transfer or disclosure of personal information
                      shall be implemented in accordance with the requirements of the relevant and currently effective
                      laws and regulations.
                    </b>
                  </span>
                </p>
              </span>
            </p>
            <p>
              <b>
                If you do not agree that we provide your personal information to third parties in accordance with the
                above terms, please stop using the services of Quectel Platform immediately.
              </b>
            </p>
            <br />
            <h1>4. How We Store Your Information</h1>
            <p>
              Unless otherwise required by law, Quectel Platform will store your information for the following period of
              time:
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">4.1</span>
              <span class="en-p-text">
                We will retain your information during your use of the products or services of Quectel Platform, unless
                you voluntarily delete or cancel your account on Quectel AEP Platform.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">4.2</span>
              <span class="en-p-text">
                When you delete information or cancel your Quectel Platform account, Quectel Platform will stop using
                your personal information and delete your personal information in a timely manner within a certain
                period of time.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">4.3</span>
              <span class="en-p-text">
                Your personal information will be anonymized and desensitized to available circulation data, and Quectel
                Platform will store and use such data without your additional consent.
              </span>
            </p>
            <br />
            <h1>5. How We Protect Your Information</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">5.1</span>
              <span class="en-p-text">
                We have taken reasonable and feasible security measures to protect your information from unauthorized
                access, public disclosure, use, modification, damage or loss in accordance with industry standards. For
                example, when exchanging data between your browser and server, your information is protected by SSL
                protocol encryption; meanwhile we provide HTTPS protocol secure browsing to websites of Quectel
                Platform; we use encryption technology to improve the security of personal information; we use trusted
                protection mechanisms to protect personal information from malicious attacks; we deploy access control
                mechanisms to ensure that only authorized personnel can access your personal information, and we conduct
                security and privacy training courses to strengthen the employee’s awareness of the importance of
                personal information protection.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">5.2</span>
              <span class="en-p-text">
                The Internet is not absolutely secure and we strongly recommend that you do not send your information
                using the communication methods not recommended by Quectel Platform when using a product or service of
                Quectel Platform. You can connect and share information with each other through our services. When you
                create communications, conduct transactions or share information through our services, you can choose
                who you communicate with, trade with or share with as a third party that can view your transaction
                information and contact details, exchange information or share content with you.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">5.3</span>
              <span class="en-p-text">
                Once a user information security incident occurs, we will inform you in accordance with the requirements
                of laws and regulations: the basic situation and possible impact of the security incident, the measures
                we have taken or are about to take, the advice you can choose on preventing and reducing risks, remedies
                for you and so on. We will inform you about the event in the form of emails, letters, phone calls, push
                notifications and so on. If it is difficult to notify them one by one, we will take a reasonable and
                effective way to make announcements. At the same time, we will also report the handling of user
                information security incidents in accordance with the requirements of relevant regulatory authorities.
              </span>
            </p>
            <br />
            <h1>6. Your Rights</h1>
            <p>You have the rights to manage your own information, which include:</p>
            <p class="en-p clearfix">
              <span class="en-p-no">6.1</span>
              <span class="en-p-text">
                Access your personal information
                <p class="en-p clearfix">
                  <span class="en-p-no">6.1.1</span>
                  <span class="en-p-text">
                    Account information: If you want to access or edit your profile information in your account, update
                    your password, profile picture, nickname and so on, you can access your information by visiting the
                    settings after you log in to your account.
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">6.1.2</span>
                  <span class="en-p-text">
                    Other personal information (such as system login, notifications and other services): for personal
                    information generated during your use of our products or services, if you need, you can contact us
                    by the ways in Article 9.
                  </span>
                </p>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">6.2</span>
              <span class="en-p-text"> Correct your personal information </span>
            </p>
            <p>
              You have the right to request us to correct or supplement your personal information if you find that we
              have made mistakes in handling your personal information. You can make corrections or supplements by
              visiting the Personal Information Settings page or contact us directly.
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">6.3</span>
              <span class="en-p-text">
                Delete your personal information

                <p>
                  In the following cases, you can contact Quectel Platform Customer Service to request the deletion of
                  your personal information.
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">6.3.1</span>
                  <span class="en-p-text">
                    If our handling of personal information violates laws and regulations;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">6.3.2</span>
                  <span class="en-p-text">
                    If we collect and use personal information without your explicit consent;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">6.3.3</span>
                  <span class="en-p-text">
                    If our handling of personal information has seriously violated our agreement with you;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">6.3.4</span>
                  <span class="en-p-text">
                    If you do not need to use our products and services, or you voluntarily cancel your Quectel AEP
                    account;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">6.3.5</span>
                  <span class="en-p-text"> If you do not need our products or services permanently. </span>
                </p>
              </span>
            </p>
            <p>
              If we decide to respond to your removal request, we will stop providing you with products or services and
              delete your personal information as required by applicable law.
            </p>
            <br />
            <h1>7. Cancel Account</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">7.1</span>
              <span class="en-p-text">
                You can cancel your previously registered account at any time, and if you need our assistance in
                cancelling your account, you can contact us by the ways in Article 9.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">7.2</span>
              <span class="en-p-text">
                When you cancel your account, we will stop providing you with products or services, and delete your
                personal information according to your request.
              </span>
            </p>
            <br />
            <h1>8. How We Protect the Information of Minors</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">8.1</span>
              <span class="en-p-text">
                Our products and services are primarily intended for adults. In the case of the collection of personal
                information of minors with the consent of the legal guardian, we will only use or disclose the
                information of minors that we collect legally to public under the circumstances permitted by law, with
                the express consent of the legal guardian or necessary for protecting the minors.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">8.2</span>
              <span class="en-p-text">
                If we find that personal information from a minor has been collected without prior consent of the legal
                guardian, we will try to delete the information as soon as possible. If the legal guardians have reason
                to believe that a minor has submitted personal information to Quectel Platform without their prior
                consent, please contact us to ensure that such personal information is removed and that the minor
                cancels his or her subscription to any applicable services of Quectel APE Platform.
              </span>
            </p>
            <br />
            <h1>9. Contact Us</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">9.1</span>
              <span class="en-p-text">
                If you have any questions, comments or suggestions about the content of this Privacy Agreement, or you
                want to exercise your rights, or if you have any request to discuss with us, you can contact us by the
                following ways:
              </span>
            </p>
            <p>
              Address: Building 5, Shanghai Business Park Phase III (Area B), No.1016 Tianlin Road, Minhang District,
              Shanghai 200233, China<br />
              Email: quecteldpo@quectel.com<br />
              Phone: 400-960-7678
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">9.2</span>
              <span class="en-p-text">
                To ensure security, you may need to provide a written request or otherwise prove your identity.
                Generally, we will reply within 15 days.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">9.3</span>
              <span class="en-p-text">
                This agreement is signed in Minhang District, Shanghai, People's Republic of China. The entry into
                force, performance, interpretation and dispute resolution of this Agreement, all disputes, claims or
                other matters arising out of your use of products or services of Quectel Platform, or all disputes
                relating to this Agreement shall be governed by the laws of the People's Republic of China (except Hong
                Kong, Macao and Taiwan). All disputes between you and Quectel company or Quectel Platform must be
                resolved in a friendly manner; if no consultation is possible, either of the following options can be
                selected:

                <p class="en-p clearfix">
                  <span class="en-p-no">9.3.1</span>
                  <span class="en-p-text">
                    Submit disputes to the Arbitration Commission for arbitration which shall be conducted in accordance
                    with the arbitration rules in effect at the time of applying for arbitration. The arbitral award is
                    final and binding upon both parties.
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">9.3.2</span>
                  <span class="en-p-text">
                    Submit disputes to the jurisdiction of the people's court in the place where the agreement is
                    signed.
                  </span>
                </p>
              </span>
            </p>
            <p>
              <b>Please reconfirm that you have read and understand the above terms completely. </b>
            </p>
            <p class="p-text-r">
              Quectel Wireless Solutions Co., Ltd<br />
              Drafting Date: July 21, 2021<br />
              Effective Date: July 21,2021
            </p>
          </div>
          <div v-if="currentType === 'service'">
            <h1>1. Important Notes</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">1.1</span>
              <span class="en-p-text">
                Before using any software, website or service (collectively called "Quectel Products or Service")
                provided by Quectel Wireless Solutions Co., Ltd. and its affiliates (hereinafter “Quectel”),
                <b>
                  please read Quectel Terms of Service and Quectel Privacy Policy (hereinafter “Terms”) carefully,
                  especially the contents marked in bold.
                </b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.2</span>
              <span class="en-p-text">
                If you have any confusion about this Agreement, please inquire through Quectel public disclosure
                channels.
                <b>The terms and conditions of this Agreement shall be modified at any time by Quectel. You understand
                  and agree that the revised terms and conditions shall have retroactive effect. By enabling Quectel
                  Products or Services, you acknowledge that you have read, understood, and agree to all the contents
                  stated in the Agreement, and that the Agreement is legally binding.</b>
                When you use a certain Quectel service, you should follow the guidelines and rules related to that
                service. All the above guidelines and rules constitute part of this Agreement.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.3</span>
              <span class="en-p-text">
                You declare that you have sufficient power, ability and authority to agree to and fulfill this
                Agreement. If you agree to this Agreement on behalf of your employer or customer, you declare that you
                have sufficient legal authority to bind your employer or customer to this Agreement. If you do not have
                sufficient legal authority, please ensure that your employer or customer's authorized person agrees to
                this Agreement.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.4</span>
              <span class="en-p-text">
                In the case that you are a minor user, please read this Agreement carefully accompanied by your legal
                guardian and use our Products or Services or provide us with information with the consent of your legal
                guardian.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">1.5</span>
              <span class="en-p-text">
                <b>
                  You confirm that you are not the subject of trade restrictions, sanctions, legal restrictions and
                  rules limits imposed by any organization, region or country.
                </b>
              </span>
            </p>
            <br />
            <h1>2. Personal Information Protection</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">2.1</span>
              <span class="en-p-text">
                Personal information protection is one of the fundamental principles to which Quectel adheres.
                <b>When you use Quectel Products or Services, we may collect and use your personal information,
                  including your location information and equipment information, and some functions may also include
                  your identity information, contact information and other information.
                </b>
                will collect, store, use and disclose your personal information in accordance with the provisions of the
                <b>Quectel Privacy Agreement</b>. Please read the Quectel Privacy Agreement carefully and use Quectel
                Products or Services only on the basis that you agree with its contents.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">2.2</span>
              <span class="en-p-text">
                When using Quectel Product or Services, you trust the way we handle your information and have agreed to
                the terms of your information collection and use in service policies such as Quectel Terms of Service
                and Privacy Agreement.
              </span>
            </p>
            <br />
            <h1>3. Service Note</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">3.1</span>
              <span class="en-p-text">
                Quectel Products or Services is provided by Quectel Wireless Solutions Co., Ltd. and its affiliates
                (collectively referred to as "Quectel" or "we").
                <b>You understand and agree that the products and services provided by Quectel are only provided in
                  their current conditions, and Quectel expressly refuses to make any express or implied warranties for
                  Products or Services, including but not limited to warranties for commercial use, suitable for
                  specific purposes or not infringing on the rights of others. Quectel is not liable for any direct,
                  indirect, incidental or consequential loss arising from the Products or Services. </b>Quectel has the right to determine the specific content and form of Products or Services and their
                functions and services. Quectel has the right to add, change, suspend and cease Quectel Products or
                Services and their functions or services at its own discretion.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">3.2</span>
              <span class="en-p-text">
                In order to improve Quectel Products or Services, Quectel will release subsequent versions of Quectel
                Products or Services in due course.
                <b>Unless otherwise expressly specified in this Agreement, any upgrade or update of Quectel Products or
                  Services shall be governed by this Agreement.</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">3.3</span>
              <span class="en-p-text">
                After new Quectel Products or Services is released, the old version may not be available.<b>
                  We do not guarantee that the old version can continue to be used, so please download the latest
                  version in time.</b>
              </span>
            </p>
            <br />
            <h1>4. Authorizations and Licenses</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">4.1</span>
              <span class="en-p-text">
                Content provided by Quectel Products or Services means any content provided by Quectel through Quectel
                Products or Services, regardless of whether such content is created by Quectel or licensed to be used by
                other third parties (hereinafter referred to as "Relevant Content").
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">4.2</span>
              <span class="en-p-text">
                <b>
                  You shall not (and you shall not permit others to) use Quectel Products or Services for any commercial
                  purpose in order to directly or indirectly gain any form of interest. If you have commercial operation
                  needs, you will need to enter into a separate written agreement with Quectel or obtain Quectel's prior
                  written permission.</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">4.3</span>
              <span class="en-p-text">
                If you use the products or services, you may download Quectel Products or Services from a third party
                authorized by Quectel
                <b>If you obtain Quectel Products or Services from a third party without authorization from Quectel, we
                  cannot guarantee the normal use of the products and any loss you suffer is not related to Quectel.</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">4.4</span>
              <span class="en-p-text">
                <b>
                  All other rights not expressly authorized by this Agreement shall remain Quectel, and this Agreement
                  shall not be construed as conferring any other rights on you by implication or otherwise. You need to
                  sign a separate written agreement or obtain prior written permission from Quectel when using other
                  rights.</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">4.5</span>
              <span class="en-p-text">
                Although we will continuously improve the service quality, due to various objective or technical
                reasons, it may lead to limitations of Quectel Products or Services. Therefore,
                <b>the functions and services provided by Quectel Products or Services are for your reference only, and
                  please do not use this as the only basis for your judgment.</b>
              </span>
            </p>
            <br />
            <h1>5. Use of the Services</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">5.1</span>
              <span class="en-p-text">
                <b>
                  When using Quectel Products or Services, you shall comply with all applicable laws, regulations,
                  rules, norms, policies, administrative orders and social order (collectively, the "Laws and
                  Regulations").</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">5.2</span>
              <span class="en-p-text">
                <b>
                  You may only use Quectel Products or Services in the manner permitted by this Agreement, and unless
                  otherwise expressly permitted by a written agreement or additional terms between you and Quectel, you
                  shall not perform, agree to, authorize or instruct any third party to do any of the following during
                  your use of Quectel Products or Services, including but not limited to:</b>

                <p class="en-p clearfix">
                  <span class="en-p-no">5.2.1</span>
                  <span class="en-p-text">
                    Modify, copy, publish, transmit, publicly display, exhibit, broadcast, reproduce, translate,
                    distribute, publish, authorize, create derivative products from, transfer, sell, reverse engineer,
                    decompile, or attempt to extract source code or obtain original data from or any part of Quectel
                    Products or Services;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">5.2.2</span>
                  <span class="en-p-text">
                    Provide Quectel Products or Services for a fee or other profit, whether or not for direct economic
                    benefits or money.
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">5.2.3</span>
                  <span class="en-p-text"> Any other acts without the <b>express permission</b> of Quectel. </span>
                </p>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">5.3</span>
              <span class="en-p-text">
                <b>
                  In the event of a breach of this Agreement by you, Quectel reserves the right to pursue all remedies
                  available to you by law for such breach. In case of any loss caused to Quectel or any other person due
                  to your illegal or violation of this Agreement, we have the right to claim compensation from you,
                  including but not limited to fines, compensation, legal fees, attorney's fees, notarial fees, evidence
                  investigation fees.</b>
              </span>
            </p>
            <br />
            <h1>6. Service of Third Parties</h1>
            <p>
              Quectel Products or Services may contain services of third parties. Access to or acceptance of services
              provided by third parties is at your sole discretion. We do not provide any warranty of any kind as to the
              availability, authenticity, adequacy or reliability of the services provided by third parties. Exercise
              caution when using third-party services. If you decide to accept the products or services of a third
              party, you shall bear all the results and risks arising therefrom.
            </p>
            <br />
            <h1>7. Intellectual Property Rights</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">7.1</span>
              <span class="en-p-text">
                Quectel owns all legal rights, qualifications and interests (no matter whether these rights have been
                registered or not, and no matter where these rights may exist in the world) in the copyright, trademark
                and patent rights of Quectel Products or Services and related contents.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">7.2</span>
              <span class="en-p-text">
                Your agreement to this Agreement and your use of the Quectel Products or Services shall not involve the
                transfer of any of the intellectual property rights mentioned above, and all such intellectual property
                rights shall remain the property of Quectel or the corresponding right holders. You cannot, without the
                prior written consent of Quectel and/or the corresponding obligee, exercise, utilize, transfer or permit
                any three parties to exercise, utilize, transfer the above intellectual property rights, nor can you
                modify, lease, lend, sell, distribute, make derivative works or otherwise use any part or all of the
                above intellectual property rights.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">7.3</span>
              <span class="en-p-text">
                You may not in any way delete, conceal, modify or replace any exclusive rights statement (including but
                not limited to copyright, trademark, patent, ownership statement.) attached to or included in Quectel
                Products or Services or any copyright marks, trademarks, service marks, logos, trade names, business
                names, domain names, drawing approval numbers, publication numbers and other obvious signs of Quectel
                and its partners.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">7.4</span>
              <span class="en-p-text">
                You may not use any of the marks of Quectel or the corresponding obligee unless you have a separate
                written agreement with Quectel or the corresponding obligee. This Agreement does not grant you the right
                to use the logo of Quectel Products or Services in any form or purpose, nor does it grant you the right
                to use any other logo of Quectel and third party.
              </span>
            </p>
            <br />
            <h1>8. User Content</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">8.1</span>
              <span class="en-p-text">
                You declare and warrant that you have appropriate and lawful rights, licenses or authorizations for
                information (collectively referred to as "User Content") that you publish or upload through Quectel
                Products or Services. Otherwise, Quectel may delete or block the information provided by you in
                accordance with the law or this Agreement.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">8.2</span>
              <span class="en-p-text">
                You agree to grant Quectel a permanent, worldwide, fee-free, transferable, sublicensable, irrevocable,
                and non-exclusive license to use the User Content you provide through Quectel Products or Services. You
                understand and agree that the aforesaid license will remain valid after you cease to use Quectel
                Products or Services.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">8.3</span>
              <span class="en-p-text">
                You understand and agree that, notwithstanding the above Agreement,
                <b>when you report, feedback, share, correct or otherwise submit or share problem screenshots, error
                  correction information, problem feedback, improvement suggestions or opinions or similar content to
                  Quectel Products or Services, all intellectual property rights such as copyright, ownership and other
                  legal rights stipulated by current and future laws of any of the foregoing contents will be
                  automatically transferred to Quectel at the time of your submission, and no fees will be paid to you
                  by Quectel.</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">8.4</span>
              <span class="en-p-text">
                <b>You understand and agree that you should abide by the relevant laws and regulations of the People's
                  Republic of China. You are responsible for the authenticity, accuracy, timeliness and completeness of
                  any information submitted by you through Quectel Products or Services. You guarantee that the above
                  information will not infringe the legitimate rights and interests of any third party or violate any
                  laws and regulations, nor will it involve or encourage the infringement or other illegal acts,
                  otherwise, you will bear the responsibilities and consequences arising therefrom.</b>
              </span>
            </p>
            <br />
            <h1>9. Warranty</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">9.1</span>
              <span class="en-p-text">
                Quectel provides the Products or Services in accordance with applicable laws, regulations, rules, norms,
                policies, compulsory standards, industry standards, etc.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">9.2</span>
              <span class="en-p-text">
                <b>You understand and agree that Quectel and its licensors do not provide you with any warranties
                  express or implied, with respect to:</b>
                <p class="en-p clearfix">
                  <span class="en-p-no">9.2.1</span>
                  <span class="en-p-text">
                    <b>Quectel Products or Services will meet your actual or specific needs or purposes;</b>
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">9.2.2</span>
                  <span class="en-p-text">
                    <b>Timeliness, accuracy, completeness and accuracy of Quectel Products or Services;</b>
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">9.2.3</span>
                  <span class="en-p-text">
                    <b>Quectel Products or Services will be uninterrupted, interrupt free, consistent, or error-free;</b>
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">9.2.4</span>
                  <span class="en-p-text">
                    <b>Any results or information obtained using Quectel Products or Services will be correct or
                      reliable.</b>
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">9.2.5</span>
                  <span class="en-p-text">
                    <b>Any errors, problems, failures in Quectel Products or Services will be corrected or resolved;</b>
                  </span>
                </p>
              </span>
            </p>
            <br />
            <h1>10. Disclaimer and Limitation of Liability</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">10.1</span>
              <span class="en-p-text">
                Quectel Products or Services may be affected by many factors, including but not limited to your reasons,
                third party reasons, communication network service quality, connection failure, network equipment or
                system maintenance, computer or communication or other system failures, power failure, strike, riot,
                fire, flood, storm, explosion, war, government actions, judicial administrative organs' orders, social
                environment and other force majeure.
                <b>Except for the liability required by laws and regulations, any losses caused to you due to the
                  reasons mentioned in this paragraph shall be borne by you, and we shall not be liable for this. In all
                  cases, Quectel shall not be liable for any loss or damage which cannot be reasonably foreseen. You
                  shall bear all the responsibilities and consequences arising from your use of Quectel Products or
                  Services in violation of laws and regulations or this Agreement.</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">10.2</span>
              <span class="en-p-text">
                Quectel Products or Services and employees shall not be liable in any way, directly or indirectly, to
                users or any other person for any fault, inaccuracy or error in the transmission or transfer of any
                information.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">10.3</span>
              <span class="en-p-text">
                <b>
                  Information provided by Quectel Products or Services, If the use or distribution to any person in any
                  jurisdiction would violate the laws or regulations of that jurisdiction or cause Quectel Products or
                  Services or its third-party agents to be subject to any regulatory requirements in that jurisdiction.
                  It is not appropriate for the information to be used by or distributed to any such person in that
                  jurisdiction. Users shall undertake that you will not be subject to any local regulations that
                  restrict or prohibit users from using or distributing the information provided by Quectel Products or
                  Services.
                </b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">10.4</span>
              <span class="en-p-text">
                <b>
                  You understand and agree that you shall be liable for any third-party claims arising out of your use
                  of the Service, violation of this Agreement or any actions taken under your account. You shall be
                  responsible for handling any claims made by any third party arising out of this, and compensate for
                  all losses and liabilities incurred by Quectel and its affiliates.
                </b>
              </span>
            </p>
            <br />
            <h1>11. Change, Suspend and Cease</h1>
            <p>
              Quectel reserves the right to change, suspend and cease providing Quectel Products or Services with or
              without notice, and to change, suspend and cease your use of Quectel Products or Services at any time.
              <b>If Quectel permanently terminating the provision of Quectel Products or Services, Quectel will make an
                announcement on Quectel Products or Services or related websites in advance without notifying you
                separately.</b>
            </p>
            <br />
            <h1>12. Miscellaneous Provisions</h1>
            <p class="en-p clearfix">
              <span class="en-p-no">12.1</span>
              <span class="en-p-text">
                These Terms, including Quectel Privacy Agreement, constitute the entire Agreement between you and
                Quectel.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">12.2</span>
              <span class="en-p-text">
                Quectel Products or Services will not be available in all languages or in all countries.
                <b>Quectel does not promise availability in other countries or regions outside China and prohibits the
                  use of products or services in areas where they are considered illegal. You confirm that you are in
                  compliance with all export control laws and regulations applicable to this Agreement. You undertake
                  not to use Products or Services for purposes prohibited by applicable law.</b>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">12.3</span>
              <span class="en-p-text">
                This Agreement is signed in Minhang District, Shanghai, The People's Republic of China. The entry into
                force, performance, interpretation and dispute resolution of this Agreement, and any disputes, claims or
                other matters arising between you and Quectel regarding the use of Quectel Products or Services, the
                content of this Agreement or its enforcement, shall be governed by the laws of the People's Republic of
                China (except Hong Kong, Macao and Taiwan regions China). Relevant disputes or claims should be settled
                through friendly consultation; If negotiation fails, you can choose any of the following ways to solve
                it:

                <p class="en-p clearfix">
                  <span class="en-p-no">12.3.1</span>
                  <span class="en-p-text">
                    Submit it to Shanghai Arbitration Commission for arbitration in accordance with the arbitration
                    rules in force at the time of applying for arbitration; The arbitration award shall be final and
                    binding on all parties;
                  </span>
                </p>
                <p class="en-p clearfix">
                  <span class="en-p-no">12.3.2</span>
                  <span class="en-p-text">
                    Submit it to the People's Court where the Agreement is signed for litigation jurisdiction.
                  </span>
                </p>
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">12.4</span>
              <span class="en-p-text">
                In the event that any provision of this Agreement conflicts with applicable laws and regulations, the
                other provisions of this Agreement shall remain in full force and effect.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">12.5</span>
              <span class="en-p-text">
                The title of this Agreement is for convenience only and has no legal or contractual effect.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">12.6</span>
              <span class="en-p-text">
                This Agreement does not grant you any other rights except as expressly provided by this Agreement and
                laws and regulations.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">12.7</span>
              <span class="en-p-text">
                All rights and obligations under this article may be transferred by Quectel for the benefit of Quectel
                and its successors and assigns.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">12.8</span>
              <span class="en-p-text">
                The English translation of this Agreement (if any) is provided for your convenience only, and the
                relationship between you and Quectel is determined by the Chinese version. In case of a conflict between
                the two versions, the Chinese version shall prevail.
              </span>
            </p>
            <p class="en-p clearfix">
              <span class="en-p-no">12.9</span>
              <span class="en-p-text">
                Your opinions and suggestions can be delivered through the contact channel disclosed by Quectel.
              </span>
            </p>
          </div>
        </div>
        <div class="gdpr-foot clearfix">
          <el-checkbox v-model="gdprChecked">{{ $t('gdpr.text') }}</el-checkbox>
          <div class="gdpr-foot-btn">
            <el-button type="primary" :disabled="!gdprChecked" @click="agree">{{ $t('gdpr.agree') }}</el-button>
            <el-button @click="refuse">{{ $t('common.back') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState, mapGetters } from 'vuex'
import variables from '@/assets/styles/variables.scss'
import { readClause } from '@/api/login'
import Cookies from 'js-cookie'
import Alert from '@/components/TextCarousel/alert.vue'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    Alert
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      theme: state => state.settings.theme,
      sideTheme: state => state.settings.sideTheme,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      activeRouter: state => state.user.activeRouter,
      switchClass: state => state.user.switchClass,
      isReadClause: state => state.user.isReadClause,
      sideStyle: state => state.settings.sideStyle
    }),
    ...mapGetters([
      'orgtype'
    ]),
    classObj () {
      return {
        mobile: this.device === 'mobile'
      }
    },
    variables () {
      return variables
    }
  },
  watch: {
    activeRouter () {
      this.handleRouter()
    },
    switchClass () {
      this.isActive = !this.switchClass
    }
  },
  data () {
    return {
      isActive: false,
      quectelLogo: require('@/assets/images/quectel-logo.png'),
      currentType: 'privacy',
      gdpr: false,
      gdprChecked: false,
      lang: Cookies.get('lang'),
      sidebarStyle: 'One'
    }
  },
  mounted () {
    this.handleRouter()
    // 首次登录协议弹窗隐藏先
    // if (this.isReadClause === 0 && this.orgtype !== 1) {
    //   this.gdpr = true
    // }
  },
  methods: {
    handleClickOutside () {
    },
    handleRouter () {
      if (JSON.parse(localStorage.getItem('activeRouter')) && !this.switchClass) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    // 同意
    agree () {
      if (!this.gdprChecked) {
        return this.msgError(this.$t('gdpr.validGdpr'))
      }
      readClause().then(res => {
        if (res.code === 200) {
          this.$store.dispatch('IsReadClause', 1).then(() => {
            this.msgSuccess(this.$t('gdpr.success'))
            this.gdpr = false
          })
        }
      })
    },
    // 拒绝
    refuse () {
      this.confirmBox(
        { title: this.$t('common.prompt'), content: this.$t('gdpr.logout') },
        () => {
          this.$store.dispatch('LogOut').then(() => {
            location.href = '/index'
          })
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixin';
@import '~@/assets/styles/variables';

.app-wrapper {
  @include clearfix;

  position: relative;
  width: 100%;
  height: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}

.fixed-header {
  position: fixed;
  z-index: 11;
  width: 100%;
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.pcOpenSidebar {
  margin-left: 340px !important;

  .fixed-header {
    width: calc(100% - 340px) !important;
  }
}

.gdprOpen {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.gdpr-mask {
  position: absolute;
  inset: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}

.gdpr {
  position: absolute;
  inset: 0;
  width: 1160px;
  height: 700px;
  padding: 30px;
  border-radius: 10px;
  margin: auto;
  background-color: #fff;

  &-header {
    &-title {
      font-size: 30px;
      color: #d20030;
    }

    &-logo {
      margin-top: 10px;
    }
  }

  &-version {
    margin: 20px 0;
    font-size: 12px;
    color: #999;

    span {
      margin-right: 30px;
    }
  }

  &-tab {
    div {
      display: inline-block;
      overflow: hidden;
      border-radius: 4px;
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
      height: 36px;
      padding: 0 20px;
      background: #f8f8f8;
      font-size: 15px;
      color: #a1a1a1;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }

    .active {
      background: #cdd9ff;
      color: #456ff7;
    }
  }

  &-container {
    height: 430px;
    background: #f2f2f2;

    div {
      height: 430px;
      padding: 10px;
      line-height: 2;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .p1 {
      text-indent: 2em;
    }

    .p-text-r {
      text-align: right;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
    }

    .en-p {
      margin: 10px 0;

      &-no {
        display: block;
        float: left;
        width: 3%;
      }

      &-text {
        display: block;
        float: right;
        width: 97%;

        .en-p-no {
          display: block;
          float: left;
          width: 4.5%;
        }

        .en-p-text {
          display: block;
          float: right;
          width: 95.5%;
        }
      }
    }
  }

  &-foot {
    margin-top: 20px;

    .el-checkbox {
      float: left;
      margin-top: 7px;
    }

    &-btn {
      float: right;
    }
  }
}
</style>
