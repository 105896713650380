const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  refreshToken: state => state.user.refreshToken,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  orgtype: state => state.user.orgtype,
  orgId: state => state.user.orgId,
  pan: state => state.user.pan,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  publicKey: state => state.user.publicKey,
  activeRouterGetters: state => state.user.activeRouter,
  activeTitle: state => state.user.activeTitle,
  activeBasePath: state => state.user.activeBasePath,
  switchClass: state => state.user.switchClass,
  permissions: state => state.user.permissions,
  userId: state => state.user.userId,
  tenantId: state => state.user.tenantId,
  agentList: state => state.user.agentList,
  permission_routes: state => state.permission.routes,
  sidebarRouters: state => state.permission.sidebarRouters,
  addRoutes: state => state.permission.addRoutes,
  confInfo: state => state.user.confInfo,
  language: state => state.app.language,
  offset: state => state.app.offset,
  isReadClause: state => state.user.isReadClause,
  firstRouters: state => state.permission.firstRouters,
  taskRunData: state => state.user.taskRunData,
  phone: state => state.user.phone,
  UnReadCount: state => state.user.UnReadCount,
  textCarousel: state => state.app.textCarousel,
  saas: state => state.user.saas,
  orgName: state => state.user.orgName
}
export default getters
