var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.item.hidden
    ? _c(
        "div",
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild.meta
                  ? _c(
                      "app-link",
                      {
                        staticClass: "child-item",
                        attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) },
                      },
                      [
                        _c("span", { on: { click: _vm.handleSave } }, [
                          _vm._v(
                            _vm._s(
                              _vm.generateText(
                                "m",
                                _vm.onlyOneChild.meta.otherName
                              )
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            : _c(
                "div",
                {
                  staticClass: "menu-item",
                  class: _vm.activeClass === true ? "active" : "",
                  on: {
                    click: function ($event) {
                      _vm.activeClass = !_vm.activeClass
                    },
                  },
                },
                [
                  _vm.item.meta
                    ? _c("p", { staticClass: "child-item" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.generateText("m", _vm.item.meta.otherName)
                            ) +
                            " "
                        ),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.item.children, function (child) {
                    return _c("child-item", {
                      key: child.path,
                      staticClass: "nest-menu",
                      attrs: {
                        item: child,
                        "base-path": _vm.resolvePath(_vm.item.path),
                      },
                      on: { saveRouter: _vm.handleSave },
                    })
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }