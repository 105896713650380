/**
 *二级页面返回

 * @param {*} backList 上级列表，非必传
 *
 * normal：跳转去二级页面时，需要额外添加路由参数，键值对{orgRoute：该页的路由名}。
 * tip:运维监控模块存在详情多次互相跳转的问题，需要特殊处理。
 * action:直接返回该详情的上级列表backList。
 *
 */

export function goBack (backList) {
  let name = ''
  let params = {}
  let query = {}

  if (backList) {
    name = backList
  } else if (this.$route.query.orgRoute) {
    name = this.$route.query?.orgRoute
    query = { tab: this.$route.query?.tab }
  } else if (this.$route.query.orgRoute) {
    name = this.$route.params?.orgRoute
    params = { tab: this.$route.params?.tab }
  }

  this.$store.dispatch('tagsView/delView', this.$route)

  if (name) {
    this.$router.push({ name, query, params })
  } else {
    this.$router.back()
  }
}
