<template>
  <div id="myChart" ref="myChart" />
</template>

<script>
import echarts from 'echarts'
import 'echarts-liquidfill'
import resize from './mixins/resize'
export default {
  mixins: [resize],
  props: {
    chartData: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.initChart()
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions (data) {
      this.chart.setOption({
        title: [
          {
            text: (data * 100).toFixed(0) + '%',
            left: '46%',
            top: '36%',
            textAlign: 'center',
            textStyle: {
              fontSize: '26',
              fontWeight: '400',
              color: '#fff',
              textAlign: 'center'
            }
          }
        ],
        polar: {
          radius: ['100%', '90%'],
          center: ['50%', '50%']
        },
        angleAxis: {
          max: 100,
          clockwise: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [{
          type: 'liquidFill',
          radius: '95%',
          z: 1,
          center: ['50%', '50%'],
          data: [data, { value: data, direction: 'left' }],
          label: {
            normal: {
              formatter: ''
            }
          },
          outline: {
            show: true,
            itemStyle: {
              borderWidth: 0
            },
            borderDistance: 0
          }
        },
        {
          type: 'bar',
          roundCap: true,
          z: 2,
          showBackground: true,
          backgroundStyle: {
            color: '#efefef'
          },
          data: [(data * 100).toFixed(0)],
          coordinateSystem: 'polar',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0.5, 1, [
                {
                  offset: 0,
                  color: '#5acef2'
                },
                {
                  offset: 0.7,
                  color: '#5073fb'
                },
                {
                  offset: 1,
                  color: '#6ae8d8'
                }
              ])
            }
          }
        }]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#myChart {
  width: 100px;
  height: 100px;
}
</style>
