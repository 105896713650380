import Table from './table/index.js'
import TableColumn from './table-column/index.js'
import Input from './input/index.js'
import Button from './button/index.js'

// 样式引入
import './theme-chalk/index.scss'

// demo引入
import './demo/index.js'

const quecComponents = {
  'q-table': Table,
  'q-table-column': TableColumn,
}

const components = [
  Input,
  Button
]
const install = function (Vue, opts = {}) {
  for (const name in quecComponents) {
    Vue.component(name, quecComponents[name])
  }

  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  install,
  Input
}
