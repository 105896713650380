/* eslint-disable no-useless-escape */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function validUsername (str) {
  return (str && str.length > 2 && str.length < 30) && (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(str) || validMobile(str))
}

export function validEmail (str) {
  return (str && str.length > 2 && str.length < 30) && /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(str)
}

export function validMobile (str) {
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(str)
}

export function validFixedPhone (str) {
  return /^([0-9]{3,4}\-)([0-9]{6,9})+(\-[0-9]{1,5})?$/.test(str)
}

export function validPassword (str) {
  return str && str.length >= 6 && str.length <= 30
}

export function validCode (str) {
  return /^\d{4}$/.test(str)
}

export function validNum (str) {
  return /^(0|[1-9]\d*)$/.test(str)
}
// 数字和一个小数点
export function validNumPoint (val) {
  return /^\d+$|^\d*\.\d+$/.test(val)
}

export function validJustNum (str) {
  return str && str !== null && String(str).trim() !== '' && !isNaN(str)
}
// 大于0的整数
export function validInteger (val) {
  return /^\+?[1-9][0-9]*$/.test(val)
}

export function validDeviceKey (val) {
  return !/^\d{15}$/.test(val)
}

export function validAppIdAndSecret (val) {
  return /[a-zA-Z0-9]{1,64}$/.test(val)
}

export function validPlatformDomain (val) {
  return /^[a-z0-9]{1,10}$/.test(val)
}

export function validDeviceSecret (val) {
  return !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{20,35}$/.test(val)
}
// 数字,字母或组合
export function validateNumAndEn (val) {
  return /^[0-9a-zA-Z]*$/g.test(val)
}
// 特殊字符和空格
export function validateCharactersAndSpaces (val) {
  return /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/.test(val)
}
// 字母数字下划线中横线
export function validvin (val) {
  return !/^[\w_-]+$/g.test(val)
}
