var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notice
    ? _c(
        "el-alert",
        {
          staticClass: "alert-box",
          attrs: { type: "warning", closable: false },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _vm.TextCarousel
                ? _c(_vm.TextCarousel, {
                    tag: "component",
                    attrs: { notice: _vm.notice },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }