<template>
  <el-input-number
    v-model="inputNumber"
    :max="max"
    :min="min"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
    @change="onChange"
    @blur="onBlur"
  />
</template>

<script>

export default {
  props: {
    value: { type: [Number, String], default: 0 },
    max: { type: Number, default: 999999999 },
    min: { type: Number, default: -999999999 },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      inputNumber: null
    }
  },
  computed: {
    // inputNumber: {
    //   get () {
    //     // let num = Number(this.value)
    //     // let len = num.toString().split('.')[1]?.length
    //     // return !this.$attrs.precision && len > 6 ? num.toFixed(6) : num // 当未设置精度precision且输入小数超过6位时，仅保留6位

    //     return this.value
    //   },
    //   set (val) {
    //     console.log('set===>', val)
    //     // this.$emit('input', val)
    //   }
    // }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        let num = Number(val)
        let len = num.toString().split('.')[1]?.length
        this.inputNumber = !this.$attrs.precision && len > 6 ? num.toFixed(6) : num // 当未设置精度precision且输入小数超过6位时，仅保留6位
      }
    }
  },
  methods: {
    onChange (val) {
      this.$emit('input', val)
    },
    onBlur () {
      // 当用户清空输入时，如果min=0时，回显为 0
      if (this.min === 0 && (this.inputNumber === undefined || this.inputNumber == null)) {
        this.inputNumber = 0
        this.$emit('input', 0)
      }
    }
  }
}
</script>
