import { login, logout, getInfo, dmpAuth, getPublicKey, tokenRefresh, getLoginPageConfInfo } from '@/api/login'
import { getToken, setToken, removeToken, getRefreshToken, setRefreshToken, removeRefreshToken } from '@/utils/auth'
import { getPlatformOemConfInfo } from '@/api/company/company'
import { randomString } from '@/utils/aep'
import { Encrypt } from '@/utils/secret'
import {
  getRunning
} from '@/api/upload/task'
import { getOffset } from '@/utils/date'
import store from '@/store'
import Cookies from 'js-cookie'

const user = {
  state: {
    token: getToken(),
    refreshToken: getRefreshToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    orgtype: '',
    orgId: '',
    pan: undefined,
    publicKey: '',
    activeRouter: [],
    activeTitle: '',
    activeBasePath: '',
    switchClass: false,
    userId: '',
    tenantId: '',
    agentList: [],
    confInfo: {},
    isReadClause: undefined,
    dmpUserInfo: {},
    taskRunData: [],
    companyOem: {},
    UnReadCount: 0,
    saas: '',
    orgName: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REFRESHTOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_PHOME: (state, phone) => {
      state.phone = phone
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_ORGTYPE: (state, orgtype) => {
      state.orgtype = orgtype
    },
    SET_ORGID: (state, orgId) => {
      state.orgId = orgId
    },
    SET_PAN: (state, pan) => {
      state.pan = pan
    },
    SET_PUBLICKEY: (state, publicKey) => {
      state.publicKey = publicKey
    },
    SET_ACTIVEROUTER: (state, activeRouter) => {
      state.activeRouter = activeRouter
    },
    SET_ACTIVETITLE: (state, activeTitle) => {
      state.activeTitle = activeTitle
    },
    SET_ACTIVEBASEPATH: (state, activeBasePath) => {
      state.activeBasePath = activeBasePath
    },
    SET_SWITCHCLASS: (state, switchClass) => {
      state.switchClass = switchClass
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_TENANTID: (state, tenantId) => {
      state.tenantId = tenantId
    },
    SET_AGENT_LIST: (state, agentList) => {
      state.agentList = agentList
    },
    SET_CONF_INFO: (state, confInfo) => {
      state.confInfo = confInfo
    },
    SET_ISREADCLAUSE: (state, isReadClause) => {
      state.isReadClause = isReadClause
    },
    SET_DMP_USERINFO: (state, dmpUserInfo) => {
      state.dmpUserInfo = dmpUserInfo
    },
    SET_TASK_RUN_DATA: (state, taskRunData) => {
      state.taskRunData = taskRunData
    },
    SET_UN_READCOUNT: (state, UnReadCount) => {
      state.UnReadCount = UnReadCount
    },
    SET_COMPANY_OEM: (state, companyOem) => {
      state.companyOem = companyOem
    },
    SET_SAAS: (state, saas) => {
      state.saas = saas
    },
    SET_ORGNAME: (state, orgName) => {
      state.orgName = orgName
    }
  },

  actions: {
    // 企业个性化配置
    ConfInfo ({ commit }, confInfo) {
      return new Promise(resolve => {
        commit('SET_CONF_INFO', confInfo)
        localStorage.setItem('confInfo', JSON.stringify(confInfo))
        resolve()
      })
    },
    // 登录
    Login ({ commit }, userInfo) {
      let random = randomString(16)
      const username = userInfo.username.trim()
      const password = Encrypt(userInfo.password, random)
      const uuid = userInfo.uuid
      const systemDomain = userInfo.systemDomain
      const saas = userInfo.saas
      return new Promise((resolve, reject) => {
        login(username, password, uuid, systemDomain, random, saas).then(res => {
          setToken(res.data.accessToken)
          commit('SET_TOKEN', res.data.accessToken)
          setRefreshToken(res.data.refreshToken)
          commit('SET_REFRESHTOKEN', res.data.refreshToken)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 刷新token
    RefreshToken ({ commit }, refreshToken) {
      return new Promise((resolve, reject) => {
        const params = {
          refreshToken
        }
        tokenRefresh(params).then(res => {
          setToken(res.data.accessToken)
          commit('SET_TOKEN', res.data.accessToken)
          setRefreshToken(res.data.refreshToken)
          commit('SET_REFRESHTOKEN', res.data.refreshToken)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    DmpLogin ({ commit }, userInfo) {
      const params = {
        code: userInfo.code,
        state: userInfo.state,
        callback: userInfo.callback
      }
      return new Promise((resolve, reject) => {
        dmpAuth(params).then(res => {
          if (res.data.tokenInfo) {
            setToken(res.data.tokenInfo.accessToken)
            commit('SET_TOKEN', res.data.tokenInfo.accessToken)
            setRefreshToken(res.data.tokenInfo.refreshToken)
            commit('SET_REFRESHTOKEN', res.data.tokenInfo.refreshToken)
          } else {
            Cookies.set('dmpUserInfo', res.data)
            commit('SET_DMP_USERINFO', res.data)
            commit('SET_SAAS', res.data.saas)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit, state }, saasInfo) {
      return new Promise((resolve, reject) => {
        getInfo({ saas: saasInfo }).then(res => {
          if (res && res.code === 200) {
            const user = res.data.user
            const avatar = user.avatar ? user.avatar : require('@/assets/images/profile.jpg')
            if (user.timeZoneOffset) {
              store.dispatch('app/setOffset', user.timeZoneOffset)
            } else {
              store.dispatch('app/setOffset', getOffset())
            }
            if (res.data.roles && res.data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', res.data.roles)
              commit('SET_PERMISSIONS', res.data.permissions)
            } else {
              commit('SET_ROLES', ['ROLE_DEFAULT'])
            }
            commit('SET_NAME', user.userName)
            commit('SET_ORGTYPE', user.orgType)
            commit('SET_ORGID', user.orgId)
            commit('SET_PAN', user.isPan)
            commit('SET_AVATAR', avatar)
            commit('SET_USERID', user.userId)
            commit('SET_TENANTID', user.tenantId)
            commit('SET_ISREADCLAUSE', user.isReadClause)
            commit('SET_PHOME', user.phonenumber)
            commit('SET_SAAS', user.saas)// 用于登陆过，关闭游览器后直接访问页面地址，跳过登录页
            commit('SET_ORGNAME', user.orgName)// 用于登陆过，关闭游览器后直接访问页面地址，跳过登录页
            resolve(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取SAAS登录类型
    GetLoginType ({ commit, dispatch }, systemDomain) {
      return new Promise((resolve, reject) => {
        getLoginPageConfInfo({ systemDomain }).then(res => {
          if (res && res.code === 200) {
            const data = res.data
            dispatch('ConfInfo', data)
            commit('SET_SAAS', data.saas) // 登录页获取saas类型
            resolve(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 运行中的任务（当前用户创建的且任务状态为进行中的任务）
    GetTaskRunning ({ commit, state }) {
      return new Promise((resolve, reject) => {
        getRunning().then(res => {
          if (res && res.code === 200) {
            const data = res.data
            commit('SET_TASK_RUN_DATA', data.saas)
            resolve(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    IsReadClause ({ commit }, isReadClause) {
      return new Promise(resolve => {
        commit('SET_ISREADCLAUSE', isReadClause)
        resolve()
      })
    },

    // 退出系统
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          commit('SET_ACTIVEROUTER', [])
          commit('SET_ACTIVETITLE', '')
          commit('SET_ACTIVEBASEPATH', '')
          commit('SET_AGENT_LIST', [])
          store.dispatch('tagsView/delAllViews', [])
          removeToken()
          commit('SET_REFRESHTOKEN', '')
          removeRefreshToken()
          localStorage.removeItem('activeRouter')
          localStorage.removeItem('activeTitle')
          localStorage.removeItem('activeBasePath')
          localStorage.removeItem('agentList')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        commit('SET_REFRESHTOKEN', '')
        removeRefreshToken()
        resolve()
      })
    },

    // 获取公钥
    PublicKey ({ commit }) {
      return new Promise((resolve, reject) => {
        getPublicKey().then(res => {
          commit('SET_PUBLICKEY', res.publicKey)
          localStorage.publicKey = res.publicKey
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取未读通知数
    getUnReadCount ({ commit }, data) {
      if (data !== undefined) commit('SET_UN_READCOUNT', data)
    },
    // 当前路由
    ActiveRouter ({ commit }, activeRouter) {
      return new Promise(resolve => {
        commit('SET_ACTIVEROUTER', activeRouter.list)
        commit('SET_ACTIVETITLE', activeRouter.title)
        commit('SET_ACTIVEBASEPATH', activeRouter.basePath)
        localStorage.setItem('activeRouter', JSON.stringify(activeRouter.list))
        localStorage.setItem('activeTitle', activeRouter.title)
        localStorage.setItem('activeBasePath', activeRouter.basePath)
        resolve()
      })
    },

    // 清除当前路由
    ClearActiveRouter ({ commit }) {
      return new Promise(resolve => {
        commit('SET_ACTIVEROUTER', [])
        commit('SET_ACTIVETITLE', '')
        commit('SET_ACTIVEBASEPATH', '')
        localStorage.removeItem('activeRouter')
        localStorage.removeItem('activeTitle')
        localStorage.removeItem('activeBasePath')
        resolve()
      })
    },

    // 左侧导航开关
    SwitchClass ({ commit }, switchClass) {
      return new Promise(resolve => {
        commit('SET_SWITCHCLASS', switchClass)
        localStorage.setItem('switchClass', switchClass)
        resolve()
      })
    },

    // 用户信息录入后设置token
    SetToken ({ commit }, tokenInfo) {
      return new Promise(resolve => {
        setToken(tokenInfo.accessToken)
        commit('SET_TOKEN', tokenInfo.accessToken)
        setRefreshToken(tokenInfo.refreshToken)
        commit('SET_REFRESHTOKEN', tokenInfo.refreshToken)
        Cookies.set('dmpUserInfo', '')
        commit('SET_DMP_USERINFO', {})
        resolve()
      })
    },

    // 获取企业OEM配置
    GetCompanyOem ({ commit, state }, userId) {
      return new Promise((resolve, reject) => {
        getPlatformOemConfInfo({ userId, saas: state.saas }).then(res => {
          const data = res.data
          if (data.systemName) {
            let lang = Cookies.get('lang')
            document.title = lang === 'zh-CN' ? data.systemName : data.systemEnName
          }
          if (data.systemFavicon) {
            let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'
            link.href = data.systemFavicon
            document.getElementsByTagName('head')[0].appendChild(link)
          }
          commit('SET_COMPANY_OEM', data)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
        resolve()
      })
    }
  }
}

export default user
