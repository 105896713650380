var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            width: "900px",
            top: "50px",
            "append-to-body": true,
            "modal-append-to-body": false,
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "before-close": _vm.closeDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "mb8",
              on: { change: _vm.taskStatusChange },
              model: {
                value: _vm.queryParams.taskStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "taskStatus", $$v)
                },
                expression: "queryParams.taskStatus",
              },
            },
            _vm._l(_vm.taskStatusOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _c("Table", {
            attrs: {
              data: _vm.list,
              columns: _vm.columns,
              border: false,
              total: _vm.total,
              "query-params": _vm.queryParams,
            },
            on: {
              "update:queryParams": function ($event) {
                _vm.queryParams = $event
              },
              "update:query-params": function ($event) {
                _vm.queryParams = $event
              },
              getList: _vm.getDetails,
            },
            scopedSlots: _vm._u([
              {
                key: "taskStatus",
                fn: function ({ row }) {
                  return [
                    row.taskStatus === 1
                      ? _c("span", {
                          staticClass: "warning",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "task.taskDetails.table.taskStatusOptions.1"
                              )
                            ),
                          },
                        })
                      : row.taskStatus === 2
                      ? _c("span", {
                          staticClass: "primary",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "task.taskDetails.table.taskStatusOptions.2"
                              )
                            ),
                          },
                        })
                      : row.taskStatus === 3
                      ? _c("span", {
                          staticClass: "success",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "task.taskDetails.table.taskStatusOptions.3"
                              )
                            ),
                          },
                        })
                      : row.taskStatus === 4
                      ? _c("span", {
                          staticClass: "danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "task.taskDetails.table.taskStatusOptions.4"
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog("form")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm("form")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }