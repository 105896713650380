import store from '@/store'

// 去掉时区概念的时间戳
export function getStampSearch (stamp) {
  try {
    var timezone = store.state.app.offset
    if (!stamp) {
      return ''
    }
    if (stamp.toString().length < 13) {
      stamp = parseInt(stamp) * 1000// ms
    }
    // 如果timezone为null,则直接返回时间戳毫秒数
    if (!timezone) {
      return parseInt(stamp)
    } else {
      var hour = timezone.split(':')[0]
      var min = timezone.split(':')[1]
      timezone = Number(hour) + Math.floor(Number(min / 60 * 100)) / 100
    }
    // 获得本地的偏移差
    var now = new Date()
    var diff = now.getTimezoneOffset() * 60 * 1000// ms
    // 再得到timeZone所在时区的时间
    var timeZoneStamp = parseInt(stamp) - timezone * 60 * 60 * 1000 - diff
    return timeZoneStamp
  } catch (e) {
    if (stamp.toString().length < 13) {
      stamp = parseInt(stamp) * 1000 // ms
    }
    return stamp
  }
}

// 加上时区概念的时间戳
export function getStamp (stamp) {
  try {
    var timezone = store.state.app.offset
    if (!stamp) {
      return ''
    }
    if (stamp.toString().length < 13) {
      stamp = parseInt(stamp) * 1000// ms
    }
    // 如果timezone为null,则直接返回时间戳毫秒数
    if (!timezone) {
      return parseInt(stamp)
    } else {
      var hour = timezone.split(':')[0]
      var min = timezone.split(':')[1]
      timezone = Number(hour) + Math.floor(Number(min / 60 * 100)) / 100
    }
    // 获得本地的偏移差
    var now = new Date()
    var diff = now.getTimezoneOffset() * 60 * 1000// ms
    // 得到此时的格林尼治时间
    var GreenichStamp = parseInt(stamp) + diff
    // 再得到timeZone所在时区的时间
    var timeZoneStamp = GreenichStamp + timezone * 60 * 60 * 1000
    return timeZoneStamp
  } catch (e) {
    if (stamp.toString().length < 13) {
      stamp = parseInt(stamp) * 1000 // ms
    }
    return stamp
  }
}

// 在字符串某位置插入字符
export function insertFlg (str, flg, sn) {
  var newstr = ''
  for (let i = 0; i < str.length; i++) {
    var tmp = str.substring(i, i + 1)
    i === sn ? newstr += tmp + flg : newstr += tmp
  }
  return newstr
}

// 获取系统时区偏移
export function getOffset () {
  const times = new Date()
  const arr = String(times).split(' ')
  const GMT = arr[arr.length - 2].replace('GMT', '')
  const offset = insertFlg(GMT, ':', 2)
  return offset
}
