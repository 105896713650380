<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="900px"
      top="50px"
      :append-to-body="true"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="closeDialog"
    >
      <el-select v-model="queryParams.taskStatus" class="mb8" @change="taskStatusChange">
        <el-option v-for="item in taskStatusOptions" :key="item.value" :label="item.name" :value="item.value" />
      </el-select>
      <Table
        :data="list"
        :columns="columns"
        :border="false"
        :total="total"
        :query-params.sync="queryParams"
        @getList="getDetails"
      >
        <template #taskStatus="{ row }">
          <span v-if="row.taskStatus === 1" class="warning" v-text="$t('task.taskDetails.table.taskStatusOptions.1')" />
          <span
            v-else-if="row.taskStatus === 2"
            class="primary"
            v-text="$t('task.taskDetails.table.taskStatusOptions.2')"
          />
          <span
            v-else-if="row.taskStatus === 3"
            class="success"
            v-text="$t('task.taskDetails.table.taskStatusOptions.3')"
          />
          <span
            v-else-if="row.taskStatus === 4"
            class="danger"
            v-text="$t('task.taskDetails.table.taskStatusOptions.4')"
          />
        </template>
      </Table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('form')">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="confirm('form')">{{ $t('common.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDetail
} from '@/api/upload/task'

export default {
  components: {

  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    parentForm: {
      type: Object,
      default () {
        return {
          id: null
        }
      }
    },
    isRunning: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      taskStatusOptions: [
        { name: this.$t('task.taskDetails.table.taskStatusOptions.0'), value: null },
        { name: this.$t('task.taskDetails.table.taskStatusOptions.1'), value: 1 },
        { name: this.$t('task.taskDetails.table.taskStatusOptions.2'), value: 2 },
        { name: this.$t('task.taskDetails.table.taskStatusOptions.3'), value: 3 },
        { name: this.$t('task.taskDetails.table.taskStatusOptions.4'), value: 4 }
      ],
      total: 0,
      listLoading: true,
      // 列表数据
      list: [],
      queryParams: {
        id: this.parentForm.id,
        taskStatus: null,
        pageNum: 1,
        pageSize: 10
      },
      // 带参数id查详情
      form: {},
      columns: [
        { code: 'taskTag', name: this.$t('task.taskDetails.table.taskTag') },
        { code: 'taskStatus', name: this.$t('task.taskDetails.table.taskStatus') },
        { code: 'errorMsg', name: this.$t('task.taskDetails.table.errorMsg') },
        { code: 'dataPayload', name: this.$t('task.taskDetails.table.dataPayload') },
        { code: 'tsCreateTime', name: this.$t('common.createTime'), type: 'time' }
      ]
    }
  },
  methods: {
    closeDialog () {
      this.$emit('update:visible', false)
    },
    taskStatusChange (formName) {
      this.getDetails()
    },
    getDetails () {
      getDetail(this.queryParams).then(res => {
        if (res.code === 200) {
          this.list = res.rows
          this.total = res.total
          this.listLoading = false
        }
      }).catch(e => {
        this.listLoading = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
}

.img-list {
  position: relative;

  .el-button {
    position: absolute;
    top: 50%;
    margin-top: -28px;
    font-size: 30px;

    &.el-icon-arrow-left {
      left: -20px;
    }

    &.el-icon-arrow-right {
      right: -20px;
    }
  }
}

.pagination-container {
  padding-bottom: 0 !important;
}

::v-deep {
  .el-drawer__header {
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
  }

  .el-table__body-wrapper {
    overflow: auto;
    max-height: calc(100vh - 67px - 44px - 44px - 72px);
    overflow-x: hidden;
  }
}
</style>
