<template>
  <div
    class="sidebar-logo-container"
    :style="{
      backgroundColor: settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? '#07081e' : '#fff'
    }"
  >
    <transition name="sidebarLogoFade">
      <router-link class="sidebar-logo-link" :to="{ name: firstRouters }">
        <img
          class="sidebar-logo"
          :src="settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? darkLogo : brightLogo"
        />
        <h1
          class="sidebar-title"
          :style="{
            color: settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? '#fff' : '#303133'
          }"
        >
          {{ title }}
        </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import variables from '@/assets/styles/variables.scss'

export default {
  name: 'SidebarLogo',
  computed: {
    variables () {
      return variables
    },
    ...mapState(['settings']),
    ...mapGetters(['firstRouters'])
  },
  data () {
    return {
      title: JSON.parse(localStorage.getItem('confInfo'))?.systemName,
      brightLogo: JSON.parse(localStorage.getItem('confInfo'))?.systemInsideBrightLogoUrl,
      darkLogo: JSON.parse(localStorage.getItem('confInfo'))?.systemInsideDarkLogoUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 66px;
  line-height: 66px;

  & .sidebar-logo-link {
    width: 100%;
    height: 100%;

    & .sidebar-logo {
      width: 50px;
      margin-right: 12px;
      margin-left: 24px;
      vertical-align: middle;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: 600;
      vertical-align: middle;
      line-height: 50px;
    }
  }
}
</style>
