var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navbar",
      class:
        _vm.settings.sideTheme === "theme2" ||
        _vm.settings.sideTheme === "theme4"
          ? "theme-dark"
          : "theme-light",
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme2" ||
          _vm.settings.sideTheme === "theme4"
            ? _vm.variables.menuBg
            : _vm.variables.menuLightBg,
      },
    },
    [
      _vm.showProgress
        ? _c(
            "div",
            {
              staticClass: "running",
              on: {
                click: function ($event) {
                  _vm.taskRunVisible = true
                },
              },
            },
            [_c("ProgressBall", { attrs: { "chart-data": _vm.chartData } })],
            1
          )
        : _vm._e(),
      _vm.taskRunVisible
        ? _c("TaskRunData", {
            ref: "addDialog",
            attrs: {
              visible: _vm.taskRunVisible,
              title: _vm.$t("task.running"),
              "task-run-data": _vm.taskRunList,
            },
            on: {
              "update:visible": function ($event) {
                _vm.taskRunVisible = $event
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tags-view fl" }, [_c("TagsView")], 1),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.orgtype === 2 && _vm.needFull === "true"
            ? _c(
                "a",
                {
                  staticClass: "fl full-screen",
                  attrs: { type: "text" },
                  on: { click: _vm.toFullScreen },
                },
                [
                  _c("img", {
                    staticClass: "vm",
                    attrs: {
                      src: require("../../../assets/images/dashboard/icon-full-top.png"),
                      alt: "",
                    },
                  }),
                  _c("i", { staticClass: "vm" }, [_vm._v("可视化大屏")]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "notify",
              on: {
                click: function ($event) {
                  return _vm.goGuide()
                },
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-document",
                staticStyle: { "font-size": "21px" },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["letter"],
                  expression: "['letter']",
                },
              ],
              staticClass: "notify",
              on: {
                click: function ($event) {
                  return _vm.goLetter()
                },
              },
            },
            [
              _c(
                "el-badge",
                {
                  attrs: {
                    value: _vm.UnReadCount,
                    max: 10,
                    hidden: _vm.UnReadCount === 0,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-bell",
                    staticStyle: { "font-size": "21px" },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm.orgtype !== 1
            ? _c(
                "div",
                {
                  staticClass: "orgName",
                  style: {
                    border: `1px solid ${_vm.orgInfo.color}`,
                    borderLeftStyle: "none",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-o",
                      style: { background: `${_vm.orgInfo.color}` },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { "icon-class": _vm.orgInfo.icon },
                      }),
                      _vm._v(_vm._s(_vm.orgInfo.name) + " "),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-o",
                      style: { color: ` ${_vm.orgInfo.color}` },
                      attrs: { title: _vm.orgName },
                    },
                    [_vm._v(" " + _vm._s(_vm.orgName) + " ")]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar },
                }),
                _c("span", { staticClass: "user-name text-o" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
                _c("i", { staticClass: "el-icon-arrow-down user-icon" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["personal"],
                          expression: "['personal']",
                        },
                      ],
                      attrs: { to: "/profile" },
                    },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(_vm._s(_vm.$t("navbar.personal"))),
                      ]),
                    ],
                    1
                  ),
                  _vm.pan === true
                    ? _c(
                        "el-dropdown-item",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["companyInfo"],
                              expression: "['companyInfo']",
                            },
                          ],
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleDeploy.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("navbar.company")) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.logout")))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "theme",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.setting = true
                },
              },
            },
            [_c("span"), _c("span"), _c("span")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }