<template>
  <span :class="disabled ? 'text-link-disabled' : 'text-link'" @click="toLink">
    <slot />
  </span>
</template>

<script>
/**
 * 目前主要用于 table 内容跳转
 * table 中包裹内容元素使用 overflow: hidden; 导致 “火狐浏览器” show-overflow-tooltip (el-toolTip) 失效
 */
export default {
  name: 'TextLink',
  props: {
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 跳转链接
    url: {
      type: String,
      default: null
    }
  },
  data () {
    return {}
  },
  methods: {
    toLink () {
      if (this.disabled === false) {
        if (this.url) this.$router.push(this.url)
        else this.$emit('toLink')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-link,
.text-link-disabled {
  display: inline;
  width: 100%;
}

.text-link {
  color: #456ff7;
  cursor: pointer;
}

.text-link-disabled {
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
