import request from '@/utils/request'

// 站内信列表
export function getList (query) {
  return request({
    url: '/notice/notice/myList',
    method: 'get',
    params: query
  })
}

// 标记已读
export function read (params) {
  return request({
    url: '/notice/notice/read',
    method: 'get',
    params
  })
}

// 获取置顶滚动
export function getTopRolling () {
  return request({
    url: '/notice/notice/top',
    method: 'get'
  })
}

// 全部已读
export function readAll (data) {
  return request({
    url: '/notice/notice/readAll',
    method: 'get',
    data
  })
}
