/**
 * input禁止输入指令构造器
 */
export default function factory (regex) {
  return {
    bind (el) {
      const obj = findEle(el, 'input') || findEle(el, 'textarea')
      const clear = function (e) {
        if (e.target.composing) return
        if (regex.exec(obj.value)) {
          obj.value = obj.value.replace(regex, '')
          clear(e)
        }
        // 分发input事件，触发v-model的更新
        obj.dispatchEvent(new Event('input'))
      }
      const blur = function (e) {
        clear(e)
        setTimeout(() => {
          if (regex.exec(obj.value)) {
            obj.value = obj.value.replace(regex, '')
            clear(e)
          }
          // 分发change事件，触发v-model的更新
          obj.dispatchEvent(new Event('change'))
        }, 50)
      }
      obj.addEventListener('keyup', clear)
      obj.addEventListener('blur', blur)
    }
  }
}

/**
* 匹配DOM元素
* @param {El} parent 指令所绑定的元素
* @param {String} type input类型
* @returns
*/
function findEle (parent, type) {
  return parent.tagName.toLowerCase() === type ? parent : parent.querySelector(type)
}
