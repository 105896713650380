<template>
  <div v-if="showApp" id="app">
    <router-view v-if="isRouterAlive" v-loading="!isRouterAlive" />
  </div>
</template>

<script>
import { getUnReadCount } from '@/api/login'
export default {
  name: 'App',
  provide () {
    return {
      adminReload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true,
      showApp: false
    }
  },
  async created () {
    let systemDomain = window.location.hostname
    await this.$store.dispatch('GetLoginType', systemDomain)
    this.showApp = true
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    accpectSocket (response) {
      try {
        if (response) {
          const warnType = this.handleWarnType(response.eventType)
          const __HTML = `<div><p>${this.$t('m.letter.eq_number')}：${response.deviceKey}</p>
            <p>${this.$t('m.letter.device_name')}：${response.deviceName}</p>
            <p>${this.$t('m.letter.warn_time')}：${this.parseTime(response.time)}</p></div>`
          this.$notify({
            title: response.eventName,
            dangerouslyUseHTMLString: true,
            duration: 30 * 1000,
            message: __HTML,
            onClick: () => {
              localStorage.setItem('deviceDtail', JSON.stringify(response))
              this.checkReaded(response.messageId)
            },
            type: warnType.type
          })
          getUnReadCount().then(response => { if (response.data !== undefined) this.$store.dispatch('getUnReadCount', response.data) })
          this.$forceUpdate(true)
        }
      } catch (error) {
        console.log(`accpectSocket response format error: ${error}`)
      }
    },
    handleWarnType (type) {
      if (type !== undefined) {
        switch (type) {
          case 1:
            return { name: this.$t('operatingStatus.error'), type: 'error' }
          case 2:
            return { name: this.$t('notice.temp.type.item2'), type: 'success' }
          case 3:
            return { name: this.$t('operatingStatus.warn'), type: 'warning' }
          case 4:
            return { name: this.$t('notice.temp.type.item4'), type: 'success' }
          case 5:
            return { name: this.$t('notice.temp.type.item5'), type: 'info' }
          case 6:
            return { name: this.$t('notice.temp.type.item6'), type: 'info' }
          default:
            break
        }
      }
    },
    async checkReaded (messageId) {
      const { read } = require('@/api/system/letter/letter')
      await read(messageId)
      const response = await getUnReadCount()
      if (response.data !== undefined) this.$store.dispatch('getUnReadCount', response.data)
      this.reload()
    }
  }
}
</script>
