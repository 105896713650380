var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.item.hidden
    ? _c(
        "div",
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild.meta
                  ? _c(
                      "app-link",
                      { attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "main-menu-item",
                            on: {
                              mouseenter: function ($event) {
                                return _vm.overMouse([], _vm.item)
                              },
                              click: _vm.handleRouter,
                            },
                          },
                          [
                            _c("item", {
                              attrs: {
                                icon:
                                  _vm.onlyOneChild.meta.icon ||
                                  (_vm.item.meta && _vm.item.meta.icon),
                                title: _vm.generateText(
                                  "m",
                                  _vm.onlyOneChild.meta.otherName
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            : _c(
                "div",
                [
                  _c(
                    "app-link",
                    {
                      class: { active: _vm.item.active },
                      attrs: { to: _vm.resolvePath(_vm.item.path) },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "main-menu-item",
                          on: {
                            mouseenter: function ($event) {
                              return _vm.overMouse(_vm.item.children, _vm.item)
                            },
                            mouseleave: function ($event) {
                              return _vm.outMouse(_vm.item)
                            },
                            click: function ($event) {
                              return _vm.handleSaveRouter(_vm.item)
                            },
                          },
                        },
                        [
                          _vm.item.meta
                            ? _c("item", {
                                attrs: {
                                  icon: _vm.item.meta && _vm.item.meta.icon,
                                  title: _vm.generateText(
                                    "m",
                                    _vm.item.meta.otherName
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }