export const menu = {
  demo: '导航',
  operating: '运营管理',
  operator_list: '运营商管理',
  opt_place: '运营场所',
  optplace_edit: '运营场所信息',
  bizDevice: '设备管理',
  bizDevice_detail: '设备详情',
  bizDevice_list: '设备列表',
  order_list: '订单列表',
  order_refund: '退款记录',
  order_renewa: '设备续费记录',
  order_detail: '订单详情',
  work_detail: '工单详情',
  optplace_add: '新增运营场所',
  alarm_detail: '告警详情',
  feedback_detail: '反馈详情',
  payment: {
    payment: '支付计费',
    payconfig: '支付配置',
    rates: '计费规则',
    ratesInfo: '计费规则信息',
    ratesList: '查看计费规则',
    ratesAdd: '新增计费规则',
    profit: '分润管理',
    recordDetail: '分润记录',
    accountInfo: '分润账户信息',
    ruleInfo: '分润规则信息',
    ruleDetail: '规则详情',
    appConfig: '应用配置',
    merchantConfig: '商户配置',
    serviceConfig: '服务商配置',
    count: '结算',
    huifuadd: '汇付账户信息'
  },
  optPlace: {
    add: '新增运营场所',
    edit: '修改运营场所',
    del: '删除运营场所',
    allocate: '分配设备',
    vendor_option: '厂商选项',
    operator_option: '运营商选项',
    unbind_rec: '回收设备记录',
    unbind: '回收设备',
    orgNameOption: '归属企业选项'
  },
  operator: {
    add: '新增运营商',
    edit: '编辑运营商',
    del: '删除运营商',
    set: '设置'
  },
  bizUser: {
    bizUser: '终端管理',
    bizUserList: '终端用户',
    userDetail: '用户详情'
  },
  order: {
    query: '订单列表查询',
    orderMan: '订单管理',
    refund: '退款',
    endOrder: '结束订单',
    payment: '支付',
    cancel: '取消'
  },
  monitor: {
    monitor: '运维监控',
    alarm: '故障告警',
    feedback: '问题反馈',
    OManage: '工单管理',
    orderDetail: '工单详情',
    finished: '结束工单',
    suggestion: '意见反馈'
  },
  device: {
    assgin: '分配设备'
  }
}

export const appZhLocale = {
  test: '测试',
  add: '添加',
  edit: '编辑',
  del: '删除',
  export: '导出',
  import: '导入',
  exportSuccess: '导出成功',
  exportFail: '导出失败',
  networkErr: '网络错误',
  operator: {
    save: '保存',
    operatorName: '运营商名称',
    parentName: '上级名称',
    optPlaceNum: '运营场所数',
    putDeviceNum: '投放设备数',
    operatorAccount: '运营商账号',
    addOperator: '添加运营商',
    operatorTips: '请为您的运营商添加一个登录账号',
    operatorShowTips: '隐藏后，则本运营场所在用户移动端列表不可见',
    operatorPlaceAnnouncementTips: '内容将会在场所详情页作为顶部横幅显示，为空则不显示',
    nickName: '昵称',
    password: '密码',
    confirmPassword: '确认密码',
    uploadTips: '通过Excel导入设备IMEI号，分配已有设备给下级运营商',
    place: {
      enterLinkman: '请输入联系人',
      enterAddress: '请输入详细地址',
      enterProvince: '请选择省份',
      enterCity: '请选择城市',
      enterNickName: '请输入昵称',
      enterPassword: '请输入密码',
      enterConfirmPassword: '请再次输入密码',
      selectOperator: '运营商',
      selectOptPlace: '运营场所'
    }
  },
  // 设备
  device: {
    portInfo: '插座信息',
    port: '号插座',
    portStatus: '插座状态',
    enterPortStatus: '请选择插座状态',
    portId: '插座ID',
    portName: '插座名称',
    deviceCode: '设备编码',
    portFree: '空闲',
    portUsing: '使用中',
    portDisable: '禁用中',
    portShortCircuit: '短路保护',
    portOverCurrent: '过流保护',
    portOverVoltage: '过压保护',
    portHardwareAbnormal: '硬件故障',
    portAbnormal: '故障',
    portOffline: '离线',
    disablePort: '禁用插座',
    startPort: '启用插座',
    selected: '选中',
    deviceOffline: '设备不在线',
    selectedPort: '请先选择插座',
    orderInfo: '订单记录',
    nickName: '用户昵称',
    orderNo: '订单号',
    optPlaceName: '运营场所',
    operatorName: '运营企业',
    devicePort: '端口号',
    orderFee: '订单金额',
    payFee: '支付金额',
    payType: '支付方式',
    orderStatus: '订单状态',
    orderTime: '订单时间',
    payStatus: '支付状态',
    amount: '实付金额',
    usingTime: '租用时长',
    orderDetail: '详情',
    refund: '退款',
    paid: '已支付',
    unPaid: '未支付',
    payFail: '支付失败',
    refunded: '已退款',
    waitingPay: '等待支付',
    using: '使用中',
    complete: '完成',
    abnormal: '订单中断',
    cancelOrder: '取消订单',
    waitingUse: '已支付待使用',
    wxPay: '微信支付',
    zhifubao: '支付宝',
    yinlian: '银联',
    canRefundFee: '可退款金额',
    getCanRefundFeeError: '获取订单可退款金额出错',
    refundFee: '退款金额',
    initRefundSucc: '发起退款成功',
    enterRefundFee: '请输入退款金额',
    enterRefundFeeError: '输入的退款金额不能大于可退款金额',
    enterRefundFeeValid: '请输入正确的支付金额',
    refundReason: '退款原因',
    deviceSn: '设备SN',
    selectOptPlace: '运营场所',
    selectOperator: '请选择运营商',
    rateName: '计费规则',
    currentOrder: '当前订单',
    deviceRates: '计费规则',
    // 1.3.2
    equipmentActivation: '设备激活',
    serviceRegulations: '服务规则',
    // 1.3.2
    pleaseSelectActivate: '请选择未激活的设备',
    pleaseSelectRenew: '请选择可续费的设备',
    produce: '选择产品',
    device: '选择设备',
    deviceTip: '不选设备将默认导出所有产品下该运营商/场所内所有设备的应用二维码',
    operatorNames: '归属运营商',
    qrcodeSn: '设备二维码',
    qrcodeApp: '应用二维码'
  },
  // 支付
  payment: {
    payconfig: {
      save: '保存',
      title: '商户号: ',
      wechatConfig: '移动端配置',
      wechatType: '移动端类型',
      miniprogram: '小程序',
      typeAppId: '请输入微信AppID',
      typeWechatType: '请选择移动端类型',
      typeAppSecret: '请输入微信AppSecret',
      typeToken: '请输入Token（令牌）',
      typeAesKey: '请输入消息加密密钥',
      mchConfig: '商户配置',
      mchType: '商户类型',
      mchId: '商户ID',
      typeMchId: '请输入微信商户ID',
      mchKey: 'APIV3密钥',
      typeMchKey: '请输入微信商户密钥',
      apiCert: 'API证书',
      typeApiCert: '请选择API证书文件',
      apiMchSerialNo: '商户证书序列号',
      wechatDirectly: '直连商户',
      wechatSubMch: '子商户',
      fileOverSize: '文件大小超出限制',
      selectFile: '选择文件',
      wechatDirectMchFileTips: '文件大小不超过4kb',
      networkErr: '网络错误',
      getMerchantNoErr: '获取支付商户号失败'
    },
    rates: {
      ratesTitle: '计费规则',
      rateImgUrl: '规则图片',
      addRates: '添加计费规则',
      ratesDetail: '计费规则详情',
      cyclePointBefore: '实际使用超过',
      cyclePointLast: '内免费，超出则按全部实际使用量计费',
      cyclePoint: '计费循环节点',
      hasfreeCredit: '免费额度',
      notPurchaseNum: '未达购买量',
      ratesName: '规则名称',
      ratesTemplate: '计费模式',
      base: '基础规则',
      ratesOption: '配置套餐',
      option: '套餐',
      timeZone: '时段',
      optPlaceQty: '关联运营场所数',
      deviceQty: '关联设备数',
      creator: '创建人',
      operator: '运营企业',
      createTime: '创建时间',
      action: '操作',
      price: '套餐金额',
      serviceFee: '服务费',
      amount: '购买量',
      dimension: '计费属性',
      time: '时间',
      electric: '电量',
      basic: '基础',
      to: '至',
      times: '次数',

      placeHolder: {
        ratesName: '计费规则名称',
        rateImgUrl: '请选择规则图片',
        rateImgType: '图片必须是png/jpg/jpeg类型',
        rateImgSize: '图片大小必须是2M以下',
        ratesTemplate: '计费模式',
        ratesNature: '请选择计费属性',
        ratesTimeZone: '时间配置范围需满足一天的时间',
        ratesTimeArea: '时段存在重叠冲突',
        price: '请输入购买金额',
        amount: '请输入购买量',
        priceFormat: '请输入正确金额',
        amountFormat: '请输入正确购买量',
        subRate: '请选择购买套餐',
        using: '请输入实际使用量',
        payTime: '请输入购买量',
        payTimeFormat: '购买时长不能大于{max}分钟',
        attribute: '请输入计算属性',
        unit: '请选择购买单位',
        base: '请选择对应的基础规则',
        baseRange: '套餐购买量需不能小于基础规则购买量',
        basePayment: '套餐金额需不能小于基础规则金额',
        cycleRange: '计费节点购买量不能超过基础规则购买量',
        timeZoneRange: '计费节点购买量不能超过时段购买量',
        startTime: '开始时间',
        endTime: '结束时间',
        orderStartTime: '请输入订单开始时间'
      },
      ratesCount: {
        title: '计费测算',
        count: '测算',
        using: '实际使用量',
        subRate: '购买套餐',
        payTime: '购买量',
        orderStartTime: '订单开始时间',
        totalFee: '订单金额',
        payFee: '使用金额',
        payFeeTips: '实际使用量小于购买量时按基础规则计费',
        refundFee: '应退金额'
      },
      ratesTips: {
        image: '仅支持上传JPG、JPEG、PNG格式文件，且大小不超过2MB',
        dimension: '产生订单时候将往该属性下发购买量',
        base: '若因提前结束订单产生退款，将按基础规则计算费用。'
      }
    },
    // 分润
    profit: {
      record: '分润记录',
      rule: '分润规则',
      profit: '分润',
      cancelProfit: '取消分润',
      operation: '操作',
      orderNoLike: '订单号',
      distributeNo: '分润单号',
      distributeType: '分润方式',
      payType: '分润服务商',
      distributeStatus: '分润状态',
      placeName: '运营场所',
      orderDetail: '详情',
      payMoney: '订单实收金额',
      realIncome: '实际收入',
      distributeMoney: '应分润金额',
      account: '分润账户',
      accountName: '账户名称',
      accountType: '账户类型',
      wechatAccountNo: '微信商户号',
      distributed: '已分润金额',
      errorMoney: '异常金额',
      undistributeMoney: '待分润金额',
      errorAmountTip: '记录分润异常的记录及入账金额，如分润失败，系统将会把应分润金额添加到对应异常金额，提供线下结算入口。',
      waitingTip: '记录分润账期为T+N的订单生成的待分润记录及金额。',
      serviceCharge: '手续费',
      baseInfo: '基础信息',
      distributeTotal: '可分润金额',
      shouldDistribute: '应分润金额',
      resetTip1: '请确认商户资金金额充足，且接收方账户信息已经填写完善',
      resetTip2: '是否重新分润？',
      cancelProfitTip1: '取消分润后，此次订单将无法再次分润',
      cancelProfitTip2: '是否取消分润？',
      distributeCalc: '分润测算',
      firstReceiver: '一级接收方',
      secondReceiver: '二级接收方',
      addSecondReceiver: '新增二级接收方',
      profitDetail: '分润详情',
      finishMoney: '已分润金额',
      accountTypeOpt: {
        self: '个人账户',
        firm: '企业账户'
      },
      typeOptions: {
        item1: '按比例',
        item2: '固定金额'
      },
      remark: '备注',
      operator: '操作人',
      operationTime: '操作时间',
      loginTitle: '系统登陆账户（默认只有查看订单记录权限）',
      accountAdd: '添加账户',
      accountEdit: '编辑账户',
      realName: '真实姓名',
      appId: '关联应用',
      loginName: '登录账号',
      password: '登录密码',
      passwordConfirm: '确认密码',
      ruleAdd: '添加规则',
      ruleEdit: '编辑规则',
      ruleName: '分润规则',
      ruleDetail: '分润规则详情',
      action: {
        addRule: '添加分润规则',
        addAccount: '添加分润账户'
      },
      disByRate: '分润比例',
      disByCount: '分润金额',
      baseRate: '基础分润比例',
      baseRateTip1: '每笔订单拿出',
      baseRateTip2: '%的金额进行分润',
      planDistributeTime: '分润时间',
      distributeTime: '分润时间（账期）',
      distributeTimeTip: '已分润的订单不支持退款，建议选择订单完成后的T+N天进行分润，避免产生交易纠纷。',
      accountDetail: '分润接收方',
      accountDetailTip: '分润接收方最多不能超过10个',
      chinaumsRuleTip: '全民付渠道下默认下单即分润，不受此项配置限制',
      placeNum: '关联营业场所的数量',
      distributePromptly: '订单完成后实时分润',
      distributeAfter1: '订单完成后',
      distributeAfter2: '天进行分润',
      distributeByRatio: '按比例分润',
      distributeByQuota: '按固定金额分润',
      quotaTip: '固定金额超出可分润金额时，会出现分润失败的情况',
      overflowTip: '超出分润金额，无法分润',
      allowDistribute: '可分润',
      qrcodeDialog: {
        title: '获取个人账号信息',
        tip: '请用微信扫码授权获取个人信息'
      },
      placeHolder: {
        orderNo: '订单号',
        distributeStatus: '分润状态',
        distributeType: '分润方式',
        payType: '分润服务商',
        distributeNo: '分润订单号',
        rule: '请输入分润规则',
        account: '账户名称',
        errorMoneyRand: '异常金额区间',
        errorMoney: '异常金额',
        appId: '请选择关联应用',
        accountName: '请输入账户名称',
        accountType: '请选择账户类型',
        realName: '请输入真实姓名',
        wechatAccountNo: '请输入或点击右侧按钮获得微信openId',
        wechatFirmNo: '请输入微信商户号',
        form: '请确认账户信息填写完整，后续分润过程中若存在账户缺失，则无法进行分润',
        loginName: '请输入6-20位字母或数字',
        password: '请输入登录密码',
        passwordConfirm: '请输入确认密码',
        ruleName: '请输入规则名称',
        baseRate: '请输入基础分润比例1-30%',
        money: '请输入分润金额',
        distributeDay: '请输入准确的分润时间',
        rateCountComment: '当前接收方分润比例总和为：',
        receiverSelect: '请选择分润账户',
        rateCountConfirm: '请确保比例总和不超过100%',
        receiverUnique: '分润账户重复，请重新选择',
        orderAmount: '请输入模拟订单金额',
        chooseData: '请选择结算数据'
      },
      halfAccount: '批量结算',
      allAccount: '全部结算',
      offlinePay: '线下结算',
      orderNo: '订单号',
      offlineAccount: '线下结算',
      offlineTip0: '确认所选订单是否都执行线下结算？',
      offlineTip1: '批量结算时，系统将对您所选订单的分润记录状态调整为已分润，并调整已分润金额为应分润金额。',
      offlineTip2: '当应分润金额大于可分润金额时不可执行批量结算，请单独结算。',
      reProfit: '重新分润',
      profitSeccess: '结算成功',
      back: '分润回退',
      actualMoney: '实收金额'
    }
  },
  // 运营商
  operating: {
    optPlace: {
      optplace_edit: '运营场所信息',
      placeName: '运营场所名称',
      location: '所在地',
      vendor: '归属厂商',
      operator: '归属运营商',
      deviceCount: '投放设备数',
      rateRule: '计费规则',
      distributeRule: '分润规则',
      operation: '操作',
      fieldName: '字段名称',
      fieldType: '字段类型',
      fieldValue: '字段值',
      allocateDevice: '分配设备',
      operatePremisesImages: '运营场所图片',
      businessTag: '业务标签',
      unbindDevice: '解绑设备',
      unbindDeviceRec: '回收记录',
      devicePhoto: '设备照片',
      locationPhoto: '位置照片',
      deviceLineBootPhoto: '设备线路引导图',
      locationEntrancePhoto: '场所入口照片',
      add: '添加运营场所',
      placeNamePlaceHold: '运营场所名称',
      vendorPlaceHold: '请选择厂商',
      operatorPlaceHold: '请选择运营商',
      newOptPlaceTitle: '新建运营场所',
      editOptPlaceTitle: '编辑运营场所',
      selectBusinussTag: '选择业务标签',
      operatePlace: '运营地址',
      formPlaceName: '场所名称',
      address: '地址',
      contact: '联系人',
      contactPhone: '联系电话',
      contactEmail: '联系邮箱',
      operateStatus: '运营状态',
      showStatus: '展示状态',
      showFlag: '是否展示',
      inOperation: '运营中',
      notOperation: '已停运',
      showFlagText: '展示',
      notShowFlagText: '隐藏',
      placeAnnouncement: '场所公告',
      mapSelectBtn: '地图选点',
      selectProvinceCity: '选择所在地',
      mapTitle: '选择位置',
      unbindSucc: '设备解绑成功',
      deviceKey: '设备DK',
      payRates: '计费规则',
      placeHolder: {
        placeName: '请输入场所名称',
        address: '请选择地址',
        contact: '请输入联系人名称',
        contactPhone: '请输入联系电话',
        contactEmail: '请输入联系邮箱',
        placeAnnouncement: '请输入场所公告',
        enterAdress: '请输入运营场所所在地址',
        devicePhoto: '请选择设备照片',
        province: '省份',
        city: '城市',
        district: '请选择地区',
        payRates: '请选择计费规则',
        rule: '请选择分润规则'
      },
      allocate: {
        allocateRecordTitle: '运营场所设备分配记录',
        allocateRecord: '设备分配记录',
        allocateDetail: '设备分配明细'
      }
    }
  },
  // 用户管理
  user: {
    accountType: '账户类型',
    relateBusiness: '关联商家',
    relateRole: '关联角色',
    bindWx: '绑定微信',
    orgType: '账户类型',
    selectRelateBusiness: '关联商家'
  },
  // 订单
  order: {
    nickName: '用户昵称',
    userId: '用户ID',
    orderNo: '订单号',
    optPlaceName: '运营场所',
    operatorName: '运营商',
    // 1.3.2
    operatorNames: '运营商名称',
    purchaseDuration: '购买时长',
    renewalAmount: '续费金额',
    orderDetails: '订单详情',
    renewal: '续费动态',
    recoveryAmount: '复机金额',
    orderMan: '订单管理',
    refund: '退款',
    endOrder: '结束订单',
    payment: '支付',
    cancel: '取消',
    details: '详情',
    carSN: '车辆识别码',
    BMSTotal: 'BMS累计充电',
    // 1.3.2
    vendorName: '厂商',
    deviceName: '设备名称',
    deviceSn: '设备SN',
    devicePort: '端口号',
    totalFee: '订单金额',
    payFee: '支付金额',
    payStatusDesc: '支付状态',
    payTypeDesc: '支付方式',
    orderTime: '订单时间',
    rentTime: '租用时间',
    usingTime: '使用量',
    orderStatusDesc: '订单状态',
    orderDetail: '详情',
    refundFee: '退款金额',
    refundType: '退款方式',
    refundTime: '退款时间',
    refundReason: '退款原因',
    refundState: '退款状态',
    operator: '运营商',
    changer: '操作人',
    orderLogs: '订单动态',
    createTime: '创建时间',
    logTime: '记录时间',
    logContent: '日志详情',
    orderFee: '订单金额',
    initRefundSucc: '发起退款成功，请稍后刷新列表',
    getCanRefundFeeError: '获取订单可退款金额出错',
    tableTitle: '自定义列表数据',
    payStatus: {
      init: '待支付',
      payed: '已支付',
      payTimeout: '支付超时',
      payFail: '支付失败',
      refunded: '已退款',
      waitForPay: '等待支付',
      refundFail: '退款失败',
      noPayed: '未支付',
      cancel: '已取消'
    },
    serviceStatusTable: {
      notActive: '未激活',
      trial: '试用中',
      inUse: '使用中',
      willExpire: '将到期',
      expired: '已到期'
    },
    orderStatus: {
      init: '订单初始化',
      payed: '已支付',
      waitingPay: '待支付',
      using: '进行中',
      finished: '已完成',
      interrupted: '已中断',
      cancel: '已取消',
      waitForUse: '待使用',
      merchantFinish: '商户完结订单'
    },
    payType: {
      wxpay: '微信支付',
      alipay: '支付宝支付',
      unionPay: '银联支付'
    },
    //  4-未收到设备上报开启端口 5-设备启动失败  6-支付失败 7-无电流中断 8-使用中无电流  9-下发命令到设备失败
    orderSubStatus: {
      PortInterrupted: '短路保护',
      PortOverCurrent: '过流保护',
      PortOverVoltage: '过压保护',
      NotRecvPortOpened: '未收到设备上报开启端口',
      PortOpenFail: '设备启动失败',
      PayFail: '支付失败',
      NoCurrentInterrupted: '无电流中断',
      NoCurrentOnUse: '使用中无电流',
      DownCommandFail: '下发命令到设备失败',
      DeviceError: '设备故障',
      DeviceOffline: '设备离线',
      PortError: '端口故障',
      PortDisabled: '端口禁用',
      PayTimeout: '支付超时',
      UserStop: '用户中止订单'
    },
    placeHolder: {
      nickName: '用户昵称',
      optPlaceName: '运营场所',
      operator: '运营商',
      orderNo: '订单号',
      deviceSN: '设备SN',
      deviceName: '设备名称',
      refundType: '退款方式',
      refundReason: '退款原因',
      refundState: '退款状态',
      orderStatusDesc: '订单状态',
      channel: '应用渠道'
    },
    refundStatus: {
      manual: '手动退款',
      autoMatic: '自动退款'
    },
    channel: '应用渠道'
  },
  // 商用最终用户
  bizUser: {
    nickName: '昵称',
    userCode: '用户ID',
    avatar: '头像',
    address: '所在地',
    openid: 'openid',
    loginTime: '授权时间',
    createTime: '创建时间',
    addBlackList: '拉黑',
    userNickName: '用户昵称',
    userDetail: '用户详情',
    phoneNumber: '电话号码',
    lastLoginTime: '最近登录时间',
    authOrgName: '授权商家',
    reallyName: '用户姓名',
    unbind: '解除绑定',
    unbindTip: '解除绑定后用户将不可再使用此设备',
    unbindConfirm: '是否确认解除绑定？'
  },
  // 运维监控
  monitor: {
    orderManage: {
      orderStatus: '工单状态',
      orderName: '工单名称',
      orderNo: '工单编号',
      deviceContext: '关联设备',
      orderContext: '关联订单',
      orderType: '工单类型',
      orderSource: '工单来源',
      appointmentTime: '预约时间',
      operator: '处理人',
      orderDetail: '工单详情',
      orderLogs: '工单动态',
      alarmToWork: '告警转工单',
      sendOrders: '派单',
      finished: '办结',
      description: '详细描述',
      strictOrders: '强制结单',
      chargeBack: '退单',
      handler: '处理人',
      sendOpinions: '派单意见',
      phone: '手机号',
      address: '联系地址',
      createOrder: '创建工单',
      deviceErr: '设备异常',
      orderErr: '订单异常',
      typeOther: '其它',
      add: '用户登记',
      waring: '告警转换',
      question: '问题转换',
      todoDist: '待派单',
      todoHandle: '待处理',
      handed: '已处理',
      back: '已退单',
      finish: '已办结',
      finishForce: '强制结单',
      cancel: '已取消',
      unHanded: '告警中',
      fixed: '解除告警',
      toCancel: '取消',
      placeHolder: {
        orderType: '工单类型',
        deviceSn: '设备编码',
        orderNo: '请输入订单号',
        contactName: '请输入联系人',
        address: '请输入设备安装地址',
        phone: '自动填充联系人手机号',
        distDesc: '请输入意见',
        distHandlerName: '处理人',
        question: '请输入详细问题及诉求',
        orderName: '工单名称',
        orderStatus: '工单状态',
        orderNum: '工单编号',
        deviceContext: '关联设备',
        orderContext: '关联订单',
        source: '工单来源'

      },
      logHead: {
        add: '用户登记工单',
        alarm: '告警转工单',
        question: '问题转工单',
        dist: '派单',
        finish_force: '强制结单',
        back: '退单',
        finished: '办结',
        cancel: '取消'
      },
      backDesc: '退单意见',
      finishForceDesc: '强制结算意见',
      finishDesc: '办结意见',
      cancelDesc: '取消意见',
      desc: '工单描述',
      enter: '请输入',
      goToAlarm: '查看告警',
      goToQuection: '查看问题'
    },
    alarm: {
      alarmEvents: '告警事件',
      alarmStatus: '告警状态',
      productName: '产品名称',
      details: '推送详情',
      alarmTime: '告警时间',
      handleStatus: '处理状态',
      clearTime: '解除时间',
      see: '查看',
      handle: '处理',
      overLook: '忽略',
      toWorkOrder: '转工单',
      alarmInfo: '告警信息',
      alarmLogs: '告警动态',
      alarmParams: '告警输出参数',
      opition: '处理意见',
      tip: '温馨提示',
      opinion: '意见',
      placeHolder: {
        opinion: '意见',
        name: '告警事件',
        alarmStatus: '告警状态',
        handleStatus: '处理状态'
      },
      ignored: '已忽略',
      alarmTip: '确认忽略所选告警？',
      pushTip: '告警推送详情',
      pushWay: '推送渠道',
      pushStatus: '推送状态',
      pushObject: '推送对象',
      toBeCompleted: '该设备/订单当前有未完成工单',
      confirm: '请您确认操作',
      associated: '关联到已有工单',
      newOrder: '创建新工单',
      handleNull: '待处理',
      handled: '已处理',
      clearFlag: '解除故障',
      newFlag: '新增故障',
      finish: '已转工单',
      logsHead: {
        deviceAlarm: '设备上报告警事件',
        todo: '处理告警',
        overLook: '忽略',
        changeOrder: '转工单',
        finished: '工单完成解除告警',
        clean: '设备上报参数解除告警'
      },
      logs: {
        deviceAlarm: '设备上报告警事件',
        todo: '处理告警',
        overLook: '忽略告警',
        changeOrder: '告警转工单',
        finished: '工单完成解除告警',
        clean: '设备上报参数解除告警'
      },
      tips: '只能关联一条工单信息',
      success: '成功',
      erroe: '失败'
    },
    feedback: {
      feedBackType: '反馈类型',
      description: '问题反馈',
      feedBackTime: '反馈时间',
      img: '相关图片',
      toWorkOrder: '已转工单',
      report: '故障上报',
      questions: '处理问题',
      detail: '反馈详情',
      logs: '反馈动态',
      logsHead: {
        matter: '故障上报',
        feedback: '订单反馈',
        handled: '处理',
        changeOrder: '转工单',
        finished: '工单流程结束'
      },
      log: {
        matter: '故障上报',
        feedback: '订单反馈',
        handled: '处理反馈',
        changeOrder: '反馈转工单',
        finished: '工单完成'
      },
      place: {
        bizType: '反馈类型',
        handleStatus: '处理状态',
        remark: '问题反馈'
      },
      seeImg: '查看图片'
    },
    seeOrder: '查看工单',
    // 意见反馈
    suggestion: {
      userNickname: '用户名称',
      content: '反馈内容',
      place: {
        userNickname: '用户名称',
        content: '反馈内容'
      }
    }
  },
  // 产品管理
  product: {
    rent: {
      inactiveDeviceConfig: '设备不可用配置',
      tips: {
        inactiveDeviceConfig: '当设备命中以下规则时，设备不可用，用户扫描设备码时将提示该设备不可使用。'
      }
    },
    productEnableFailed: '产品启用失败',
    productEnableFailedTips: '产品启用失败，未完善业务配置，无法进行正常的订单流程。',
    goToConfiguration: '去配置',
    guideTip: '使用指引帮助您快速了解智能设备商用流程及配置'
  },
  // 营销管理
  marketing: {
    placeHolder: {
      activityName: '活动名称',
      activityStatus: '活动状态',
      createCompany: '创建企业',
      placeName: '请输入场所名称',
      useInstructions: '请输入使用说明',
      couponName: '优惠券名称',
      couponType: '优惠券类型',
      couponCompany: '发券企业',
      orderBackMechanismRadio0: '一经使用，不可退还',
      orderBackMechanismRadio1: '提前结束，优先消耗实付金额，若优惠券未被消耗则进行退还'
    },
    tip: {
      preferential: '即实际订单金额=（商品单价 * 折扣比例%）+（服务费单价 * 折扣比例%）',
      activityName: '请输入活动名称'
    },
    activity: {
      createActivity: '创建活动',
      editActivity: '编辑活动',
      activityName: '活动名称',
      activityTime: '活动时间',
      activityForm: '活动形式',
      activityStatus: '活动状态',
      createCompany: '创建企业',
      editPerson: '修改人',
      lastChangeTime: '最后修改时间',
      post: '活动封面',
      longImg: '长图',
      multiText: '多行文本',
      activitySet: '活动配置',
      activityTimes: '活动次数',
      discount: '折扣活动',
      unlimitedTimes: '不限次数',
      preferential: '活动优惠',
      operaterRadioLabel: '按运营商维度选择',
      cityRadioLabel: '按城市选择',
      activityPlace: '活动场所',
      activityDetail: '详情',
      activityDetailTitle: '活动详情',
      activityStop: '停止',
      activityEdit: '编辑',
      activityContent: '活动内容'
    },
    activityRecord: {
      userId: '用户ID',
      userName: '用户昵称',
      heldCompany: '举办企业',
      activityName: '参与活动名称',
      activityForm: '参与活动形式',
      activityLocation: '活动场所',
      order: '关联订单',
      participateTime: '参与时间'
    },
    coupons: {
      createCoupon: '创建优惠券',
      name: '优惠券名称',
      type: '优惠券类型',
      denomination: '优惠券面额',
      useCondition: '使用条件',
      status: '领券状态',
      total: '总数',
      publishTotal: '发放/领用数',
      hasUsed: '已使用数',
      leftNumber: '剩余数',
      createCompany: '创建企业',
      editPerson: '修改人',
      lastChangeTime: '最后修改时间',
      takedown: '下架',
      detail: '详情',
      detailTitle: '优惠券详情',
      issueRule: '发放规则',
      allowReceiveTime: '可领取时间',
      discountFee: '折扣费用',
      orderBackMechanism: '订单退还限制',
      useInstructions: '使用说明',
      allowPlace: '可用场所',
      couponsNumber: '优惠券数量',
      deductExpensLimit: '抵扣费用门槛',
      allowUsePlace: '可用场所'

    },
    couponsRecord: {
      userId: '用户ID',
      userName: '用户昵称',
      heldCompany: '发券企业',
      activityName: '参与活动名称',
      activityForm: '参与活动形式',
      activityLocation: '活动场所',
      order: '关联订单',
      participateTime: '参与时间',
      status: '优惠券状态',
      time: '领取/发放时间',
      useTime: '使用时间',
      participateAmount: '优惠金额',
      mandatoryWriteOff: '强制核销',
      batchWriteOff: '批量核销'
    }
  }
}
