import { menu } from '@/app/lang/zh'

export default {
  title: '自助商用平台',
  common: {
    createTime: '创建时间',
    updateTime: '更新时间',
    confirm: '确定',
    cancel: '取消',
    search: '搜索',
    reset: '重置',
    action: '操作',
    edit: '编辑',
    detail: '详情',
    close: '关闭',
    save: '保存',
    cite: '引用',
    cancelCite: '移除引用',
    success: '成功',
    show: '展示',
    hide: '隐藏',
    open: '开启',
    delete: '删除',
    publish: '发布',
    add: '新增',
    addTo: '添加',
    update: '修改',
    editSuccess: '编辑成功',
    deleteSuccess: '删除成功',
    deleteFail: '删除失败',
    deleteCancel: '已取消删除',
    addSuccess: '新增成功',
    addToSuccess: '添加成功',
    publishSuccess: '发布成功',
    modifySuccess: '修改成功',
    bindSuccess: '绑定成功',
    unbindSuccess: '解绑成功',
    unbindCancel: '已取消解绑',
    saveSuccess: '保存成功',
    updateSuccess: '更新成功',
    actionSuccess: '操作成功',
    uploadFail: '上传失败',
    tipUpload: '点击上传',
    tipUploadImg: '请上传".jpg"、".png"格式的图片',
    tipUploadXls: '提示：仅允许导入“xls”或“xlsx”格式文件！',
    tipUploadPdf: '只能上传PDF格式文件',
    tipUploadIco: '请上传".ico"格式的图片',
    sure: '确认要',
    sureDel: '是否确认删除?',
    ma: '吗？',
    de: '的',
    warn: '警告',
    import: '导入',
    export: '导出',
    importRecords: '导入记录',
    uploadFiles: '上传文件',
    dragFile: '将文件拖到此处，或',
    productName: '产品名称',
    prompt: '提示',
    see: '查看',
    seeDetails: '查看详情',
    // 1.3.2
    activate: '激活',
    renew: '设备续费',
    selected: '已选设备',
    renewBtn: '续费',
    buyTime: '购买时长（年）',
    renewal: '续费费用',
    resume: '复机费用',
    orderAmount: '订单金额',
    next: '下一步',
    // 1.3.2
    basicInfo: '基本信息',
    back: '返回',
    start: '开始',
    pause: '暂停',
    noData: '暂无数据',
    to: '至',
    minute: '分钟',
    hour: '小时',
    location: '位置',
    email: '邮件',
    sms: '短信',
    enter: '进入',
    no: '否',
    yes: '是',
    zh: '中文',
    en: '英文',
    logout: '确定退出系统吗？',
    cookiesInvalid: '登录状态已过期，您可以继续留在该页面，或者重新登录',
    sysHint: '系统提示',
    reLogin: '重新登录',
    networkError: '后端接口连接异常',
    timeOut: '系统接口请求超时',
    systemInterface: '系统接口',
    abnormal: '异常',
    copy: '复制',
    clipboardSuccess: '复制成功',
    clipboardError: '复制失败',
    day: ' 天',
    isConfirm: '确定要{0}“{1}”吗？',
    once: '执行一次',
    view: '详细',
    confirmButton: '确认'
  },
  navbar: {
    personal: '个人中心',
    company: '企业中心',
    logout: '退出登录'
  },
  m: {
    app: menu,
    quickSet: '快速配置',
    fullSet: '完善配置',
    dashboard: '数据总览',
    dataStatistics: '数据统计',
    incomeStatement: '收入报表',
    product: '产品管理',
    productList: '产品列表',
    productDetail: '产品详情',
    marketingManagement: '营销管理',
    activityManagement: '活动管理',
    couponsManagement: '优惠券管理',
    activityAddBtn: '创建/编辑活动按钮',
    stopActivity: '停止活动按钮',
    addActivity: '创建活动',
    addCoupons: '创建优惠券',
    takedownCoupons: '下架优惠券按钮',
    couponsAddBtn: '创建优惠券按钮',
    couponsVerification: '核销优惠券按钮',
    chargeDatav: '充电运营',
    businessGuidelines: '业务指引',
    device: '设备管理',
    agent: '代理商管理',
    end: '终端管理',
    company: '厂商管理',
    storageDuration: '上行数据存储周期',
    sys: '系统管理',
    monitor: '系统监控',
    device_list: '设备列表',
    group: '安装点管理',
    device_map: '设备地图',
    group_list: '安装点列表',
    person_list: '负责人列表',
    device_fence: '电子围栏',
    agent_list: '代理商列表',
    agent_select: '代理商查询',
    agent_add: '代理商新增',
    agent_update: '代理商修改',
    agent_delete: '代理商删除',
    agent_export: '代理商导出',
    device_share: '已分配设备',
    end_user: '终端用户',
    end_user_detail: '终端用户详情',
    end_feedback: '意见反馈',
    end_question: '常见问题',
    sys_user: '账户管理',
    sys_user_select: '用户查询',
    sys_user_add: '用户新增',
    sys_user_update: '用户修改',
    sys_user_delete: '用户删除',
    sys_user_export: '用户导出',
    sys_user_import: '用户导入',
    sys_reset_password: '重置密码',
    sys_role: '角色管理',
    sys_role_select: '角色查询',
    sys_role_add: '角色新增',
    sys_role_update: '角色修改',
    sys_role_delete: '角色删除',
    sys_role_export: '角色导出',
    syu_menu: '菜单管理',
    syu_menu_select: '菜单查询',
    syu_menu_add: '菜单新增',
    syu_menu_update: '菜单修改',
    syu_menu_delete: '菜单删除',
    syu_web_menu: 'Web菜单',
    syu_wx_app_menu: '小Q商家菜单',
    syu_mail_account: '邮件账户',
    sys_group: '分组管理',
    group_editor: '分组管理信息',
    group_add: '分组新增',
    group_edit: '分组编辑',
    group_del: '分组删除',
    sys_dict: '字典管理',
    sys_dict_select: '字典查询',
    sys_dict_add: '字典新增',
    sys_dict_update: '字典修改',
    sys_dict_delete: '字典删除',
    sys_dict_export: '字典导出',
    sys_config: '系统配置',
    sys_config_select: '参数查询',
    sys_config_add: '参数新增',
    sys_config_update: '参数修改',
    sys_config_delete: '参数删除',
    sys_config_export: '参数导出',
    sys_notice: '通知公告',
    sys_notice_select: '公告查询',
    sys_notice_add: '公告新增',
    sys_notice_update: '公告修改',
    sys_notice_delete: '公告删除',
    sys_log: '日志管理',
    sys_log_opera: '操作日志',
    sys_log_opera_select: '操作查询',
    sys_log_opera_delete: '操作删除',
    sys_log_opera_export: '日志导出',
    sys_log_login: '登录日志',
    sys_login_select: '登录查询',
    sys_login_delete: '登录删除',
    sys_letter_state: '站内信管理状态',
    job_log: '调度日志',
    monitor_online: '在线用户',
    m_online_select: '在线查询',
    m_batch_logout: '批量强退',
    m_force_logout: '单条强退',
    monitor_time: '定时任务',
    m_task_select: '任务查询',
    m_task_add: '任务新增',
    m_task_update: '任务修改',
    m_task_delete: '任务删除',
    m_status_update: '状态修改',
    m_task_export: '任务导出',
    user_profile: '个人中心',
    company_config: '企业配置',
    company_role: '权限配置',
    dict_data: '字典数据',
    function: '功能定义',
    statsConfig: '统计配置',
    flowConfig: '流程配置',
    propConfig: '字段配置',
    qrConfig: '二维码配置',
    notice_email: '邮件通知',
    notice_sms: '短信通知',
    notice_voice: '语音通知',
    notice_app: 'App通知',
    group_manage_device: '设备管理',
    device_detail: '设备详情',
    fence_add: '自定义围栏',
    fence_bind: '绑定设备',
    scooter_opera: '运营管理',
    scooter_shop: '授权门店',
    scooter_repair: '在线报修',
    scooter_lose: '失窃上报',
    lose_manual: '说明书',
    store_info: '门店信息',
    sim: 'SIM卡管理',
    simCharge: '充值',
    device_info: '基本信息',
    device_attr: '设备属性',
    device_location: '位置信息',
    device_mileage: '里程报表',
    device_fault: '故障日志',
    device_alarm: '告警日志',
    device_set: '下发指令',
    device_sim: 'SIM卡',
    device_run: '运行记录',
    device_order: '订单记录',
    device_port: '插座信息',
    device_electricity: '用电信息',
    device_userInfo: '用户信息',
    device_info_edit: '基本信息编辑', // 1.4.3
    device_distribute: '分润规则',
    appVersion: 'APP版本管理',
    sys_notify: '通知管理',
    sys_notify_sms: '短信通知',
    sys_notify_email: '邮件通知',
    sys_notify_voice: '语音通知',
    sys_notify_app: 'APP推送模板',
    sys_notify_mini: '小程序通知',
    sys_category: '品类管理',
    sys_letter: '站内信',
    sys_letter_admin: '站内信管理',
    sys_letter_edit: '站内信编辑',
    m_letter_add: '创建站内信',
    m_letter_edit: '编辑站内信',
    m_letter_del: '删除站内信',
    m_agent_select: '代理商查询',
    m_agent_add: '代理商新增',
    m_agent_update: '代理商修改',
    m_agent_delete: '代理商删除',
    m_agent_export: '代理商导出',
    m_user_select: '用户查询',
    m_user_add: '用户新增',
    m_user_update: '用户修改',
    m_user_delete: '用户删除',
    m_user_export: '用户导出',
    m_user_import: '用户导入',
    m_reset_password: '重置密码',
    m_role_select: '角色查询',
    m_role_add: '角色新增',
    m_role_update: '角色修改',
    m_role_delete: '角色删除',
    m_role_export: '角色导出',
    m_menu_select: '菜单查询',
    m_menu_add: '菜单新增',
    m_menu_update: '菜单修改',
    m_menu_delete: '菜单删除',
    m_dict_select: '字典查询',
    m_dict_add: '字典新增',
    m_dict_update: '字典修改',
    m_dict_delete: '字典删除',
    m_dict_export: '字典导出',
    m_sys_config_select: '参数查询',
    m_sys_config_add: '参数新增',
    m_sys_config_update: '参数修改',
    m_sys_config_delete: '参数删除',
    m_sys_config_export: '参数导出',
    m_notice_select: '公告查询',
    m_notice_add: '公告新增',
    m_notice_update: '公告修改',
    m_notice_delete: '公告删除',
    m_add_group: '新增安装点',
    m_group_batch_set: '批量控制',
    m_group_manage_dev: '管理设备',
    m_group_set_person: '设备负责人',
    m_group_edit: '编辑',
    m_group_delete: '删除',
    m_person_add: '新增',
    m_person_edit: '编辑',
    m_person_delete: '删除',
    sys_upload: '文件管理',
    sys_dashboard: '看板管理',
    sys_dashboard_data: '数据源配置',
    sys_dashboard_card: '看板配置',
    appmanage: 'APP版本管理',
    m_profit_data: '列表数据',
    m_rule_add: '规则新增',
    m_rule_edit: '规则编辑',
    m_rule_remove: '规则删除',
    m_account_add: '账户新增',
    m_account_edit: '账户修改',
    m_account_remove: '账户删除',
    m_account_config: '账户配置',
    m_record_profit: '分润',
    m_serviceRegulations: '服务规则',
    m_dvActivation: '设备激活',
    m_deviceRates: '计费规则',
    m_renewBtn: '续费',
    m_renewDetail: '续费详情',
    m_allocationDevice: '分配设备',
    m_business: '商户管理',
    m_editDevice: '基本信息编辑',
    m_delDevice: '删除设备',
    dv_ruleDetail: '设备服务规则详情',
    business: {
      add: '新增商户',
      edit: '修改商户',
      del: '删除商户',
      allocate: '分配设备',
      unbind: '回收设备'
      // vendor_option: '厂商选项',
      // operator_option: '运营商选项',
    },
    device_sn: '生成SN',
    device_pay: '初始收款码',
    ignore: '忽略',
    handle: '处理',
    toWO: '转工单',
    forceEndWO: '强制结单',
    endWO: '办结',
    distWo: '派单',
    cancelWo: '取消',
    backWO: '退单',
    reProfit: '重新分润',
    cancelProfit: '取消分润',
    listView: '查看列表',
    wxapp: {
      m_index: '首页',
      m_msg: '运维',
      m_statistics: '统计',
      m_mine: '我的',
      m_deviceList: '设备列表',
      m_devDetail: '设备详情',
      m_portInfo: '端口信息',
      enablePort: '启用端口',
      disablePort: '禁用端口',
      m_orderRecord: '订单记录',
      orderDetail: '订单详情',
      refundBtn: '退款',
      m_deviceStatus: '设备状态',
      m_deviceAlarm: '故障告警',
      m_workOrder: '工单',
      m_alarm: '告警',
      m_question: '问题',
      m_manage: '管理',
      device_manage: '设备管理',
      place_manage: '运营场所',
      optPlaceAdd: '运营场所添加',
      placeDetail: '运营场所详情',
      optPlaceSet: '运营场所配置',
      delOptPlace: '运营场所删除',
      setOptPlace: '运营场所设置',
      p_partner_profit: '合作方分润',
      distribution: '分配',
      recycle: '回收',
      mer_manage: '商户管理',
      set_rule: '分润规则设置',
      mer_save: '保存商户配置',
      mer_del: '删除商户',
      addWOBtn: '登记工单',
      forceEndWO: '强制结单',
      endWO: '办结',
      deliveryWO: '派单',
      cancelWO: '取消',
      alarmToWO: '转工单',
      ignoreAlarm: '忽略',
      handleAlarm: '处理',
      questionToWO: '转工单',
      handleQuestion: '处理',
      m_pay_set: '支付配置',
      minePayApp: '应用配置',
      minePayMer: '商户配置',
      minePaySer: '服务商配置',
      minePayList: '配置列表',
      m_user_info: '个人信息',
      backWO: '退单',
      share: '设备分享',
      deviceList: '设备列表',
      deviceEdit: '设备编辑',
      deviceConfig: '设备配置',
      control: '设备控制',
      deviceNet: '设备配网',
      bindQrCode: '绑定收款码',
      deviceAllot: '设备分配',
      allocatRecod: '分配记录',
      deviceRecycle: '设备回收',
      recoveryRecord: '回收记录',
      bluetooth: '蓝牙配网',
      scan: '扫码添加',
      devChange: '设备更换',
      wOrderDetail: '查看工单详情',
      alarmDetail: '查看告警详情',
      aboutUs: '关于我们',
      version: '当前软件版本',
      service: '移远服务协议',
      orderManage: '订单管理',
      finishOrder: '结束订单',
      addMerchant: '添加商户',
      setMerchant: '商户设置',
      delMerchant: '商户删除',
      merchantDetail: '商户详情',
      merchantSave: '保存配置',
      merchantSet: '商户配置',
      orderList: '订单列表',
      viewDetail: '查看详情',
      viewList: '查看列表',
      opDevice: '设备分配操作',
      deviceRe: '设备回收操作',
      privacy: '移远隐私协议',
      opt_manage: '运营商',
      operatorAdd: '运营商添加',
      operatorSet: '运营商配置',
      operatorDetail: '运营商详情',
      delOperator: '运营商删除',
      acct_manage: '账户管理',
      addAccount: '添加账户',
      delAccount: '删除账户',
      editAccount: '编辑账户',
      setOperator: '运营商设置',
      o_step_profit: '逐级分润',
      channelEntry: '渠道进件',
      edit: '编辑',
      ceRecord: '渠道进件记录'
    }
  },
  valid: {
    userName: '用户名不能为空',
    passWord: '密码不能为空',
    code: '验证码不能为空',
    name: '名称不能为空',
    filedName: '字段名称不能为空',
    key: '标识不能为空',
    filedCode: '请选择业务字段',
    templateName: '模板名称不能为空',
    templateContent: '模板内容不能为空',
    checkPeople: '请勾选接收人员',
    leastOne: '至少填写一个收件人',
    removeAlarmParmValue: '请选择解除告警参数值！',
    sendNotice: '请选择是否需要发送通知！',
    fence: {
      length: '电子围栏名称长度不能超过30个字符',
      repeat: '您输入的电子围栏名称已存在',
      format: '请输入正确的电子围栏名称'
    },
    agent: {
      required: '请输入代理商名称',
      repeat: '代理商名称重复！'
    },
    mobile: {
      required: '请输入联系电话',
      format: '请输入正确的联系电话',
      repeat: '联系电话重复！'
    },
    sysAccount: {
      required: '请输入系统账号',
      format: '请输入至少6位字母、数字或组合',
      format2: '请输入6-20位字母或数字',
      repeat: '系统账号重复！'
    },
    email: {
      required: '请输入邮箱',
      format: '请输入正确的邮箱地址',
      repeat: '邮箱重复！'
    },
    uploadFiles: '请上传文件',
    nickName: {
      required: '请输入用户昵称',
      format: '请输入中文、字母或数字',
      repeat: '用户昵称重复！'
    },
    phoneNumber: {
      required: '手机号码不能为空',
      format: '请输入正确的手机号码'
    },
    password: {
      required: '请输入密码',
      format: '密码长度6到20位，必须包含大小写字母和数字'
    },
    equalToPassword: '两次输入的密码不一致',
    oldPassword: '旧密码不能为空',
    confirmPassword: '确认密码不能为空',
    uploadImg: '文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件',
    access: {
      AccessKeyRequired: 'AccessKey不能为空',
      AccessSecretRequired: 'AccessSecret不能为空'
    },
    domain: {
      required: '请配置域名',
      repeat: '域名重复！'
    },
    loginTemplate: '请选择登录模板',
    loginPageLogo: '请上传登录页面logo',
    sysInnerPageLogo: '请上传系统内部页面logo',
    loginBg: '请上传登录页背景图',
    numAlp: '请输入字母或数字',
    systemName: '请输入网站名称',
    url: '请输入正确的URL地址'
  },
  place: {
    enter: '请输入',
    select: '请选择',
    selectHandleStatus: '请选择处理状态',
    selectDate: '请选择日期时间',
    selectCompany: '请选择企业',
    selectProduct: '请选择产品',
    selectLocationType: '请选择定位方式',
    selectStayMark: '请选择停留标记',
    selectTriggerState: '请选择触发状态',
    selectFenceType: '请选择围栏类型',
    selectEffectiveType: '请选择围栏生效时间类型',
    selectOperationState: '运营状态',
    selectTenant: '请选择厂商',
    selectAgent: '请选择代理商',
    selectCountry: '请选择国家或地区',
    selectState: '请选择州',
    selectProvince: '请选择省',
    selectCity: '请选择市',
    selectArea: '请选择区县',
    enterDeviceKey: '请输入DeviceKey',
    enterFenceName: '请输入电子围栏名称',
    enterPhone: '请输入手机号码',
    enterAgentName: '代理商名称',
    enterParentName: '直属上级',
    enterSysAccount: '请输入登录账号',
    enterLinkMain: '请输入联系人',
    enterAdd: '请输入详细地址',
    enterEmail: '请输入邮箱',
    enterUserId: '请输入用户ID',
    enterQuestion: '请输入问题描述',
    enterAnswer: '请输入解决方法',
    enterOldPassword: '请输入旧密码',
    enterNewPassword: '请输入新密码',
    confirmPassword: '请确认密码',
    placeContent: '请输入内容',
    service: '服务协议',
    placeZhService: '请输入协议名称（中文）',
    placeZhServiceUrl: '请输入有效url链接（中文）',
    placeEnService: '请输入协议名称（英文）',
    placeEnServiceUrl: '请输入有效url链接（英文）',
    privacy: '隐私政策',
    placeZhPrivacy: '请输入隐私政策名称（中文）',
    placeZhPrivacyUrl: '请输入有效url链接（中文）',
    placeEnPrivacy: '请输入隐私政策名称（英文）',
    placeEnPrivacyUrl: '请输入有效url链接（英文）'
  },
  onlineStatus: {
    title: '在线状态',
    offline: '离线',
    online: '在线'
  },
  operatingStatus: {
    title: '运行状态',
    inactivated: '未激活',
    normal: '正常',
    warn: '告警',
    error: '故障',
    errorwarn: '故障+告警'
  },
  handleStatus: {
    title: '处理状态',
    text1: '已处理',
    text2: '未处理'
  },
  login: {
    title: '登录',
    place: {
      user: '请输入账号',
      password: '请输入密码',
      code: '请输入验证码'
    },
    codeLogin: '验证码登录',
    findPassword: '忘记密码',
    confirmLogin: '确认登录',
    logging: '登 录 中...',
    oauthLogin: '授权登录',
    oauthTip: '或使用以下方式登录'
  },
  findPassword: {
    title: '找回密码',
    accountTip: '请输入登录账号',
    accountPlaceholder: '用户名',
    enterAccount: '请输入账号',
    noAccount: '用户名不存在',
    backLogin: '返回登录',
    next: '下一步',
    back: '上一步',
    sendTip: '将往以下邮箱发送重置密码链接，请确认是否您使用的邮箱',
    sendEmail: '发送邮件',
    sendSuccess: '邮件已成功发送到您的邮箱！',
    sendSuccessTip: '如果您未收到邮件，请注意查看垃圾邮件或者重试',
    close: '关闭',
    resend: '重新发送',
    mailSource: '请注意来自“biz_service@quectel.com”的邮件',
    resetPassword: '重置密码',
    linkFailure: '抱歉，重置链接已失效！',
    newPasswordLabel: '新密码（6-20位大小写字母，数字组合）',
    newPasswordPlaceholder: '请输入您的新密码',
    confirmPasswrodLabel: '确认密码',
    confirmPasswrodPlaceholder: '请确认您的新密码',
    confirmUpdate: '确认修改',
    updateSuccess: '修改成功',
    goLogin: '去登录',
    passwrodTip: '请输入新密码',
    IllegalPassword: '非法的密码',
    inconformityPassword: '两次输入密码不一致!',
    passwrodAgenTip: '请再次输入密码',
    notLogin: '未登录',
    code: '验证码',
    sendCode: '发送验证码',
    place: {
      code: '请输入验证码'
    }

  },
  dashboard: {
    title: '数据面板',
    online: {
      title1: '设备',
      title2: '在线率',
      onlineDevice: '在线设备',
      offlineDevice: '离线设备',
      tipTitle: '设备状态'
    },
    area: {
      title: '设备地域分布',
      cityTitle: '城市',
      countryTitle: '国家',
      numTitle: '设备数',
      percentTitle: '占比'
    },
    active: {
      title: '设备活跃趋势',
      tip: '活跃数'
    },
    alert: {
      title: '告警统计',
      tip: '告警数'
    },
    malfunction: {
      title: '故障统计',
      tip: '故障数'
    },
    deviceTotal: '设备总数',
    deviceActive: '设备活跃率',
    userTotal: '用户总数',
    userActive: '用户活跃度',
    allProduct: '全部产品',
    today: '今天',
    weekDate: '近一周',
    monthDate: '近一月',
    startDateRange: '开始日期',
    endDateRange: '结束日期',
    createStartDateRange: '创建开始日期',
    createEndDateRange: '创建结束日期',
    dateSelect: {
      today: '日',
      week: '周',
      month: '月'
    },
    countType: {
      day: '日统计',
      month: '月统计',
      year: '年统计'
    },
    cancelEdit: '取消编辑',
    saveEdit: '保存编辑',
    kanbanEdit: '看板编辑',
    kanbanReset: '重置看板',
    kanbanConfig: '首页看板配置',
    resetSuccess: '重置成功',
    total: '总计',
    resetTips: '重置看板将清除您当前的看板组件和布局配置，恢复到系统默认配置，确认是否继续？'
  },
  product: {
    search: {
      connProtocol: '全部连接协议',
      dataFormat: '全部数据格式',
      productName: '请输入产品名称'
    },
    tableTitle: {
      productName: '产品名称',
      vendorName: '所属厂商',
      connProtocol: '连接协议',
      dataFormat: '数据格式',
      status: '发布状态'
    },
    status: {
      published: '产品已发布',
      unPublished: '产品未发布'
    },
    actionBtn: {
      device: '设备列表',
      function: '功能定义',
      category: '品类配置'
    },
    queue: '消息队列',
    booking: '消息订阅',
    enableProduct: '启用产品',
    queuePlace: '请输入消息队列',
    queueDisable: '请先禁用队列',
    queueStatus: {
      title: '队列监听',
      stop: '未启用',
      start: '运行中',
      empty: '空状态',
      enable: '启用',
      disable: '禁用'
    },
    startSuccess: '启动成功',
    closeSuccess: '关闭成功',
    function: {
      tabAttr: '属性',
      tabEvent: '事件',
      updateData: '更新数据',
      noUpdate: '暂无更新',
      month: '月前',
      week: '周前',
      day: '天前',
      hour: '小时前',
      min: '分钟前',
      just: '刚刚',
      labelManage: '标签管理',
      addLabel: '添加标签',
      editLabel: '编辑标签',
      batchEdit: '批量编辑',
      batchImport: '导入属性配置',
      label: {
        labelName: '标签名称',
        setLabelName: '请输入标签名称',
        labelKey: '标签标识',
        setLabelKey: '请输入标签唯一标识'
      },
      attr: {
        tableName: '功能名称',
        tableSubType: '读写类型',
        tableCode: '标识符',
        tableDataType: '数据类型',
        tableIcon: '图标',
        tableUnit: '单位',
        tableDisplay: '在设备详情页面展示该功能属性',
        tableSort: '排序',
        subTypeR: '只读',
        subTypeW: '只写',
        subTypeRW: '读写',
        placeName: '请输入功能名称',
        placeIcon: '点击选择图标',
        placeUnit: '请输入单位',
        placeParm: '请输入参数名称',
        sensorIcon: '传感器图标',
        enableReport: '是否开启报表',
        parmName: '参数名称',
        allowControl: '允许控制该功能属性',
        attrSet: '属性设置'
      },
      event: {
        tableName: '事件名称',
        batch: '批量设置',
        batchWarn: '请选择一项进行操作!',
        tableCode: '标识符',
        tableSubType: '事件类型',
        tableNotice: '通知管理',
        warn: '告警',
        info: '信息',
        error: '故障',
        unknown: '未知',
        config: '配置',
        parmName: '参数名称',
        parmType: '参数类型',
        parmValue: '参数值',
        placeName: '请输入事件名称',
        placeParmName: '请选择参数名称',
        placeParmType: '请输入参数类型',
        dismiss: '解除',
        notice: '通知',
        send: '发送',
        noSend: '不发送',
        confirmEmail: '的邮件通知吗?',
        confirmSms: '的短信通知吗?',
        noConfig: '不可配置！',
        configSuccess: '配置成功',
        callerSet: '接警人设置',
        msgVoicer: '短信/语音接警人',
        phoneError: '第{0}个手机号不符合规范',
        emailError: '第{0}个邮箱不符合规范',
        emailr: '邮件接警人：',
        batchLimitWarn: '您只能添加3条',
        msgVoicerTip: '请输入接警人手机号',
        emailrTip: '请输入接警人邮箱地址',
        atleast: '至少设置一个邮件接警人',
        callerNotice:
          '若设备产生的告警/故障消息，需要通过短信、语音、邮箱方式推送，请设置接受人信息并开启对应事件的推送开关'
      }
    },
    fileImgTip: '只能上传jpg/png格式文件，建议上传尺寸：800*800像素',
    fileImgSizeTip: '上传图片大小不能不超过500kb',
    place: {
      netWay: '联网方式',
      releaseStatus: '发布状态',
      productName: 'PK/产品名称',
      detailPageName: '详情页'
    },
    deviceNum: '设备数量',
    accessType: '设备类型',
    netWay: '联网方式',
    detailPageName: '详情页',
    category: '产品品类',
    PK: '产品PK',
    qrConfig: {
      deviceSn: '设备二维码配置',
      SN: 'SN二维码',
      IMEI: '模组(IMEI)二维码'
    },
    propConfig: {
      addField: '新增字段',
      editField: '编辑字段',
      filedName: '字段名称',
      filedCode: '字段代码',
      type: '字段类型',
      required: '是否必填',
      placeFiledName: '请输入字段名称',
      placeFiledDefault: '请输入字段默认值',
      desc: '配置字段在设备导入时使用，用于记录设备的特殊属性或业务功能，例如出厂日期、设备倍率、设备独立标识等',
      delConfirm: '是否确认删除字段名称为“{0}”的数据项？',
      default: '默认值',
      list: '列表展示',
      search: '搜索条件',
      confirm2: '的数据项?'
    }
  },
  notice: {
    email: '邮件通知',
    sms: '短信通知',
    titleEmail: '邮件模板编辑',
    titleSms: '短信接收人编辑',
    titleVoice: '语音模板编辑',
    titleApp: 'App模板编辑',
    emailTemplate: '邮件模板',
    templateMgt: '模板管理',
    placeContent: '请输入内容',
    receiver: '接收人员设置',
    endUserPush: '设备绑定用户',
    orgPush: '设备所属组织',
    fixedPush: '固定邮箱',
    fixedRecipient: '固定收件人',
    recipient: '收件人',
    addTemplate: '新建模板',
    tableSelect: '选择',
    tableName: '名称',
    tableContent: '内容',
    templateName: '模板名称',
    placeTemplateName: '请输入模板名称',
    placeTemplateContent: '请输入模板内容',
    templateContent: '模板内容',
    insert: '插入',
    insertData: '插入数据',
    warnContent: '告警内容',
    sendDate: '发送时间',
    action: '动作',
    editEmailTemplate: '修改邮件模板',
    confirm1: '是否确认删除模板名称为',
    confirm2: '的数据项?',
    addEmailTemplate: '新增邮件模板',
    valid: {
      templateId: '请选择邮件模板',
      template: '请选择模板',
      templateType: '请选择模板类型',
      templateCode: '请输入模板代码',
      language: '请选择语言',
      params: '请选择参数',
      sysParams: '请选择系统参数',
      templateTitle: '请输入模板标题',
      tenantName: '请选择使用企业'
    },
    voice: '语音通知',
    app: 'App通知',
    language: '语言',
    tenantName: '使用企业',
    setDefault: '设为默认',
    sign: {
      title: '签名',
      add: '新增签名',
      name: '签名名称',
      default: '默认签名',
      edit: '编辑签名'
    },
    temp: {
      title: '模板',
      add: '新增模板',
      code: '模板代码',
      typeName: '模板类型',
      type: {
        item1: '故障',
        item2: '解除故障',
        item3: '告警',
        item4: '解除告警',
        item5: '地理围栏',
        item6: '订单提醒',
        item7: '修改密码验证码'
      },
      default: '默认模板',
      param: '模板参数',
      details: '模板详情',
      edit: '编辑模板',
      tips: '该模板内容无需配置参数',
      pageUrl: '跳转地址'
    },
    rules: {
      signName: '请输入签名名称'
    },
    sure: {
      sign: '确定把该签名设置为<span style="color:#456ff7">默认签名</span>吗？',
      temp: '确定把该模板设置为<span style="color:#456ff7">默认模板</span>吗？'
    },
    setSucc: '设置成功',
    product: '使用产品',
    paramMap: '参数映射',
    details: '详情',
    next: '下一步',
    prev: '上一步',
    sysParams: '系统参数',
    commonParams: '通用参数映射',
    tslParams: '物模型参数映射',
    triggerFault: '触发故障',
    triggerAlarm: '触发告警',
    dismissFault: '解除故障',
    dismissAlarm: '解除告警',
    triggerFence: '触发围栏'
  },
  device: {
    activated: '已激活设备',
    inactivated: '未激活设备',
    selectProduct: '请选择产品',
    selectCompany: '请选择企业',
    placeDeviceKey: 'DeviceKey',
    validDeviceKey: '请输入4~32位字母或数字',
    checkFile: '请导入“xls”或“xlsx”格式文件！',
    downDeviceImpTemplate: '下载设备导入模板',
    deviceImpTemplate: '设备导入模板',
    importRecords: '设备导入记录',
    importTotal: '导入总数',
    importSuccessNum: '导入成功数',
    importFailNum: '导入失败数',
    importStatus: '导入状态',
    importTime: '导入时间',
    importDevice: '导入设备',
    importSubmitted: '导入已提交',
    importResults: '导入结果',
    importDeviceDetails: '设备导入明细',
    processFinish: '处理完毕',
    processing: '处理中',
    downDetails: '下载明细',
    confirmDelDevice: '确定删除设备?',
    deviceName: '名称',
    deviceSn: '设备SN',
    exportPay: '导出初始应用码',
    payCode: '导出应用码',
    orgName: '归属企业',
    lastOnlineTime: '最后上线时间',
    placeOrgName: '归属企业',
    // 1.3.2
    serviceStatus: '服务状态',
    serviceMode: '服务模式',
    effective: '有效期',
    // 1.3.2
    listField: '列表显示字段',
    customField: '自定义字段',
    listFieldFilter: '列表项展示筛选',
    deviceDtl: '设备详情',
    locationInfo: '位置信息',
    basicInfo: {
      deviceKey: 'DeviceKey',
      name: '设备名称',
      sn: '设备SN',
      modelSpec: '模组型号',
      activationTime: '激活时间',
      editInfo: '编辑信息',
      valid: {
        deviceName: '请输入设备名称',
        deviceSn: '请输入设备SN'
      }
    },
    deviceAttr: '设备属性',
    unfold: '展开',
    putAway: '收起',
    baseInfo: {
      title: '基本信息'
    },
    portInfo: {
      title: '插座信息'
    },
    orderInfo: {
      title: '订单记录'
    },
    location: {
      title: '位置信息',
      deviceNo: '设备号',
      state: {
        title: '状态',
        offline: '设备离线',
        online: '设备在线'
      },
      lal: '经纬度',
      locate: '定位地点',
      view: '查看地址',
      time: '定位时间',
      targeting: '定位方式',
      signal: '信号强度',
      satellite: '当前卫星数'
    },
    mileage: {
      title: '里程报表',
      placeMonth: '选择月',
      date: '日期',
      milage: '里程数',
      day: '日'
    },
    alarm: {
      title: '故障告警'
    },
    setInstruction: {
      title: '下发指令',
      attribute: '属性控制',
      sendControl: '下发控制',
      curValue: '当前值',
      setValue: '设定值',
      msg1: '下发成功',
      msg2: '请选择属性',
      msg3: '请给属性赋值',
      msg4: '请填写设定值'
    },
    sim: {
      title: 'SIM卡',
      supplier: '运营商',
      setMeal: '套餐',
      networkType: '网络类型',
      active: '激活状态',
      activateTime: '激活日期',
      expiryDate: '计费结束日期',
      status: '状态',
      flow: '当月已用流量',
      residueFlow: '剩余流量',
      cardType: '卡类型',
      setmealmonths: '套餐周期（单位：月）',
      cardOrderNo: '订单编号',
      cardOrderType: {
        title: '订单类型',
        text1: '套餐续费',
        text2: '流量充值'
      },
      cardOrderStatus: {
        title: '订单状态',
        text1: '充值中',
        text2: '充值成功',
        text3: '充值失败',
        text4: '退款中',
        text5: '已退款',
        text6: '退款失败'
      },
      paymentType: {
        title: '充值渠道',
        text1: '微信',
        text2: '支付宝',
        text3: '余额充值'
      },
      cardProductName: '产品名称',
      cardOperatorName: '运营商名称',
      createTime: '下单时间',
      completeTime: '完成时间',
      cardFlow: '流量包大小',
      cardSetmealMonths: '套餐周期',
      cardRenewalCycle: '实际续费周期',
      cardProductPrice: '产品单价',
      cardRenewalAmount: '订单金额',
      yuan: '元',
      retry: '重试',
      refund: '退款',
      placeOrderNo: '订单编号',
      placeIccid: 'ICCID',
      placeOrderStatus: '订单状态',
      deviceKey: '关联DeviceKey'
    },
    map: {
      all: '全部',
      collectStatus: {
        title: '收藏状态',
        text1: '收藏',
        text2: '取消收藏',
        text3: '未收藏'
      },
      track: '轨迹回放',
      noLocation: '暂无位置信息',
      msg1: '已收藏成功',
      msg2: '已取消收藏'
    },
    trackPlay: {
      distance: '总里程',
      speed: '速度',
      tips: '温馨提示: 最多可查询连续3天的记录',
      stayMark: '停留标记',
      timeSelect: '时间选择',
      showFence: '显示围栏',
      trackLine: '轨迹总览',
      trackDetail: '轨迹明细',
      soc: '剩余电量',
      hdop: 'GPS信号',
      noFence: '暂无围栏信息',
      timeTips: '请选择3天内的连续时间',
      drivingTips: '该时间段无行驶记录',
      getAdd: '点击获取地址'
    },
    fence: {
      fenceName: '电子围栏名称',
      triggerConditions: '触发状态',
      fenceType: '围栏类型',
      effectiveType: '生效时间',
      effectiveType0: '长期有效',
      effectiveType1: '时间范围',
      notice: '通知渠道',
      isEnabled: '是否启用',
      // 1.3.2
      serviceRule: '服务规则',
      serviceRuleDetail: '设备服务规则',
      eqService: '设备服务模式',
      free: '免费使用',
      pay: '付费使用',
      setUp: '设置',
      probation: '试用期',
      nothingProbation: '无试用期',
      renew: '续费单价',
      recovery: '复机单价',
      noRecovery: '无复机单价',
      exceed: '到期超过',
      remind: '到期提醒',
      renewNotice: '续费通知',
      stationNotice: '站内通知',
      emailNotice: '邮件通知',
      msgNotice: '短信通知',
      addSuccess: '保存成功',
      // 1.3.2
      viewDevice: '查看设备',
      sure: '是否确认删除该电子围栏?',
      emailTips: '请先配置邮件通知！',
      smsTips: '请先配置短信通知！',
      voiceTips: '请先配置语音通知！',
      pushTips: '请先配置App通知！',
      bindDevice: '绑定设备',
      unbindDevice: '解绑设备',
      batchAdd: '批量添加',
      deviceTips: '请先选择设备',
      trigger: {
        item1: '出界',
        item2: '入界',
        item3: '双向'
      },
      startTime: '开始时间',
      endTime: '结束时间',
      drawTips1: '鼠标单击开始绘制，双击结束',
      drawTips2: '鼠标拖动开始绘制，放开结束',
      polygon: '多边形',
      round: '圆形',
      empty: '清空',
      hefei: '合肥',
      drawTips: '请先绘制电子围栏！',
      openTimeTips: '请先启用一个时间范围！',
      effectTimeTips: '请先选择生效的时间范围！',
      clearFence: '清除围栏',
      saveFence: '保存围栏'
    },
    sn: '生成SN',
    generateSum: '生成数量',
    selectApplication: '选择应用',
    snValid: '生成数量不能为空',
    snMsgSuccess: '生成成功！',
    snList: 'SN列表',
    add: '添加设备',
    singleAdd: '单个添加',
    batchImport: '批量导入',
    run: {
      title: '运行记录',
      more: '更多',
      newChart: '新建图表',
      heading: '标题',
      dataType: '统计数据类型',
      chartType: '图表类型',
      yunit: 'Y轴单位',
      dataSource: '数据源',
      countType: '统计方式',
      place: {
        title: '请输入标题',
        dataType: '请选择统计数据类型',
        chartType: '请选择图表类型',
        dataSource: '请选择数据源',
        countType: '请选择统计方式'
      },
      singleChoice: '单选',
      multipleChoice: '多选',
      dataTypeOptions: {
        item1: '布尔类型',
        item2: '数值类型',
        item3: '枚举类型'
      },
      chartTypeOptions: {
        item1: '柱状图',
        item2: '折线图'
      },
      countTypeOptions: {
        item1: '最大值',
        item2: '最小值',
        item3: '平均值',
        item4: '非聚合',
        item5: '总数'
      },
      editChart: '编辑图表'
    },
    electricity: {
      title: '用电信息',
      recordTime: '抄表时间',
      countTime: '统计时间',
      usedTotal: '使用量(KW·h)',
      cost: '费用'
    },
    userInfo: {
      title: '用户信息'
    }
  },
  agent: {
    recovery: {
      record: '回收记录',
      title: '回收设备',
      btnName: '回收',
      tsCreateTime: '回收时间'
    },
    allocationRecord: '分配记录',
    agentName: '代理商名称',
    parentName: '直属上级',
    attribution: '所在地',
    opStatus: {
      title: '运营状态',
      item1: '正常',
      item2: '停运'
    },
    linkman: '联系人',
    linkphone: '手机号码',
    allocationDevice: '分配设备',
    addAgent: '添加代理商',
    sysAccount: '系统账号',
    loginAccount: '登录账号',
    sysAccountTips: '请为你的代理商创建一个账号名',
    assignProduct: '分配产品',
    address: '详细地址',
    email: '邮箱',
    emailTips: '该邮箱将用于接收初始密码与重置密码',
    uploadTips: '通过Excel导入DeviceKey，分配已有设备给下级代理商',
    downTemplate: '下载设备分配模板',
    deviceAllocation: '设备分配',
    deviceAllocated: '已分配设备',
    activation: '激活',
    manual: '手动分配',
    batch: '批量分配',
    assignedSucc: '分配成功!',
    importTotal: '分配总数',
    importSuccessNum: '分配成功',
    importFailNum: '分配失败',
    importStatus: '分配状态',
    importTime: '分配时间',
    type: '操作方式'
  },
  user: {
    userId: '用户ID',
    avatar: '头像',
    nickname: '昵称',
    source: '来源',
    address: '地址',
    createTime: '创建时间',
    uploadTips: '是否确认导出所有角色数据项?',
    gender: {
      title: '性别',
      item1: '男',
      item2: '女',
      item3: '保密'
    },
    langTips: '我们将使用您设置的语言发送电子邮件、短信等通知，例如告警、故障消息推送。',
    lastLoginTime: '最后上线时间',
    boundDevice: '已绑定设备',
    unbindTips: '确定解绑此设备?',
    feedbackUser: '反馈用户',
    opinionName: '意见名称',
    feedback: '反馈意见',
    feedbackImg: '反馈图片',
    feedbackTime: '反馈时间',
    attrProduct: '归属产品',
    question: '问题描述',
    answer: '解决方法',
    addQuestion: '添加问题',
    profile: {
      personalInfo: '个人信息',
      sysAccount: '系统账号',
      nickName: '用户昵称',
      phoneNumber: '手机号码',
      email: '用户邮箱',
      department: '所属部门',
      ownedRole: '所属角色',
      basicInfo: '基本资料',
      changePassword: '修改密码',
      resetPwd: {
        oldPassword: '旧密码',
        newPassword: '新密码',
        confirmPassword: '确认密码'
      },
      userAvatar: {
        uploadAvatarTitle: '点击上传头像',
        select: '选择',
        submit: '提 交',
        modifyAvatar: '修改头像'
      },
      cancelEdit: '取消编辑',
      editInfo: '编辑信息',
      saveInfo: '保存信息',
      createTime: '创建时间'
    },
    sysLanguage: '系统语言',
    placeSysLanguage: '请选择系统语言',
    timeZone: '所在时区',
    placeTimeZone: '请选择所在时区',
    adaptive: '自适应'
  },
  system: {
    userStatus: {
      title: '用户状态',
      item1: '正常',
      item2: '停用'
    },
    place: {
      nickName: '用户昵称',
      roleName: '角色名称',
      roleKey: '角色标识',
      userPwd: '用户密码',
      leader: '负责人',
      selectRole: '角色',
      account: '账户名称'
    },
    userId: '用户编号',
    nickName: '用户昵称',
    userPwd: '用户密码',
    role: '角色',
    importUpdateTips: '是否更新已经存在的用户数据',
    downTemp: '下载模板',
    validNickName: '请输入中文、字母或数字',
    userMa: '用户吗?',
    addUser: '添加用户',
    editUser: '修改用户',
    resetPwdTip1: '请输入',
    resetPwdTip2: '的新密码',
    resetPwdTip3: '修改成功，新密码是：',
    delUserTips: '删除后账户不可登录且将可能影响接收消息通知，是否确认删除？',
    exportTips: '是否确认导出数据项?',
    userImport: '用户导入',
    roleName: '角色名称',
    roleKey: '角色标识',
    labelCode: '角色标签',
    roleStatus: '角色状态',
    roleId: '角色编号',
    roleDefault: '预设角色',
    roleSort: '显示顺序',
    roleWebMenuEdit: 'web菜单权限',
    roleWxAppMenuEdit: '小程序菜单权限',
    dataPermission: '数据权限',
    menuPermission: '菜单权限',
    loading: '加载中，请稍后',
    autScope: '权限范围',
    dataScope1: '全部数据权限',
    dataScope2: '自定数据权限',
    dataScope3: '本部门数据权限',
    dataScope4: '本部门及以下数据权限',
    dataScope5: '仅本人数据权限',
    validRoleName: '角色名称不能为空',
    validRoleKey: '角色标识不能为空',
    validRoleSort: '显示顺序不能为空',
    roleMa: '角色吗?',
    addRole: '添加角色',
    editRole: '修改角色',
    allocationDataPermission: '分配数据权限',
    delRoleTips: '是否确认删除角色名称为',
    leader: '负责人',
    dashboard: {
      globalParams: '全局参数',
      dataSourceName: '数据源名称',
      reqUrl: '接口地址',
      reqWay: '请求方式',
      dataType: '数据类型',
      parameter: '参数',
      createParameters: '创建参数',
      parameterName: '参数名称',
      paramCode: '参数标识',
      nameCn: '中文名称',
      nameEn: '英文名称',
      staticParameters: '静态参数',
      dynamicParameters: '接口请求参数',
      configStaticParameters: '配置静态参数',
      showValueCode: '展现参数',
      actualValueCode: '实际参数',
      defaultValue: '默认值',
      place: {
        isShow: '请选择是否展示',
        reqWay: '请选择请求方式',
        dataType: '请选择数据类型',
        paramCode: '请输入参数标识',
        nameCn: '请输入中文名称',
        nameEn: '请输入英文名称',
        dataName: '请输入数据源名称',
        reqUrl: '请输入接口地址',
        pageSize: '请输入展示条数',
        cardType: '请选择卡片类型',
        cardStyle: '请选择卡片样式',
        fieldCnName: '请填写接口字段中文名',
        fieldEnName: '请填写接口字段英文名'
      },
      typeOptions: {
        0: '柱状图/曲线图',
        1: '饼图',
        2: '地图',
        3: '数字',
        4: '列表',
        5: '排行'
      },
      addDataSource: '新增数据源',
      pageNumberItem: '每页展示条数',
      showNumber: '展示条数',
      pageSize: '分页条数',
      editParameters: '编辑参数',
      editDataSource: '编辑数据源',
      addGlobalParameters: '添加全局参数',
      editGlobalParameters: '编辑全局参数',
      cardName: '卡片名称',
      cardStyle: '卡片样式',
      cardCnName: '卡片中文名称',
      cardEnName: '卡片英文名称',
      jumpUrl: '跳转地址',
      cardType: '卡片类型',
      dataSourceConfig: '数据源配置',
      cardTypeOptions: {
        0: '柱状图',
        1: '曲线图',
        2: '饼图',
        3: '地图',
        4: '数字卡片',
        5: '卡片组',
        6: '列表',
        7: '排行'
      },
      addCard: '新增卡片',
      editCard: '编辑卡片',
      index: '指标',
      selectDataSource: '选择数据源',
      selectIcon: '选择图标',
      fieldMapping: '接口字段映射',
      refreshField: '刷新字段',
      fieldName: '字段名',
      color: '颜色',
      most4Index: '最多添加4个指标',
      leftIndicator: '左一指标',
      leftSecIndicator: '左二指标',
      mapIndicators: '地图指标',
      rankingIndex: '排行指标',
      upperIndex: '上部指标',
      centralIndex: '中部指标',
      lowerIndex: '下部指标'
    }
  },
  company: {
    companySetting: '企业设置',
    platformCustomSetting: '平台个性化配置',
    dataChannelSetting: '数据通道配置',
    paymentSetting: '微信支付配置',
    notificationSetting: '通知配置',
    thirdPartyAccessSetting: '第三方接入配置',
    securitySetting: '安全设置',
    connectionSucceeded: '连接成功',
    ConnectionTest: '连接测试',
    name: '企业名称',
    notice: {
      title: '通知配置',
      emailConfig: '邮箱配置',
      senderNickname: '发件人昵称',
      emailAccount: '账号',
      emailPassword: '邮箱授权码',
      smtpServer: 'smtp服务器',
      smtpPort: 'smtp端口',
      sceneConfig: '场景配置',
      warningNotice: '告警通知',
      faultNotice: '故障通知',
      fenceNotice: '电子围栏通知',
      validSenderNickname: '发件人昵称不能为空',
      validEmailAccount: '账号不能为空',
      validSmtpServer: 'smtp服务器不能为空',
      validSmtpPort: 'smtp端口不能为空',
      validWarningNotice: '请选择场景配置',
      validFaultNotice: '请选择故障通知',
      validFenceNotice: '请选择电子围栏通知',
      smsSign: '短信签名',
      appPush: 'APP推送通知配置',
      validAppKeyIos: 'AppKeyIOS不能为空',
      validAppKeyAndroid: 'AppKeyAndroid不能为空',
      validIosApnsEnv: '请选择IOSApnsEnv',
      validProductId: '请选择使用产品'
    },
    oemConf: {
      enable: '允许企业自定义',
      templateSelection: '模板选择',
      officialWebsite: '官网地址',
      configureDomain: '配置域名',
      personalizedDomain: '个性域名',
      customize: '自定义',
      recordQuery: '备案查询',
      siteName: '网站名称',
      loginPageLogo: '登录页面logo',
      tipUploadLogo: '点击上传logo',
      systemInsideBrightLogo: '明色系logo',
      systemInsideDarkLogo: '暗色系logo',
      websiteIcon: '网站图标',
      tipUploadFavicon: '点击上传favicon',
      loginBgImage: '登录背景图',
      tipUploadBgImage: '点击上传登录背景图',
      logo: {
        size: '尺寸：100*100px',
        format: '格式：JPG、PNG'
      },
      ico: {
        size: '尺寸：32*32px',
        format: '格式：ICO'
      },
      uploadBg: {
        size: '尺寸：1920*970px',
        format: '格式：JPG、PNG'
      },
      siteDescription: '网站描述',
      websiteKeywordsDesc: '网站关键词（输入关键词，并以英文逗号“,”隔开）',
      copyrightStatement: '版权说明',
      caseNumber: '备案号',
      basicConfig: '基础配置',
      advancedConfig: '高级配置',
      enableAdvancedConfig: '启用高级配置'

    },
    pay: {
      wechatPay: '微信支付',
      businessNumber: '商户号',
      businessKey: '商户秘钥',
      CertificateUpload: '证书上传',
      validAppid: 'appid不能为空',
      validMchId: '商户号不能为空',
      validMchKey: '商户秘钥不能为空',
      validCertificateUrl: '请上传证书',
      validTradeType: '请选择支付方式',
      validReferer: '请填写授权域名',
      tradeType: {
        title: '支付类型',
        item1: 'App支付',
        item2: '公众号支付/小程序支付',
        item3: 'H5支付'
      },
      referer: '授权域名'
    },
    safe: {
      title: '安全设置',
      loginCaptcha: '登录验证码',
      loginLock: '登录锁定',
      ruleSet: '规则设定',
      ruleSetText1: '分钟内，失败',
      ruleSetText2: '次，锁定',
      ruleSetText3: '分钟',
      authCodeLogin: '验证码登录',
      resetPwd: '找回密码',
      dmpOauthLogin: 'DMP授权登录',
      validLoginCaptcha: '是否开启登录验证码',
      validLoginLock: '是否开启登录锁定',
      validTimeRange: '请输入时间范围',
      validFailNum: '请输入失败次数',
      validLockTime: '请输入锁定时长',
      validAuthCodeLogin: '是否开启验证码登录',
      validResetPwd: '是否开启重置密码',
      validDmpOauthLogin: '是否开启DMP授权登录'
    },
    third: {
      title: '第三方接入配置',
      appKey: '秘钥',
      validAppId: 'appid不能为空',
      validAppKey: '秘钥不能为空',
      validAppToken: 'Token不能为空',
      validAesKey: 'AesKey不能为空'
    },
    sim: {
      title: '移远SIM卡平台授权配置',
      validKey: 'AppKey不能为空',
      validSecret: 'AppSecret不能为空'
    }
  },
  gdpr: {
    title: '移远隐私协议/移远服务协议',
    time1: '2021年07月21日版本',
    time2: '2021年07月21日生效',
    item1: '《移远隐私协议》',
    item2: '《移远服务协议》',
    text: '我已阅读并同意《移远隐私协议》与《移远服务协议》',
    agree: '同意',
    refuse: '拒绝',
    validGdpr: '请先勾选已阅读！',
    success: '已同意《移远隐私协议》与《移远服务协议》',
    logout: '拒绝将注销登出系统，确认继续吗？'
  },
  tagsView: {
    refresh: '刷新页面',
    closeCurrent: '关闭当前',
    closeOthers: '关闭其他',
    closeRight: '关闭右侧',
    closeAll: '关闭所有'
  },
  dict: {
    dictName: '字典名称',
    dictType: '字典类型',
    status: '字典状态',
    place: {
      dictName: '字典名称',
      dictType: '字典类型',
      dictLabel: '字典标签',
      dataLabel: '数据标签',
      dataValue: '数据键值'
    },
    valid: {
      dictName: '字典名称不能为空',
      dictType: '字典类型不能为空',
      dataLabel: '数据标签不能为空',
      dataValue: '数据键值不能为空',
      dataSort: '数据顺序不能为空'
    },
    clearCache: '清理缓存',
    dictId: '字典编号',
    addTitle: '添加字典类型',
    editTitle: '修改字典类型',
    delTips: '是否确认删除该数据项?',
    exportTips: '是否确认导出所有类型数据项?',
    clearCacheSuccess: '清理成功',
    dictLabel: '字典标签',
    dataLabel: '数据标签',
    dataStatus: '数据状态',
    dictCode: '字典编码',
    dictValue: '字典键值',
    dataValue: '数据键值',
    dictSort: '字典排序',
    dataSort: '数据排序',
    addDictData: '添加字典数据',
    editDictData: '修改字典数据'
  },
  config: {
    place: {
      configName: '参数名称',
      configKey: '参数键名',
      configValue: '参数键值'
    },
    valid: {
      configName: '参数名称不能为空',
      configKey: '参数键名不能为空',
      configValue: '参数键值不能为空'
    },
    configName: '参数名称',
    configKey: '参数键名',
    configType: '系统内置',
    configId: '参数主键',
    configValue: '参数键值',
    addConfig: '添加参数',
    editConfig: '修改参数'
  },
  companyList: {
    userName: '账户',
    linkPhoneNumber: '电话',
    viewProduct: '查看产品',
    role: '权限配置',
    vendorRole: '厂商权限',
    agencyRole: '代理商权限',
    proPage: '产品个性页面',
    editRole: '编辑权限',
    storageDurationText: '设备上行数据存储周期',
    name: '企业名称',
    set: '存储设置',
    productList: '移远通信产品列表',
    batchSet: '批量配置',
    signalSet: '配置'
  },
  oauth: {
    title: '用户信息录入',
    desc: '首次使用系统需补充录入以下信息，用于系统账号登录、接收通知信息与找回密码',
    userName: '账号',
    password: '平台登录密码',
    phonenumber: '绑定手机号',
    email: '绑定邮箱',
    submit: '提交并登入'
  },
  menu: {
    menuName: '菜单名称',
    status: '菜单状态',
    place: {
      menuName: '菜单名称',
      status: '菜单状态',
      parentId: '选择上级菜单',
      icon: '点击选择图标',
      otherName: '请输入菜单别名',
      path: '请输入路由地址',
      component: '请输入页面路径',
      perms: '请输入权限标识',
      redirect: '请输入路由重定向',
      tag: '请选择菜单标签'
    },
    orderNum: '排序',
    perms: '权限标识',
    resource: '数据类型',
    component: '页面路径',
    parentId: '上级菜单',
    menuType: {
      title: '菜单类型',
      item1: '目录',
      item2: '菜单',
      item3: '按钮'
    },
    icon: '菜单图标',
    otherName: '菜单别名',
    isFrame: '是否外链',
    path: '路由地址',
    visible: '显示状态',
    isCache: {
      title: '是否缓存',
      item1: '缓存',
      item2: '不缓存'
    },
    redirect: '路由重定向',
    tag: '标签',
    menuTag: '菜单标签'
  },
  rightToolbar: {
    hideSearch: '隐藏搜索',
    showSearch: '显示搜索',
    refresh: '刷新',
    showColumn: '列表字段设置',
    show: '显示',
    hide: '隐藏',
    showOrHide: '显示/隐藏'
  },
  dataFormatOptions: {
    item1: '透传/自定义',
    item2: 'JSON(标准数据格式)'
  },

  group: {
    name: '安装点名称',
    address: '安装点地址',
    person: '负责人',
    createTime: '创建时间',
    batchSet: '批量设置',
    managerDevice: '管理设备',
    setPerson: '设置负责人',
    enterName: '请输入安装点名称',
    enterNameLengthLimit: '安装点名称长度不能超过256位',
    enterAdress: '请输入安装点地址',
    enterAdressLengthLimi: '请输入安装点地址长度不能超过1024位',
    deleteTips: '是否需要删除安装点名称为',
    bind: '绑定',
    unbind: '解绑',
    deviceKey: 'DeviceKey',
    deviceName: '设备名称',
    productName: '产品名称',
    send: '发送',
    product: '控制产品'
  },

  person: {
    add: '添加负责人',
    name: '负责人名称',
    mobile: '负责人手机号',
    smsNotice: '短信通知',
    voiceNotice: '语音通知',
    enterName: '请输入负责人名称',
    enterNameLengthLimit: '负责人名称长度不能超过50位',
    enterMobile: '请输入负责人手机号',
    enterAdressLengthLimi: '请输入正确的手机号',
    deleteTips: '删除后已关联的安装点将无法通知该负责人确认删除吗?'
  },

  scooter: {
    operate: {
      storeName: '门店名称',
      addStore: '添加门店',
      area: '地区',
      address: '门店地址',
      businessTime: '营业时间',
      contactWay: '联系方式',
      storeImg: '门店图片',
      locationView: '定位查看',
      storeLocation: '门店位置',
      agent: '代理商',
      shopOwner: '店长',
      telephone: '联系电话',
      areaSelect: '地区选择',
      countryOrRegion: '国家或地区',
      specificLocation: '具体位置',
      uploadTips: '只能上传jpg/jpeg/png格式文件，且不超过200kb，建议上传尺寸：600*600像素',
      remark: '备注',
      notFoundAdd: '未搜索到该地址',
      selectStoreTips: '请在具体位置下拉中选择门店位置',
      editSuccess: '编辑更新成功',
      uploadImgTips: '文件格式错误，只能上传jpg/jpeg/png格式文件',
      uploadImgSizeTips: '文件大小错误，上传图片大小不能超过200kb',
      stolenAdd: '失窃地址',
      place: {
        enterStoreName: '请输入门店名称',
        enterShopOwner: '请输入店长',
        enterSpecificLocation: '请输入具体位置',
        enterRemark: '请输入备注',
        enterDevType: '请输入车辆型号',
        startTime: '请选择开始时间',
        endTime: '请选择结束时间',
        selectTheft: '请选择失窃类型',
        selectDevManual: '请选择车辆说明书'
      },
      vin: '车架号',
      devType: '车辆型号',
      user: '用户',
      repairStore: '报修门店',
      questionImg: '问题图片',
      reportTime: '报修时间',
      addRepair: '新增报修',
      tenantName: '厂商',
      loseType: {
        title: '失窃类型',
        item1: '车辆',
        item2: '电池'
      },
      reporterName: '上报人姓名',
      loseRemark: '失窃描述',
      loseTime: '失窃时间',
      loseAddress: '失窃地点',
      realTimeTrack: '实时跟踪',
      addLose: '新增失窃',
      addManual: '添加说明书',
      carManual: '车辆说明书',
      down: '下载',
      uploadFormatTips: '文件格式错误，只能上传PDF格式文件',
      uploadSizeTips: '文件大小错误，上传文件大小不得超过',
      uploadedTips: '您已上传过文件，如需重新上传请先删除'
    }
  },
  // 统一文件管理
  upload: {
    tagsTitle: '我的标签',
    queryParams: {
      fileType: '文件类型',
      fileName: '文件名称',
      fileTag: '请选择标签名称',
      uploadBtn: '上传',
      file: '请选择文件',
      enter: '请输入标签名称，点击enter添加'
    },
    table: {
      typeObj: {
        1: '图片',
        2: '文档',
        3: '音频',
        4: '视频',
        5: '压缩包',
        6: '其它'
      },
      fileType: '类别',
      fileTag: '标签名称',
      view: '预览',
      fileName: '文件名称',
      fileSize: '文件大小',
      mimeType: 'Mime类型',
      select: '选择',
      dest: '文件存储位置'
    },
    preview: '图片预览',
    addFiles: '添加文件',
    fileSelection: '文件选择',
    validNull: '请勿上传空文件'
  },
  // 批量任务
  task: {
    running: '进行中任务',
    queryParams: {
      taskType: '请选择任务类型',
      batchTaskStatus: '请选择批任务状态'
    },
    table: {
      batchTaskNo: '批次号',
      taskType: '任务类型',
      totalNum: '任务总数',
      successNum: '成功数量',
      failNum: '失败数量',
      tsFinishTime: '完成时间',
      batchTaskStatus: '批次任务状态',
      timeUsed: '耗时',
      batchTaskProgress: '进度',

      taskTypeOptions: {
        1: '设备导入',
        2: '设备分配'
      },
      batchTaskOptions: {
        1: '运行中',
        2: '已完成',
        3: '异常',
        0: '准备就绪'
      }
    },
    taskDetails: {
      table: {
        taskTag: '任务标识',
        taskStatus: '任务状态',
        errorMsg: '错误信息',
        dataPayload: '数据载荷',
        deviceName: '设备名称',
        taskStatusOptions: {
          0: '全部',
          1: '运行中',
          2: '已完成',
          3: '异常',
          4: '准备就绪'
        }
      }
    }
  },
  // 登录验证
  validation: {
    title: '请完成安全验证',
    toRight: '向右滑动完成验证',
    successful: '验证成功',
    failure: '验证失败'
  },
  // app版本管理
  appmanage: {
    versionType: '版本类型',
    appVersion: 'APP版本号',
    plappVersion: 'APP版本号不能为空',
    downloadAddress: '下载地址',
    pldownloadAddress: '下载地址不能为空',
    versionDetail: '版本详情',
    plversionDetail: '版本详情不能为空',
    publishState: '发布状态',
    verify: '验证账号',
    // verifyTit: '验证账号',
    verifyManage: '验证账号管理',
    rulapp: 'APP版本号以V开头，只支持数字和 “ . ”，格式为xx.xx.xx',
    state0: '待验证',
    state1: '已发布',
    state2: '已下架',
    delete01: '是否确认删除该版本？',
    publish01: '是否确认发布该版本？',
    deletever: '是否确认删除该验证账号？',
    nofile: '未选择任何文件'

  },
  unit: {
    sec: '秒',
    min: '分钟',
    hour: '小时',
    day: '天',
    month: '月',
    year: '年',
    watts: '瓦',
    kWh: '度',
    yuan: '元',
    times: '次'
  },
  // 品类管理
  category: {
    title: '产品品类管理',
    addBtn: '添加品类',
    chooseCategory: '品类选择',
    detailBtn: '详情页管理',
    tableLevel1: '一级品类',
    tableLevel2: '二级品类',
    detailName: '详情页名称',
    webDetailName: 'Web端详情页',
    appDetailName: '移动端详情页',
    saveValidateError: '请完成表单内容',
    canNotNull: '不能为空',
    categoryDialogEdit: '编辑产品品类',
    categoryDialogAdd: '添加产品品类',
    noSelectCategorySub: '请输入自定义品类名称'
  },
  // 站内信
  letter: {
    announcement: '通知公告',
    deviceMsg: '设备消息',
    allread: '全部已读',
    table_title: '标题',
    table_time: '创建时间',
    table_release_time: '发布时间',
    status: '状态',
    eq_number: '设备编码',
    device_name: '设备名称',
    warn_type: '告警类型',
    warn_time: '告警时间',
    createdNotice: '创建公告',
    fixedTop: '置顶滚动',
    accpected: '接收目标',
    releaseNow: '立即发布',
    releaseDelay: '定时发布',
    scroll: '滚动',
    all: '所有企业',
    designated: '指定企业',
    temporary: '暂存编辑',
    edit_header: '编辑内信',
    show_header: '查看站内信',
    canNot: '{0}不能为空',
    status1: '草稿',
    status2: '计划发布',
    status3: '已发布',
    status4: '发布失败',
    selectDeadlineDate: '请选择置顶滚动截止日期',
    selectDeadlineTime: '请选择置顶滚动截止时间',
    selectReleaseDate: '请选择发布日期',
    selectReleaseTime: '请选择发布时间',
    place: {
      status: '请选择状态'
    }
  },
  // 商户管理
  business: {
    name: '商户名称',
    site: '所在地',
    add: '添加商户',
    creator: '创建人',
    place: {
      name: '商户名称',
      select: '商户',
      site: '请输入所在地',
      status: '请选择状态',
      password: '请输入登录密码',
      phone: '请输入手机号',
      nickName: '请输入昵称',
      confirmPassword: '请输入确认密码'
    },
    allocateDevice: '分配',
    unbindDevice: '回收',
    pay: '支付配置',
    password: '登录密码',
    phone: '手机号',
    list: '商户列表',
    more: '更多配置'
  }
}
