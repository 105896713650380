import factory from './factory'

/** 表情 */
const emoji = factory(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g)

/** 空格 */
const space = factory(/@"s+"/)

/**
 * 禁止input输入处理指令
 * v-emoji：禁止输入表情
 * v-space：禁止输入空格
 */
const install = function (Vue) {
  Vue.directive('emoji', emoji)
  Vue.directive('space', space)
}

if (window.Vue) {
  window.emoji = emoji
  window.space = space
  Vue.use(install); // eslint-disable-line
}

export default install
