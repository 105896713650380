module.exports = {
  title: 'BIZ 自助商用平台',

  /**
     * 侧边栏主题
     */
  sideTheme: 'theme1',

  /**
     * 是否系统布局配置
     */
  showSettings: false,

  /**
     * 侧边栏风格
     */
  sideStyle: 'menu2',

  /**
     * @type {string | array} 'production' | ['production', 'development']
     * @description Need show err logs component.
     * The default is only used in the production env
     * If you want to also use it in dev, you can pass ['production', 'development']
     */
  errorLog: 'production',
  amapAk: '689f8314d3cead94682b1b6e84fbe1d9',
  amapSk: '22e3c60a3a7fdf8424caed6e4d4692f1',
  gmapAk: ''
}
