<template>
  <el-dialog :visible.sync="open" title="列表字段设置" width="700px" :close-on-click-modal="false" append-to-body>
    <el-row>
      <el-col class="header-cell" :span="4">字段排序</el-col>
      <el-col class="header-cell" :span="6">字段显示</el-col>
      <el-col class="header-cell text-left" :span="6">字段名称</el-col>
      <el-col class="header-cell" :span="8">
        字段别称
        <el-tooltip popper-class="q-tooltip" content="设备列表默认展示字段别称" placement="top">
          <i class="el-icon-warning-outline tip-icon" />
        </el-tooltip>
      </el-col>
    </el-row>
    <div :id="'colSetting' + tableName">
      <el-row v-for="(row, index) in list" :key="row.code + index" class="row">
        <el-row>
          <el-col class="row-cell" :span="4">
            <i class="sort-icon" />
          </el-col>
          <el-col class="row-cell" :span="6">
            <el-switch v-model="row.visible" />
          </el-col>
          <el-col class="row-cell text-left" :span="6" :title="row.name">
            {{ row.name }}
          </el-col>
          <el-col class="row-cell" :span="8">
            <el-input v-model="row.otherName" :maxlength="20" size="small" show-word-limit clearable />
          </el-col>
        </el-row>
        <template v-if="row.children">
          <el-row v-for="child in row.children" :key="child.code">
            <el-col class="row-cell text-left" :offset="10" :span="6" :title="child.name">
              {{ child.name }}
            </el-col>
            <el-col class="row-cell" :span="8">
              <el-input v-model="child.otherName" :maxlength="20" size="small" show-word-limit clearable />
            </el-col>
          </el-row>
        </template>
      </el-row>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" plain class="fl" @click="reset"> 恢复默认 </el-button>
      <el-button @click="close">
        {{ $t('common.cancel') }}
      </el-button>
      <el-button type="primary" @click="submit">
        {{ $t('common.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { setCols } from '@/api/colSetting'
import Sortable from 'sortablejs'
export default {
  name: 'ColSetting',
  props: {
    visible: { type: Boolean, default: false }, // 弹窗显示
    tableName: { type: String, default: '' }, // 列表名称，用于绑定列信息
    customCols: { type: Array, default: () => [] }, // 自定义列的信息
    defaultCols: { type: Array, default: () => [] }// 初始列信息
  },
  data () {
    return {
      list: []
    }
  },
  computed: {
    open: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    open: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.list = this.cloneDeep(this.customCols) // 每次打开还原指最新保存配置
          this.startRowDrop()
        }
      }
    }
  },
  methods: {
    // 行拖拽
    startRowDrop () {
      document.body.ondrop = event => {
        event.preventDefault()
        event.stopPropagation()
      }
      this.$nextTick(() => { this.rowDrop() })
    },
    rowDrop () {
      const tbody = document.querySelector('#colSetting' + this.tableName)
      const _this = this
      Sortable.create(tbody, {
        animation: 150,
        chosenClass: 'row-chosen',
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.list.splice(oldIndex, 1)[0]
          _this.list.splice(newIndex, 0, currRow)
        }
      })
    },
    reset () {
      this.list = this.cloneDeep(this.defaultCols)
    },
    submit () {
      setCols(this.tableName, this.list).then(res => {
        this.msgSuccess('设置成功')
        this.$emit('refreshCols', this.list)
        this.close()
      })
    },
    close () {
      this.open = false
    }
  }
}
</script>
<style>
.row-chosen {
  background-color: #fff;
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
}

.sort-icon {
  display: inline-block;
  width: 14px;
  height: 12px;
  border-color: #848484;
  border-top: 7px double;
  border-bottom: 2px solid;
  cursor: move;
}

.header-cell {
  border-bottom: 1px solid #e8e8e8;
  background-color: #f8f8f9;
  font-weight: 600;
  color: #515a6e;
}

.row {
  border-bottom: 1px solid #e8e8e8;
}

.header-cell,
.row-cell {
  overflow: hidden;
  height: 40px;
  padding: 0 8px;
  font-size: 13px;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 40px;
  white-space: nowrap;
  word-break: break-word;
}
</style>
