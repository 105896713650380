<template>
  <div>
    <q-table ref="table" :border="border" v-bind="$attrs" v-on="$listeners">
      <q-table-column v-if="hasSelection" type="selection" align="center" width="40" :selectable="selectable" />
      <slot name="selection" />
      <template v-for="(col, index) in columns">
        <!--普通列 -->
        <TableColumn
          v-if="!col.children && (col.visible !== undefined ? col.visible : true)"
          :key="col.code + index + col.width"
          v-bind="col"
        >
          <!-- 插槽透传 -->
          <template v-for="name in Object.keys($scopedSlots)" #[name]="{ scope }">
            <slot :name="name" :row="scope.row" :col="scope.column" :index="scope.$index" />
          </template>
        </TableColumn>

        <!--含子集列 -->
        <q-table-column
          v-if="col.children && (col.visible !== undefined ? col.visible : true)"
          :key="col.code + index + col.width"
          :label="col.otherName || col.name"
          :align="col.align"
          :width="col.width"
          show-overflow-tooltip
        >
          <TableColumn v-for="(child, i) in col.children" :key="child.code + i + child.width" v-bind="child">
            <!-- 插槽透传 -->
            <template v-for="name in Object.keys($scopedSlots)" #[name]="{ scope }">
              <slot :name="name" :row="scope.row" :col="scope.column" :index="scope.$index" />
            </template>
          </TableColumn>
        </q-table-column>
      </template>
      <!-- 操作栏 align="center"-->
      <q-table-column
        v-if="actions.length"
        :label="$t('common.action')"
        fixed="right"
        :min-width="actionMinWidth"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row, $index }">
          <el-button
            v-for="btn in actions.slice(0, actions.length > 3 ? 2 : 3)"
            v-show="btn.show == undefined || (btn.show && btn.show(row))"
            :key="btn.label"
            v-hasPermi="btn.permi"
            type="text"
            :disabled="btn.disabled && btn.disabled(row, $index)"
            :class="{ 'table-del-action': btn.isDel }"
            @click.native.stop="btn.click(row)"
          >
            {{ $t(btn.label) }}
          </el-button>
          <slot name="action" :row="row" :index="$index" />
          <el-dropdown v-if="actions.length > 3" class="ml0" trigger="hover">
            <el-button type="text">
              {{ $t('device.run.more') }}
              <i class="el-icon-arrow-down" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="btn in actions.slice(2)"
                :key="btn.label"
                :disabled="btn.disabled && btn.disabled(row, $index)"
                class="table-action-drop-item"
                :class="[{ 'table-action-drop-del-item': btn.isDel }]"
                @click.native.stop="btn.click(row)"
              >
                {{ $t(btn.label) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </q-table-column>
    </q-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="$emit('getList')"
    />
  </div>
</template>
<script>
import TableColumn from './TableColumn'
export default {
  name: 'Table',
  components: { TableColumn },
  props: {
    hasSelection: { type: Boolean, default: false }, // 是否存在复选框
    actionMinWidth: { type: Number, default: 100 }, // 操作栏最小宽度
    border: { type: Boolean, default: true },
    total: { type: Number, default: 0 }, // 翻页场景的总行数
    queryParams: { type: Object, default: () => ({}) }, // 查询参数
    columns: { type: Array, default: () => [] }, // 列信息
    actions: { type: Array, default: () => [] }, // 操作栏信息
    selectable: {
      type: Function,
      default () {
        return () => true
      }
    }
  },
  computed: {
    getRef () {
      return this.$refs.table
    }
  }
}
</script>
