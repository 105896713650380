var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.orgtype !== 1
    ? _c(
        "div",
        { staticClass: "tool guide-tool", class: { active: _vm.popoverShow } },
        [
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _vm.popoverShow
              ? _c("div", { staticClass: "popover-area" }, [
                  !_vm.allSetted
                    ? _c("div", { staticClass: "popover-box tips" }, [
                        _vm._v(
                          " 发现您有以下设置未完成，未完成会影响本系统的整体使用！ "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "ul",
                    { staticClass: "popover-box menus" },
                    [
                      _vm._l(_vm.list, function (item, index) {
                        return [
                          item.status !== null
                            ? _c(
                                "li",
                                {
                                  key: item.name,
                                  class: { "success-status": _vm.allSetted },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpPage(item, "name")
                                    },
                                  },
                                },
                                [
                                  !_vm.allSetted && item.status
                                    ? _c("svg-icon", {
                                        staticClass: "svg-icon",
                                        attrs: { "icon-class": "success" },
                                      })
                                    : _vm._e(),
                                  !item.status && !_vm.allSetted
                                    ? _c("svg-icon", {
                                        staticClass: "svg-icon",
                                        attrs: { "icon-class": "warning" },
                                      })
                                    : _vm._e(),
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  !_vm.allSetted
                                    ? _c(
                                        "div",
                                        { staticClass: "operate-area" },
                                        [
                                          !item.status
                                            ? _c(
                                                "div",
                                                [
                                                  index === 4
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.ignoreHandle.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("忽略")]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.jumpPage(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("去配置")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "link-arrow",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.jumpPage(item)
                                                  },
                                                },
                                              }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "icon",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.switchTool.apply(null, arguments)
                  },
                },
              },
              [_c("svg-icon", { attrs: { "icon-class": "right-next" } })],
              1
            ),
            _c(
              "div",
              {
                staticClass: "icon set",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.switchTool.apply(null, arguments)
                  },
                },
              },
              [_c("svg-icon", { attrs: { "icon-class": "guide-tool" } })],
              1
            ),
            _c(
              "div",
              {
                staticClass: "label",
                on: {
                  click: function ($event) {
                    _vm.popoverShow = !_vm.popoverShow
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.allSetted ? _vm.$t("m.quickSet") : _vm.$t("m.fullSet")
                  )
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }