import { menu } from '@/app/lang/en'

export default {
  title: 'Self-Service Sloution',
  common: {
    createTime: 'Creation time',
    updateTime: 'Update time',
    confirm: 'Confirm',
    cancel: 'Cancel',
    search: 'Search',
    reset: 'Reset',
    action: 'Action',
    edit: 'Edit',
    detail: 'Detail',
    close: 'Close',
    save: 'Save',
    success: 'Successful',
    show: 'Show',
    hide: 'Hide',
    open: 'Enable',
    delete: 'Delete',
    publish: 'publish',
    add: 'Add',
    addTo: 'Add',
    editSuccess: 'Successful edition.',
    deleteSuccess: 'Successful deletion.',
    deleteFail: 'Failed deletion.',
    deleteCancel: 'Deletion request is cancelled.',
    addSuccess: 'Successful addition.',
    addToSuccess: 'Successful addition.',
    publishSuccess: 'Successful',
    modifySuccess: 'Successful modification.',
    bindSuccess: 'Successful binding.',
    unbindSuccess: 'Successful unbinding.',
    unbindCancel: 'Unbinding request is cancelled.',
    saveSuccess: 'Successful saving.',
    updateSuccess: 'Successful update.',
    uploadFail: 'Failed uploading.',
    tipUpload: 'Click to Upload',
    tipUploadImg: 'The picture should be in .jpg or .png format.',
    tipUploadXls: 'The file should be in .xls or .xlsx format.',
    tipUploadPdf: 'Only PDF file is supported.',
    tipUploadIco: 'Please upload the image in ".ico" format',
    sure: 'Are you sure you want to',
    sureDel: 'Are you sure you want to delete',
    ma: '?',
    de: 'de',
    warn: 'Alert',
    import: 'Import',
    export: 'Export',
    importRecords: 'Import Records',
    uploadFiles: 'Upload File',
    dragFile: 'Drag the file here, or',
    productName: 'Product Name',
    prompt: 'Tip',
    see: 'View',
    seeDetails: 'Details',
    basicInfo: 'Basic Information',
    back: 'Go Back',
    start: 'Start',
    pause: 'Suspend',
    noData: 'No data currently available',
    to: 'to',
    minute: 'Minute(s)',
    hour: 'Hour(s)',
    location: 'Location',
    email: 'Email',
    sms: 'SMS',
    enter: 'Enter',
    no: 'No',
    yes: 'Yes',
    zh: 'Simplified Chinese',
    en: 'English',
    logout: 'Are you sure you want to log out?',
    actionSuccess: 'Succssful operation.',
    cookiesInvalid: 'Your session has expired. You can stay at this page or proceed to login again.',
    sysHint: 'Session Expiring',
    reLogin: 'Log In Again',
    networkError: 'Connection error.',
    timeOut: 'Request timeout.',
    systemInterface: 'System Interface',
    abnormal: 'Error',
    copy: 'Copy',
    clipboardSuccess: 'Copy successfully',
    clipboardError: 'Copy failed'
  },
  navbar: {
    personal: 'Personal Center',
    company: 'Enterprise Information',
    logout: 'Log Out'
  },
  m: {
    app: menu,
    dashboard: 'Overview',
    product: 'Product',
    device: 'Device',
    agent: 'Agent',
    end: 'Terminal User',
    company: 'Enterprise',
    sys: 'System',
    monitor: 'Monitoring',
    device_list: 'Device List',
    device_map: 'Device Geo-Location',
    device_fence: 'GeoFence',
    agent_list: 'Agent List',
    device_share: 'Allocated device',
    end_user: 'Terminal User',
    end_user_detail: 'Terminal Details',
    end_feedback: 'Feedback',
    end_question: 'FAQs',
    sys_user: 'Account',
    sys_role: 'Role',
    sys_menu: 'Menu',
    sys_dict: 'Dictionary',
    sys_config: 'System Configuration',
    sys_notice: 'Notifications',
    sys_log: 'Logs',
    sys_log_opera: 'Activity log',
    sys_log_login: 'User Login History',
    monitor_online: 'Online User',
    monitor_time: 'Timer Task',
    user_profile: 'Personal Center',
    company_config: 'Enterprise Setting',
    dict_data: 'Dictionaries',
    function: 'Function Definition',
    notice_email: 'Email Notification',
    notice_sms: 'SMS Notification',
    notice_voice: 'Voice Notification',
    notice_app: 'App Notification',
    device_detail: 'Device Details',
    fence_add: 'Draw a GeoFence',
    fence_bind: 'Bind Device',
    scooter_opera: 'Operations Management',
    scooter_shop: 'Authorized Store',
    scooter_repair: 'Repair Request',
    scooter_lose: 'Theft and Loss',
    lose_manual: 'Manual',
    store_info: 'Store Information',
    sim: 'SIM Card',
    job_log: 'Task Logs',
    agent_select: 'Agent inquiry',
    agent_add: 'Agent added',
    agent_update: 'Agent edit',
    agent_delete: 'Agent delete',
    agent_export: 'Agent export',
    sys_user_select: 'User query',
    sys_user_add: 'User added',
    sys_user_update: 'User edit',
    sys_user_delete: 'User delete',
    sys_user_export: 'User export',
    sys_user_import: 'User import',
    sys_reset_password: 'reset Password',
    sys_role_select: 'Role query',
    sys_role_add: 'Role added',
    sys_role_update: 'Role edit',
    sys_role_delete: 'Role delete',
    sys_role_export: 'Role export',
    sys_menu_select: 'Menu query',
    sys_menu_add: 'Menu added',
    sys_menu_update: 'Menu edit',
    sys_menu_delete: 'Menu delete',
    sys_dict_select: 'Dictionary lookup',
    sys_dict_add: 'Dictionary added',
    sys_dict_update: 'Dictionary edit',
    sys_dict_delete: 'Dictionary delete',
    sys_dict_export: 'Dictionary export',
    sys_config_select: 'Parameter query',
    sys_config_add: 'Parameter added',
    sys_config_update: 'Parameter edit',
    sys_config_delete: 'Parameter delete',
    sys_config_export: 'Parameter export',
    sys_notice_select: 'Announcement query',
    sys_notice_add: 'Announcement added',
    sys_notice_update: 'Announcement edit',
    sys_notice_delete: 'Announcement delete',
    sys_log_opera_select: 'Operation query',
    sys_log_opera_delete: 'Operation delete',
    sys_log_opera_export: 'Log export',
    sys_login_select: 'Login query',
    sys_login_delete: 'Login delete',
    m_online_select: 'Online search',
    m_batch_logout: 'Batch retreat',
    m_force_logout: 'Single forced retreat',
    m_task_select: 'Task query',
    m_task_add: 'Task added',
    m_task_update: 'Task edit',
    m_task_delete: 'Task delete',
    m_status_update: 'State modification',
    m_task_export: 'Task export',
    company_role: 'Rights Profile',
    device_info: 'Basic information',
    device_attr: 'Device attributes',
    device_location: 'Location information',
    device_mileage: 'Mileage report',
    device_fault: 'Fault log',
    device_alarm: 'Alarm log',
    device_set: 'Issue instructions',
    device_sim: 'SIM card',
    device_run: 'Running record',
    device_order: 'Order record',
    device_port: 'Socket information',
    appVersion: 'APP version',
    sys_notify: 'Notification',
    sys_notify_sms: 'SMS template',
    sys_notify_email: 'Email Notification',
    sys_notify_voice: 'Voice template',
    sys_notify_app: 'APP push template',
    sys_notify_mini: 'MINI Notification',
    sys_category: 'Category',
    m_agent_select: 'Find an agent',
    m_agent_add: 'Add agent',
    m_agent_update: 'Edit agency',
    m_agent_delete: 'Delete agency',
    m_agent_export: 'Export agency',
    m_user_select: 'Query user',
    m_user_add: 'Add user',
    m_user_update: 'Edit user',
    m_user_delete: 'Delete user',
    m_user_export: 'Export user',
    m_user_import: 'Import user',
    m_reset_password: 'Reset password',
    m_role_select: 'Query role',
    m_role_add: 'Add role',
    m_role_update: 'Edit role',
    m_role_delete: 'Delete role',
    m_role_export: 'Export role',
    m_menu_select: 'Query menu',
    m_menu_add: 'Add menu',
    m_menu_update: 'Edit menu',
    m_menu_delete: 'Delete menu',
    m_dict_select: 'Query dictionary',
    m_dict_add: 'Add dictionary',
    m_dict_update: 'Edit dictionary',
    m_dict_delete: 'Delete dictionary',
    m_dict_export: 'Export dictionary',
    m_sys_config_select: 'Query parameter',
    m_sys_config_add: 'Add parameter',
    m_sys_config_update: 'Edit parameter',
    m_sys_config_delete: 'Delete parameter',
    m_sys_config_export: 'Export parameter',
    m_notice_select: 'Query announcement',
    m_notice_add: 'Add announcement',
    m_notice_update: 'Edit announcement',
    m_notice_delete: 'Delete announcement',
    group: 'Installation Point Management',
    group_list: 'Installation Point list',
    person_list: 'Principals list',
    m_add_group: 'Add installation point',
    m_group_batch_set: 'Batch control',
    m_group_manage_dev: 'Manage Device',
    m_group_set_person: 'Set Principals',
    m_group_edit: 'Edit',
    m_group_delete: 'Delete',
    m_person_add: 'Add',
    m_person_edit: 'Edit',
    m_person_delete: 'Delete',
    sys_upload: 'File management',
    sys_dashboard: 'Dashboard management',
    sys_dashboard_data: 'Data source configuration',
    sys_dashboard_card: 'Dashboard configuration',
    appmanage: 'APP version',
    m_profit_data: 'Data source profit',
    m_rule_add: 'Add rule',
    m_rule_edit: 'Edit rule',
    m_rule_remove: 'Remove rule',
    m_account_add: 'Add account',
    m_account_edit: 'Edit account',
    m_account_remove: 'Remove account',
    m_record_profit: 'Distribute'
  },
  valid: {
    userName: 'Username can not be empty',
    passWord: 'Password can not be empty',
    code: 'Verification code can not be empty',
    name: 'Name can not be empty',
    key: 'Identification can not be empty',
    filedName: 'Self-defined device parameter name can not be empty',
    templateName: 'Template name can not be empty',
    templateContent: 'Email content can not be empty',
    checkPeople: 'Please select one or more recipient type.',
    leastOne: 'At least one receiver is required.',
    removeAlarmParmValue: 'Please select a threshold value for solving the alert.',
    sendNotice: 'Please select whether to send a notification after the alert is resolved.',
    fence: {
      length: 'GeoFence name cannot be more than 30 characters long.',
      repeat: 'The GeoFence name you entered already exists.',
      format: 'Please enter a valid GeoFence name.'
    },
    agent: {
      required: 'Please enter the agent name.',
      repeat: 'The agent name you entered already exists.'
    },
    mobile: {
      required: 'Please enter a phone number.',
      format: 'Please enter a valid phone number.',
      repeat: 'The phone number you entered already exists.'
    },
    sysAccount: {
      required: 'Please enter login username.',
      format: 'Login username must be 6-20 characters and contain both numbers and letters.',
      format2: 'Login username must be 6-20 characters and contain both numbers and letters.',
      repeat: 'The login username you entered already exists.'
    },
    email: {
      required: 'Plaese enter a email address.',
      format: 'Please enter a valid email address.'
    },
    uploadFiles: 'Please upload a device list file.',
    nickName: {
      required: 'Please enter your username.',
      format: 'Username must be Chinese characters, letters or numbers.',
      repeat: 'The username you entered already exists.'
    },
    phoneNumber: {
      required: 'Phone number can not be empty.',
      format: 'Please enter a valid phone number.'
    },
    password: {
      required: 'Please enter the password.',
      format: 'Password must contain 6-20 case sensivie letters and numbers.'
    },
    equalToPassword: 'The two passwords you entered are inconsistent.',
    oldPassword: 'Old password can not be empty.',
    confirmPassword: 'Confirm password can not be empty.',
    uploadImg: 'Wrong file format. The picture can only be a jpg/png file.',
    access: {
      AccessKeyRequired: 'AccessKey can not be empty.',
      AccessSecretRequired: 'AccessSecret can not be empty.'
    },
    domain: {
      required: 'Please enter a domain name.'
    },
    loginTemplate: 'Please select a login form.',
    loginPageLogo: 'Please upload the logo for login page.',
    sysInnerPageLogo: 'Please upload the logo for site pages.',
    loginBg: 'Please upload login background image.',
    numAlp: 'Please enter letters or numbers',
    systemName: 'Please enter a website name'
  },
  place: {
    enter: 'Enter here',
    select: 'Select here',
    selectHandleStatus: 'Select processing status',
    selectDate: 'Specify date',
    selectCompany: 'Select enterprise',
    selectProduct: 'Product',
    selectLocationType: 'Select positioning method',
    selectStayMark: 'Select residence time threshold',
    selectTriggerState: 'Select triggering event',
    selectFenceType: 'Select GeoFence shape',
    selectEffectiveType: 'Specify effective period',
    selectOperationState: 'Company status',
    selectTenant: 'Select manufacturer',
    selectAgent: 'Select agent',
    selectState: 'Select state',
    selectProvince: 'Province',
    selectCity: 'Select city',
    selectArea: 'select county',
    enterDeviceKey: 'Enter DeviceKey',
    enterFenceName: 'Enter GeoFence name',
    enterPhone: 'Enter phone number',
    enterAgentName: 'Enter agent name',
    enterParentName: 'Enter the enterprise belonging to',
    enterSysAccount: 'Enter login username',
    enterLinkMain: 'Enter contact',
    enterAdd: 'Enter detailed address',
    enterEmail: 'Enter email address',
    enterUserId: 'Enter terminal user ID',
    enterQuestion: 'Enter question description',
    enterAnswer: 'Enter solution',
    selectCountry: 'Please select country or region.',
    enterOldPassword: 'Please enter the old password',
    enterNewPassword: 'Please enter a new password',
    confirmPassword: 'Please confirm your password',
    placeContent: 'Please enter content'
  },
  onlineStatus: {
    title: 'Status',
    offline: 'Offline',
    online: 'Online'
  },
  operatingStatus: {
    title: 'Operating Status',
    inactivated: 'Inactivated',
    normal: 'Normal',
    warn: 'Alert',
    error: 'Fault',
    errorwarn: 'Alert+Fault'
  },
  handleStatus: {
    title: 'Processing Status',
    text1: 'Processed',
    text2: 'Unprocessed'
  },
  login: {
    title: 'Log In',
    codeLogin: 'Log in via SMS verification code.',
    findPassword: 'Forgot password',
    confirmLogin: 'Confirm',
    logging: 'Logging...',
    oauthLogin: 'Authorized login',
    oauthTip: 'Or by the following way',
    place: {
      user: 'Enter username',
      password: 'Enter password',
      code: 'Enter verification code'
    }
  },
  findPassword: {
    title: 'Retrieve Password',
    accountTip: 'Please enter your login account',
    accountPlaceholder: 'username',
    enterAccount: 'Please enter your account',
    noAccount: 'The user name does not exist',
    backLogin: 'Go to login',
    next: 'Next',
    back: 'Back',
    sendTip:
      'A password reset link will be sent to the following email address, please confirm whether you are using the email address',
    sendEmail: 'Sendmail',
    sendSuccess: 'The email has been sent to your mailbox successfully!',
    sendSuccessTip: "If you don't receive an email, be aware of the spam or retry",
    close: 'Close',
    resend: 'Resend',
    mailSource: 'Please note the message from "biz_service@quectel.com"',
    resetPassword: 'Reset Password',
    linkFailure: 'Sorry, reset link is dead!',
    newPasswordLabel: 'New password (6-20 characters of upper and lower case letters and numbers)',
    newPasswordPlaceholder: 'Please enter your new password',
    confirmPasswrodLabel: 'Confirm Password',
    confirmPasswrodPlaceholder: 'Please confirm your new password',
    confirmUpdate: 'Confirm Modification',
    updateSuccess: 'modify successfully',
    goLogin: 'Go to login',
    passwrodTip: 'Please enter a new password',
    IllegalPassword: 'Illegal password',
    inconformityPassword: 'The password entered twice is inconsistent!',
    passwrodAgenTip: 'Please enter your password again',
    notLogin: 'Not Login'
  },
  dashboard: {
    title: 'Overview',
    deviceTotal: 'Total Device',
    deviceActive: 'Active Devices Rate',
    userTotal: 'Total Terminal Users',
    userActive: 'Active Terminal Users Rate',
    allProduct: 'All Products',
    today: 'Today',
    weekDate: 'Last 7 Days',
    monthDate: 'Last 30 Days',
    startDateRange: 'Start Date',
    endDateRange: 'End Date',
    online: {
      title1: 'Online',
      title2: 'Devices Rate',
      onlineDevice: 'Online Device',
      offlineDevice: 'Offline Device',
      tipTitle: 'Device Online Status'
    },
    area: {
      title: 'Regional Distribution of Devices',
      cityTitle: 'City',
      countryTitle: 'Country',
      numTitle: 'Device(s)',
      percentTitle: 'Percentage'
    },
    active: {
      title: 'Line Chart of Daily Active Devices',
      tip: 'Daily Active Devices'
    },
    alert: {
      title: 'Alerts',
      tip: 'Total Alerts of the Day'
    },
    malfunction: {
      title: 'Faults',
      tip: 'Total Faults of the Day'
    },
    dateSelect: {
      today: 'Daily',
      week: 'Weekly',
      month: 'Monthly'
    },
    countType: {
      day: 'Daily',
      month: 'Monthly',
      year: 'Yearly'
    },
    cancelEdit: 'Cancel edit',
    saveEdit: 'Save edit',
    kanbanEdit: 'Dashboard editor',
    kanbanReset: 'Dashboard reset',
    kanbanConfig: 'Dashboard configuration',
    resetSuccess: 'Reset successfully',
    total: 'Total',
    resetTips: 'Resetting the Dashboard will clear your current Dashboard components and layout configuration and restore the system default configuration. Do you want to continue?'
  },
  product: {
    search: {
      connProtocol: 'All Access Protocol',
      dataFormat: 'All Data Format',
      productName: 'Enter product name'
    },
    tableTitle: {
      productName: 'Product Name',
      vendorName: 'Manufacturer',
      connProtocol: 'Access Protocol',
      dataFormat: 'Data Format',
      status: 'Status'
    },
    status: {
      published: 'Published',
      unPublished: 'Unpublished'
    },
    actionBtn: {
      device: 'Devide List',
      function: 'Function Definition'
    },
    queue: 'Message Queue Service',
    queuePlace: 'Enter message queue identifier',
    queueDisable: 'Please disable the queue first',
    queueStatus: {
      title: 'Queue monitor',
      stop: 'Not Started',
      start: 'Running',
      empty: 'Idle',
      enable: 'Start',
      disable: 'Suspend'
    },
    startSuccess: 'Successful starting',
    closeSuccess: 'Successful suspending',
    function: {
      tabAttr: 'Attribute',
      tabEvent: 'Event',
      updateData: 'Refresh',
      noUpdate: 'No updates',
      month: 'month(s) ago',
      week: 'week(s) ago',
      day: 'day(s) ago',
      hour: 'hour(s) ago',
      min: 'minute(s) ago',
      labelManage: 'Label Manage',
      addLabel: 'Add Label',
      editLabel: 'Edit Label',
      batchEdit: 'Batch Editing',
      batchImport: 'Batch Import',
      just: 'Just now',
      label: {
        labelName: 'Label Name',
        setLabelName: 'Please enter label name',
        labelKey: 'Label Identification',
        setLabelKey: 'Please enter the label unique identification'
      },
      attr: {
        tableName: 'Attribute Name',
        tableSubType: 'R/RW',
        tableCode: 'Attribute Identifier',
        tableDataType: 'Data Type',
        tableIcon: 'Icon',
        tableUnit: 'Unit',
        tableDisplay: 'The function attributes are displayed on the device details page',
        tableSort: 'Sort',
        subTypeR: 'Read Only',
        subTypeW: 'Write Only',
        subTypeRW: 'Read and Write',
        placeName: 'Enter attribute name',
        placeIcon: 'Click to select an icon',
        placeUnit: 'Enter unit',
        placeParm: 'Enter parameter name',
        sensorIcon: 'Sensor Icon',
        enableReport: 'Wheter to Enable Report',
        parmName: 'Parameter Name',
        allowControl: 'Allows control of this feature property',
        attrSet: 'AttributeSet'
      },
      event: {
        tableName: 'Event Name',
        batch: 'Batch settings',
        batchWarn: 'Please choose at least one in Table to operate!',
        tableCode: 'Event Identifier',
        tableSubType: 'Event Type',
        tableNotice: 'Notifications',
        warn: 'Alert',
        info: 'Message',
        error: 'Fault',
        unknown: 'Unknown',
        config: 'Configure',
        parmName: 'Parameter Name',
        parmType: 'Parameter Type',
        parmValue: 'Value',
        placeName: 'Enter event name',
        placeParmName: 'Select parameter name',
        placeParmType: 'Enter parameter type',
        dismiss: 'Resolve',
        notice: 'Notification',
        send: 'Send',
        noSend: 'Not Send',
        confirmEmail: 'Are you sure you want to disable email notification for',
        confirmSms: 'Are you sure you want to disable SMS notification for',
        noConfig: "You can't configure it.",
        configSuccess: 'Successful configuration.',
        callerSet: 'Caller settings',
        msgVoicer: 'SMS/Voice Caller',
        phoneError: 'The {0} mobile phone number does not meet the specification',
        emailError: 'The {0} mailbox is out of specification',
        emailr: 'Email recipient:',
        batchLimitWarn: 'You can only add 3',
        msgVoicerTip: 'Please enter the phone number of the caller',
        emailrTip: 'Please enter the email address of the caller',
        atleast: 'Set up at least one email receiver',
        callerNotice:
          'If the alarm/fault message generated by the device needs to be pushed through SMS, voice, or email, please set the recipient information and enable the push switch of the corresponding event.'
      }
    },
    fileImgTip: 'The recommended pixel of the image is 800*800. The image can be a jpg/png file.',
    fileImgSizeTip: 'Upload image size cannot exceed 500kb',
    place: {
      netWay: 'Connection method',
      releaseStatus: 'Release status',
      productName: 'PK/Product name',
      detailPageName: 'Details page'
    },
    deviceNum: 'Device number',
    accessType: 'Equipment type',
    netWay: 'Networking',
    detailPageName: 'Details page',
    category: 'Category',
    propConfig: {
      addField: 'Define a Device Parameter',
      editField: 'Edit the Device Parameter',
      filedName: 'Device Parameter Name',
      filedCode: 'Field Code',
      type: 'Field Type',
      required: 'Required',
      placeFiledName: 'Enter device parameter name',
      placeFiledDefault: 'Please enter the field default',
      desc: 'The self-defined device parameter will be used when you import device(s).Self-defined device parameter records special attributes of device, such as ex-factory date and device unique identification.',
      delConfirm: 'Are you sure you want to delete {0} ?',
      default: 'Default',
      list: 'List',
      search: 'Query'
    }
  },
  notice: {
    language: 'Language',
    tenantName: 'Target Enterprise',
    setDefault: 'Set as Default',

    rules: {
      signName: 'Please input name.'
    },
    sure: {
      sign: 'Are you sure you want to set this signature as <span style="color:#456ff7"> default signature </span>?',
      temp: 'Are you sure you want to set this template as <span style="color:#456ff7"> default template </span>?'
    },
    setSucc: 'Successfully.',
    product: 'Target Product',
    paramMap: 'Parameter Mapping',
    details: 'Details',
    next: 'Next',
    prev: 'Previous',
    sysParams: 'System Parameter',
    commonParams: 'Common Parameter Mapping',
    tslParams: 'TSL Parameter Mapping',
    triggerFault: 'Fault is Triggered',
    triggerAlarm: 'Alert is Triggered',
    dismissFault: 'Fault is Resolved',
    dismissAlarm: 'Alert is Resolved',
    triggerFence: 'GeoFence Alert',

    email: 'Email Notification',
    sms: 'SMS Notification',
    titleEmail: 'Edit Email Template',
    titleSms: 'Edit SMS Receiver',
    titleVoice: 'Edit Voice Template',
    titleApp: 'Edit App Template',
    emailTemplate: 'Email Template',
    templateMgt: 'Templates',
    placeContent: 'Enter content',
    receiver: 'Receiver',
    endUserPush: 'Bound Terminal User',
    orgPush: 'Associated Enterprise',
    fixedPush: 'Sepcific Email Address',
    fixedRecipient: 'Sepcific Recipient',
    recipient: 'Receiver',
    addTemplate: 'Add an Email Template',
    tableSelect: 'Select',
    tableName: 'Name',
    tableContent: 'Content',
    templateName: 'Template Name',
    placeTemplateName: 'Enter Template name',
    placeTemplateContent: 'Enter content',
    templateContent: 'Emial Content',
    insert: 'Insert',
    insertData: 'Insert Data',
    warnContent: 'Alert Information',
    sendDate: 'Sending Date',
    action: 'Action',
    editEmailTemplate: 'Edit Email Template',
    confirm1: 'Are you sure you want to delete',
    confirm2: '?',
    addEmailTemplate: 'Add an Email Template',
    valid: {
      templateType: 'Please select a template category.',
      templateCode: 'Please input template code.',
      language: 'Please select a language.',
      params: 'Please select a parameter.',
      sysParams: 'Please select a system parameter.',
      templateTitle: 'Please input template title.',
      templateId: 'Please select email template',
      template: 'Please select template'
    },
    voice: 'Voice Notification',
    app: 'App Notification',
    sign: {
      title: 'Signature',
      add: 'Add a Signature',
      name: 'Name',
      default: 'Default',
      edit: 'Edit Signature'
    },
    temp: {
      title: 'SMS Template',
      add: 'Add a Template',
      code: 'Template Code',
      typeName: 'Template Category',
      type: {
        item1: 'Triggered Fault',
        item2: 'Resolved Fault',
        item3: 'Triggered Alert',
        item4: 'Resolved Alert',
        item5: 'GeoFence Alert'
      },
      default: 'Default',
      param: 'Parameter',
      details: 'Template Details',
      edit: 'Edit Template',
      tips: 'The template content does not require configuration parameters'
    }
  },
  device: {
    activated: 'Activated Device',
    selectProduct: 'Product',
    selectCompany: 'Select enterprise',
    placeDeviceKey: 'DeviceKey',
    validDeviceKey: 'Please enter 4~32 letters or numbers',
    downDeviceImpTemplate: 'Download Device List Template',
    deviceImpTemplate: 'Device List Template',
    importRecords: 'Device Import Records',
    importTotal: 'Total Imported Device',
    importSuccessNum: 'Successfully Imported Device(s)',
    importFailNum: 'Device(s) Failed to be Imported',
    importStatus: 'Status',
    importTime: 'Date',
    importDevice: 'Import Device',
    importSubmitted: 'Import is submitted.',
    importResults: 'Import Result',
    importDeviceDetails: 'Device Import Results',
    processFinish: 'Processed',
    processing: 'Processing',
    downDetails: 'Download Import Results',
    confirmDelDevice: 'Are you sure you want to delete?',
    deviceName: 'Device Name',
    deviceSn: 'Serial Number',
    orgName: 'Enterprise Belonging To',
    lastOnlineTime: 'Previous Online Time',
    listField: 'Listed Parameter',
    customField: 'Self-defined Device Parameter',
    listFieldFilter: 'Set Listed Parameter',
    deviceDtl: 'Device Details',
    locationInfo: 'Locations',
    deviceAttr: 'Device Attributes',
    unfold: 'Show',
    putAway: 'Hide',
    basicInfo: {
      deviceKey: 'DeviceKey',
      name: 'Device Name',
      sn: 'Serial Number',
      modelSpec: 'Module Model',
      activationTime: 'Activation Date',
      editInfo: 'Edit information',
      valid: {
        deviceName: 'Device name',
        deviceSn: 'Device SN'
      }
    },
    baseInfo: {
      title: 'Basic'
    },
    portInfo: {
      title: 'Port'
    },
    orderInfo: {
      title: 'Order'
    },
    location: {
      title: 'Location',
      deviceNo: 'DeviceKey',
      lal: 'Coordinates',
      locate: 'Location',
      view: 'View',
      time: 'Positioning Time',
      targeting: 'Positioning System',
      signal: 'Signal Strength',
      satellite: 'Number of Satellites',
      state: {
        title: 'Status',
        offline: 'Offline',
        online: 'Online'
      }
    },
    mileage: {
      title: 'Mileage Report',
      placeMonth: 'Select month',
      date: 'Date',
      milage: 'Mileage',
      day: 'Day'
    },
    alarm: {
      title: 'Alarm'
    },
    setInstruction: {
      title: 'Send Command',
      attribute: 'Set Attribute',
      sendControl: 'Send Command',
      curValue: 'Current Value',
      setValue: 'Configured Value',
      msg1: 'Successful sending.',
      msg2: 'Please select a attribute.',
      msg3: 'Please assign a value to the property.',
      msg4: 'Please fill in the set value.'
    },
    sim: {
      title: 'SIM Card',
      supplier: 'Operator',
      setMeal: 'Data Package',
      networkType: 'Network Type',
      active: 'Activation Status',
      activateTime: 'Activation Date',
      expiryDate: 'Billing Cycle End Date',
      status: 'Status',
      flow: 'Data Used in Current Month',
      residueFlow: 'Data Available',
      cardType: 'SIM Card Type',
      setmealmonths: 'Package Cycle (Unit: Month)',
      cardOrderNo: 'Order Number',
      cardProductName: 'Product Name',
      cardOperatorName: 'Operator',
      createTime: 'Order Date',
      cardFlow: 'Package Size',
      cardSetmealMonths: 'Package Cycle',
      cardRenewalCycle: 'Actual Renewed Packages',
      cardProductPrice: 'Unit Price',
      cardRenewalAmount: 'Order Amount',
      cardOrderType: {
        title: 'Order Type',
        text1: 'Data Package Renewal',
        text2: 'Recharge Data Credit'
      },
      cardOrderStatus: {
        title: 'Order Status',
        text1: 'Recharging',
        text2: 'Recharged',
        text3: 'Recharge failed',
        text4: 'Refunding',
        text5: 'Refunded',
        text6: 'Refund failed'
      },
      yuan: 'RMB',
      retry: 'Retry',
      refund: 'Refund',
      paymentType: {
        title: 'Payment Type',
        text1: 'WeChat Pay',
        text2: 'Ali Pay',
        text3: 'Balance Pay'
      },
      completeTime: 'Completion Time',
      placeOrderNo: 'Order number',
      placeIccid: 'ICCID',
      placeOrderStatus: 'Order status',
      deviceKey: 'DeviceKey'
    },
    map: {
      all: 'All',
      track: 'Trajectory-Based Dynamic Map',
      noLocation: 'No location informaiton available.',
      msg1: 'Successful following.',
      msg2: 'Successful unfollowing.',
      collectStatus: {
        title: 'Following Status',
        text1: 'Follow',
        text2: 'Unfollow',
        text3: 'Not Follow'
      }
    },
    trackPlay: {
      distance: 'Total Mileage',
      speed: 'Speed',
      tips: 'Tips: Up to 3 consecutive days records are available.',
      stayMark: 'Residence Time Threshold',
      timeSelect: 'Date',
      showFence: 'Show GeoFence',
      trackLine: 'Trajectory Map',
      trackDetail: 'Trajectory Details',
      soc: 'SOC',
      hdop: 'GPS Information',
      noFence: 'No GeoFence.',
      timeTips: 'Please select 3 consecutive days.',
      drivingTips: 'No driving records during the selected period.',
      getAdd: 'Clic to Obtain Address'
    },
    fence: {
      fenceName: 'GeoFence Name',
      triggerConditions: 'Triggering Event',
      fenceType: 'Geofence Shape',
      effectiveType: 'Effective Time',
      effectiveType0: 'Long Term',
      effectiveType1: 'Time Range',
      notice: 'Notifice Method',
      isEnabled: 'Enable/Disable',
      createTime: 'Creation Date',
      viewDevice: 'Device',
      sure: 'Are you sure you want to delete the GeoFence?',
      emailTips: 'Please configure email notification firstly.',
      smsTips: 'Please configure SMS noticationn firstly.',
      voiceTips: 'Please configure Voice noticationn firstly.',
      pushTips: 'Please configure App noticationn firstly.',
      bindDevice: 'Bind Device',
      unbindDevice: 'Unbind Device',
      batchAdd: 'Bulk Add',
      deviceTips: 'Please select a device firstly.',
      startTime: 'Start Date',
      endTime: 'End Date',
      drawTips1: 'Click to add a point to the route geofence. Double-click to end drawing.',
      drawTips2: 'Drag the route with the mouse. Release the mouse to end drawing.',
      polygon: 'Polygon',
      round: 'Circular',
      empty: 'Clear',
      hefei: 'Hefei',
      drawTips: 'Please draw the geofence firstly.',
      openTimeTips: 'Please enable a range firstly.',
      effectTimeTips: 'Please select the range of effective time.',
      clearFence: 'Clear',
      saveFence: 'Save',
      trigger: {
        item1: 'Geofence Exit Event',
        item2: 'Geofence Enter Event',
        item3: 'Geofence Enter/Exit Event',
        item4: 'Closed'
      }
    },
    checkFile: 'Please import "xls" or "xlsx" format files!',
    sn: 'Generate SN',
    generateSum: 'Number of generations',
    snValid: 'The generated quantity cannot be empty',
    snMsgSuccess: 'Generated successfully!',
    snList: 'SN List',
    add: 'Add device',
    singleAdd: 'Single Add',
    batchImport: 'Batch Import',
    run: {
      title: 'Device Statistics',
      more: 'More',
      newChart: 'Make a Chart',
      heading: 'Title',
      dataType: 'Data Type',
      chartType: 'Chart Type',
      yunit: 'Y-axis (Unit)',
      dataSource: 'Data Source',
      countType: 'Statistical Method',
      place: {
        title: 'Enter title.',
        dataType: 'Select data type.',
        chartType: 'Select chart type.',
        dataSource: 'Se;ect data source.',
        countType: 'Select statistical method.'
      },
      singleChoice: 'Single-choice',
      multipleChoice: 'Multiple-choice',
      dataTypeOptions: {
        item1: 'Bool Type',
        item2: 'Integer Type',
        item3: 'Enumeration Type'
      },
      chartTypeOptions: {
        item1: 'Column Chart',
        item2: 'Line Chart'
      },
      countTypeOptions: {
        item1: 'Maximum Value',
        item2: 'Minimum Value',
        item3: 'Average Value',
        item4: 'Non-aggregated Data',
        item5: 'Total Quantity'
      },
      editChart: 'Edit the Chart'
    }
  },
  agent: {
    recovery: {
      record: 'Recycling records',
      title: 'Recycling equipment',
      btnName: 'Recycle',
      tsCreateTime: 'Recovery time'
    },
    allocationRecord: 'Device Distribution Records',
    agentName: 'Agent Name',
    parentName: 'Enterprise Belonging To',
    attribution: 'Location',
    linkman: 'Contact',
    linkphone: 'Phone Number',
    allocationDevice: 'Distribute Device',
    addAgent: 'Add an Agent',
    sysAccount: 'System Account',
    loginAccount: 'Login Username',
    sysAccountTips: 'Please create a login username for your agent.',
    assignProduct: 'Distribute Product',
    address: 'Detailed Address',
    email: 'Email',
    emailTips: 'The Email will be used to receive the initial password and reset the password',
    uploadTips: 'Import DeviceKey through excel file to distribute the device to agent.',
    downTemplate: 'Download Device List Template',
    deviceAllocation: 'Device Distribution',
    deviceAllocated: 'Distributed Device',
    activation: 'Activate',
    opStatus: {
      title: 'Company Status',
      item1: 'Normal',
      item2: 'Suspended'
    },
    manual: 'Manual assignment',
    batch: 'Batch allocation',
    assignedSucc: 'Allocation is successful!',
    importTotal: 'Total',
    importSuccessNum: 'Successfully',
    importFailNum: 'Failed',
    importStatus: 'Status',
    importTime: 'Time',
    type: 'Operation method'
  },
  user: {
    userId: 'User ID',
    avatar: 'Profile Photo',
    nickname: 'Name',
    source: 'Source',
    address: 'Address',
    createTime: 'Participation Date',
    uploadTips: 'Are you sure you want to export all roles information?',
    lastLoginTime: 'Previous Online Time',
    boundDevice: 'Bound Deivce',
    unbindTips: 'Are you sure you want to unbind the device?',
    feedbackUser: 'Sender',
    opinionName: 'Subject',
    feedback: 'Description',
    feedbackImg: 'Picture',
    feedbackTime: 'Receiving Date',
    attrProduct: 'Product',
    question: 'Question Description',
    answer: 'Solution',
    addQuestion: 'Add a Question',
    gender: {
      title: 'Gender',
      item1: 'Male',
      item2: 'Female',
      item3: 'Secret'
    },
    profile: {
      personalInfo: 'Personal Information',
      sysAccount: 'System Account',
      nickName: 'The user Nickname',
      phoneNumber: 'Mobile Phone Number',
      email: 'User Mailbox',
      department: 'Subordinate Departments',
      ownedRole: 'Role',
      basicInfo: 'Basic Information',
      changePassword: 'Change The Password',
      resetPwd: {
        oldPassword: 'The Old Password',
        newPassword: 'The New Password',
        confirmPassword: 'ConfirmPassword'
      },
      userAvatar: {
        uploadAvatarTitle: 'Clikc to upload profile photo',
        select: 'Select',
        submit: 'Submit',
        modifyAvatar: 'Change Profile Photo'
      },
      cancelEdit: 'Cancel Editor',
      editInfo: 'Edit Information',
      saveInfo: 'Save Information',
      createTime: 'Creation Time'
    },
    sysLanguage: 'Language',
    placeSysLanguage: 'Select a language to use',
    timeZone: 'Time Zone',
    placeTimeZone: 'Select time zone',
    adaptive: 'Set Time Zone Automatically',
    langTips: 'We will use the language you set to send notifications such as emails, SMS, etc., such as alerts and fault messages.'
  },
  system: {
    userId: 'User ID',
    nickName: 'Username',
    userPwd: 'Password',
    role: 'Role',
    importUpdateTips: 'Whether to refresh the existing data.',
    downTemp: 'Download Template',
    validPwd: 'Password must be 6-20 characters and contain both numbers and letters/special characters.',
    validNickName: 'Username must be Chinese characters, letters or numbers.',
    userMa: '?',
    addUser: 'Add an Account',
    editUser: 'Edit the Account',
    resetPwdTip1: 'Please enter',
    resetPwdTip2: 'a new password.',
    resetPwdTip3: 'Successful changing. The new password is',
    delUserTips: 'Are you sure you want to delete',
    exportTips: 'Are you sure you want to the data?',
    userImport: 'Import Accounts',
    roleName: 'Role Name',
    roleKey: 'Role Identifier',
    labelCode: 'Role tag',
    roleStatus: 'Role Status',
    roleId: 'Role ID',
    roleDefault: 'Default Role',
    roleSort: 'Display Order',
    dataPermission: 'Data Access Permission',
    menuPermission: 'Menu Access Permission',
    loading: 'Loading...',
    autScope: 'Permissions',
    dataScope1: 'All Data',
    dataScope2: 'Custom Data',
    dataScope3: 'Data of the Department',
    dataScope4: 'Data of the Department and its Subordinate(s)',
    dataScope5: 'Self-owned Data Only',
    validRoleName: 'Role name can not be empty.',
    validRoleKey: 'Role identifier can not be empty.',
    validRoleSort: 'Display order can not be empty.',
    roleMa: '?',
    addRole: 'Add a Role',
    editRole: 'Edit the Role',
    allocationDataPermission: 'Assign Data Access Permission',
    delRoleTips: 'Are you sure you want to delete',
    leader: 'Person in Charge',
    userStatus: {
      title: 'Status',
      item1: 'Normal',
      item2: 'Suspended'
    },
    place: {
      nickName: 'Username',
      roleName: 'Role name',
      roleKey: 'Role identifier',
      userPwd: 'Password',
      leader: 'Person in charge',
      selectRole: 'Role'
    },
    dashboard: {
      globalParams: 'Global parameters',
      dataSourceName: 'Data source name',
      reqUrl: 'Request url',
      reqWay: 'Request method',
      dataType: 'Type of data',
      parameter: 'Parameter',
      createParameters: 'Create parameters',
      parameterName: 'Parameter name',
      paramCode: 'Parameter identification',
      nameCn: 'Chinese name',
      nameEn: 'English name',
      staticParameters: 'Static parameters',
      dynamicParameters: 'Interface request parameters',
      configStaticParameters: 'Configure static parameters',
      showValueCode: 'Display parameters',
      actualValueCode: 'Actual parameter',
      defaultValue: 'Defaults',
      place: {
        isShow: 'Please select whether to show',
        reqWay: 'Please select the request method',
        dataType: 'Please select data type',
        paramCode: 'Please enter the parameter ID',
        nameCn: 'Please enter Chinese name',
        nameEn: 'Please enter an English name',
        dataName: 'Please enter the data source name',
        reqUrl: 'Please enter the interface address',
        pageSize: 'Please enter the number of displays',
        cardType: 'Please select card type',
        cardStyle: 'Please select a card style',
        fieldCnName: 'Please fill in the Chinese name of the interface field',
        fieldEnName: 'Please fill in the English name of the interface field'
      },
      typeOptions: {
        0: 'Bar chart/Curve chart',
        1: 'Pie chart',
        2: 'Map',
        3: 'Number',
        4: 'List',
        5: 'Ranking'
      },
      addDataSource: 'New data source',
      pageNumberItem: 'Number of display items per page',
      showNumber: 'Display number',
      pageSize: 'Number of pagination',
      editParameters: 'Edit parameters',
      editDataSource: 'Edit data source',
      addGlobalParameters: 'Add global parameters',
      editGlobalParameters: 'Edit global parameters',
      cardName: 'Card name',
      cardStyle: 'Card style',
      cardCnName: 'Chinese name',
      cardEnName: 'English name',
      jumpUrl: 'Jump url',
      cardType: 'Card type',
      dataSourceConfig: 'Data source configuration',
      cardTypeOptions: {
        0: 'Histogram',
        1: 'Graph',
        2: 'Pie chart',
        3: 'Map',
        4: 'Digital card',
        5: 'Card deck',
        6: 'List',
        7: 'Ranking'
      },
      addCard: 'Add card',
      editCard: 'Edit card',
      index: 'Index',
      selectDataSource: 'Select data source',
      selectIcon: 'Select icon',
      fieldMapping: 'Interface field mapping',
      refreshField: 'Refresh field',
      fieldName: 'Field name',
      color: 'Colour',
      most4Index: 'Add up to 4 indicators',
      leftIndicator: 'Left indicators',
      leftSecIndicator: 'Left two indicators',
      mapIndicators: 'Map indicators',
      rankingIndex: 'Ranking indicators',
      upperIndex: 'The upper indicator',
      centralIndex: 'The central index',
      lowerIndex: 'The lower indicators'
    }
  },
  company: {
    companySetting: 'Enterprise Setting',
    platformCustomSetting: 'Customization',
    dataChannelSetting: 'Data Channel',
    paymentSetting: 'WeChat Pay',
    notificationSetting: 'Notifications',
    thirdPartyAccessSetting: 'Third-party Access',
    securitySetting: 'Security Policy',
    connectionSucceeded: 'Successful connection.',
    ConnectionTest: 'Connecting Test',
    notice: {
      title: 'Notifications',
      emailConfig: 'Email Address',
      senderNickname: 'Sender\'s Name',
      emailAccount: 'Sender\'s Email',
      emailPassword: 'Sender\'s Email Authorization code',
      smtpServer: 'SMTP Server',
      smtpPort: 'SMTP Port',
      sceneConfig: 'Options',
      warningNotice: 'Alerts',
      faultNotice: 'Faults',
      fenceNotice: 'GeoFence Alerts',
      validSenderNickname: 'Sender\'s name can not be empty.',
      validEmailAccount: 'Sender\'s email can not be empty.',
      validSmtpServer: 'SMTP server can not be empty',
      validSmtpPort: 'SMTP port can not be empty',
      validWarningNotice: 'Please set whether to support sending Alerts through email notification.',
      validFaultNotice: 'Please set whether to support sending Faults through email notification.',
      validFenceNotice: 'Please set whether to support sending GeoFence Alerts through email notification.',
      smsSign: 'SMS signature',
      appPush: 'APP push notification configuration',
      validAppKeyIos: 'AppKeyIOS cannot be empty',
      validAppKeyAndroid: 'AppKeyAndroid cannot be empty',
      validIosApnsEnv: 'Please choose IOSApnsEnv',
      validProductId: 'Please choose the product'
    },
    oemConf: {
      enable: 'Enterprise Customization',
      templateSelection: 'Login Form',
      officialWebsite: 'Official Website',
      configureDomain: 'Domain',
      personalizedDomain: 'Hostname Only',
      customize: 'Custom Domain',
      recordQuery: 'Verify Domain',
      siteName: 'Website Name',
      loginPageLogo: 'Logo for Login Page',
      tipUploadLogo: 'Click to upload logo',
      systemInsideBrightLogo: 'Bright color logo',
      systemInsideDarkLogo: 'Dark color logo',
      websiteIcon: 'Website Icon',
      tipUploadFavicon: 'Click to upload website Icon',
      loginBgImage: 'Login Background Image',
      tipUploadBgImage: 'Click to upload login background image',
      logo: {
        size: 'Size: 100*100px',
        format: 'Format: JPG,PNG'
      },
      ico: {
        size: 'Size: 32*32px',
        format: 'Format: ICO'
      },
      uploadBg: {
        size: 'Size: 1920*970px',
        format: 'Format: JPG,PNG'
      },
      siteDescription: 'Website Description',
      websiteKeywordsDesc: 'Website Keywords (Multiple keywords separated by English commas.)',
      copyrightStatement: 'Copyright',
      caseNumber: 'ICP Number',
      basicConfig: 'Basic configuration',
      advancedConfig: 'Advanced configuration',
      enableAdvancedConfig: 'Enable advanced configuration'
    },
    pay: {
      wechatPay: 'WeChat Pay',
      businessNumber: 'Merchant ID',
      businessKey: 'API Key',
      CertificateUpload: 'Certificate',
      validAppid: 'App ID can not be empty.',
      validMchId: 'Merchant ID can not be empty.',
      validMchKey: 'API Key can not be empty.',
      validCertificateUrl: 'Please upload a certificate.',
      validTradeType: 'Please select payment type.',
      validReferer: 'Please enter authroized domain.',
      tradeType: {
        title: 'Payment Type',
        item1: 'Mobile Payment',
        item2: 'Official Account Payment/Mini Program Payment',
        item3: 'H5 Payment'
      },
      referer: 'Authorized Domain'
    },
    safe: {
      title: 'Security Policy',
      loginCaptcha: 'Login Verification Code',
      loginLock: 'Account Lockout',
      ruleSet: 'Lockout Policy',
      ruleSetText1: 'minute(s), after',
      ruleSetText2: 'invalid login attempts, your account will be locked for',
      ruleSetText3: 'minute(s).',
      authCodeLogin: 'Login via SMS Verification Code',
      resetPwd: 'Forgot Password',
      dmpOauthLogin: 'Login via Quectel DMP Account',
      validLoginCaptcha: 'Please select whether to enable login verification code.',
      validLoginLock: 'Please select whether to enable account lockout.',
      validTimeRange: 'Please enter a given time range.',
      validFailNum: 'Please specify number of invalid sign-in attempts.',
      validLockTime: 'Please enter lockout duration.',
      validAuthCodeLogin: 'Please select whether to enable login via SMS verification code.',
      validResetPwd: 'Please select whether to enable login password resetting.',
      validDmpOauthLogin: 'Please select whether to enable login via Quectel DMP Account.'
    },
    third: {
      title: 'Third-party Access',
      appKey: 'Private Key',
      validAppId: 'App ID can not be empty.',
      validAppKey: 'Private key can not be empty.',
      validAppToken: 'Token can not be empty.',
      validAesKey: 'AesKey can not be empty.'
    },
    sim: {
      title: 'QuecConnectivity Authorization',
      validKey: 'AppKey can not be empty.',
      validSecret: 'AppSecret can not be empty.'
    }
  },
  gdpr: {
    title: 'Quectel Privacy Agreement/Quectel Service Agreement',
    time1: 'Last Updated: July 21, 2021',
    time2: 'Effective Date: July 21, 2021',
    item1: 'Quectel Privacy Agreement',
    item2: 'Quectel Service Agreement',
    text: 'I have read and agree to Quectel Privacy Agreement and Quectel Service Agreement.',
    agree: 'Agree',
    refuse: 'Disagree',
    validGdpr: 'You must read and agree to the privacy policy and service agreement to continue.',
    success: 'You have read and agree to Quectel Privacy Agreement and Quectel Service Agreement.',
    logout: 'Disagree means you will log out the system. continue?'
  },
  tagsView: {
    refresh: 'Refresh',
    closeCurrent: 'Close the Window',
    closeOthers: 'Close Other Windows',
    closeRight: 'Close Right Window',
    closeAll: 'Close All Window'
  },
  oauth: {
    title: 'User information entry',
    desc: 'The first time you use the system, you need to add the following information to log in to the system account, receive notification information, and retrieve your password',
    userName: 'Account',
    password: 'Platform login password',
    phonenumber: 'Bind mobile phone number',
    email: 'Bind Email',
    submit: 'Submit and login'
  },
  dict: {
    dictName: 'Dictionary name',
    dictType: 'Dictionary type',
    status: 'Dictionary status',
    place: {
      dictName: 'Dictionary name',
      dictType: 'Dictionary type',
      dictLabel: 'Dictionary tag',
      dataLabel: 'Data label',
      dataValue: 'Data key value'
    },
    valid: {
      dictName: 'Dictionary name cannot be empty',
      dictType: 'The dictionary type cannot be empty',
      dataLabel: 'Data label cannot be empty',
      dataValue: 'Data key value cannot be empty',
      dataSort: 'Data sequence cannot be empty'
    },
    clearCache: 'Clear cache',
    dictId: 'Dictionary number',
    addTitle: 'Add dictionary type',
    editTitle: 'Modify dictionary type',
    delTips: 'Are you sure to delete this data item?',
    exportTips: 'Are you sure to export all types of data items?',
    clearCacheSuccess: 'Cleaned up successfully',
    dictLabel: 'Dictionary tags',
    dataLabel: 'Data label',
    dataStatus: 'Data status',
    dictCode: 'Dictionary encoding',
    dictValue: 'Dictionary key',
    dataValue: 'Data key',
    dictSort: 'Dictionary sort',
    dataSort: 'Data sorting',
    addDictData: 'Add dictionary data',
    editDictData: 'Modify dictionary data'
  },
  config: {
    place: {
      configName: 'Parameter name',
      configKey: 'Parameter key name',
      configValue: 'Parameter key value'
    },
    valid: {
      configName: 'The parameter name cannot be empty',
      configKey: 'The parameter key name cannot be empty',
      configValue: 'The parameter key value cannot be empty'
    },
    configName: 'Parameter name',
    configKey: 'Parameter key name',
    configType: 'Built-in system',
    configId: 'Parameter primary key',
    configValue: 'Parameter key value',
    addConfig: 'Add parameters',
    editConfig: 'Change parameters'
  },
  companyList: {
    userName: 'Account',
    linkPhoneNumber: 'Telephone',
    viewProduct: 'View product',
    role: 'Rights Profile',
    vendorRole: 'Vendor authority',
    agencyRole: 'Agency authority',
    proPage: 'Product personality page',
    editRole: 'Edit authority'
  },
  rightToolbar: {
    hideSearch: 'Hide search',
    showSearch: 'Show search',
    refresh: 'Refresh',
    showColumn: 'Explicitly hidden',
    show: 'Show',
    hide: 'Hide',
    showOrHide: 'Show/Hide'
  },
  dataFormatOptions: {
    item1: 'Transparent Transmission/Self-defined',
    item2: 'JSON (Standard Data Format)'
  },
  group: {
    name: 'Name',
    address: 'Address',
    person: 'Person in Charge',
    batchSet: 'Set Device in Batches',
    managerDevice: 'Manage Device',
    setPerson: 'Set Person in Charge',
    enterName: 'Enter the name of the device installation center.',
    enterNameLengthLimit: 'Name cannot be more than 256 bytes long.',
    enterAdress: 'Enter the address of the device installation center.',
    enterAdressLengthLimi: 'Address cannot be more than 1024 bytes long.',
    deleteTips: 'Are you sure you want to delete the device installation center {name}?',
    product: 'Control Product',
    bind: 'Binding',
    unbind: 'Unbundling',
    deviceKey: 'DeviceKey',
    deviceName: 'Device name',
    productName: 'Product Name',
    send: 'send'
  },

  person: {
    add: 'Add a Person in Charge',
    name: 'Name',
    mobile: 'Phone Number',
    smsNotice: 'SMS Notification',
    voiceNotice: 'Voice Notification',
    enterName: 'Enter name',
    enterNameLengthLimit: 'Name cannot be more than 50 characters long.',
    enterMobile: 'Eneter phone number',
    enterAdressLengthLimi: 'Please enter a valid phone number.',
    deleteTips: 'The deleted person in charge are not reachable to the associated device installation center. Are you sure you want to delete the person in charge?'
  },

  scooter: {
    operate: {
      storeName: 'Store Name',
      addStore: 'Add a Store',
      area: 'City',
      address: 'Address',
      businessTime: 'Business Hours',
      contactWay: 'Contact',
      storeImg: 'Photo',
      locationView: 'Geo-Location',
      storeLocation: 'Geo-Location',
      agent: 'Agent',
      shopOwner: 'Manager',
      telephone: 'Contact',
      areaSelect: 'City and Area',
      countryOrRegion: 'Country or Region',
      specificLocation: 'Detailed Address',
      uploadTips: 'The recommended pixel of the image is 600*600. The image can be a jpg/jpeg/png file and the size is limited within 200kb.',
      remark: 'Remarks',
      notFoundAdd: 'The address is not found on the map.',
      selectStoreTips: 'Please select the store address from the dropbox of Detailed Address.',
      editSuccess: 'Successful edition.',
      uploadImgTips: 'Wrong file format. The image can only be a jpg/jpeg/png file.',
      uploadImgSizeTips: 'Wrong file size. The image size is limited within 200kb.',
      stolenAdd: 'Where',
      reporterName: 'Reporter',
      loseRemark: 'Additional Information',
      loseTime: 'When',
      loseAddress: 'Where',
      realTimeTrack: 'Real-time Tracking',
      addLose: 'Report a New Theft/Lost',
      addManual: 'Add Manual',
      carManual: 'Vehicle Manual',
      down: 'Download',
      uploadFormatTips: 'Wrong file format. Only PDF file is supported.',
      uploadSizeTips: 'Wrong file size. The file size is limited within',
      uploadedTips: 'Please remove the existing file before uploading a new file.',
      vin: 'Vehicle Identification Number',
      devType: 'Vehicle Model',
      user: 'Terminal User',
      repairStore: 'Store Name',
      questionImg: 'Pictures',
      reportTime: 'Request Time',
      addRepair: 'Add a Repair Request',
      tenantName: 'Manufacturer',
      place: {
        enterStoreName: 'Enter store name',
        enterShopOwner: 'Enter manager name',
        enterSpecificLocation: 'Enter detailed address',
        enterRemark: 'Enter remarks',
        enterDevType: 'Enter vehicle model',
        startTime: 'Select start date',
        endTime: 'Select end date',
        selectTheft: 'Select theft/lost device type',
        selectDevManual: 'Select vehicle manual'
      },
      loseType: {
        title: 'Theft/Lost Device Type',
        item1: 'Vehicle',
        item2: 'Battery'
      }
    }
  },

  // 统一文件管理
  upload: {
    tagsTitle: 'My Tags',
    queryParams: {
      fileType: 'File type',
      fileName: 'File name',
      fileTag: 'Select a tag',
      uploadBtn: 'Upload',
      file: 'Select a file',
      enter: 'Input tag name and click "Enter" to add a new tag'
    },
    table: {
      typeObj: {
        1: 'Image',
        2: 'Document',
        3: 'Audio File',
        4: 'Video File',
        5: 'Compressed Files',
        6: 'Other'
      },
      fileType: 'File Type',
      fileTag: 'Tag',
      view: 'Preview',
      fileName: 'File Name',
      fileSize: 'Size',
      mimeType: 'Mime Type',
      select: 'Select',
      dest: 'File storage location'
    },
    preview: 'Image Preview',
    addFiles: 'Add a File',
    fileSelection: 'Select a File',
    validNull: 'Do not upload empty files'
  },
  // 批量任务
  task: {
    running: 'Running Task',
    queryParams: {
      taskType: 'Select task type',
      batchTaskStatus: 'Select batch job status'
    },
    table: {
      batchTaskNo: 'Batch ID',
      taskType: 'Task Type',
      totalNum: 'Total Tasks',
      successNum: 'Number of Completed Tasks',
      failNum: 'Number of Failed Tasks',
      tsFinishTime: 'Date of Completion',
      batchTaskStatus: 'Batch Job Status',
      timeUsed: 'Duration',
      batchTaskProgress: 'Progress',
      taskTypeOptions: {
        1: 'Device Import',
        2: 'Device Distribution'
      },
      batchTaskStatusOptions: {
        1: 'Preparing',
        2: 'Waiting',
        3: 'Processing',
        4: 'Result'
      }
    },
    taskDetails: {
      table: {
        taskTag: 'Task Identifier',
        taskStatus: 'Task Status',
        errorMsg: 'Error Info',
        dataPayload: 'Payload Data',
        taskStatusOptions: {
          0: 'All',
          1: 'Waiting to Run',
          2: 'Running',
          3: 'Completed',
          4: 'Failed'
        }
      }
    }
  },
  // 登录验证
  validation: {
    title: 'Please complete the security check to access Quectel AEP',
    toRight: 'Swipe right to complete verification',
    successful: 'Verification Successful',
    failure: 'Verification Failed'
  },
  // app版本管理
  appmanage: {
    versionType: 'Type',
    appVersion: 'App Version',
    plappVersion: 'App Version can not be empty.',
    downloadAddress: 'Download Address',
    pldownloadAddress: 'Download address can not be empty.',
    versionDetail: 'Version Details',
    plversionDetail: 'Version details can not be empty.',
    publishState: 'Status',
    verify: 'Verify Account',
    verifyManage: 'Account Verification',
    rulapp: 'App version number is started with V and only supports numbers and the character ".". The format is xx.xx.xx.',
    state0: 'Wait for verification',
    state1: 'Published',
    state2: 'Sold out',
    delete01: 'Are you sure you want to delete this version?',
    publish01: 'Are you sure you want to release this version?',
    deletever: 'Are you sure you want to delete the authentication account?',
    nofile: 'No files are selected.'

  },
  unit: {
    sec: 'sec',
    min: 'min',
    hour: 'hour',
    day: 'day',
    month: 'month',
    year: 'year',
    watts: 'watts',
    kWh: 'kWh',
    yuan: 'RMB',
    times: 'times'
  },
  // 品类管理
  category: {
    title: 'Product category management',
    addBtn: 'Add category',
    chooseCategory: 'Category Choose',
    detailBtn: 'Detail management',
    tableLevel1: 'First class',
    tableLevel2: 'Secondary category',
    detailName: 'Detail name',
    webDetailName: 'Web detail name',
    appDetailName: 'App detail name',
    saveValidateError: 'Please complete the form content',
    canNotNull: 'Can not null',
    categoryDialogEdit: 'Edit Product Category',
    categoryDialogAdd: 'Add Product Category',
    noSelectCategorySub: 'Please select a subcategory'
  }
}
