import { Routes } from '@/router'
import path from 'path'
import { getRouters } from '@/api/menu'
import ParentView from '@/components/ParentView'
/* Layout */
import aepLayout from '@/layout'
import store from '@/store'

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: [],
    firstRouters: ''
  },
  getters: {
    // 获取所有有权限的path
    routeNameAll (state) {
      let all = []
      const fun = (routes, basePath = '/') => {
        routes.forEach(route => {
          const p = path.resolve(basePath, route.path)
          if (route.children) fun(route.children, p)
          else all.push(route.name)
        })
      }
      fun(state.routes)
      return all
    },
    // 获取第一个有权限，且可显示的path
    firstPermRoute (state) {
      let r
      const fun = (routes, basePath = '/') => {
        if (r) return
        for (const route of routes) {
          if (route.hidden === false && r === undefined) {
            const p = path.resolve(basePath, route.path)
            if (route.children) fun(route.children, p)
            else r = p
          }
        }
      }
      fun(state.routes)
      return r
    }
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = Routes.concat(routes)
    },
    SET_SIDEBAR_ROUTERS: (state, routers) => {
      state.sidebarRouters = Routes.concat(routers)
    },
    SET_FIRST_ROUTERS: (state, firstRouters) => {
      state.firstRouters = firstRouters
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes ({ commit }) {
      return new Promise((resolve, reject) => {
        // 向后端请求路由数据
        getRouters({ saas: store.getters.saas }).then(res => {
          const sdata = JSON.parse(JSON.stringify(res.data))
          const rdata = JSON.parse(JSON.stringify(res.data))
          const sidebarRoutes = filterAsyncRouter(sdata)
          const rewriteRoutes = filterAsyncRouter(rdata, false, true)
          rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
          if (res.data.length > 0) {
            let newSideBarRoutes = [...sidebarRoutes]
            for (let i = 0; i < newSideBarRoutes.length; i++) {
              let cur = newSideBarRoutes[i]
              cur.children && cur.children.sort(function (a, b) {
                if (Number(a.orderNum) < Number(b.orderNum)) {
                  return -1
                } else if (Number(a.orderNum) === Number(b.orderNum)) {
                  return 0
                } else {
                  return 1
                }
              })
            }
            newSideBarRoutes.sort(function (a, b) {
              if (Number(a.orderNum) < Number(b.orderNum)) {
                return -1
              } else if (Number(a.orderNum) === Number(b.orderNum)) {
                return 0
              } else {
                return 1
              }
            })
            commit('SET_FIRST_ROUTERS', sdata?.[0]?.children?.[0].name)
            commit('SET_ROUTES', rewriteRoutes)
            commit('SET_SIDEBAR_ROUTERS', newSideBarRoutes)
            resolve(rewriteRoutes)
          } else {
            reject(rewriteRoutes)
          }
        })
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter (asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        // 行业应用是否采用AEP的Layout风格
        let Layout = aepLayout
        route.component = Layout
      } else if (route.component === 'ParentView') {
        route.component = ParentView
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route.children
      delete route.redirect
    }
    return true
  })
}

function filterChildren (childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

export const loadView = (view) => { // 路由懒加载
  if (view.indexOf('views/') === -1) {
    return (resolve) => require([`@/views/${view}`], resolve)
  } else {
    return (resolve) => require([`@/app/${view}`], resolve)
  }
}

export default permission
