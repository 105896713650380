let choiceDate = ''

export const dateValid = {
  // 今天之后date
  todayAfterTime: {
    disabledDate (time) {
      return time.getTime() < Date.now() - 8.64e7
    }
  },
  // 今天之前
  todayBefore: {
    disabledDate (time) {
      let _beforeDay = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      return time.getTime() >= _beforeDay
    }
  },
  // 今天之前 && 30天以内（轨迹回放需要）
  todayBeforeAndMonth: {
    disabledDate (time) {
      let _beforeDay = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      return time.getTime() >= _beforeDay || time.getTime() < Date.now() - 3600 * 1000 * 24 * 30
    }
  },
  // 今天之前任意30天
  todayBeforeThirty: {
    onPick: ({ maxDate, minDate }) => {
      choiceDate = minDate.getTime()
      if (maxDate) {
        choiceDate = ''
      }
    },
    disabledDate: (time) => {
      let _beforeDay = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      if (choiceDate) {
        const one = 30 * 24 * 3600 * 1000
        const minTime = choiceDate - one
        const maxTime = choiceDate + one
        return time.getTime() >= _beforeDay || time.getTime() < minTime || time.getTime() > maxTime
      } else {
        return time.getTime() >= _beforeDay
      }
    }
  },
  // 今天之前任意12个月
  todayBeforeThreeMonths: {
    onPick: ({ maxDate, minDate }) => {
      choiceDate = minDate.getTime()
      if (maxDate) {
        choiceDate = ''
      }
    },
    disabledDate: (time) => {
      let _beforeDay = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      if (choiceDate) {
        let oneYear = 31104000000 // 一年的时间戳
        const minTime = choiceDate - oneYear
        const maxTime = choiceDate + oneYear
        return time.getTime() >= _beforeDay || time.getTime() < minTime || time.getTime() > maxTime
      } else {
        return time.getTime() >= _beforeDay
      }
    }
  },
  // 前五年
  beforeFiveYears: {
    disabledDate (time) {
      let _FiveYear = new Date(new Date().getTime() - 31104000000 * 5).getFullYear()
      return time.getFullYear() < _FiveYear || time.getFullYear() > new Date().getFullYear()
    }
  }
}

