<template>
  <el-alert v-if="notice" type="warning" :closable="false" class="alert-box">
    <template slot="title">
      <component :is="TextCarousel" v-if="TextCarousel" :notice="notice" />
    </template>
  </el-alert>
</template>

<script>
import { getTopRolling } from '@/api/system/letter/letter'
import store from '@/store'
export default {
  name: 'NoticeAlert',
  props: {
    hasTimer: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({ notice: null, TextCarousel: null, timer: null }),
  watch: {
    notice: {
      // 可监听到对象、数组的变化
      handler (val, oldVal) {
        store.commit('app/SET_TEXTCAROUSEL', val)
      }

    }
  },
  created () {
    this.GetRolling()
    if (this.hasTimer) {
      this.timer = setInterval(() => this.GetRolling(), 60 * 1000)
      window.BeforeUnloadEvent = function () { this.timer = null }
    }
  },
  beforeDestroy () { this.timer = null },
  methods: {
    async GetRolling () {
      const response = await getTopRolling()
      if (response?.data) {
        this.$set(this, 'notice', response.data)
        this.TextCarousel = require('@/components/TextCarousel').default
      }
    }
  }
}
</script>
