var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "child" }, [
    _c("div", { staticClass: "main-title text-o" }, [
      _vm._v(
        " " + _vm._s(_vm.title ? _vm.generateText("m", _vm.title) : "") + " "
      ),
    ]),
    _c(
      "div",
      { staticClass: "child-item" },
      _vm._l(_vm.list, function (route, index) {
        return _c("child-item", {
          key: route.path + index,
          attrs: { item: route, "base-path": _vm.basePath },
          on: { saveRouter: _vm.handleSaveRouter },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }