<template>
  <div class="sidebar-container">
    <One v-if="sideStyle === 'menu1'" class="sidebar-one" />
    <Two v-else-if="sideStyle === 'menu2'" class="sidebar-two" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import One from './One'
import Two from './Two'
export default {
  components: {
    One,
    Two
  },
  computed: {
    ...mapState({
      sideStyle: state => state.settings.sideStyle
    })
  }
}
</script>
