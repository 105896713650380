<template>
  <el-form ref="form" :model="form" :inline="true" @submit.native.prevent>
    <el-form-item v-for="(item, prop) in formItems" :key="prop" :prop="prop">
      <!-- 下拉框配置prop: { ph, selectOpts, selectKey: { label, value }, required, dict } -->
      <el-select
        v-if="item.selectOpts"
        v-model="form[prop]"
        :placeholder="item.ph"
        :clearable="!item.required"
        style="width: 220px"
        @change="handleQuery"
      >
        <el-option
          v-for="opt in item.selectOpts"
          :key="opt[item.dict ? 'dictValue' : item.selectKey?.value || 'value']"
          :label="opt[item.dict ? 'dictLabel' : item.selectKey?.label || 'label']"
          :value="opt[item.dict ? 'dictValue' : item.selectKey?.value || 'value']"
        />
      </el-select>
      <!-- 根据字段位置自定义插槽 -->
      <slot v-else-if="item.slot" :name="prop" :handleQuery="handleQuery" />
      <el-input
        v-else
        v-model="form[prop]"
        :placeholder="item.ph"
        clearable
        :maxlength="item.max"
        suffix-icon="el-icon-search"
        style="width: 220px"
        @keyup.enter.native="handleQuery"
      />
    </el-form-item>
    <el-form-item v-if="hasDate">
      <el-date-picker
        v-model="dateRange"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        type="daterange"
        range-separator="-"
        :picker-options="datePickerOpts || $dValid.todayBefore"
        :start-placeholder="datePlaceholder[0] || $t('dashboard.startDateRange')"
        :end-placeholder="datePlaceholder[1] || $t('dashboard.endDateRange')"
        style="width: 220px"
        @change="dateChange"
      />
    </el-form-item>
    <!-- 额外slot内容（用于设备拓展列） -->
    <slot />
    <el-form-item>
      <el-button type="primary" icon="el-icon-search" @click="handleQuery">
        {{ $t('common.search') }}
      </el-button>
      <el-button icon="el-icon-refresh" @click="resetQuery">
        {{ $t('common.reset') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: 'TableSearch',
  props: {
    formItems: { type: Object, default: () => ({}) },
    form: { type: Object, default: () => ({}) },
    hasDate: { type: Boolean, default: false },
    dateKey: { type: Array, default: () => ['startTime', 'endTime'] },
    datePickerOpts: { type: Object, default: null },
    datePlaceholder: { type: Array, default: () => ['开始日期', '结束日期'] }
  },
  data () {
    return {
      dateRange: []
    }
  },
  methods: {
    dateChange (val) {
      if (val) {
        let form = this.addDateRange(this.form, val, this.dateKey)
        this.$emit('update:form', form)
      } else {
        this.resetDate()
      }
      this.handleQuery()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.form.pageNum = 1
      if (this.$listeners.handleQuery) {
        this.$listeners.handleQuery()
      } else {
        this.$parent.getList()
      }
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.resetForm('form')
      if (this.hasDate) this.resetDate()
      if (this.$listeners.resetQuery) {
        this.$listeners.resetQuery()
      } else {
        this.handleQuery()
      }
    },
    resetDate () {
      this.dateRange = []
      this.form[this.dateKey[0]] = undefined
      this.form[this.dateKey[1]] = undefined
      this.form.offset = undefined
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-form-item__content {
  height: 36px;
}
</style>
