import request from '@/utils/request'

// 登录方法
export function login (userName, password, uuid, systemDomain, random, saas) {
  const data = {
    userName,
    password,
    uuid,
    systemDomain,
    random,
    saas
  }

  return request({
    url: '/sysuser/login/pwdAuth',
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo () {
  return request({
    url: '/sysuser/user/userInfo',
    method: 'get'
  })
}

// 退出方法
export function logout () {
  return request({
    url: '/sysuser/onlineUser/out',
    method: 'get'
  })
}

// 获取dmp授权登录地址
export function oauthDmp (query) {
  return request({
    url: '/sysuser/login/dmpRender',
    method: 'get',
    params: query
  })
}

// DMP授权登录
export function dmpAuth (query) {
  return request({
    url: '/sysuser/login/dmpAuth',
    method: 'get',
    params: query
  })
}

// 根据域名查询OEM
export function getLoginPageConfInfo (query) {
  return request({
    url: '/system/company/conf/getLoginPageConfInfo',
    method: 'get',
    params: query
  })
}

// 确认隐私协议
export function readClause () {
  return request({
    url: '/sysuser/user/readClause',
    method: 'put'
  })
}

// DMP授权注册校验
export function registerCheck (data) {
  return request({
    url: '/sysuser/login/registerCheck',
    method: 'post',
    data: data
  })
}

// DMP授权注册登录
export function registerLogin (data) {
  return request({
    url: '/sysuser/login/registerLogin',
    method: 'post',
    data: data
  })
}

// 刷新token
export function tokenRefresh (query) {
  return request({
    url: '/sysuser/login/refresh/token',
    method: 'get',
    params: query
  })
}

// 检测是否服务升级
export function maintenanceGet (query) {
  return request({
    url: '/maintenance/get',
    method: 'get',
    params: query
  })
}

// 获取未读信息条数
export function getUnReadCount () {
  return request({
    url: '/notice/notice/unread',
    method: 'get'
  })
}

// 发送忘记密码邮件
export function forgetPassword (query) {
  return request({
    url: '/sysuser/biz/user/forgetPassword',
    method: 'get',
    params: query
  })
}

// 用户密码重置
export function resetUserPwd (data) {
  return request({
    url: '/sysuser/biz/user/resetUserPwd',
    method: 'put',
    data
  })
}

// 检测重置链接是否过期
export function checkToken (query) {
  return request({
    url: '/sysuser/biz/user/checkToken',
    method: 'get',
    params: query
  })
}

// 根据用户名判断用户是否存在
export function checkUser (query) {
  return request({
    url: '/sysuser/biz/user/checkUser',
    method: 'get',
    params: query
  })
}

// 用户密码重置
export function resetUserPwdByPhone (data) {
  return request({
    url: '/sysuser/biz/user/resetUserPwd/phone',
    method: 'post',
    data
  })
}

// 发送验证码
export function sendCode (query) {
  return request({
    url: '/sysuser/biz/user/verificationCode/resetPassword',
    params: query
  })
}
