import store from '@/store'
import router from '@/router'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import {checkPermi, hasRoute} from '@/utils/permission'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register', '/oauth', '/loading', '/maintenance', '/forget', '/reset-password']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
    /* has token */
    if (to.path === '/login' || to.path === '/loading' || to.path === '/oauth') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          // 运行中的任务（当前用户创建的且任务状态为进行中的任务）
          store.dispatch('GetTaskRunning')
          store.dispatch('GenerateRoutes')
            .then(accessRoutes => {
              // 根据roles权限生成可访问的路由表
              router.addRoutes(accessRoutes) // 动态添加可访问路由表
              // 没有权限404，点击返回首页到第一个有权限的path
              if (to.path === '/index') {
                let p = store.getters.firstPermRoute
                if (store.getters.saas === 'CSMS') {
                  if (checkPermi(['dashboard:chargeDatav']) && hasRoute('ChargeDatav', accessRoutes)) {
                    p = '/dataStatistics/chargeDatav?init=1'
                  }
                }
                next({ path: p, replace: true })
              } else {
                next({ ...to, replace: true })
              }
            })
            .catch(() => {
              // next({ path: '/401' })
              store.dispatch('LogOut').then(() => {
                Message.error('您没有任何菜单权限！')
                next({ path: '/login' })
              })
            })
        }).catch(err => {
          console.log('catch', err)
          store.dispatch('LogOut').then(() => {
            if (err !== 429) {
              Message.error(err)
            }
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
