var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme1" ||
          _vm.settings.sideTheme === "theme4"
            ? "#07081e"
            : "#fff",
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _c(
            "router-link",
            {
              staticClass: "sidebar-logo-link",
              attrs: { to: { name: _vm.firstRouters } },
            },
            [
              _c("img", {
                staticClass: "sidebar-logo",
                attrs: {
                  src:
                    _vm.settings.sideTheme === "theme1" ||
                    _vm.settings.sideTheme === "theme4"
                      ? _vm.darkLogo
                      : _vm.brightLogo,
                },
              }),
              _c(
                "h1",
                {
                  staticClass: "sidebar-title",
                  style: {
                    color:
                      _vm.settings.sideTheme === "theme1" ||
                      _vm.settings.sideTheme === "theme4"
                        ? "#fff"
                        : "#303133",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }