var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.open,
        title: "列表字段设置",
        width: "700px",
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.open = $event
        },
      },
    },
    [
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "header-cell", attrs: { span: 4 } }, [
            _vm._v("字段排序"),
          ]),
          _c("el-col", { staticClass: "header-cell", attrs: { span: 6 } }, [
            _vm._v("字段显示"),
          ]),
          _c(
            "el-col",
            { staticClass: "header-cell text-left", attrs: { span: 6 } },
            [_vm._v("字段名称")]
          ),
          _c(
            "el-col",
            { staticClass: "header-cell", attrs: { span: 8 } },
            [
              _vm._v(" 字段别称 "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    "popper-class": "q-tooltip",
                    content: "设备列表默认展示字段别称",
                    placement: "top",
                  },
                },
                [_c("i", { staticClass: "el-icon-warning-outline tip-icon" })]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "colSetting" + _vm.tableName } },
        _vm._l(_vm.list, function (row, index) {
          return _c(
            "el-row",
            { key: row.code + index, staticClass: "row" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "row-cell", attrs: { span: 4 } },
                    [_c("i", { staticClass: "sort-icon" })]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "row-cell", attrs: { span: 6 } },
                    [
                      _c("el-switch", {
                        model: {
                          value: row.visible,
                          callback: function ($$v) {
                            _vm.$set(row, "visible", $$v)
                          },
                          expression: "row.visible",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "row-cell text-left",
                      attrs: { span: 6, title: row.name },
                    },
                    [_vm._v(" " + _vm._s(row.name) + " ")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "row-cell", attrs: { span: 8 } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: 20,
                          size: "small",
                          "show-word-limit": "",
                          clearable: "",
                        },
                        model: {
                          value: row.otherName,
                          callback: function ($$v) {
                            _vm.$set(row, "otherName", $$v)
                          },
                          expression: "row.otherName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              row.children
                ? _vm._l(row.children, function (child) {
                    return _c(
                      "el-row",
                      { key: child.code },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "row-cell text-left",
                            attrs: { offset: 10, span: 6, title: child.name },
                          },
                          [_vm._v(" " + _vm._s(child.name) + " ")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "row-cell", attrs: { span: 8 } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: 20,
                                size: "small",
                                "show-word-limit": "",
                                clearable: "",
                              },
                              model: {
                                value: child.otherName,
                                callback: function ($$v) {
                                  _vm.$set(child, "otherName", $$v)
                                },
                                expression: "child.otherName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
            ],
            2
          )
        }),
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "fl",
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.reset },
            },
            [_vm._v(" 恢复默认 ")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " "),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(" " + _vm._s(_vm.$t("common.confirm")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }