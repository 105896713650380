<template>
  <router-link
    class="logo"
    :to="{ name: firstRouters }"
    :style="{
      backgroundColor:
        settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? variables.menuBg : variables.menuLightBg
    }"
  >
    <img :src="settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? darkLogo : brightLogo" />
    <span
      :style="{
        color: settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? '#fff' : '#303133'
      }"
    >
      {{ title }}
    </span>
  </router-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import variables from '@/assets/styles/variables.scss'

export default {
  name: 'SidebarLogo',
  computed: {
    variables () {
      return variables
    },
    sideTheme () {
      return this.$store.state.settings.sideTheme
    },
    ...mapState(['settings']),
    ...mapGetters(['firstRouters'])
  },
  data () {
    return {
      title: JSON.parse(localStorage.getItem('confInfo')).systemName,
      brightLogo: JSON.parse(localStorage.getItem('confInfo')).systemInsideBrightLogoUrl,
      darkLogo: JSON.parse(localStorage.getItem('confInfo')).systemInsideDarkLogoUrl
    }
  },
  methods: {
    handleRouter () {
      this.$store.dispatch('ClearActiveRouter').then(() => {
        this.$emit('handleClick')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  display: flex !important;
  overflow: hidden;
  z-index: 1;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 34%;
  }

  span {
    display: block;
    overflow: hidden;
    width: 90%;
    margin: 10px auto 0;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
