import request from '@/utils/request'

// 批量任务列表接口
export function getList (query) {
  return request({
    url: '/device/bizdevice/import/record/list',
    method: 'get',
    params: query
  })
}
// 批量任务明细
export function getDetail (query) {
  return request({
    url: '/device/bizdevice/import/record/detail',
    method: 'get',
    params: query
  })
}
// 运行中的任务（当前用户创建的且任务状态为进行中的任务）
export function getRunning (query) {
  return request({
    url: '/stream/task/running',
    method: 'get',
    params: query
  })
}
